import { PipelineStepType } from 'src/dto/PipelineStep/PipelineStepType';

import { PipelineStepConfiguration } from 'src/dto/PipelineStep';
import { FileMoverStepConfiguration } from 'src/dto/PipelineStep/FileMoverStep';
import { HttpInboundRetrieverStepConfiguration } from 'src/dto/PipelineStep/HttpInboundRetrieverStep';
import { AzureOutboundBearerStepConfiguration } from 'src/dto/PipelineStep/AzureOutboundBearerStep';
import { HttpOutboundBearerStepConfiguration } from 'src/dto/PipelineStep/HttpOutboundBearerStep';
import { DataStagingStepConfiguration } from 'src/dto/PipelineStep/DataStagingStep/DataStagingStepConfiguration';
import { DataProcessingStepConfiguration } from 'src/dto/PipelineStep/DataProcessingStep';

import { SftpScriptedSessionEvent } from 'src/dto/inbound-retrievers/SftpScriptedSessionEvent';
import { SftpOutboundBearerEvent } from 'src/dto/sftp_outbound_bearer/SftpOutboundBearerEvent';
import { AzureInboundRetrieverStepConfiguration } from 'src/dto/PipelineStep/AzureInboundRetrieverStep';

export const defaultConfigurationByStepIdMap: Record<PipelineStepType, Partial<PipelineStepConfiguration>> = {
  sftp_inbound_retriever: {
    connection_config: {
      port: 22,
      host_name: '',
    },
    file_search_expression: '',
    destination_path: '',
    path_to_file: '/',
    delete_after_download: true,
  } as SftpScriptedSessionEvent,
  http_inbound_retriever: {} as HttpInboundRetrieverStepConfiguration,
  azure_inbound_retriever: {
    file_search_expression: '',
    source_folder: null,
    blob_sas_token: '',
    blob_sas_url: '',
    destination_folder: null,
    delete_after: true,
  } as AzureInboundRetrieverStepConfiguration,
  azure_outbound_bearer: {} as AzureOutboundBearerStepConfiguration,
  sftp_outbound_bearer: {
    connection_config: {
      host_name: '',
      port: 22,
    },
    file_search_expression: '',
    destination_path: '/',
    move_after_upload_to_processed: false,
  } as SftpOutboundBearerEvent,
  http_outbound_bearer: {} as HttpOutboundBearerStepConfiguration,
  data_staging: {
    files_configuration_model: [],
  } as Partial<DataStagingStepConfiguration>,
  data_processing: {} as DataProcessingStepConfiguration,
  files_mover: {
    backup_to_subfolder: false,
    to_folder: null,
    from_folder: null,
    fnmatch_expression: '*.*',
  } as FileMoverStepConfiguration,
};
