import { styled } from '@visualfabriq/vf-ui-kit';

export const CodeBlock = styled('code', ({ $theme }) => ({
  whiteSpace: 'pre',
  fontSize: $theme.typography.MonoParagraphXSmall.fontSize,
  fontFamily: $theme.typography.MonoParagraphXSmall.fontFamily,
  minHeight: '50px',
  maxHeight: '300px',
  overflow: 'auto',
  width: '100%',
  padding: $theme.sizing.scale500,
  backgroundColor: $theme.colors.mono100,
}));
