import { useState, SetStateAction } from 'react';
import { FormControl, Input, useStyletron } from '@visualfabriq/vf-ui-kit';

import * as DataStagingStep from 'src/dto/PipelineStep/DataStagingStep';

type Props = {
  file_type_parameters: DataStagingStep.ExcelFileParameters;
  onUpdate: (file_type_parameters: DataStagingStep.ExcelFileParameters) => void;
};

export function ExcelFileParametersConfig(props: Props) {
  const { file_type_parameters, onUpdate } = props;
  const [_, $theme] = useStyletron();

  const [sheet, setExcelSheet] = useState(file_type_parameters?.sheet || '');

  const handleSheetChange = (event: { target: { value: SetStateAction<string> } }) => {
    setExcelSheet(event.target.value);
    onUpdate({
      ...file_type_parameters,
      sheet: event.target.value,
    } as DataStagingStep.ExcelFileParameters);
  };

  return (
    <FormControl label="Sheet" disabled={false} bottomSpace={$theme.sizing.scale200} required>
      <Input value={sheet} onChange={handleSheetChange} placeholder="Leave empty in case of single sheet" />
    </FormControl>
  );
}
