import { Accordion, NoContent, Panel } from '@visualfabriq/vf-ui-kit';

import { LogTitle } from './LogTitle';
import { LogDetails } from './LogDetails';

import { UsageLogOutputItemModel } from 'src/api-new/bullis';

export function Logs({ logs }: { logs: UsageLogOutputItemModel[] }) {
  if (!logs.length) {
    return <NoContent />;
  }

  return (
    <Accordion>
      {logs.map((log) => (
        <Panel key={log.id} title={<LogTitle log={log} />}>
          <LogDetails log={log} />
        </Panel>
      ))}
    </Accordion>
  );
}
