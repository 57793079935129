import { Skeleton, Wrapper, styled } from '@visualfabriq/vf-ui-kit';

import { Tab } from './Tab';

export type Mode = 'tabs' | 'navigation';

type Props = {
  tabs: { to: string; name: string }[];
  isLoading?: boolean;
  itemTestId?: string;
  mode?: Mode;
  showBorder?: boolean;
};

export function RouterTabs(props: Props) {
  const { tabs, itemTestId, mode = 'tabs', isLoading = false, showBorder = false } = props;

  return (
    <Wrapper direction="column">
      <Tabs>
        {tabs.map((navItem) => {
          return isLoading ? (
            <Skeleton key={navItem.to} width="6rem" height="100%" overrides={skeletonOverrides} />
          ) : (
            <Tab key={navItem.to} testId={itemTestId} mode={mode} to={navItem.to} name={navItem.name} />
          );
        })}
      </Tabs>
      {showBorder && <Border />}
    </Wrapper>
  );
}

const Tabs = styled('div', () => ({
  display: 'flex',
  overflow: 'auto',
  alignItems: 'baseline',
}));

const Border = styled('div', ({ $theme }) => ({
  position: 'relative',
  height: $theme.sizing.scale100,
  minHeight: $theme.sizing.scale100,
  backgroundColor: $theme.borders.border300.borderColor,
  marginTop: `-${$theme.sizing.scale100}`,
}));

const skeletonOverrides: React.ComponentProps<typeof Skeleton>['overrides'] = {
  Root: { style: ({ $theme }) => ({ marginRight: $theme.sizing.scale100 }) },
};
