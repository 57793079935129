export function groupMultipleByField<TItem, TKey extends keyof TItem>(
  items: TItem[],
  groupBy: TKey,
): Map<TItem[TKey], TItem[]> {
  return items.reduce<Map<TItem[TKey], TItem[]>>((map, item) => {
    if (map.has(item[groupBy])) {
      map.get(item[groupBy])!.push(item);
    } else {
      map.set(item[groupBy], [item]);
    }
    return map;
  }, new Map());
}
