import { Checkbox, FormControl, Input } from '@visualfabriq/vf-ui-kit';

import * as DataStagingStep from 'src/dto/PipelineStep/DataStagingStep';

type ToFloatMethodProps = {
  fields: DataStagingStep.ToFloat;
  onUpdate: (fields: DataStagingStep.ToFloat) => void;
};

export function ToFloatMethod(props: ToFloatMethodProps) {
  const { fields, onUpdate } = props;

  return (
    <>
      <FormControl label="Default Value" disabled={false} required>
        <Input
          value={fields.default as unknown as number}
          type="number"
          onChange={(event) =>
            onUpdate({
              ...fields,
              default: Number.parseFloat(event.target.value),
            })
          }
        />
      </FormControl>

      <Checkbox
        checked={fields.force_type}
        onChange={(event) => {
          onUpdate({
            ...fields,
            force_type: !!(event.target as any).checked,
          });
        }}
      >
        Set default in case of invalid value
      </Checkbox>
    </>
  );
}
