import { createContext, ReactNode, useEffect, useState } from 'react';
import { v4 as uuid4 } from 'uuid';

import { useBifrostApi } from 'src/services/useBifrostApi';
import {
  PipelineTemplate,
  PipelineTemplateCreate,
  PipelineTemplateListItem,
  PipelineTemplatesApi,
  PipelineTemplateUpdate,
  VFModule,
  VFModuleCodes,
  VfModulesApi,
} from 'src/api-new/bifrost';

type PipelineTemplatesState = {
  modules: VFModule[];
  templatesListItems: PipelineTemplateListItem[];
  loading: boolean;
  error: string | null;
  api: {
    createTemplate: (template: PipelineTemplateCreate) => Promise<PipelineTemplate | undefined>;
    updateTemplate: (event: {
      id: string;
      templateUpdate: PipelineTemplateUpdate;
    }) => Promise<PipelineTemplate | undefined>;
    refreshTemplates: () => Promise<void>;
    deleteTemplate: (idToDelete: string) => Promise<void>;
    refresh: () => void;
  };
};

export const PipelineTemplatesContext = createContext<PipelineTemplatesState>({} as PipelineTemplatesState);

export function PipelineTemplatesProvider(props: { children: ReactNode; managedModulesCodes: Set<VFModuleCodes> }) {
  const { children, managedModulesCodes } = props;
  const pipelineTemplatesApi = useBifrostApi(PipelineTemplatesApi);
  const vfModulesApi = useBifrostApi(VfModulesApi);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [modules, setModules] = useState<VFModule[]>([]);
  const [templatesListItems, setTemplatesListItems] = useState<PipelineTemplateListItem[]>([]);

  useEffect(() => {
    initialFetch();
  }, [managedModulesCodes]);

  const initialFetch = async () => {
    try {
      setLoading(true);
      let { data: modules } = await vfModulesApi.getVfModules();
      modules = modules.filter((module) => managedModulesCodes.has(module.code));
      modules.sort((module1, module2) => module1.name.localeCompare(module2.name));
      await fetchTemplates(modules.map((module) => module.id));
      setModules(modules);
      setError(null);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchTemplates = async (modulesIds: string[]) => {
    try {
      setLoading(true);
      const { data: templates } = await pipelineTemplatesApi.getPipelineTemplatesShort({
        moduleId: modulesIds,
        limit: 500,
      });
      setTemplatesListItems(templates);
      setError(null);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCreateTemplate = async (template: PipelineTemplateCreate) => {
    try {
      setLoading(true);
      const { data: createdTemplate } = await pipelineTemplatesApi.createPipelineTemplate({
        pipelineTemplateCreate: { ...template, id: uuid4() },
      });
      setTemplatesListItems([...templatesListItems, createdTemplate]);
      setError(null);
      return createdTemplate;
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async ({ id, templateUpdate }: { id: string; templateUpdate: PipelineTemplateUpdate }) => {
    try {
      setLoading(true);
      const { data: updatedTemplate } = await pipelineTemplatesApi.updatePipelineTemplate({
        pipelineTemplateId: id,
        pipelineTemplateUpdate: templateUpdate,
      });
      setTemplatesListItems(
        templatesListItems.map((template) => (template.id === updatedTemplate.id ? updatedTemplate : template)),
      );
      setError(null);
      return updatedTemplate;
    } finally {
      setLoading(false);
    }
  };

  const handleRefresh = async () => {
    fetchTemplates(modules.map((module) => module.id));
  };

  const handleDelete = async (idToDelete: string) => {
    try {
      setLoading(true);
      const deleted = await pipelineTemplatesApi.deletePipelineTemplate({ pipelineTemplateId: idToDelete });
      if (deleted) {
        setTemplatesListItems(templatesListItems.filter((template) => template.id !== idToDelete));
      }
      setError(null);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <PipelineTemplatesContext.Provider
      value={{
        loading,
        error,
        modules,
        templatesListItems,
        api: {
          createTemplate: handleCreateTemplate,
          updateTemplate: handleUpdate,
          refreshTemplates: handleRefresh,
          deleteTemplate: handleDelete,
          refresh: initialFetch,
        },
      }}
    >
      {children}
    </PipelineTemplatesContext.Provider>
  );
}
