import { AuthConfig } from 'src/dto/auth/AuthConfig';
import { buildUrl } from 'src/services/buildUrl';
import { endpointPaths } from '../endpointPaths';
import { EnvVars } from 'src/services/envVars';

export async function getAuthConfig(provider: string): Promise<AuthConfig> {
  const response = await fetch(
    buildUrl({ baseUrl: EnvVars.authBaseUrl, path: `${endpointPaths.auth.auth}/${provider}` }),
  );
  const responseData = await response.json();
  if (!response.ok) {
    throw Error((responseData as { message: string }).message);
  }
  return responseData as AuthConfig;
}
