import { endpointPaths } from '../../endpointPaths';
import { User as AuthUser } from 'src/dto/auth/User';
import { buildUrl } from '../../../services/buildUrl';

import { EnvVars } from '../../../services/envVars';

export async function getSecurityDBUser(getAccessToken: () => Promise<string>): Promise<AuthUser> {
  const accessToken = await getAccessToken();
  const response = await fetch(buildUrl({ baseUrl: EnvVars.authBaseUrl, path: endpointPaths.auth.user }), {
    headers: {
      authorization: accessToken,
    },
  });
  const responseData = await response.json();
  if (!response.ok) {
    throw Error((responseData as { message: string }).message);
  }
  return responseData as AuthUser;
}
