import { useState } from 'react';
import { Wrapper } from '@visualfabriq/vf-ui-kit';
import { useAsyncRetry } from 'react-use';

import { useInstances } from 'src/components/hooks/useInstances';
import { SelectedCount } from 'src/components/atoms/SelectedCount';
import { NoFilesToDisplay } from './components/FilesTable/NoFilesToDisplay';
import { getPathString } from 'src/domain/files/utils/getPathString';
import { ROOT_FOLDER_NAME } from 'src/domain/files/constants';
import { useFilesPaths } from 'src/domain/files/useFilesPaths';
import { useBifrostApi } from 'src/services/useBifrostApi';
import { FilesFlatApi } from 'src/api-new/bifrost';
import { FileObject } from 'src/domain/files/types';
import { ErrorMessage } from 'src/components/organisms/ErrorMessage';
import { FileObjectActions } from './components/FileObjectActions';
import { FilesTable } from './components/FilesTable';
import { FilesNavigationLinks } from './components/FilesNavigationLinks';
import { NameColumnNavLink } from './components/FilesTable/NameColumn';

export function Files() {
  const paths = useFilesPaths();
  const { selectedInstance } = useInstances();
  const filesFlatApi = useBifrostApi(FilesFlatApi);

  const currentDirPath = getPathString(paths);
  const currentDirPathWithRoot = getPathString(paths, true);
  const currentLocation = paths.slice(-1)[0];
  const readOnly = currentLocation === ROOT_FOLDER_NAME;
  const [selectedObjectsIds, setSelectedObjectsIds] = useState(new Set<string>());
  const {
    value: files,
    error,
    retry,
    loading,
  } = useAsyncRetry(async () => {
    const { data: files } = await filesFlatApi.getInstanceFilesFlat({
      instanceId: selectedInstance.id,
      path: currentDirPath,
    });

    return files.map<FileObject>((file) => ({
      ...file,
      id: file.name,
      date_modified: file.date_modified ? new Date(file.date_modified) : new Date(0),
    }));
  }, [currentDirPath]);

  const handleSelect = (tableData: { id: string; selected: boolean }[]) => {
    const selected = tableData.reduce((ids, { id, selected }) => {
      if (selected) {
        ids.add(id);
      }
      return ids;
    }, new Set<string>());
    setSelectedObjectsIds(selected);
  };

  if (error) {
    return (
      <Wrapper direction="column" gap={400}>
        <ErrorMessage message={error.message} onTryAgain={retry} />
      </Wrapper>
    );
  }

  return (
    <Wrapper direction="column" gap={400}>
      <FileObjectActions
        paths={paths}
        instanceId={selectedInstance.id}
        loading={loading}
        readOnly={readOnly}
        fileObjects={files ?? []}
        onRefresh={retry}
        selectedIds={selectedObjectsIds}
        setSelectedObjectsIds={setSelectedObjectsIds}
      />

      <Wrapper alignItems="center" justifyContent="space-between">
        <FilesNavigationLinks paths={paths} />
        {selectedObjectsIds.size >= 1 && <SelectedCount count={selectedObjectsIds.size} />}
      </Wrapper>

      {!loading && files?.length === 0 ? (
        <NoFilesToDisplay rootFolder={currentDirPathWithRoot === ROOT_FOLDER_NAME} />
      ) : (
        <FilesTable
          selectable={!readOnly}
          onSelect={handleSelect}
          fileObjects={files || []}
          loading={loading}
          renderNameColumn={(row) => <NameColumnNavLink row={row} currentDirPath={currentDirPath} />}
        />
      )}
    </Wrapper>
  );
}
