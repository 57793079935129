export enum UserPermission {
  FilesGet = 600,

  EndpointsGet = 610,

  PipelinesGet = 620,
  PipelinesCreate = 621,
  PipelinesDelete = 622,
  PipelinesUpdate = 623,

  FilesReplicatorsGet = 630,

  InstancesGet = 640,

  InsightsGet = 650,

  ManageSubscriptions = 624,
}

export type UserPermissionName = keyof typeof UserPermission;
