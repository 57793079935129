import { getTimeZoneDisplayName } from 'src/utils/date/getTimeZoneDisplayName';
import { FSMState } from 'src/api-new/bifrost';
import { StateDisplayName } from './types';

export const FSM_STATES = Object.values(FSMState) as FSMState[];
export const FINISHED_EXECUTION_STATES = [FSMState.Failed, FSMState.Finished, FSMState.NoAction, FSMState.Stopped];
export const RUNNING_EXECUTION_STATES = FSM_STATES.filter((state) => !FINISHED_EXECUTION_STATES.includes(state));

export const TIME_ZONES_OPTIONS = [
  'America/New_York',
  'America/Chicago',
  'America/Sao_Paulo',
  'Europe/London',
  'Europe/Amsterdam',
  'Europe/Athens',
  'Africa/Johannesburg',
  'Asia/Dubai',
  'Asia/Riyadh',
  'Asia/Singapore',
  'Asia/Tokyo',
].map((tz) => ({
  id: tz,
  label: getTimeZoneDisplayName(tz),
}));
TIME_ZONES_OPTIONS.push({ id: 'GMT', label: 'UTC (GMT)' });

export const EXECUTIONS_STATE_DISPLAY_NAMES = [
  'Processing',
  'Successful',
  'Failed',
  'Started',
  'Retrieving',
  'Sending',
  'Staging',
  'Moving',
  'No Action',
  'Stopped',
  'Stopping',
] as const;
export const FINISHED_EXECUTIONS_STATE_DISPLAY_NAMES: StateDisplayName[] = [
  'Failed',
  'Successful',
  'No Action',
  'Stopped',
];
export const EXECUTING_EXECUTION_STATES_DISPLAY_NAMES = EXECUTIONS_STATE_DISPLAY_NAMES.filter(
  (state) => !FINISHED_EXECUTIONS_STATE_DISPLAY_NAMES.includes(state),
);

export const PIPELINE_STATUSES = ['Enabled', 'Disabled'] as const;
