import { Outlet } from 'react-router-dom';
import { AuthConsumerProvider, UserConsumerProvider } from '@visualfabriq/mfe-utils';

import { EnvVars } from 'src/services/envVars';
import { UserProvider } from './index';

export const StandaloneOrMFE = () => {
  if (EnvVars.isMFE) {
    return (
      <AuthConsumerProvider>
        <UserConsumerProvider>
          <Outlet />
        </UserConsumerProvider>
      </AuthConsumerProvider>
    );
  }

  return (
    <UserProvider>
      <Outlet />
    </UserProvider>
  );
};
