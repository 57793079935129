import { useEffect, useState } from 'react';
import { Button, FormLoadingBox, Wrapper, useSnackbar } from '@visualfabriq/vf-ui-kit';
import { AxiosError } from 'axios';

import { NotificationMode, PipelineNotification, PipelineNotificationsApi } from 'src/api-new/bifrost';
import { useBifrostApi } from 'src/services/useBifrostApi';
import { ModeSelect } from '../ModeSelect';
import { getErrorMessage } from 'src/api-new/bifrost/utils';
import { MutedNotificationsMessage } from './MutedNotificationsMessage';
import { captureException } from 'src/services/sentry';

export function UserNotificationConfig({ pipelineId, muted }: { pipelineId: string; muted: boolean }) {
  const notificationsApi = useBifrostApi(PipelineNotificationsApi);
  const snackbar = useSnackbar();
  const [userNotification, setUserNotification] = useState<PipelineNotification | null>(null);
  const [mode, setMode] = useState<NotificationMode>('always');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getUserNotification(pipelineId);
  }, [pipelineId]);

  useEffect(() => {
    if (userNotification) {
      setMode(userNotification.mode);
    }
  }, [userNotification]);

  const getUserNotification = async (pipelineId: string) => {
    try {
      setLoading(true);
      const { data: userNotification, status } = await notificationsApi.getPipelineNotificationForUserByPipelineId({
        pipelineId,
      });

      if (status === 200) {
        setUserNotification(userNotification);
      }
    } catch (error) {
      if (error instanceof AxiosError && error.response?.status === 404) {
        return;
      }

      captureException(error);
      snackbar.enqueueErrorSnackbar(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async () => {
    if (!userNotification) return;

    try {
      setLoading(true);
      await notificationsApi.updatePipelineNotification({
        pipelineNotificationId: userNotification.id,
        pipelineNotificationUpdate: { mode },
      });
      snackbar.enqueueSuccessSnackbar('Notification settings been updated');
    } catch (error) {
      captureException(error);
      snackbar.enqueueErrorSnackbar(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const handleSubscribe = async () => {
    try {
      setLoading(true);
      const { data: createdUserNotification } = await notificationsApi.createPipelineNotification({
        pipelineNotificationCreate: {
          pipeline_id: pipelineId,
          mode,
        },
      });
      setUserNotification(createdUserNotification);
      snackbar.enqueueSuccessSnackbar("You've been subscribed!");
    } catch (error) {
      captureException(error);
      snackbar.enqueueErrorSnackbar(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const handleUnsubscribe = async () => {
    if (!userNotification) return;

    try {
      setLoading(true);
      await notificationsApi.deletePipelineNotification({ pipelineNotificationId: userNotification.id });
      snackbar.enqueueSuccessSnackbar("You've been unsubscribed");
      setUserNotification(null);
    } catch (error) {
      captureException(error);
      snackbar.enqueueErrorSnackbar(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <FormLoadingBox />;
  }

  return (
    <Wrapper direction="column" marginBlock={200} gap={200}>
      <ModeSelect value={mode} onChange={setMode} />
      {muted && <MutedNotificationsMessage />}
      <Wrapper gap={200}>
        {userNotification ? (
          <>
            <Button onClick={handleUpdate}>Update</Button>
            <div style={{ marginLeft: 'auto' }}>
              <Button kind="secondary" onClick={handleUnsubscribe}>
                Unsubscribe
              </Button>
            </div>
          </>
        ) : (
          <Button onClick={handleSubscribe}>Subscribe</Button>
        )}
      </Wrapper>
    </Wrapper>
  );
}
