import { useStyletron } from '@visualfabriq/vf-ui-kit';
import { NavLink, To } from 'react-router-dom';
import { Mode } from './index';

type Props = {
  mode: Mode;
  to: To;
  name: string;
  testId?: string;
};

export function Tab({ mode, to, testId, name }: Props) {
  const [css, $theme] = useStyletron();
  return (
    <NavLink
      className={({ isActive }) =>
        css({
          display: 'inline-block',
          flexShrink: '0',
          textDecoration: 'none',
          backgroundColor: 'transparent',
          fontSize: $theme.typography.LabelSmall.fontSize,
          color: isActive ? $theme.colors.black : $theme.colors.buttonDisabledText,
          padding:
            mode === 'tabs'
              ? $theme.sizing.scale600
              : `${$theme.sizing.scale600} ${$theme.sizing.scale600} ${$theme.sizing.scale600} 0`,
          ':hover': {
            color: $theme.colors.black,
            backgroundColor: mode === 'tabs' ? $theme.colors.menuFillHover : 'initial',
          },
          ...(mode === 'tabs'
            ? {
                borderBottomWidth: $theme.sizing.scale100,
                borderBottomStyle: 'solid',
                borderBottomColor: isActive ? $theme.colors.borderAccent : 'transparent',
              }
            : {
                fontSize: isActive ? $theme.typography.HeadingMedium.fontSize : $theme.typography.LabelSmall.fontSize,
                fontWeight: isActive ? $theme.typography.HeadingMedium.fontWeight : 'initial',
              }),
        })
      }
      to={to}
    >
      <span data-testid={testId}>{name}</span>
    </NavLink>
  );
}
