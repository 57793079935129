import { useState } from 'react';
import { Button, ContentWrapper, FormControl, Tag, Wrapper } from '@visualfabriq/vf-ui-kit';

import { FileType } from 'src/dto/PipelineStep/DataStagingStep/DataStagingStepConfiguration';
import { splitWithTruncate } from 'src/utils/string/splitWithTruncate';
import * as DataStagingStep from 'src/dto/PipelineStep/DataStagingStep';

import { Form, FormFields } from '../Form';
import { TableTransformations } from '../../TableTransformations';

type Props = {
  fileType: FileType | undefined;
  table: DataStagingStep.SingleTableConfiguration;
  onUpdate: (table: DataStagingStep.SingleTableConfiguration) => void;
  onDelete: () => void;
  onCancel: () => void;
};
export function SingleTableConfiguration(props: Props) {
  const { table: configuration, onUpdate, onDelete, fileType, onCancel } = props;
  const [formFields, setFormFields] = useState<FormFields>({
    key: configuration.key ?? '',
    lowerLevelKey: configuration.lower_level_key ?? '',
    targetCollection: configuration.import_parameters.target_collection,
    fnmatchExpression: configuration.fnmatch_expression ?? '',
    persistMethod: configuration.import_parameters.persist_method,
    updateKeys: configuration.import_parameters!.update_keys.join(', ') || '',
    mandatoryKeys: configuration.import_parameters!.mandatory_keys.join(', ') || '',
    fileType,
    transformations: configuration.import_parameters.transformations ?? [],
  });
  const [isValid, setIsValid] = useState(true);
  const [unsaved, setUnsaved] = useState(false);
  const showKeyField = fileType && [FileType.JSON, FileType.XML].includes(fileType);

  const handleValidChange = (value: boolean) => {
    setIsValid(value);
  };

  const handleTableUpdate = () => {
    onUpdate({
      import_type: configuration.import_type,
      fnmatch_expression: formFields.fnmatchExpression,
      lower_level_key: formFields.lowerLevelKey,
      key: showKeyField && formFields.key ? formFields.key : null,
      import_parameters: {
        ...configuration.import_parameters,
        target_collection: formFields.targetCollection,
        persist_method: formFields.persistMethod,
        update_keys: splitWithTruncate(formFields.updateKeys),
        mandatory_keys: splitWithTruncate(formFields.mandatoryKeys),
        transformations: formFields.transformations,
      },
    });
    setUnsaved(false);
  };

  const handleFormChange = (formState: FormFields) => {
    setFormFields(formState);
    setUnsaved(true);
  };

  const handleTransformationsChange = (transformations: DataStagingStep.TransformationModel[]) => {
    setFormFields({ ...formFields, transformations });
    setUnsaved(true);
  };

  return (
    <Wrapper direction="column" margin={200}>
      <Form value={formFields} onChange={handleFormChange} onValidChange={handleValidChange} />
      <ContentWrapper>
        <FormControl label="Transformations" required>
          <TableTransformations transformations={formFields.transformations} onUpdate={handleTransformationsChange} />
        </FormControl>
      </ContentWrapper>

      <Wrapper gap="200" marginBlock="200">
        <Button disabled={!isValid} onClick={handleTableUpdate}>
          Update Table
        </Button>
        <Button kind="secondary" onClick={onCancel}>
          Cancel
        </Button>
        {unsaved && <Tag color="orange">Unsaved</Tag>}

        <div style={{ marginLeft: 'auto' }}>
          <Button kind="secondary" onClick={onDelete}>
            Delete Table
          </Button>
        </div>
      </Wrapper>
    </Wrapper>
  );
}
