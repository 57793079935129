import { Outlet } from 'react-router-dom';
import { Wrapper } from '@visualfabriq/vf-ui-kit';

import { RouterTabs } from 'src/components/organisms/RouterTabs';

const tabs = [
  { to: 'http', name: 'HTTP Keys' },
  { to: 'sftp', name: 'SFTP Keys' },
];

export function Credentials() {
  return (
    <Wrapper direction="column" gap={300}>
      <RouterTabs tabs={tabs} showBorder />
      <Outlet />
    </Wrapper>
  );
}
