import * as DataStagingStep from 'src/dto/PipelineStep/DataStagingStep';

export function getTransformationMethodDefaultParameters(
  method: DataStagingStep.TransformationParametersMethod,
): DataStagingStep.TransformationParameters {
  switch (method) {
    case DataStagingStep.TransformationParametersMethod.ToDate:
      return {
        columns: '',
        method,
        date_format: '%Y%m%d',
        errors_as_none: false,
      };

    case DataStagingStep.TransformationParametersMethod.ToInt:
      return {
        columns: '',
        method,
        default: 0,
        force_type: true,
      };

    case DataStagingStep.TransformationParametersMethod.ToFloat:
      return {
        columns: '',
        method,
        default: 0,
        force_type: true,
      };

    case DataStagingStep.TransformationParametersMethod.ToBoolean:
      return {
        columns: '',
        method,
        default: true,
        force_type: true,
      };

    case DataStagingStep.TransformationParametersMethod.StripValue:
      return {
        columns: '',
        method,
        to_strip: '',
        how: DataStagingStep.StripValueHow.Both,
      };

    case DataStagingStep.TransformationParametersMethod.RenameColumn:
      return {
        columns: {},
        method,
      };

    case DataStagingStep.TransformationParametersMethod.AddStaticColumn:
      return {
        columns: {},
        method,
      };

    case DataStagingStep.TransformationParametersMethod.ToLowercase:
      return {
        columns: '',
        method,
      };

    case DataStagingStep.TransformationParametersMethod.ReplaceColumnValue:
      return {
        columns: '',
        to_replace: '',
        from_replace: '',
        method,
      };

    case DataStagingStep.TransformationParametersMethod.ReplaceValue:
      return {
        columns: '',
        to_replace: '',
        from_replace: '',
        method,
      };

    case DataStagingStep.TransformationParametersMethod.ConcatenateColumns:
      return {
        columns: '',
        to_column: '',
        concatenator_value: '',
        method,
      };

    case DataStagingStep.TransformationParametersMethod.ValueArithmeticOperation:
      return {
        columns: '',
        operand: 1,
        operator: DataStagingStep.ArithmeticOperators.Mulitply,
        method,
      };

    case DataStagingStep.TransformationParametersMethod.ColumnArithmeticOperation:
      return {
        columns: '',
        to_column: '',
        operator: DataStagingStep.ArithmeticOperators.Mulitply,
        method,
      };
    case DataStagingStep.TransformationParametersMethod.PivotColumnValues:
      return {
        method,
        columns: {},
        keep_columns: false,
      };
  }
}
