import { Breadcrumbs, Button } from '@visualfabriq/vf-ui-kit';

import { breadcrumbsOverrides } from 'src/components/styles';

type Props = {
  paths: string[];
  onLocationChange: (pathId: string) => void;
};

export function FilesNavigation({ paths, onLocationChange }: Props) {
  return (
    <Breadcrumbs overrides={breadcrumbsOverrides}>
      {paths.map((path) => (
        <Button kind="tertiary" key={path} onClick={() => onLocationChange(path)}>
          {path}
        </Button>
      ))}
    </Breadcrumbs>
  );
}
