import { useState } from 'react';
import { useAsyncRetry } from 'react-use';
import { HeadingXSmall, Input, NoContent, Wrapper, useStyletron } from '@visualfabriq/vf-ui-kit';

import { DbCollectionsApi } from 'src/api-new/bifrost';
import { useBifrostApi } from 'src/services/useBifrostApi';
import { useInstances } from 'src/components/hooks/useInstances';
import { usePaginate } from 'src/components/hooks/usePaginate';

import { collectionListItemTestId, collectionListLoadingTestId } from './constants';
import { useResize } from 'src/components/hooks/useResize';
import { Resizer } from 'src/components/atoms/Resizer';
import { List, ListHeader, ListItem, ListPagination } from 'src/components/organisms/List';
import { captureException } from 'src/services/sentry';
import { useDebouncedValue, useSnackbar } from '@visualfabriq/mfe-utils';
import { getErrorMessage } from 'src/api-new/bifrost/utils';

export function Collections() {
  const dbCollectionApi = useBifrostApi(DbCollectionsApi);
  const [, theme] = useStyletron();
  const { selectedInstance } = useInstances();
  const [search, setSearch] = useState('');
  const { value: searchDebounce } = useDebouncedValue(search, 500);
  const snackbar = useSnackbar();

  const collections = useAsyncRetry(async () => {
    try {
      const { data } = await dbCollectionApi.getCollections({
        instanceId: selectedInstance.id,
        nameQuery: search !== '' ? search : undefined,
      });
      setCurrentPage(0);
      return data.map(({ name }) => ({
        id: name,
        name: name,
      }));
    } catch (error) {
      captureException(error);
      snackbar.enqueueErrorSnackbar(getErrorMessage(error));
      return undefined;
    }
  }, [selectedInstance.id, searchDebounce]);
  const { currentPage, paginatedItems, setCurrentPage } = usePaginate({
    items: collections.value ?? [],
    pageSize: PAGE_SIZE,
  });

  const [resizeRef, width] = useResize<HTMLDivElement>({ initialWidth: 250, minWidth: 150, maxWidths: 500 });

  return (
    <Wrapper marginInline={`0 ${theme.sizing.scale800}`}>
      <div style={{ width }}>
        <List
          heading={
            <Wrapper direction="column" gap="200" marginBlock={`0 ${theme.sizing.scale200}`}>
              <ListHeader onRefresh={collections.retry}>
                <HeadingXSmall marginBottom="0">Collections</HeadingXSmall>
              </ListHeader>
              <Input
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search Collection"
                clearable
              />
            </Wrapper>
          }
          loaderTestId={collectionListLoadingTestId}
          loading={collections.loading}
          loadingItems={PAGE_SIZE}
          style={{ height: 'calc(100% - 42px)' }}
        >
          {paginatedItems.length ? (
            paginatedItems.map((item) => <ListItem key={item.id} item={item} testId={collectionListItemTestId} />)
          ) : (
            <NoContent message="No collections available" />
          )}
        </List>
        {collections.value?.length && (
          <ListPagination
            currentPageItemsCount={paginatedItems.length}
            skip={currentPage * PAGE_SIZE}
            onPrev={() => setCurrentPage(currentPage - 1)}
            onNext={() => setCurrentPage(currentPage + 1)}
            size={PAGE_SIZE}
          />
        )}
      </div>
      <Resizer ref={resizeRef} />
    </Wrapper>
  );
}

const PAGE_SIZE = 30;
