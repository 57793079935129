import { DraftableStepConfiguration } from '../../drafts';
import { DataProcessingTemplateForm } from './DataProcessingTemplateForm';
import { DataStagingTemplateForm } from './DataStagingTemplateForm';

type Props = {
  stepConfiguration: DraftableStepConfiguration;
  onChange: (value: DraftableStepConfiguration) => void;
  onApplyChanges: () => void;
  onCancel: () => void;
  onValidChange: (id: string, isValid: boolean) => void;
  onDelete: (id: string) => void;
};

export function TemplateStepForm(props: Props) {
  const { stepConfiguration, onChange, onValidChange, onApplyChanges, onCancel, onDelete } = props;

  switch (stepConfiguration.type) {
    case 'data_processing':
      return (
        <DataProcessingTemplateForm
          stepConfiguration={stepConfiguration}
          onChange={onChange}
          onValidChange={onValidChange}
          onApplyChanges={onApplyChanges}
          onCancel={onCancel}
          onDelete={onDelete}
        />
      );

    case 'data_staging':
      return (
        <DataStagingTemplateForm
          stepConfiguration={stepConfiguration}
          onChange={onChange}
          onValidChange={onValidChange}
          onApplyChanges={onApplyChanges}
          onCancel={onCancel}
          onDelete={onDelete}
        />
      );
  }
}
