import React, { useMemo, useState } from 'react';
import { Input, ModalHeader, ModalBody, ModalFooter, LabelSmall, ModalButton } from '@visualfabriq/vf-ui-kit';

type CreateFolderProps = {
  onCancel: () => void;
  onConfirm: (name: string) => void;
  fObjectNames: Set<string>;
};

export const CreateFolderModal = (props: CreateFolderProps) => {
  const { onCancel, onConfirm, fObjectNames } = props;

  const [inputValue, setInputValue] = useState('');
  const isValid = useMemo(() => !fObjectNames.has(inputValue), [fObjectNames, inputValue]);

  return (
    <>
      <ModalHeader>Please specify folder name:</ModalHeader>
      <ModalBody>
        <Input
          dataTestid="CreateFolder-name-input"
          value={inputValue}
          onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            setInputValue(event.target.value);
          }}
        />
        {!isValid && <LabelSmall>There is already a file object with the same name here!</LabelSmall>}
      </ModalBody>

      <ModalFooter>
        <ModalButton
          data-testid="CreateFolder-confirm-btn"
          onClick={() => {
            onConfirm(inputValue);
          }}
          disabled={!isValid}
        >
          Create
        </ModalButton>
        <ModalButton kind="secondary" onClick={onCancel}>
          Cancel
        </ModalButton>
      </ModalFooter>
    </>
  );
};
