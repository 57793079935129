import { ModalBody, ModalHeader } from '@visualfabriq/vf-ui-kit';
import { JsonContentViewer } from 'src/components/atoms/JsonContentViewer';

export function ConfigContentModal({ content }: { content: string }) {
  return (
    <>
      <ModalHeader>Config Content</ModalHeader>
      <ModalBody>
        <JsonContentViewer content={content} />
      </ModalBody>
    </>
  );
}
