import { endpointPaths } from '../../endpointPaths';
import { makeApiRequest } from 'src/services/makeApiRequest';
import { BadResponse } from 'src/api/types';
import { PipelineStep } from 'src/dto/PipelineStep';

export async function deleteStep(id: string, getAccessToken: () => Promise<string>) {
  const response = await makeApiRequest({
    path: `${endpointPaths.pipelinesSteps}/${id}`,
    method: 'DELETE',
    getAccessToken,
  });

  const data = await response.json();

  if (!response.ok) {
    throw new Error((data as BadResponse).detail);
  }

  return data as PipelineStep;
}
