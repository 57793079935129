import { useMemo } from 'react';

import { VFModuleCodes } from 'src/api-new/bifrost';
import { UserPermission } from 'src/domain/permissions/types';
import { PAGES_CONFIG, Pages } from 'src/pages/config';
import { pathConstants } from 'src/routes';

export function useUserPermittedTabs({
  permissions,
  instanceId,
  managedModules,
}: {
  permissions: Set<UserPermission>;
  instanceId: string | undefined;
  managedModules: Set<VFModuleCodes>;
}) {
  return useMemo(() => {
    const tabsOrdered: Pages[] = [
      'welcome',
      'instances',
      'files',
      'endpoints',
      'pipelines',
      'pipelineTemplates',
      'replicator',
      'insights',
      'dashboard',
    ];

    return instanceId
      ? tabsOrdered
          .map((tab) => PAGES_CONFIG[tab])
          .filter((tab) => tab.hasAccess({ permissions, managedModules }))
          .map((navItem) => ({
            to: `${pathConstants.ROOT}/${instanceId}/${navItem.to}`,
            name: navItem.name,
          }))
      : [];
  }, [instanceId, permissions, managedModules]);
}
