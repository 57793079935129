import { Tag } from '@visualfabriq/vf-ui-kit';

type Props = {
  isDraft: boolean;
  label: string;
  isUnsaved: boolean;
};

export function Heading(props: Props) {
  const { isDraft, label, isUnsaved } = props;

  return (
    <span>
      {label}

      {isDraft && (
        <Tag size="small" color="orange">
          Draft
        </Tag>
      )}

      {isUnsaved && !isDraft && (
        <Tag size="small" color="blue">
          Unsaved
        </Tag>
      )}
    </span>
  );
}
