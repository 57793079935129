import { styled } from '@visualfabriq/vf-ui-kit';

export const Card = styled<'div', { disabled?: boolean }>('div', ({ $theme, disabled = false }) => {
  const border = $theme.borders.border300;
  const hoverBorder = $theme.borders.border600;

  return {
    padding: $theme.sizing.scale600,
    minWidth: '100px',
    maxWidth: '300px',
    minHeight: '30px',
    borderRadius: $theme.borders.radius300,
    border: `${border.borderWidth} ${border.borderStyle} ${border.borderColor}`,
    cursor: disabled ? 'not-allowed' : 'pointer',
    ':hover': disabled
      ? {}
      : {
          border: `${hoverBorder.borderWidth} ${hoverBorder.borderStyle} ${$theme.colors.accent200}`,
        },
  };
});
