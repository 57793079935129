const date = new Date();

export function getTimeZoneDisplayName(timeZone: string) {
  const longGeneric = getTimeZoneName(timeZone, 'longGeneric');
  const shortOffset = getTimeZoneName(timeZone, 'shortOffset');

  return `${timeZone} - ${longGeneric} (${shortOffset})`;
}

export function getTimeZoneName(
  timeZone: string,
  timeZoneName: 'short' | 'long' | 'shortOffset' | 'longOffset' | 'shortGeneric' | 'longGeneric',
) {
  return new Intl.DateTimeFormat(undefined, { timeZoneName, timeZone }).format(date).split(', ')[1];
}
