import { HeadingLarge, ParagraphLarge, styled, Wrapper } from '@visualfabriq/vf-ui-kit';

import welcomeImage from './bifrost.png';
import { Card, CardLabel } from 'src/components/atoms/Card';
import { EnvVars } from 'src/services/envVars';

export function Welcome() {
  return (
    <Wrapper direction="column" alignItems="center" margin={400} gap={300}>
      <img alt="Welcome" src={welcomeImage} width="200px" loading="lazy" title={EnvVars.version} />
      <HeadingLarge>Welcome to Bifrost</HeadingLarge>
      <Wrapper justifyContent="center" gap={800} margin={400}>
        <Link
          href="https://visualfabriq.zendesk.com/hc/en-us/categories/6740259543964-Bifrost"
          target="_blank"
          rel="noreferrer"
        >
          <Card>
            <CardLabel>Get started</CardLabel>
            <ParagraphLarge>
              Take your first steps in the world of Bifrost based on the steps described in our user manual.
            </ParagraphLarge>
          </Card>
        </Link>
        <Card disabled>
          <CardLabel>Walkthrough</CardLabel>
          <ParagraphLarge kind="secondary">
            SOON TO COME - Follow our walkthough Wizard to quickly setup your first Instance!
          </ParagraphLarge>
        </Card>
        <Card disabled>
          <CardLabel>Analytics</CardLabel>
          <ParagraphLarge kind="secondary">
            SOON TO COME - Dive into the world of data & analytics. See what data is flowing through the system, analyse
            and extract to your heart&apos;s content
          </ParagraphLarge>
        </Card>
      </Wrapper>
    </Wrapper>
  );
}

const Link = styled('a', () => ({
  display: 'flex',
  textDecoration: 'none',
}));
