import { UserPermission } from 'src/domain/permissions/types';
import { pathConstants } from 'src/routes';

export type Pages =
  | 'welcome'
  | 'instances'
  | 'files'
  | 'endpoints'
  | 'pipelines'
  | 'pipelineTemplates'
  | 'replicator'
  | 'insights'
  | 'dashboard';

export type HasAccessPredicate = (args: { permissions: Set<UserPermission>; managedModules: Set<string> }) => boolean;

export type Config = {
  to: string;
  name: string;
  hasAccess: HasAccessPredicate;
};

export const PAGES_CONFIG: Record<Pages, Config> = {
  welcome: { name: 'Welcome', to: 'welcome', hasAccess: () => true },
  instances: {
    name: 'Instances',
    to: pathConstants.INSTANCES,
    hasAccess: ({ permissions }) => permissions.has(UserPermission.InstancesGet),
  },
  files: {
    name: 'Files',
    to: pathConstants.FILES,
    hasAccess: ({ permissions }) => permissions.has(UserPermission.FilesGet),
  },
  endpoints: {
    name: 'Endpoints',
    to: pathConstants.ENDPOINTS.root,
    hasAccess: ({ permissions }) => permissions.has(UserPermission.EndpointsGet),
  },
  pipelines: {
    name: 'Pipelines',
    to: pathConstants.PIPELINES.root,
    hasAccess: ({ permissions }) => permissions.has(UserPermission.PipelinesGet),
  },
  pipelineTemplates: {
    name: 'Pipeline Templates',
    to: pathConstants.PIPELINE_TEMPLATES,
    hasAccess: ({ managedModules }) => managedModules.size > 0,
  },
  replicator: {
    name: 'Replicator',
    to: pathConstants.REPLICATOR,
    hasAccess: ({ permissions }) => permissions.has(UserPermission.FilesReplicatorsGet),
  },
  insights: {
    name: 'Insights',
    to: pathConstants.INSIGHTS.root,
    hasAccess: ({ permissions }) => permissions.has(UserPermission.InsightsGet),
  },
  dashboard: {
    name: 'Dashboard',
    to: pathConstants.DASHBOARD.root,
    hasAccess: ({ permissions }) => permissions.has(UserPermission.PipelinesGet),
  },
};
