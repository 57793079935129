import { useContext } from 'react';
import { AddIcon, Button, useSnackbar, LabelLarge, Tooltip, Wrapper } from '@visualfabriq/vf-ui-kit';
import { useAsyncRetry } from 'react-use';

import { FilesTable } from '../FilesTable';
import { useBifrostApi } from 'src/services/useBifrostApi';
import { FilesApi, FilesFlatApi, S3ObjectKind } from 'src/api-new/bifrost';
import { useInstances } from 'src/components/hooks/useInstances';
import { CreateFolderModal } from '../CreateFolderModal';
import { ModalContext } from 'src/components/molecules/Modal/ModalProvider';
import { NoFilesToDisplay } from '../FilesTable/NoFilesToDisplay';
import { getPathString } from 'src/domain/files/utils/getPathString';
import { ROOT_FOLDER_NAME } from 'src/domain/files/constants';
import { FileObject } from 'src/domain/files/types';
import { useFileNavigation } from 'src/domain/files/useFilesNavigation';
import { FileObjectList } from 'src/components/molecules/FileObjectList';
import { FilesNavigation } from '../FilesNavigation';
import { NameColumnButton } from '../FilesTable/NameColumn';
import { captureException } from 'src/services/sentry';

type Props = {
  onCancel: () => void;
  onMove: (path: string) => void;
  initialPaths: string[];
  movingObjects: FileObject[];
};

export const MoveDetails = (props: Props) => {
  const { onCancel, onMove, initialPaths, movingObjects } = props;
  const { selectedInstance } = useInstances();
  const { openModal, closeModal } = useContext(ModalContext);
  const snackbar = useSnackbar();
  const filesFlatApi = useBifrostApi(FilesFlatApi);
  const filesApi = useBifrostApi(FilesApi);
  const { paths, navigateFolderNext, navigateFolderBack } = useFileNavigation({ initialPaths });

  const currentDirPathWithRoot = getPathString(paths, true);
  const originalDirPathWithRoot = getPathString(initialPaths, true);
  const isSameDir = currentDirPathWithRoot === originalDirPathWithRoot;

  const {
    value: files,
    loading,
    retry: refresh,
  } = useAsyncRetry(async () => {
    const { data: s3Objects } = await filesFlatApi.getInstanceFilesFlat({
      instanceId: selectedInstance.id,
      path: getPathString(paths),
    });

    return s3Objects.map(
      (file) =>
        ({
          ...file,
          id: file.name,
          date_modified: file.date_modified ? new Date(file.date_modified) : new Date(0),
        }) as FileObject,
    );
  }, [paths]);

  const handleMove = () => {
    return onMove(currentDirPathWithRoot);
  };

  const handleOpenFolder = (id: string) => {
    const fObject = files?.find((fObject) => fObject.id === id);
    if (!fObject) return;
    navigateFolderNext(fObject.name);
  };

  const handleCreateFolder = async () => {
    openModal(
      <CreateFolderModal
        onConfirm={async (folderName) => {
          try {
            const newPath = `${currentDirPathWithRoot}/${folderName}`;
            await filesApi.uploadFilesForInstance({
              instanceId: selectedInstance.id,
              s3UploadObject: [{ path: { new: newPath }, kind: S3ObjectKind.Folder }],
            });
            refresh();
          } catch (error) {
            captureException(error);
            snackbar.enqueueErrorSnackbar(error.message);
          } finally {
            closeModal();
          }
        }}
        onCancel={closeModal}
        fObjectNames={new Set(files?.map((f) => f.name))}
      />,
    );
  };

  return (
    <Wrapper data-testid="MoveDetails" direction="column" gap={200} margin={200} width="700px">
      <FilesNavigation paths={paths} onLocationChange={navigateFolderBack} />
      {!loading && !files?.length ? (
        <NoFilesToDisplay rootFolder={paths.length === 1} />
      ) : (
        <FilesTable
          onSelect={() => {
            // do nothing
          }}
          renderNameColumn={(row) => <NameColumnButton row={row} onClick={handleOpenFolder} />}
          fileObjects={files ?? []}
          loading={loading}
          foldersOnTop
          hideSelectColumn
        />
      )}

      <LabelLarge marginBottom="0">Files to be moved</LabelLarge>
      <FileObjectList files={movingObjects} />

      <Wrapper gap="200" justifyContent="flex-end">
        <div style={{ marginRight: 'auto' }}>
          <Button
            disabled={currentDirPathWithRoot === ROOT_FOLDER_NAME}
            startEnhancer={<AddIcon />}
            onClick={handleCreateFolder}
          >
            Create Folder
          </Button>
        </div>

        <Tooltip content={isSameDir ? 'You cannot move a file to the folder it is already in' : ''}>
          <Button onClick={handleMove} disabled={isSameDir}>
            Move
          </Button>
        </Tooltip>

        <Button kind="secondary" onClick={onCancel}>
          Cancel
        </Button>
      </Wrapper>
    </Wrapper>
  );
};
