import { Button, CloseIcon, FileIcon, FolderIcon, LabelMedium, Wrapper, useStyletron } from '@visualfabriq/vf-ui-kit';
import { ComponentProps } from 'react';
import { S3ObjectKind } from 'src/api-new/bifrost';
import { textTruncate } from 'src/components/styles';

export function FileObject({
  file,
  onObjectClick,
}: {
  file: { name: string; id: string; kind: S3ObjectKind };
  onObjectClick?: (fileName: string) => void;
}) {
  const [_, theme] = useStyletron();

  return (
    <Wrapper gap={100} alignItems="flex-start">
      {file.kind === 'File' ? <FileIcon /> : <FolderIcon color={theme.colors.primary400} />}

      <LabelMedium marginBottom={theme.sizing.scale100} overrides={labelMediumOverrides}>
        {file.name}
      </LabelMedium>

      {onObjectClick && (
        <Button kind="tertiary" size="mini" startEnhancer={CloseIcon} onClick={() => onObjectClick(file.name)} />
      )}
    </Wrapper>
  );
}

const labelMediumOverrides: ComponentProps<typeof LabelMedium>['overrides'] = {
  Block: {
    style: {
      ...textTruncate,
      flex: 1,
    },
  },
};
