import { LabelSmall, LabelLarge, styled } from '@visualfabriq/vf-ui-kit';

import { PipelineStep } from 'src/dto/PipelineStep';
import { STEP_NAME_BY_ID } from 'src/containers/Pipelines/components/constants';
import { textBlockOverrides } from 'src/components/styles';

export function Step(props: { step: PipelineStep }) {
  const { step } = props;

  return (
    <StepWrapper>
      <StepName>
        <LabelLarge overrides={textBlockOverrides}>{step.name}</LabelLarge>
        <LabelSmall overrides={textBlockOverrides} kind="tertiary">
          {STEP_NAME_BY_ID[step.type]}
        </LabelSmall>
      </StepName>
      <LabelSmall kind="secondary">{step.description}</LabelSmall>
    </StepWrapper>
  );
}

const StepWrapper = styled('div', ({ $theme }) => {
  return {
    display: 'flex',
    padding: `${$theme.sizing.scale200} `,
    flexDirection: 'column',
    gap: $theme.sizing.scale200,
  };
});

const StepName = styled('div', ({ $theme }) => {
  return {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    gap: $theme.sizing.scale200,
    alignItems: 'baseline',
  };
});
