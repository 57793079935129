import { BadResponse } from 'src/api/types';
import { PipelineStep } from 'src/dto/PipelineStep';

import { makeApiRequest } from 'src/services/makeApiRequest';

import { endpointPaths } from '../../endpointPaths';

export const getPipelineStep = async (stepId: string, getAccessToken: () => Promise<string>): Promise<PipelineStep> => {
  const response = await makeApiRequest({
    path: `${endpointPaths.pipelinesSteps}/${stepId}`,
    getAccessToken,
  });

  const data = await response.json();
  if (response.ok) {
    return data as PipelineStep;
  } else {
    throw new Error((data as BadResponse).detail);
  }
};
