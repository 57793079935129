import React, { useState } from 'react';
import { Modal } from '@visualfabriq/vf-ui-kit';
import { SIZE } from '@visualfabriq/vf-ui-kit/dist/components/atoms/surface/modal/types';

type ModalConfig = {
  closeable?: boolean;
  size?: keyof typeof SIZE;
};

type ModalContextValue = {
  openModal: (content: React.ReactNode, config?: ModalConfig) => void;
  closeModal: () => void;
};

export const ModalContext = React.createContext<ModalContextValue>({
  openModal: () => {
    // do nothing
  },
  closeModal: () => {
    // do nothing
  },
});

type ModalProviderProps = {
  children: React.ReactNode;
};

const ModalProvider = (props: ModalProviderProps) => {
  const { children } = props;

  const [modalState, setModalState] = useState<{ content: React.ReactNode | null; config: ModalConfig }>({
    content: null,
    config: {},
  });

  const openModal = (content: React.ReactNode, config: ModalConfig = {}) => {
    setModalState({ content, config });
  };

  const closeModal = () => {
    setModalState({ content: null, config: {} });
  };

  return (
    <ModalContext.Provider value={{ openModal, closeModal }}>
      {children}
      <Modal
        size={modalState.config.size ?? 'default'}
        isOpen={!!modalState.content}
        onClose={closeModal}
        closeable={modalState.config.closeable ?? false}
      >
        {modalState.content}
      </Modal>
    </ModalContext.Provider>
  );
};

export default ModalProvider;
