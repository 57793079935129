import { Button } from '@visualfabriq/vf-ui-kit';
import { Link } from 'react-router-dom';

import { S3ObjectKind } from 'src/api-new/bifrost';
import { FileTableData } from '../index';
import { nameButtonOverrides, useFileObjectIcon } from './shared';

export function NameColumnNavLink({ row, currentDirPath }: { row: FileTableData; currentDirPath: string }) {
  const isFolder = row.type === S3ObjectKind.Folder;
  const icon = useFileObjectIcon(row.type);

  if (isFolder) {
    return (
      <Link to={currentDirPath ? `${currentDirPath}/${row.name}` : row.name}>
        <Button startEnhancer={icon} kind="tertiary">
          {row.name}
        </Button>
      </Link>
    );
  }
  return (
    <Button startEnhancer={icon} disabled kind="tertiary" overrides={nameButtonOverrides}>
      {row.name}
    </Button>
  );
}
