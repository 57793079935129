import { CopyIcon, LabelSmall, Wrapper, useStyletron } from '@visualfabriq/vf-ui-kit';
import { IconButton } from 'src/components/molecules/IconButton';

export function ContentViewerHeader({ displayName, onCopy }: { displayName: string; onCopy: () => void }) {
  const [css, theme] = useStyletron();

  return (
    <Wrapper
      alignItems="center"
      padding={300}
      className={css({
        backgroundColor: theme.colors.mono100,
        border: `${theme.borders.border400.borderWidth} ${theme.borders.border400.borderStyle} ${theme.borders.border400.borderColor}`,
        borderTopLeftRadius: theme.borders.radius200,
        borderTopRightRadius: theme.borders.radius200,
      })}
    >
      <LabelSmall marginBottom="0" color={theme.colors.accent700}>
        {displayName}
      </LabelSmall>
      <div style={{ marginLeft: 'auto' }}>
        <IconButton
          kind="tertiary"
          icon={<CopyIcon />}
          title="Copy document content"
          onClick={onCopy}
          placement="bottom"
        />
      </div>
    </Wrapper>
  );
}
