import { ModalFooter, ModalHeader, ModalButton } from '@visualfabriq/vf-ui-kit';

type Props = {
  onConfirm: () => void;
  onCancel: () => void;
  question: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
};

export function ConfirmModal(props: Props) {
  const { onConfirm, onCancel, confirmButtonText = 'Ok', cancelButtonText = 'Cancel', question } = props;
  return (
    <>
      <ModalHeader data-testid="Confirm-question">{question}</ModalHeader>
      <ModalFooter>
        <ModalButton data-testid="Confirm-confirm-btn" onClick={onConfirm}>
          {confirmButtonText}
        </ModalButton>
        <ModalButton data-testid="Confirm-cancel-btn" kind="secondary" onClick={onCancel}>
          {cancelButtonText}
        </ModalButton>
      </ModalFooter>
    </>
  );
}
