import { FormControl, Input, Select } from '@visualfabriq/vf-ui-kit';

import * as DataStagingStep from 'src/dto/PipelineStep/DataStagingStep';

type Props = {
  fields: DataStagingStep.ColumnArithmeticOperation;
  onUpdate: (fields: DataStagingStep.ColumnArithmeticOperation) => void;
};

export function ColumnArithmeticOperationMethod(props: Props) {
  const { fields, onUpdate } = props;

  return (
    <>
      <FormControl label="Operator" disabled={false} required>
        <Select
          options={Array.from(Object.values(DataStagingStep.ArithmeticOperators)).map((type) => ({
            id: type,
            label: type,
          }))}
          value={[{ id: fields.operator }]}
          onChange={(event) => {
            if (event.type !== 'select') return;
            onUpdate({
              ...fields,
              operator: event.value[0].id as DataStagingStep.ArithmeticOperators,
            });
          }}
        />
      </FormControl>

      <FormControl label="To Column" disabled={false} required>
        <Input
          value={fields.to_column as unknown as string}
          placeholder="Enter the column where the result will be stored"
          onChange={(event) =>
            onUpdate({
              ...fields,
              to_column: event.target.value as unknown as string,
            })
          }
        />
      </FormControl>
    </>
  );
}
