import { LabelMedium, Notification, Wrapper } from '@visualfabriq/vf-ui-kit';

import { pathConstants } from 'src/routes';

export function AuthProviderHelp({ testId }: { testId?: string }) {
  const exampleURL = `${window.location.protocol}//${window.location.host}/${pathConstants.SSO}/visualfabriq`;

  return (
    <Wrapper height="100vh" alignItems="center" justifyContent="center" data-testid={testId}>
      <LabelMedium>
        <Notification kind="warning" title="" description="" closeable={false}>
          <Wrapper direction="column">
            <LabelMedium>Provider is missing</LabelMedium>
            <LabelMedium>Please specify a provider</LabelMedium>
            <LabelMedium>
              e.g.
              <code>
                <a target="_self" href={exampleURL}>
                  {exampleURL}
                </a>
              </code>
            </LabelMedium>
          </Wrapper>
        </Notification>
      </LabelMedium>
    </Wrapper>
  );
}
