import { useContext, useMemo, useRef } from 'react';
import {
  AddIcon,
  Button,
  DeleteIcon,
  DURATION,
  EditIcon,
  RefreshIcon,
  useSnackbar,
  Wrapper,
} from '@visualfabriq/vf-ui-kit';

import { ModalContext } from 'src/components/molecules/Modal/ModalProvider';
import { downloadFile } from 'src/utils/files/downloadFile';
import { CreateFolderModal } from '../CreateFolderModal';
import { UploadLimitMessageModal } from '../UploadLimitMessageModal';
import { MoveDetails } from '../MoveDetails';
import { UploadingProgressModal } from '../UploadingProgressModal';
import RenameFileObjectModal from '../RenameFileObject';

import { DeleteFileObjectModal } from '../DeleteFileObjectModal';
import { UploadModal } from '../UploadModal';
import { useBifrostApi } from 'src/services/useBifrostApi';
import { FilesApi, S3ObjectKind, S3ObjectPath } from 'src/api-new/bifrost';
import { useAuthorizedAxios } from 'src/services/AuthorizedAxios/useAuthorizedAxios';
import { FileObject } from 'src/domain/files/types';
import { FilesService } from 'src/services/FilesService';
import { getPathString } from 'src/domain/files/utils/getPathString';
import { useDrawer } from 'src/components/molecules/Drawer/useDrawer';
import { captureException } from 'src/services/sentry';

const NO_BATCH_FILE_SIZE = 104857600; // 1000mb
const UPLOAD_LIMIT_BYTES = 5368709120; // 5GB

type Props = {
  fileObjects: FileObject[];
  paths: string[];
  instanceId: string;
  loading: boolean;
  readOnly: boolean;
  onRefresh: () => void;
  selectedIds: Set<string>;
  setSelectedObjectsIds: (ids: Set<string>) => void;
};

export function FileObjectActions(props: Props) {
  const { paths, instanceId, loading, readOnly, fileObjects, onRefresh, selectedIds, setSelectedObjectsIds } = props;
  const { openModal, closeModal } = useContext(ModalContext);
  const { openDrawer, closeDrawer } = useDrawer();
  const snackbar = useSnackbar();
  const progressState = useRef<{ [key: string]: number }>({});
  const pathWithRoot = getPathString(paths, true);
  const fileObjectMap = useMemo(() => {
    return new Map(
      fileObjects.map((file) => {
        return [
          file.name,
          {
            ...file,
            id: file.name,
            date_modified: file.date_modified ? new Date(file.date_modified) : new Date(0),
          } as FileObject,
        ];
      }),
    );
  }, [fileObjects]);
  const canDownloadFiles = useMemo(
    () =>
      !!selectedIds.size &&
      Array.from(selectedIds.values(), (id) => fileObjectMap.get(id)).every((fObject) => fObject?.kind === 'File'),
    [selectedIds, fileObjectMap],
  );
  const authorizedAxios = useAuthorizedAxios();
  const filesApi = useBifrostApi(FilesApi);
  const filesService = useMemo(() => new FilesService(authorizedAxios), [authorizedAxios]);

  const handleCreateFolder = async () => {
    openModal(
      <CreateFolderModal
        onConfirm={async (folderName) => {
          try {
            const newPath = `${pathWithRoot}/${folderName}`;
            await filesApi.uploadFilesForInstance({
              instanceId,
              s3UploadObject: [{ path: { new: newPath }, kind: S3ObjectKind.Folder }],
            });
            onRefresh();
          } catch (error) {
            captureException(error);
            snackbar.enqueueErrorSnackbar(error.message, DURATION.infinite);
          } finally {
            closeModal();
          }
        }}
        onCancel={closeModal}
        fObjectNames={new Set(fileObjectMap.keys())}
      />,
    );
  };

  function handleUploadProgress({ name, progress }) {
    progressState.current = { ...progressState.current, [name]: progress };
    openModal(<UploadingProgressModal uploadingProcess={progressState.current} />, { closeable: false, size: 'auto' });
  }

  const handleFilesUpload = async (selectedFiles: File[]) => {
    if (!selectedFiles.length) {
      return;
    }

    const exceedLimitFiles = Array.from(selectedFiles).filter((file) => file.size >= UPLOAD_LIMIT_BYTES);
    if (exceedLimitFiles.length) {
      openModal(<UploadLimitMessageModal exceedLimitFiles={exceedLimitFiles} limitInBytes={UPLOAD_LIMIT_BYTES} />);
      return;
    }

    progressState.current = {};
    try {
      await Promise.all(
        Array.from(selectedFiles, (file) => {
          const path = `${pathWithRoot}/${file.name}`;
          if (file.size > NO_BATCH_FILE_SIZE) {
            return filesService.uploadMultipartFile({ file, path, instanceId, onProgressUpdate: handleUploadProgress });
          } else {
            return filesService.uploadFile({ file, path, instanceId, onProgressUpdate: handleUploadProgress });
          }
        }),
      );
      snackbar.enqueueSuccessSnackbar('Successfully uploaded file(s)!');
      setTimeout(() => {
        closeModal();
      }, 1000);
      onRefresh();
    } catch (error) {
      captureException(error);
      snackbar.enqueueErrorSnackbar(error.message);
      return;
    } finally {
      closeModal();
    }
  };

  const handleRename = async () => {
    const selectedFile = fileObjectMap.get(selectedIds.values().next().value);

    if (!selectedFile) {
      return;
    }

    openModal(
      <RenameFileObjectModal
        oldName={selectedFile.name}
        onCancel={closeModal}
        fObjectNames={new Set(fileObjectMap.keys())}
        onConfirm={async (newName) => {
          try {
            await filesApi.moveFilesForInstance({
              instanceId,
              s3ObjectPath: [
                {
                  path: {
                    old: `${pathWithRoot}/${selectedFile.name}`,
                    new: `${pathWithRoot}/${newName}`,
                  },
                  kind: selectedFile.kind,
                },
              ],
            });

            selectedIds.delete(selectedFile.id);

            onRefresh();
          } catch (error) {
            snackbar.enqueueErrorSnackbar(error.message);
            captureException(error);
          } finally {
            closeModal();
          }
        }}
      />,
    );
  };

  const handleDelete = async () => {
    const handleDelete = async () => {
      closeModal();
      snackbar.enqueueInfoSnackbar('Deleting...');

      try {
        await filesApi.deleteFilesForInstance({
          instanceId,
          s3DeleteObject: filesObjectsToRemove.map((fObject) => ({
            path: { old: `${pathWithRoot}/${fObject.name}` },
            kind: fObject.kind,
          })),
        });
        setSelectedObjectsIds(new Set());
      } catch (error) {
        captureException(error);
        snackbar.enqueueErrorSnackbar(error?.message);
        return;
      }
      snackbar.enqueueSuccessSnackbar('Successfully deleted!');
      onRefresh();
    };

    const filesObjectsToRemove = Array.from(selectedIds, (id) => fileObjectMap.get(id)!);

    openModal(
      <DeleteFileObjectModal
        filesObjectsToRemove={filesObjectsToRemove}
        onConfirm={handleDelete}
        onCancel={closeModal}
      />,
    );
  };

  const handleMove = () => {
    const movingObjects = Array.from(selectedIds, (id) => fileObjectMap.get(id)!);

    openDrawer(
      <MoveDetails
        initialPaths={paths}
        onMove={async (newPathWithRoot) => {
          try {
            const files = movingObjects.map<S3ObjectPath>((fileObject) => {
              return {
                path: {
                  old: `${pathWithRoot}/${fileObject.name}`,
                  new: `${newPathWithRoot}/${fileObject.name}`,
                },
                kind: fileObject.kind,
              };
            });

            snackbar.enqueueInfoSnackbar(`Moving ${files.length} file(s)`);

            const { data } = await filesApi.moveFilesForInstance({ instanceId, s3ObjectPath: files });

            if (data.failed_to_move?.length > 0) {
              throw new Error(`Failed to move the following file(s): ${(data.failed_to_move as string[]).join(', ')}`);
            }

            snackbar.enqueueSuccessSnackbar(`File(s) has been moved successfully!`, DURATION.long);
            setSelectedObjectsIds(new Set());
            onRefresh();
          } catch (error) {
            captureException(error);
            snackbar.enqueueErrorSnackbar(error?.message, DURATION.infinite);
          } finally {
            closeDrawer();
          }
        }}
        onCancel={closeDrawer}
        movingObjects={movingObjects}
      />,
      { title: 'Moving files to:', size: 'auto' },
    );
  };

  const handleDownloadFiles = async () => {
    try {
      snackbar.enqueueInfoSnackbar('Preparing download');
      for await (const id of selectedIds.values()) {
        const file = fileObjectMap.get(id)!;
        const {
          data: { url },
        } = await filesApi.downloadFileForInstance({ instanceId, path: `${pathWithRoot}/${file.name}` });
        await downloadFile(url, file.name);
      }
      snackbar.dequeue();
      snackbar.enqueueInfoSnackbar('Download ready. Please check download panel.');
    } catch (error) {
      captureException(error);
      snackbar.enqueueErrorSnackbar(error.message);
    }
  };

  return (
    <Wrapper gap={200}>
      <Button
        kind="primary"
        onClick={handleCreateFolder}
        disabled={readOnly || loading}
        dataTestid="FilesTemplate-create-folder-btn"
        startEnhancer={<AddIcon />}
      >
        Create folder
      </Button>
      <Button
        dataTestid="FilesTemplate-rename-btn"
        kind="primary"
        onClick={handleRename}
        disabled={selectedIds.size !== 1 || readOnly}
        startEnhancer={<EditIcon />}
      >
        Rename
      </Button>
      <Button
        kind="secondary"
        onClick={handleDelete}
        disabled={!selectedIds.size || readOnly}
        startEnhancer={<DeleteIcon />}
      >
        Delete
      </Button>

      <Button
        kind="secondary"
        onClick={handleDownloadFiles}
        disabled={!canDownloadFiles}
        dataTestid="FileTemplate-download-single-file-btn"
      >
        Download file(s)
      </Button>
      <Button kind="secondary" onClick={onRefresh} disabled={loading} startEnhancer={<RefreshIcon />}>
        Refresh contents
      </Button>
      <Button
        kind="secondary"
        onClick={handleMove}
        disabled={loading || !selectedIds.size || readOnly}
        dataTestid="FileTemplate-move-btn"
      >
        Move file(s)
      </Button>
      <Button
        kind="secondary"
        onClick={() => {
          openModal(<UploadModal onConfirm={handleFilesUpload} onCancel={closeModal} />, { closeable: false });
        }}
        disabled={loading || readOnly}
      >
        Upload file(s)
      </Button>
    </Wrapper>
  );
}
