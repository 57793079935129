import { useState } from 'react';
import { ModalBody, ModalButton, ModalFooter, ModalHeader } from '@visualfabriq/vf-ui-kit';

import { Form, FormValue } from 'src/containers/Pipelines/TableConfiguration/MultiTable/Form';

export function CreateMultipleTableModal({
  onCancel,
  onCreate,
}: {
  onCancel: () => void;
  onCreate: (value: FormValue) => void;
}) {
  const [formState, setFormState] = useState<FormValue>({
    fnmatchExpression: '*.*',
    lowerLevelKey: '',
    importParameters: [],
  });

  return (
    <>
      <ModalHeader>Add Multiple Table</ModalHeader>
      <ModalBody>
        <Form value={formState} onChange={setFormState} isCreatingModal />
      </ModalBody>
      <ModalFooter>
        <ModalButton onClick={onCancel} kind="secondary">
          Cancel
        </ModalButton>
        <ModalButton disabled={formState.importParameters.length == 0} onClick={() => onCreate(formState)}>
          Create
        </ModalButton>
      </ModalFooter>
    </>
  );
}
