import { useState } from 'react';

import { ModalBody, ModalFooter, Input, ModalHeader, ModalButton } from '@visualfabriq/vf-ui-kit';

type Props = {
  onAdd: (key: string) => void;
  onCancel: () => void;
};

export function NewHTTPKeyModal({ onAdd, onCancel }: Props) {
  const [key, setKey] = useState('');
  return (
    <>
      <ModalHeader>Add Key</ModalHeader>
      <ModalBody>
        <Input value={key} onChange={(e) => setKey(e.target.value)} />
      </ModalBody>
      <ModalFooter>
        <ModalButton onClick={() => onAdd(key)}>Add Key</ModalButton>
        <ModalButton kind="secondary" onClick={onCancel}>
          Cancel
        </ModalButton>
      </ModalFooter>
    </>
  );
}
