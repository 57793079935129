import { useEffect, useState } from 'react';
import { Button, FormControl, FormLoadingBox, Input, Select } from '@visualfabriq/vf-ui-kit';

import { CronSchedule } from 'src/components/organisms/CronSchedule';
import { ContinueNotSuccessExecution } from 'src/api-new/bifrost';
import { PipelineDTO, CONTINUE_NOT_SUCCESS_EXECUTION_LABELS } from 'src/dto/pipelines/Pipeline';
import { TIME_ZONES_OPTIONS } from 'src/domain/pipelines/constants';
import { DayOfWeek, replaceCron } from 'src/utils/awsCron/utils';

export function ConfigurationForm(props: {
  isLoading: boolean;
  pipeline: PipelineDTO | undefined;
  onUpdate: (args: {
    name: string;
    description: string;
    schedule: string;
    continueNotSuccessExecution: ContinueNotSuccessExecution;
    timeZone: string;
  }) => void;
}) {
  const { isLoading, pipeline, onUpdate } = props;

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [schedule, setSchedule] = useState<string>('');
  const [isScheduleValid, setIsScheduleValid] = useState(false);
  const [continueNotSuccessExecution, setContinueNotSuccessExecution] = useState<ContinueNotSuccessExecution>('never');
  const [timeZone, setTimeZone] = useState<string>('');

  const isFormValid = isScheduleValid;

  useEffect(() => {
    if (pipeline) {
      setName(pipeline.name);
      setDescription(pipeline.description);
      const cron = replaceCron(pipeline.schedule, { dow: DayOfWeek.awsToStandard });
      setSchedule(cron);
      setIsScheduleValid(!!pipeline.schedule);
      setContinueNotSuccessExecution(pipeline.continue_not_success_execution ?? 'never');
      setTimeZone(pipeline.time_zone ?? 'UTC');
    }
  }, [pipeline]);

  const handleUpdate = () => {
    onUpdate({
      name,
      description,
      schedule: replaceCron(schedule, { dow: DayOfWeek.standardToAWS }),
      continueNotSuccessExecution,
      timeZone,
    });
  };

  if (isLoading) {
    return <FormLoadingBox itemsCount={5} items={['text', 'text', 'buttonGroup', 'text']} />;
  }

  return (
    <>
      <FormControl label="Name" disabled={false} required>
        <Input value={name} onChange={(event) => setName(event.target.value)} />
      </FormControl>
      <FormControl label="Description" disabled={false} required>
        <Input value={description} onChange={(event) => setDescription(event.target.value)} />
      </FormControl>
      <CronSchedule
        value={schedule}
        tzOnly={false}
        timeZoneInput={
          <FormControl label="Time Zone" disabled={false} required>
            <Select
              options={TIME_ZONES_OPTIONS}
              value={[{ id: timeZone }]}
              onChange={(event) => {
                if (event.type !== 'select') return;
                setTimeZone(event.value[0].id as string);
              }}
            />
          </FormControl>
        }
        onChange={setSchedule}
        onValidChange={setIsScheduleValid}
      />

      <FormControl label="Continue in case of non-successful step" disabled={false}>
        <Select
          value={[{ id: continueNotSuccessExecution }]}
          options={Object.values(ContinueNotSuccessExecution).map((continueNotSuccessExecution) => ({
            id: continueNotSuccessExecution,
            label: CONTINUE_NOT_SUCCESS_EXECUTION_LABELS[continueNotSuccessExecution] ?? continueNotSuccessExecution,
          }))}
          onChange={(event) => {
            if (event.type !== 'select') return;
            setContinueNotSuccessExecution(event.value[0].id as ContinueNotSuccessExecution);
          }}
        />
      </FormControl>

      <Button disabled={!isFormValid} onClick={handleUpdate}>
        Update
      </Button>
    </>
  );
}
