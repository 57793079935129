export enum PipelineStepType {
  sftp_inbound_retriever = 'sftp_inbound_retriever',
  http_inbound_retriever = 'http_inbound_retriever',
  azure_outbound_bearer = 'azure_outbound_bearer',
  azure_inbound_retriever = 'azure_inbound_retriever',
  sftp_outbound_bearer = 'sftp_outbound_bearer',
  http_outbound_bearer = 'http_outbound_bearer',
  data_staging = 'data_staging',
  data_processing = 'data_processing',
  files_mover = 'files_mover',
}
