import { FileUploader, FormControl, useSnackbar, useStyletron } from '@visualfabriq/vf-ui-kit';

import { getConfigurationFromFile } from 'src/domain/pipelines/getConfigurationFromFile';
import * as DataStagingStep from 'src/dto/PipelineStep/DataStagingStep';
import { captureException } from 'src/services/sentry';

export function ImportConfiguration({
  onImport,
}: {
  onImport: (configuration: DataStagingStep.DataStagingStepConfiguration) => void;
}) {
  const { enqueueErrorSnackbar } = useSnackbar();
  const [_, $theme] = useStyletron();

  const handleConfigurationImport = async (selectedFiles: File[]) => {
    const configFile = selectedFiles[0];

    if (!configFile) {
      return;
    }

    try {
      const configuration = await getConfigurationFromFile<DataStagingStep.DataStagingStepConfiguration>(configFile);
      onImport(configuration);
    } catch (error) {
      captureException(error);
      enqueueErrorSnackbar('Unable to parse file');
    }
  };

  return (
    <FormControl label="Import Configuration" bottomSpace={$theme.sizing.scale200}>
      <FileUploader onDrop={handleConfigurationImport} />
    </FormControl>
  );
}
