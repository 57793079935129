import { FileConfiguration, ImportType } from 'src/dto/PipelineStep/DataStagingStep';

export function getConfigurationName(configuration: FileConfiguration): string {
  if (configuration.import_type === ImportType.Table) {
    return configuration.import_parameters.target_collection;
  }

  return configuration.import_parameters
    .map(({ target_collection: [_, targetCollection] }) => targetCollection)
    .join(', ');
}
