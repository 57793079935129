import { RefreshIcon, Wrapper, styled } from '@visualfabriq/vf-ui-kit';

export function ListHeader({ onRefresh, children }: React.PropsWithChildren<{ onRefresh: () => void }>) {
  return (
    <Wrapper alignItems="baseline">
      {children}
      <SyncIconWrapper>
        <RefreshIcon onClick={onRefresh} />
      </SyncIconWrapper>
    </Wrapper>
  );
}

const SyncIconWrapper = styled('div', ({ $theme }) => ({
  margin: `0px ${$theme.sizing.scale200}`,
  color: $theme.colors.mono400,
  cursor: 'pointer',
  ':hover': { color: $theme.colors.mono800 },
}));
