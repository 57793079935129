import { DndList, NoContent, useStyletron } from '@visualfabriq/vf-ui-kit';
import { Link } from 'react-router-dom';

import { PipelineStep } from 'src/dto/PipelineStep';

import { Step } from './Step';

type Props = {
  steps: PipelineStep[] | undefined;
  reorderEnabled: boolean;
  onReorder: (event: { oldIndex: number; newIndex: number }) => void;
};

export function StepList(props: Props) {
  const { steps, reorderEnabled, onReorder } = props;
  const [css, $theme] = useStyletron();

  if (!steps?.length) {
    return <NoContent isFilterApplied hideHintMessage message="No Steps to display" />;
  }

  if (reorderEnabled) {
    return (
      <DndList
        overrides={dndListOverrides}
        initialState={{ items: steps!.map((step) => <Step key={step.id} step={step} />) }}
        removable={false}
        removableByMove={false}
        onChange={onReorder}
      />
    );
  }

  return (
    <>
      {steps.map((step) => (
        <Link
          key={step.id}
          to={step.id}
          className={css({
            textDecoration: 'none',
            ':not(:last-of-type)': {
              borderBottom: `${$theme.borders.border200.borderWidth} ${$theme.borders.border200.borderStyle} ${$theme.borders.border200.borderColor}`,
            },
            ':hover': {
              backgroundColor: $theme.colors.backgroundAccentLight,
              cursor: 'pointer',
            },
          })}
        >
          <Step step={step} />
        </Link>
      ))}
    </>
  );
}

const dndListOverrides = {
  Item: {
    style: ({ $theme }) => ({
      padding: $theme.sizing.scale100,
    }),
  },
};
