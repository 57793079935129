import { useEffect, useMemo, useState } from 'react';
import { Button, ParagraphMedium, Wrapper, useStyletron } from '@visualfabriq/vf-ui-kit';
import { useAsync } from 'react-use';

import { PipelineTemplateListItem, PipelineTemplatesApi, PipelinesApi } from 'src/api-new/bifrost';
import { useBifrostApi } from 'src/services/useBifrostApi';
import { groupMultipleByFn } from 'src/utils/groupMultipleByFn';
import { CreateCustomTemplateState } from '../types';
import * as StepLayout from '../StepLayout';
import { SelectTemplates } from './SelectTemplates';

type Props = {
  state: CreateCustomTemplateState;
  instanceId: string;
  onNext: (state: { selectedTemplatesIds: string[] }) => void;
  onPrev: () => void;
};

export function SelectTemplatesStep(props: Props) {
  const { state, instanceId, onNext, onPrev } = props;
  const [selectedTemplatesIds, setSelectedTemplatesIds] = useState<Set<string>>(new Set(state.selectedTemplatesIds));
  const pipelineTemplatesApi = useBifrostApi(PipelineTemplatesApi);
  const pipelinesApi = useBifrostApi(PipelinesApi);
  const [css] = useStyletron();

  const templates = useAsync(async () => {
    if (!state.selectedModule) throw Error('No selected module been found');
    const { data } = await pipelineTemplatesApi.getPipelineTemplatesShort({
      moduleId: [state.selectedModule.id],
      limit: 500,
    });
    return data;
  }, [state.selectedModule]);

  const pipelineNames = useAsync(async () => {
    const { data: pipelinesList } = await pipelinesApi.getPipelinesList({ instanceId });
    return new Set(pipelinesList.map((p) => p.name));
  }, []);

  const groupedTemplates = useMemo(() => {
    if (!templates.value) return { templatesWithoutCategory: [], mandatoryTemplates: [] };

    const mandatoryTemplates = templates.value.filter((t) => t.mandatory);
    const templatesWithoutCategory = templates.value.filter((t) => !t.mandatory && !t.category);
    const templatesByCategory = groupMultipleByFn(
      templates.value.filter((t) => !t.mandatory && t.category),
      (t) => t.category!,
    );

    return {
      ...Object.fromEntries(templatesByCategory),
      mandatoryTemplates,
      templatesWithoutCategory,
    } as {
      templatesWithoutCategory: PipelineTemplateListItem[];
      mandatoryTemplates: PipelineTemplateListItem[];
      [key: string]: PipelineTemplateListItem[];
    };
  }, [templates.value]);

  useEffect(() => {
    if (!pipelineNames.value || !templates.value) return;
    const mandatoryTemplateIds: string[] = [];
    for (const template of templates.value) {
      if (template.mandatory && !pipelineNames.value.has(template.label)) {
        mandatoryTemplateIds.push(template.id);
      }
    }
    setSelectedTemplatesIds(new Set([...Array.from(selectedTemplatesIds.values()), ...mandatoryTemplateIds]));
  }, [pipelineNames.value, templates.value]);

  const handleNext = () => onNext({ selectedTemplatesIds: Array.from(selectedTemplatesIds) });

  if (templates.loading || pipelineNames.loading) {
    return <StepLayout.StepLoading title="Confirm pipelines" />;
  }

  const module = state.selectedModule!;
  const pipelineNamesSet = pipelineNames.value!;
  const { mandatoryTemplates, templatesWithoutCategory, ...templatesByCategory } = groupedTemplates!;

  return (
    <StepLayout.StepLayout>
      <StepLayout.StepHeader title={module.name} />
      <StepLayout.StepBody className={css({ width: '600px' })}>
        <Wrapper direction="column" gap={200} width="100%">
          {module.description && <ParagraphMedium>{module.description}</ParagraphMedium>}
          {mandatoryTemplates && (
            <SelectTemplates
              pipelineNamesSet={pipelineNamesSet}
              selectedTemplatesIds={selectedTemplatesIds}
              templates={mandatoryTemplates}
              title="Mandatory Templates"
              onSelect={setSelectedTemplatesIds}
            />
          )}
          {Object.entries(templatesByCategory).map(([key, templates]) => (
            <SelectTemplates
              key={key}
              pipelineNamesSet={pipelineNamesSet}
              selectedTemplatesIds={selectedTemplatesIds}
              templates={templates}
              title={key}
              onSelect={setSelectedTemplatesIds}
            />
          ))}
          {templatesWithoutCategory && (
            <SelectTemplates
              pipelineNamesSet={pipelineNamesSet}
              selectedTemplatesIds={selectedTemplatesIds}
              templates={templatesWithoutCategory}
              title="Templates without category"
              onSelect={setSelectedTemplatesIds}
            />
          )}
        </Wrapper>
      </StepLayout.StepBody>
      <StepLayout.StepFooter>
        <Wrapper gap={200} width="100%" justifyContent="end" marginBlock="200">
          <Button size="large" onClick={onPrev}>
            Back
          </Button>
          <Button size="large" disabled={selectedTemplatesIds.size === 0} onClick={handleNext}>
            Continue
          </Button>
        </Wrapper>
      </StepLayout.StepFooter>
    </StepLayout.StepLayout>
  );
}
