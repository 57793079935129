import { styled } from '@visualfabriq/vf-ui-kit';

export const DocumentWrapper = styled('div', () => ({
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  paddingBottom: '50px',
  flex: 1,
  overflow: 'auto',
}));
