import { FSMTransition, PipelineStep as PipelineStepApiNew } from 'src/api-new/bifrost';
import { PipelineStep } from 'src/dto/PipelineStep';

import { groupByField } from 'src/utils/groupByField';

export function getOrderedPipelineSteps<TStep extends PipelineStep | PipelineStepApiNew>(
  steps: TStep[],
  transactions: FSMTransition[],
): TStep[] {
  if (!steps.length) {
    return steps;
  }

  const transactionBySourceStateMap = groupByField(transactions, 'source_state');
  const stepsByStepIdMap = groupByField(steps, 'id');
  const firstTransaction = transactions.find((transaction) => transaction.source_state === 'started');

  if (!firstTransaction) {
    throw new Error(`Can't find a transaction with status "started"`);
  }

  let current = firstTransaction;
  const orderedSteps: TStep[] = [];
  do {
    const step = stepsByStepIdMap.get(current.pipeline_step_id!);
    if (!step) {
      throw new Error(`Can't find an step with id "${current.pipeline_step_id}"`);
    }
    orderedSteps.push(step);

    const nextTransaction = transactionBySourceStateMap.get(current.target_state);
    if (!nextTransaction) {
      throw Error(`Can't find a transaction with source_state "${current.target_state}"`);
    }
    current = nextTransaction;
  } while (current.target_state !== 'finished');

  return orderedSteps;
}
