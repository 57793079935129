import { FormControl, Select } from '@visualfabriq/vf-ui-kit';
import { NotificationMode } from 'src/api-new/bifrost';

const NOTIFICATION_TRIGGER_OPTIONS = Object.values(NotificationMode).map((trigger) => ({
  id: trigger,
  label: trigger,
}));

export function ModeSelect(props: { value: NotificationMode; onChange: (value: NotificationMode) => void }) {
  const { value, onChange } = props;

  const handleModeSelectChange: React.ComponentProps<typeof Select>['onChange'] = (event) => {
    if (event.type !== 'select') return;
    onChange(event.value[0].id as NotificationMode);
  };

  return (
    <FormControl label="Trigger" required>
      <Select options={NOTIFICATION_TRIGGER_OPTIONS} value={[{ id: value }]} onChange={handleModeSelectChange} />
    </FormControl>
  );
}
