import { AxiosError } from 'axios';

export function getErrorMessage(error: Error, defaultMessage = 'Something went wrong') {
  let message = defaultMessage;
  if (error instanceof AxiosError) {
    message = error.response?.data.detail ?? error.message ?? message;
  } else {
    message = error.message ?? message;
  }

  return message;
}
