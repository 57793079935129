import { ModalHeader, ModalBody, ModalFooter, ModalButton } from '@visualfabriq/vf-ui-kit';
import { FileObject } from 'src/domain/files/types';
import { FileObjectList } from 'src/components/molecules/FileObjectList';

type Props = {
  onCancel: () => void;
  onConfirm: () => void;
  filesObjectsToRemove: FileObject[];
};

export const DeleteFileObjectModal = (props: Props) => {
  const { onCancel, onConfirm, filesObjectsToRemove } = props;

  const singleObject = filesObjectsToRemove.length === 1;

  return (
    <>
      <ModalHeader>{`Are you sure you want to delete the selected object${!singleObject ? 's' : ''}?`}</ModalHeader>

      <ModalBody>
        <FileObjectList files={filesObjectsToRemove} />
      </ModalBody>

      <ModalFooter>
        <ModalButton onClick={onConfirm}>
          {singleObject ? 'Delete' : `Delete ${filesObjectsToRemove.length} objects`}
        </ModalButton>
        <ModalButton kind="secondary" onClick={onCancel}>
          Cancel
        </ModalButton>
      </ModalFooter>
    </>
  );
};
