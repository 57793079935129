import { Button, RefreshIcon, SmartToyIcon, Tooltip, Wrapper } from '@visualfabriq/vf-ui-kit';

import { useDrawer } from 'src/components/molecules/Drawer/useDrawer';
import { getExecutionStatusIcon, getStatusColor, getStatusDisplayName } from 'src/containers/Pipelines/utils';
import { PipelineFilteredDTO } from 'src/dto/pipelines/Pipeline';
import { FSMState } from 'src/api-new/bifrost';

import { PipelineHistory } from './PipelineHistory';

type Props = {
  pipeline: PipelineFilteredDTO;
};

const HISTORY_ITEMS_LENGTHS = 5;

export function HistoryColumn(props: Props) {
  const { pipeline } = props;
  const { openDrawer } = useDrawer();

  const handleOpenHistory = () => {
    openDrawer(<PipelineHistory pipelineId={pipeline.id} />, {
      title: `History: ${pipeline.name}`,
      size: 'auto',
    });
  };

  return (
    <Wrapper alignItems="center" gap={200}>
      {pipeline.executions.slice(0, HISTORY_ITEMS_LENGTHS).map((execution) => {
        const Icon = getExecutionStatusIcon(execution.display_state ?? (execution.state as FSMState)) || SmartToyIcon;
        return (
          <Tooltip
            key={execution.datetime_started}
            content={getStatusDisplayName(execution.display_state ?? (execution.state as FSMState))}
          >
            <Icon size="20px" color={getStatusColor(execution.display_state ?? (execution.state as FSMState))} />
          </Tooltip>
        );
      })}
      <div style={{ marginLeft: 'auto' }}>
        <Tooltip content="Open History">
          <Button kind="secondary" onClick={handleOpenHistory}>
            <RefreshIcon />
          </Button>
        </Tooltip>
      </div>
    </Wrapper>
  );
}
