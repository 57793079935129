import {
  DbDocumentsApi,
  DocumentFilter,
  DocumentFiltersOperator,
  ExportCollectionStatusDataResponse,
  ExportStatus,
} from 'src/api-new/bifrost';
import { downloadBlobFile } from 'src/utils/files/downloadBlobFile';

export class DocumentsExportService {
  private dbDocumentsApi: DbDocumentsApi;

  constructor(dbDocumentsApi: DbDocumentsApi) {
    this.dbDocumentsApi = dbDocumentsApi;
  }

  async export({
    instanceId,
    collectionName,
    filters,
    filterOperator,
    onProgress,
  }: {
    instanceId: string;
    collectionName: string;
    filters: DocumentFilter[];
    filterOperator: DocumentFiltersOperator | undefined;
    onProgress: OnProgressFn;
  }) {
    const { data: exportDocData } = await this.dbDocumentsApi.exportDocuments({
      documentsQueryExport: {
        instance_id: instanceId,
        collection_name: collectionName,
        filters,
        filters_operator: filterOperator,
      },
    });

    const status = await this.longPollingExportStatus({
      fileNameKey: exportDocData.file_name_key,
      timeout: 1000,
      onProgress,
    });

    if (status.status === ExportStatus.Finished) {
      const response = await fetch(status.presigned_url!);
      const fileBlob = await response.blob();
      downloadBlobFile(fileBlob, exportDocData.export_file_name.split('/').pop()!);
      onProgress({ message: 'Export complete', type: 'message' });
    } else if (status.status === ExportStatus.Failed) {
      throw new Error(`Export failed with status: ${JSON.stringify(status)}`);
    }
  }

  private async longPollingExportStatus({
    fileNameKey,
    timeout,
    onProgress,
    attempt = 0,
  }: {
    fileNameKey: string;
    timeout: number;
    onProgress: OnProgressFn;
    attempt?: number;
  }): Promise<ExportCollectionStatusDataResponse> {
    return await new Promise((resolve) => {
      setTimeout(
        async () => {
          const response = await this.dbDocumentsApi.exportDocumentsByFileNameKey({ fileNameKey });
          const exportStatus = response.data.status;

          if ([ExportStatus.Finished, ExportStatus.Failed].includes(exportStatus)) {
            resolve(response.data);
          } else {
            if (exportStatus === ExportStatus.InProgress) {
              onProgress({ message: `Exporting... Please don't start new export!`, type: 'progress' });
            }
            const status = await this.longPollingExportStatus({
              fileNameKey,
              timeout,
              onProgress,
              attempt: attempt + 1,
            });
            resolve(status);
          }
        },
        timeout * Math.min(attempt, 10),
      );
    });
  }
}

type OnProgressFn = (event: { message: string; type: 'message' | 'progress' }) => void;
