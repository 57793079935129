import React, { useEffect, useState } from 'react';

import { ICredentials } from '@aws-amplify/core';

import { CognitoUserSession, useAuth } from 'src/components/hooks/useAuth';
import { Login } from 'src/pages/Login';
import { ProfileMenu } from 'src/components/ProfileMenu';
import { Loader } from 'src/components/atoms/Loader';
import { AuthProviderHelp } from 'src/components/organisms/AuthProviderHelp';
import { AuthProviderError } from 'src/components/organisms/AuthProviderError';
import { getAuthConfig } from 'src/api/auth';
import { AUTH_PROVIDER_KEY } from 'src/pages/AuthProvider/constants';
import { AuthConfig } from 'src/dto/auth/AuthConfig';
import {
  authProviderHelpTestId,
  authProviderErrorTestId,
  userProviderLoaderTestId,
  userProviderLoginTestId,
} from './constants';
import { UserContext } from './UserContext';

export type UserState = {
  user: User;
  api: {
    signIn: () => Promise<ICredentials>;
    signOut: () => Promise<void>;
    getUser: () => Promise<User>;
  };
};

export type User = {
  firstName: string;
  email: string;
  accessToken: string;
};

export function UserProvider({ children }: { children: React.ReactNode }) {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [authConfig, setAuthConfig] = useState<AuthConfig | null>(null);
  const provider = localStorage.getItem(AUTH_PROVIDER_KEY);

  useEffect(() => {
    async function fetchAuthConfig(provider: string) {
      try {
        const config = await getAuthConfig(provider);
        setAuthConfig(config);
        setIsLoading(false);
      } catch (error) {
        setError(error.message);
      }
    }

    if (provider) {
      fetchAuthConfig(provider);
    }
  }, [provider]);

  const {
    session,
    isSignedIn,
    api: { signIn, signOut, getUserSession },
  } = useAuth(authConfig);

  const getUserData = (session: CognitoUserSession): User => {
    const payload = session.getIdToken().decodePayload();
    return {
      firstName: payload.given_name,
      email: payload.email,
      accessToken: session.getAccessToken().getJwtToken(),
    };
  };

  const getUser = async () => {
    const session = await getUserSession();
    return getUserData(session);
  };

  if (!provider) {
    return <AuthProviderHelp testId={authProviderHelpTestId} />;
  }

  if (isSignedIn) {
    const signedInSession = session!;
    const user = getUserData(signedInSession);

    return (
      <UserContext.Provider
        value={{
          user,
          api: {
            signIn,
            signOut,
            getUser,
          },
        }}
      >
        <ProfileMenu name={user.firstName} onSignOut={signOut} />
        {children}
      </UserContext.Provider>
    );
  }

  if (error) {
    return <AuthProviderError testId={authProviderErrorTestId} />;
  }

  return isLoading ? (
    <Loader data-testid={userProviderLoaderTestId} cover />
  ) : (
    <Login data-testid={userProviderLoginTestId} onSignIn={signIn} />
  );
}
