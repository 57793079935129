import { useEffect, useState } from 'react';
import { ROOT_FOLDER_NAME } from '../constants';
import { useParams } from 'react-router-dom';

export function useFilesPaths() {
  const { '*': splat } = useParams();

  const [paths, setPaths] = useState<string[]>([]);

  useEffect(() => {
    const paths = splat ? splat.split('/') : [];
    paths.unshift(ROOT_FOLDER_NAME);
    setPaths(paths);
  }, [splat]);

  return paths;
}
