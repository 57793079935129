import { useContext, useMemo, useState } from 'react';
import { Button, useSnackbar, ContentWrapper, LabelMedium, FormControl, Input, Wrapper } from '@visualfabriq/vf-ui-kit';

import { PipelinesContext } from 'src/domain/pipelines/PipelinesProvider';
import { UserNotificationConfig } from './UserNotificationConfig';
import { useUserPermissions } from 'src/domain/permissions/useUserPermissions';
import { AdminNotificationConfig } from './AdminNotificationConfig';
import { ConfigurationForm } from './ConfigurationForm';
import { ContinueNotSuccessExecution } from 'src/api-new/bifrost';

type Props = {
  pipelineId: string;
};

export function PipelineConfiguration(props: Props) {
  const { pipelineId } = props;
  const {
    pipelines,
    api: { updatePipeline },
  } = useContext(PipelinesContext);
  const userPermissions = useUserPermissions();
  const [isConfigurationLoading, setConfigurationLoading] = useState(false);
  const snackbar = useSnackbar();

  const pipeline = useMemo(() => pipelines.find(({ id }) => id === pipelineId), [pipelines]);

  const handleUpdateForm = async (update: {
    name: string;
    description: string;
    schedule: string;
    continueNotSuccessExecution: ContinueNotSuccessExecution;
    timeZone: string;
  }) => {
    if (!update.schedule || !pipeline) return;

    try {
      setConfigurationLoading(true);
      const { name, description, schedule, continueNotSuccessExecution, timeZone } = update;
      await updatePipeline(pipeline.id, {
        name,
        description,
        schedule,
        continue_not_success_execution: continueNotSuccessExecution,
        time_zone: timeZone,
      });
      snackbar.enqueueSuccessSnackbar('Pipeline configuration has been updated');
    } catch (error) {
      snackbar.enqueueErrorSnackbar(error.message);
    } finally {
      setConfigurationLoading(false);
    }
  };

  const handleMute = async () => {
    if (!pipeline) return;
    const changedEnabled = !pipeline.notifications_enabled;
    try {
      setConfigurationLoading(true);
      await updatePipeline(pipeline.id, { notifications_enabled: changedEnabled });
      snackbar.enqueueSuccessSnackbar(`Pipeline Notifications have been ${changedEnabled ? 'unmuted' : 'muted'}`);
    } catch (error) {
      snackbar.enqueueErrorSnackbar(error.message);
    } finally {
      setConfigurationLoading(false);
    }
  };

  return (
    <Wrapper direction="column" width="800px" margin={400} gap={200}>
      <ConfigurationForm
        pipeline={pipeline}
        isLoading={isConfigurationLoading}
        onUpdate={handleUpdateForm}
      ></ConfigurationForm>

      <FormControl label="Owner" disabled={false} required>
        <Input disabled={true} value={pipeline!.owner_email ?? ''} />
      </FormControl>

      <ContentWrapper>
        <LabelMedium>Manage personal notifications</LabelMedium>
        <UserNotificationConfig pipelineId={pipelineId} muted={!pipeline?.notifications_enabled} />
      </ContentWrapper>

      {userPermissions.has('ManageSubscriptions') && (
        <ContentWrapper>
          <LabelMedium>Manage automated notifications</LabelMedium>
          <AdminNotificationConfig
            pipelineId={pipelineId}
            muteButton={
              <Button onClick={handleMute} isLoading={isConfigurationLoading} isDanger>
                {pipeline?.notifications_enabled ?? false ? 'Mute' : 'Unmute'}
              </Button>
            }
          />
        </ContentWrapper>
      )}
    </Wrapper>
  );
}
