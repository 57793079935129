import { Outlet } from 'react-router';
import { SnackbarProvider, SNACKBAR_PLACEMENT, Wrapper, useStyletron } from '@visualfabriq/vf-ui-kit';

import ModalProvider from 'src/components/molecules/Modal/ModalProvider';
import { PageWrapper } from 'src/components/molecules/PageWrapper';
import { UserPermissionsProvider } from 'src/domain/permissions/UserPermissionsProvider';
import { EnvVars } from 'src/services/envVars';

import { Navigation } from './Navigation';

export function PageLayout() {
  const [css, theme] = useStyletron();

  return (
    <Wrapper
      direction="column"
      className={css({ position: 'relative', paddingTop: EnvVars.isMFE ? theme.sizing.scale1400 : 'initial' })}
    >
      <SnackbarProvider placement={SNACKBAR_PLACEMENT.bottom}>
        <ModalProvider>
          <UserPermissionsProvider>
            <Navigation />
            <PageWrapper>
              <Outlet />
            </PageWrapper>
          </UserPermissionsProvider>
        </ModalProvider>
      </SnackbarProvider>
    </Wrapper>
  );
}
