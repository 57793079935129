import { useEffect, useMemo } from 'react';
import { Pagination, TableBuilder, TableBuilderColumn } from '@visualfabriq/vf-ui-kit';

import { Selectable, useSelectColumn } from 'src/components/hooks/table/useSelectColumn';
import { useSortedColumn } from 'src/components/hooks/table/useSortedColumn';
import { usePaginate } from 'src/components/hooks/usePaginate';
import { Copyable } from 'src/components/atoms/CopyableColumn';
import { toLocalStringWithTimeZone } from 'src/utils/date/toLocalStringWithTimeZone';
import { SftpCredentials } from '../SftpCredentialsState';

type Props = {
  credentials: SftpCredentials[];
  onSelect: (tableData: Selectable<SftpCredentials>[]) => void;
  loading: boolean;
};

export function HttpKeysTable({ credentials, onSelect, loading }: Props) {
  const tableData = useMemo(
    () =>
      credentials.map((credentials) => ({
        id: credentials.id,
        date: credentials.importedDate,
        inboundKey: credentials.inbound.key,
        outboundKey: credentials.outbound?.key ?? '',
      })),
    [credentials],
  );

  const { sortedData, sortColumn, sortAsc, handleSort } = useSortedColumn({
    tableData,
    initialSortColumn: 'inboundKey',
  });
  const { currentPage, pagesNumber, paginatedItems, setCurrentPage } = usePaginate({ items: sortedData, pageSize: 10 });

  const [selectColumn, selectableTableData] = useSelectColumn(
    {
      tableData: paginatedItems,
      onSelect: (selectedIds) => {
        const newTableItems = credentials.map((tableItem) => {
          return { ...tableItem, selected: selectedIds.has(tableItem.id) };
        });
        onSelect(newTableItems);
      },
    },
    [credentials],
  );

  useEffect(() => {
    setCurrentPage(0);
  }, [credentials]);

  return (
    <>
      <TableBuilder
        overrides={{ Root: { style: { maxHeight: '90%' } } }}
        data={selectableTableData}
        sortColumn={sortColumn}
        sortOrder={sortAsc ? 'ASC' : 'DESC'}
        onSort={handleSort}
        isLoading={loading}
      >
        {selectColumn}
        <TableBuilderColumn id="date" header="Imported Date" sortable>
          {(row) => toLocalStringWithTimeZone(new Date(row.date))}
        </TableBuilderColumn>
        <TableBuilderColumn id="inboundKey" header="Inbound Fingerprint (ssh_public_key_id for now)" sortable>
          {(row) => <Copyable>{row.inboundKey}</Copyable>}
        </TableBuilderColumn>
        <TableBuilderColumn id="outboundKey" header="Outbound Fingerprint (ssh_public_key_id for now)" sortable>
          {(row) => <Copyable>{row.outboundKey}</Copyable>}
        </TableBuilderColumn>
      </TableBuilder>
      <Pagination
        currentPage={!pagesNumber ? 0 : currentPage + 1}
        numPages={pagesNumber}
        onPageChange={({ nextPage }) => setCurrentPage(nextPage - 1)}
      />
    </>
  );
}
