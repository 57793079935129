import { Button, NoContent, Wrapper } from '@visualfabriq/vf-ui-kit';

export function ErrorMessage({ message, onTryAgain }: { message: string; onTryAgain: () => void }) {
  return (
    <Wrapper direction="column" gap={100} width="300px" margin="auto">
      <NoContent isSystemData hideHintMessage message={message} />
      <Button onClick={onTryAgain}> Try again</Button>
    </Wrapper>
  );
}
