import { useState } from 'react';
import { ModalBody, ModalButton, ModalFooter, ModalHeader } from '@visualfabriq/vf-ui-kit';

import * as DataStagingStep from 'src/dto/PipelineStep/DataStagingStep';
import { Form, FormFields } from 'src/containers/Pipelines/TableConfiguration/SingleTable/Form';

export function CreateSingleTableModal({
  fileType,
  onCancel,
  onCreate,
}: {
  fileType: DataStagingStep.FileType;
  onCancel: () => void;
  onCreate: (value: FormFields) => void;
}) {
  const [formFields, setFormFields] = useState<FormFields>({
    fnmatchExpression: '*.*',
    lowerLevelKey: '',
    key: '',
    targetCollection: '',
    persistMethod: DataStagingStep.PersistMethod.Append,
    updateKeys: '',
    mandatoryKeys: '',
    transformations: [],
    fileType,
  });
  const [isValid, setIsValid] = useState(true);

  const handleValidChange = (value: boolean) => {
    setIsValid(value);
  };

  return (
    <>
      <ModalHeader>Add Single Table</ModalHeader>
      <ModalBody>
        <Form value={formFields} onChange={setFormFields} onValidChange={handleValidChange} />
      </ModalBody>
      <ModalFooter>
        <ModalButton kind="secondary" onClick={onCancel}>
          Cancel
        </ModalButton>
        <ModalButton disabled={!isValid} onClick={() => onCreate(formFields)}>
          Create
        </ModalButton>
      </ModalFooter>
    </>
  );
}
