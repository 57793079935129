import { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { Breadcrumbs, Button } from '@visualfabriq/vf-ui-kit';

import { ROOT_FOLDER_NAME } from 'src/domain/files/constants';
import { breadcrumbsOverrides } from 'src/components/styles';

export function FilesNavigationLinks({ paths }: { paths: string[] }) {
  const links = useMemo(() => {
    let prevPath = '';
    return paths.map((path) => {
      if (path === ROOT_FOLDER_NAME) {
        return { name: path, to: '' };
      }

      const to = prevPath ? `${prevPath}/${path}` : path;
      prevPath = to;
      return { name: path, to };
    });
  }, [paths]);

  return (
    <Breadcrumbs overrides={breadcrumbsOverrides}>
      {links.map((link) => (
        <NavLink to={link.to} key={link.name}>
          <Button kind="tertiary">{link.name}</Button>
        </NavLink>
      ))}
    </Breadcrumbs>
  );
}
