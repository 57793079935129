import { HeadingXSmall, Wrapper } from '@visualfabriq/vf-ui-kit';

export function OnlyRawMessage() {
  return (
    <Wrapper margin="auto" padding={1000} direction="column" width="600px" alignItems="center">
      <HeadingXSmall>
        Only instances with prefix <code>raw_</code> are compatible with replicator. For example{' '}
        <code>raw_myinstance_name</code>
      </HeadingXSmall>
    </Wrapper>
  );
}
