import { useMemo } from 'react';

import { EnvVars } from 'src/services/envVars';
import { BaseAPI } from 'src/api-new/bullis/base';

import { useAuthorizedAxios } from '../AuthorizedAxios/useAuthorizedAxios';

type Class<T extends BaseAPI> = new (...args: ConstructorParameters<typeof BaseAPI>) => T;

export function useBullisApi<T extends BaseAPI>(Api: Class<T>): T {
  const authorizedAxios = useAuthorizedAxios();
  return useMemo(() => new Api(undefined, EnvVars.bullisBaseUrl, authorizedAxios), []);
}
