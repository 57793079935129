import { FormControl } from '@visualfabriq/vf-ui-kit';
import { useMemo } from 'react';

import { KeysValues } from 'src/components/molecules/KeysValues';

type RenameColumnsProps = {
  columns: [string, string][];
  onChange: (columns: [string, string][]) => void;
};

export function ColumnsKeyValue(props: RenameColumnsProps) {
  const { columns, onChange } = props;

  const columnsKeysValues = useMemo(
    () => columns.map<[string, string, 'text']>(([key, value]) => [key, value, 'text']),
    [columns],
  );

  return (
    <FormControl label="Columns" required>
      <KeysValues
        value={columnsKeysValues}
        onChange={({ key, value, index, action }) => {
          switch (action) {
            case 'delete':
              onChange(columns.filter((_, paramIndex) => paramIndex !== index));
              break;
            case 'edit':
              onChange(columns.map((param, paramIndex) => (paramIndex !== index ? param : [key, value])));
              break;
            case 'add':
              onChange([...columns, [key, value]]);
              break;
            default:
          }
        }}
      />
    </FormControl>
  );
}
