import { Outlet } from 'react-router-dom';

import { Collections } from './Collections';
import { Wrapper, useStyletron } from '@visualfabriq/vf-ui-kit';

export function Insights() {
  const [css] = useStyletron();

  return (
    <Wrapper height="100%" width="100%" className={css({ position: 'relative' })}>
      <Collections />
      <Outlet />
    </Wrapper>
  );
}
