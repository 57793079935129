import * as DataStagingStep from 'src/dto/PipelineStep/DataStagingStep';

import { splitWithTruncate } from 'src/utils/string/splitWithTruncate';

import { FormValue } from './index';

export function getFileConfiguration(event: FormValue): DataStagingStep.MultiTableConfiguration {
  return {
    import_type: DataStagingStep.ImportType.MultiTable,
    fnmatch_expression: event.fnmatchExpression,
    lower_level_key: event.lowerLevelKey,
    import_parameters: event.importParameters
      .filter(({ isDraft }) => !isDraft)
      .map((parameters) => ({
        target_collection: [parameters.keyFromFile, parameters.targetCollection],
        persist_method: parameters.persistMethod,
        update_keys: splitWithTruncate(parameters.updateKeys),
        mandatory_keys: splitWithTruncate(parameters.mandatoryKeys),
        inherit_keys: splitWithTruncate(parameters.inheritKeys),
        transformations: parameters.transformations,
      })),
  };
}
