import Lowlight from 'react-lowlight';
import json from 'highlight.js/lib/languages/json';
import { styled } from '@visualfabriq/vf-ui-kit';

Lowlight.registerLanguage('json', json);

export function JsonContentViewer({ content, style }: { content: string; style?: React.CSSProperties }) {
  return (
    <Content style={style}>
      <Lowlight language="json" value={content} />
    </Content>
  );
}

const Content = styled('div', ({ $theme }) => ({
  border: `${$theme.borders.border400.borderWidth} ${$theme.borders.border400.borderStyle} ${$theme.borders.border400.borderColor}`,
  padding: $theme.sizing.scale100, // make space for border darius
  borderRadius: $theme.borders.radius200,
  '.hljs': {
    backgroundColor: $theme.colors.white,
  },
  pre: {
    margin: 0,
  },
}));
