import { Skeleton } from '@visualfabriq/vf-ui-kit';

import { StepBody } from './StepBody';
import { StepHeader } from './StepHeader';
import { StepLayout } from './StepLayout';

type Props = {
  title?: string;
  width?: string;
  height?: string;
};

export function StepLoading({ title, width = '600px', height = '320px' }: Props) {
  return (
    <StepLayout>
      {title && <StepHeader title={title} />}
      <StepBody>
        <Skeleton width={width} height={height} />
      </StepBody>
    </StepLayout>
  );
}
