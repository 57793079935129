export function sizeToBytes(value: string): number {
  const [sizeStr, unit] = value.split(' ') as [string, Unit];
  const size = +sizeStr;

  if (size < 0) throw new Error(`Size should be >= 0. Size: ${size}`);

  const unitSize = unitsMap[unit];

  if (!unitSize) throw new Error(`Unsupported unit type: ${unit}`);

  return size * unitsMap[unit];
}

const K = 1000;
const M = K * K;
const G = M * K;
const T = G * K;

type Unit = 'TB' | 'GB' | 'MB' | 'KB' | 'B';
const unitsMap: Record<Unit, number> = {
  B: 1,
  KB: K,
  MB: M,
  GB: G,
  TB: T,
};
