import React, { ReactNode, useState } from 'react';
import { Drawer } from '@visualfabriq/vf-ui-kit';
import { AnchorType, SizeType } from '@visualfabriq/vf-ui-kit/dist/components/atoms/surface/drawer/types';

import { useDrawerStylesOverrides } from 'src/components/styles';
import { EnvVars } from 'src/services/envVars';

type Config = {
  size?: SizeType;
  title?: string;
  headerElement?: ReactNode;
  anchor?: AnchorType;
};

export type DrawerContextValue = {
  openDrawer: (content: React.ReactNode, config?: Config) => void;
  closeDrawer: () => void;
};

export const DrawerContext = React.createContext<DrawerContextValue>({
  openDrawer: () => {
    // do nothing
  },
  closeDrawer: () => {
    // do nothing
  },
});

type Props = {
  children: React.ReactNode;
};

export const DrawerProvider = (props: Props) => {
  const { children } = props;

  const [state, setState] = useState<{ content: React.ReactNode | null; config: Config }>({
    content: null,
    config: {},
  });
  const anchor = state.config.anchor || 'right';
  const drawerStylesOverrides = useDrawerStylesOverrides(anchor, EnvVars.isMFE);

  const openDrawer = (content: React.ReactNode, config: Config = {}) => {
    setState({ content, config });
  };

  const closeDrawer = () => {
    setState({ content: null, config: {} });
  };

  return (
    <DrawerContext.Provider value={{ openDrawer, closeDrawer }}>
      {children}
      <Drawer
        title={state.config.title}
        isOpen={!!state.content}
        onClose={closeDrawer}
        size={state.config.size || 'default'}
        overrideStyle={drawerStylesOverrides}
        headerElement={state.config.headerElement}
        anchor={anchor}
      >
        {state.content}
      </Drawer>
    </DrawerContext.Provider>
  );
};
