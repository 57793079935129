import { CSSProperties } from 'react';
import { LabelMedium, ModalBody, ModalHeader } from '@visualfabriq/vf-ui-kit';
import ProgressBar from '@visualfabriq/vf-ui-kit/dist/components/atoms/progress-bar/line/component';

export function UploadingProgressModal(props: { uploadingProcess: { [name: string]: number } }) {
  const { uploadingProcess } = props;

  const progresses = Object.entries(uploadingProcess)
    .map(([name, progress]) => ({ name, progress }))
    .sort((a, b) => {
      const progressDelta = a.progress - b.progress;

      return progressDelta !== 0 ? progressDelta : a.name.localeCompare(b.name);
    });

  const getProgressLabel = (value: number) => (value !== 100 ? `${value}% Uploaded` : `Uploaded`);

  return (
    <>
      <ModalHeader>Uploading files...</ModalHeader>
      <ModalBody style={modalBodyStyle}>
        {progresses.map(({ name, progress }) => (
          <div key={name}>
            <LabelMedium>{name}</LabelMedium>
            <ProgressBar
              value={progress as number}
              overrides={{
                BarContainer: { style: () => ({ marginLeft: 0, marginRight: 0 }) },
                BarProgress: {
                  style: ({ $theme }) => ({
                    backgroundColor: progress === 100 ? $theme.colors.positive : $theme.colors.primary,
                  }),
                },
                Label: { style: ({ $theme }) => ({ color: $theme.colors.mono600 }) },
              }}
              getProgressLabel={getProgressLabel}
            />
          </div>
        ))}
      </ModalBody>
    </>
  );
}

const modalBodyStyle: CSSProperties = {
  maxHeight: '700px',
  overflowY: 'auto',
  width: '500px',
};
