import { ErrorPage404, styled } from '@visualfabriq/vf-ui-kit';
import { useParams } from 'react-router';

export function InstanceNotFoundPage() {
  const { instanceName } = useParams();
  return (
    <Container>
      <ErrorPage404
        description={`Can't find instance with name ${instanceName}. Please check permissions or select another instance.`}
      />
    </Container>
  );
}

const Container = styled('div', () => ({
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
}));
