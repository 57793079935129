import { Navigate } from 'react-router-dom';
import { LabelSmall, LabelLarge, useStyletron, Wrapper } from '@visualfabriq/vf-ui-kit';

import image from './maintenance.png';
import { EnvVars } from 'src/services/envVars';

export function MaintenancePage() {
  const [css] = useStyletron();

  if (!EnvVars.isMaintenance) {
    return <Navigate to="/" replace />;
  }

  return (
    <Wrapper height="calc(100vh - 46px)" direction="column" justifyContent="center" alignItems="center">
      <LabelLarge>
        We&apos;re currently doing some terribly exciting technical thingies to Bifrost. That does mean he/she cannot
        help you right now. <br />
        Don&apos;t worry though: once back up and running Bifrost will be more awesome than ever before!
      </LabelLarge>
      <LabelSmall>
        Please check <a href="https://visualfabriq.slack.com/archives/C028J9W071C">#bifrost-support</a> for status
        updates
      </LabelSmall>
      <img src={image} className={css({ height: '500px' })} alt="maintenance"></img>
    </Wrapper>
  );
}
