import { FormControl, Input, Select, Wrapper } from '@visualfabriq/vf-ui-kit';

import { TIME_ZONES_OPTIONS } from 'src/domain/pipelines/constants';
import { CronSchedule } from 'src/components/organisms/CronSchedule';
import { ScheduleType, ScheduleTypeDisplayName, scheduleTypeOptions } from '../constants';

export function Form({
  value,
  onChange,
  onScheduleValidChange,
}: {
  value: FormState;
  onChange: (event: Partial<FormState>) => void;
  onScheduleValidChange: (valid: boolean) => void;
}) {
  const getScheduleBasedOnType = (scheduleType: ScheduleType) => {
    switch (scheduleType) {
      case ScheduleType.Custom:
        return 'unset unset unset unset unset unset';
      case ScheduleType.Daily:
        return 'unset unset * * ? *';
      case ScheduleType.Hourly:
        return 'unset * * * ? *';
      case ScheduleType.Minute:
        return '* * * * ? *';
    }
  };

  return (
    <Wrapper direction="column" gap={200}>
      <FormControl label="Pipeline name" required>
        <Input
          dataTestid="CreateFolder-name-input"
          value={value.name}
          onChange={(event) => onChange({ name: event.target.value })}
        />
      </FormControl>
      <FormControl label="Description" required>
        <Input value={value.description} onChange={(event) => onChange({ description: event.target.value })} />
      </FormControl>

      <FormControl label="Schedule type" required>
        <Select
          options={scheduleTypeOptions.map((type) => ({ id: type, label: ScheduleTypeDisplayName[type] }))}
          value={[{ id: value.selectedSchedule }]}
          onChange={(event) => {
            if (event.type !== 'select') return;
            const schedule = getScheduleBasedOnType(event.value[0].id as ScheduleType);
            onChange({ selectedSchedule: event.value[0].id as ScheduleType, schedule });
          }}
        />
      </FormControl>
      <CronSchedule
        value={value.schedule}
        onChange={(schedule) => onChange({ schedule })}
        onValidChange={onScheduleValidChange}
        tzOnly={value.selectedSchedule === ScheduleType.Minute}
        timeZoneInput={
          <FormControl label="Time Zone" disabled={false} required>
            <Select
              options={TIME_ZONES_OPTIONS}
              value={[{ id: value.timeZone }]}
              onChange={(event) => {
                if (event.type !== 'select') return;
                onChange({ timeZone: event.value[0].id as string });
              }}
            />
          </FormControl>
        }
      />
    </Wrapper>
  );
}

export type FormState = {
  name: string;
  schedule: string;
  selectedSchedule: ScheduleType;
  description: string;
  timeZone: string;
};
