import React, { useContext, useState } from 'react';
import {
  styled,
  Accordion,
  Panel,
  Pagination,
  NoContent,
  Notification,
  ListLoadingBox,
  Button,
  useSnackbar,
  Wrapper,
} from '@visualfabriq/vf-ui-kit';

import { PipelinesContext } from 'src/domain/pipelines/PipelinesProvider';
import { StepExecution } from './StepExecution';
import { ExecutionTitle } from './ExecutionTitle';
import { useAsyncRetry } from 'react-use';
import { getErrorMessage } from 'src/api-new/bifrost/utils';
import { useBifrostApi } from 'src/services/useBifrostApi';
import { PipelineExecutionOutput, PipelineExecutionsApi } from 'src/api-new/bifrost';

const ITEMS_PER_PAGE = 20;

type Props = {
  pipelineId: string;
};

export function StepExecutionHistory(props: Props) {
  const { pipelineId } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const { api } = useContext(PipelinesContext);
  const snackbar = useSnackbar();
  const pipelineExecutionsApi = useBifrostApi(PipelineExecutionsApi);

  const [loadingStopExecutions, setLoadingStopExecutions] = useState<Set<string>>(new Set());

  const [executions, setExecutions] = useState<PipelineExecutionOutput[]>();
  const executionsData = useAsyncRetry(async () => {
    const skip = ITEMS_PER_PAGE * (currentPage - 1);
    const { data } = await pipelineExecutionsApi.getPipelineExecutions({
      pipelineExecutionRequestBody: {
        pipeline_id_filter: { key: 'pipeline_id', exact_value: pipelineId, condition: 'exact' },
        skip,
        limit: ITEMS_PER_PAGE,
      },
    });
    setExecutions(data);
    return data;
  }, [pipelineId, currentPage, ITEMS_PER_PAGE]);

  const handleExecutionStop = async (executionId: string) => {
    if (!executions) return;

    try {
      setLoadingStopExecutions(structuredClone(loadingStopExecutions.add(executionId)));
      const stoppedExecution = await api.stopExecution(executionId);
      setExecutions(
        executions.map((execution) => (execution.id === stoppedExecution.id ? stoppedExecution : execution)),
      );
      snackbar.enqueueSuccessSnackbar('Execution has been stopped');
    } catch (error) {
      snackbar.enqueueErrorSnackbar(getErrorMessage(error));
    } finally {
      loadingStopExecutions.delete(executionId);
      setLoadingStopExecutions(structuredClone(loadingStopExecutions));
    }
  };

  if (executionsData.loading) {
    return (
      <Wrapper direction="column" gap={600} height="100%" width="1000px">
        <ListLoadingBox itemsCount={10} />
      </Wrapper>
    );
  }

  if (executionsData.error) {
    return (
      <ErrorWrapper>
        <Button hasMargin kind="secondary" size="large" onClick={executionsData.retry}>
          Try again
        </Button>
        <Notification kind="negative" closeable={false}>
          {executionsData.error.message}
        </Notification>
      </ErrorWrapper>
    );
  }

  return (
    <Wrapper direction="column" gap={600}>
      <Wrapper>
        <Button hasMargin kind="secondary" size="large" onClick={executionsData.retry}>
          Refresh
        </Button>
      </Wrapper>

      {executions?.length ? (
        <Accordion overrides={accordionOverrides}>
          {executions.map((execution) => (
            <Panel key={execution.id} title={<ExecutionTitle execution={execution} />}>
              <StepExecution
                execution={execution}
                isStopLoading={loadingStopExecutions.has(execution.id)}
                onStopExecution={handleExecutionStop}
              />
            </Panel>
          ))}
        </Accordion>
      ) : (
        <NoContent />
      )}

      <Pagination
        currentPage={currentPage}
        numPages={executions && executions.length < ITEMS_PER_PAGE ? currentPage : currentPage + 1}
        overrides={{ MaxLabel: { style: () => ({ display: 'none' }) } }}
        onPageChange={({ nextPage }) => setCurrentPage(nextPage)}
      />
    </Wrapper>
  );
}

const accordionOverrides: React.ComponentProps<typeof Accordion>['overrides'] = {
  Root: {
    style: () => ({
      maxHeight: '90%',
      overflow: 'auto',
    }),
  },
  Header: {
    style: ({ $theme }) => ({
      padding: $theme.sizing.scale400,
    }),
  },
};

const ErrorWrapper = styled('div', ({ $theme }) => ({
  margin: $theme.sizing.scale400,
}));
