import { NoContent } from '@visualfabriq/vf-ui-kit';

import * as DataStagingStep from 'src/dto/PipelineStep/DataStagingStep';

import { CsvFileParametersConfig } from '../CsvFileParametersConfig';
import { ExcelFileParametersConfig } from '../ExcelFileParametersConfig';

type Props = {
  parameters: DataStagingStep.DataStagingFileTypeParameters;
  onChange: (parameters: DataStagingStep.DataStagingFileTypeParameters) => void;
};

export function FileTypeParametersForm(props: Props) {
  const { parameters, onChange } = props;
  switch (parameters.file_type) {
    case DataStagingStep.FileType.XML:
    case DataStagingStep.FileType.JSON:
    case DataStagingStep.FileType.PARQUET:
      return null;
    case DataStagingStep.FileType.CSV:
      return <CsvFileParametersConfig parameters={parameters} onUpdate={onChange} />;
    case DataStagingStep.FileType.EXCEL:
      return <ExcelFileParametersConfig file_type_parameters={parameters} onUpdate={onChange} />;
    default:
      return <NoContent isSystemData message="Configuration for this File Type in not implemented" hideHintMessage />;
  }
}
