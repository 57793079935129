import { useState } from 'react';

export function useFileNavigation({ initialPaths }: { initialPaths: string[] }) {
  const [paths, setPaths] = useState<string[]>(initialPaths);

  const navigateFolderNext = (nextPath: string) => {
    setPaths([...paths!, nextPath]);
  };

  const navigateFolderBack = (name: string) => {
    const backPathIndex = paths.findIndex((path) => path === name);
    setPaths(paths!.slice(0, backPathIndex + 1));
  };

  return { paths, navigateFolderBack, navigateFolderNext };
}
