import { NoContent } from '@visualfabriq/vf-ui-kit';

import { SFTPInboundRetrieverStepConfig } from '../components/SFTPInboundRetrieverStepConfig';
import { SftpOutboundBearerStepConfig } from '../components/SftpOutboundBearerStepConfig';
import { AzureOutboundBearerStepConfig } from '../components/AzureOutboundBearerStepConfig';
import { HttpOutboundBearerStepConfig } from '../components/HttpOutboundBearerStepConfig';
import { DataStagingStepConfig } from '../components/DataStagingStepConfig';
import { DataProcessingStepConfig } from '../components/DataProcessingStepConfig';
import { MoveFilesStepConfig } from '../components/MoveFilesStepConfig';
import { AzureInboundRetrieverStepConfig } from '../components/AzureInboundRetrieverStepConfig';
import { PipelineStep, PipelineStepType } from 'src/dto/PipelineStep';

type Props = {
  step: PipelineStep;
  defaultFieldsConfiguration: JSX.Element;
  deleteButton: JSX.Element;
  loading: boolean;
  onUpdate: (step: PipelineStep) => void;
};

export function StepConfigForm(props: Props) {
  const { step, defaultFieldsConfiguration, loading, onUpdate, deleteButton } = props;

  switch (step.type) {
    case PipelineStepType.sftp_inbound_retriever:
      return (
        <SFTPInboundRetrieverStepConfig
          step={step}
          defaultFieldsConfiguration={defaultFieldsConfiguration}
          deleteButton={deleteButton}
          loading={loading}
          onUpdate={onUpdate}
        />
      );
    case PipelineStepType.azure_outbound_bearer:
      return (
        <AzureOutboundBearerStepConfig
          step={step}
          defaultFieldsConfiguration={defaultFieldsConfiguration}
          deleteButton={deleteButton}
          loading={loading}
          onUpdate={onUpdate}
        />
      );
    case PipelineStepType.sftp_outbound_bearer:
      return (
        <SftpOutboundBearerStepConfig
          step={step}
          defaultFieldsConfiguration={defaultFieldsConfiguration}
          deleteButton={deleteButton}
          loading={loading}
          onUpdate={onUpdate}
        />
      );
    case PipelineStepType.http_outbound_bearer:
      return (
        <HttpOutboundBearerStepConfig
          step={step}
          defaultFieldsConfiguration={defaultFieldsConfiguration}
          deleteButton={deleteButton}
          loading={loading}
          onUpdate={onUpdate}
        />
      );
    case PipelineStepType.data_staging:
      return (
        <DataStagingStepConfig
          defaultFieldsConfiguration={defaultFieldsConfiguration}
          deleteButton={deleteButton}
          step={step}
          loading={loading}
          onUpdate={onUpdate}
        />
      );
    case PipelineStepType.data_processing:
      return (
        <DataProcessingStepConfig
          defaultFieldsConfiguration={defaultFieldsConfiguration}
          deleteButton={deleteButton}
          step={step}
          loading={loading}
          onUpdate={onUpdate}
        />
      );
    case PipelineStepType.files_mover:
      return (
        <MoveFilesStepConfig
          defaultFieldsConfiguration={defaultFieldsConfiguration}
          deleteButton={deleteButton}
          onUpdate={onUpdate}
          step={step}
          loading={loading}
        />
      );
    case PipelineStepType.azure_inbound_retriever:
      return (
        <AzureInboundRetrieverStepConfig
          defaultFieldsConfiguration={defaultFieldsConfiguration}
          deleteButton={deleteButton}
          onUpdate={onUpdate}
          step={step}
          loading={loading}
        />
      );

    default:
    case PipelineStepType.http_inbound_retriever:
      return (
        <div>
          <NoContent isSystemData message="Step Configuration in not implemented for this step type" hideHintMessage />
          {deleteButton}
        </div>
      );
  }
}
