import { FormControl, Input } from '@visualfabriq/vf-ui-kit';

import * as DataStagingStep from 'src/dto/PipelineStep/DataStagingStep';

type ReplaceColumnValueMethodProps = {
  fields: DataStagingStep.ReplaceColumnValue;
  onUpdate: (fields: DataStagingStep.ReplaceColumnValue) => void;
};

export function ReplaceColumnValueMethod(props: ReplaceColumnValueMethodProps) {
  const { fields, onUpdate } = props;

  return (
    <>
      <FormControl label="Replace From" disabled={false} required>
        <Input
          value={fields.from_replace as unknown as string}
          onChange={(event) =>
            onUpdate({
              ...fields,
              from_replace: event.target.value,
            })
          }
        />
      </FormControl>

      <FormControl label="Replace To" disabled={false} required>
        <Input
          value={fields.to_replace as unknown as string}
          onChange={(event) =>
            onUpdate({
              ...fields,
              to_replace: event.target.value,
            })
          }
        />
      </FormControl>
    </>
  );
}
