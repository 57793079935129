import { useState } from 'react';

export function useStateWithWatchChange<S>(
  initialState: S | (() => S),
  onChange: (value: boolean) => void,
): [S, (prevState: S, silent?: boolean) => void] {
  const [value, setValue] = useState<S>(initialState);

  function watchWrapper(value: S, silent = false) {
    if (!silent) {
      onChange(true);
    }
    setValue(value);
  }

  return [value, watchWrapper];
}
