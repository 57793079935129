import { styled } from '@visualfabriq/vf-ui-kit';

export const Resizer = styled('div', ({ $theme }) => ({
  width: $theme.borders.border200.borderWidth,
  backgroundColor: $theme.borders.border200.borderColor,
  padding: `0 ${$theme.sizing.scale400}`,
  backgroundClip: 'content-box',
  boxSizing: 'content-box',

  ':hover': {
    width: $theme.borders.border600.borderWidth,
    backgroundColor: $theme.borders.border600.borderColor,
    cursor: 'col-resize',
  },
}));
