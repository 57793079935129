import { BadResponse } from 'src/api/types';
import { PipelineDTO } from 'src/dto/pipelines/Pipeline';
import { makeApiRequest } from 'src/services/makeApiRequest';
import { endpointPaths } from '../../endpointPaths';

export const getPipeline = async (pipelineId: string, getAccessToken: () => Promise<string>): Promise<PipelineDTO> => {
  const response = await makeApiRequest({ path: `${endpointPaths.pipelines}/${pipelineId}`, getAccessToken });

  const data = await response.json();
  if (response.ok) {
    return data as PipelineDTO;
  } else {
    throw new Error((data as BadResponse).detail);
  }
};
