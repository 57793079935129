import { TableImportModel } from './TableImportModel';

export enum ImportType {
  Table = 'table',
  MultiTable = 'multi_table',
}

type TableConfigurationBase = {
  fnmatch_expression?: string;
  lower_level_key: string | null;
};

export interface SingleTableConfiguration extends TableConfigurationBase {
  import_type: ImportType.Table;
  import_parameters: TableImportModel & { target_collection: string };
  key: string | null;
}

export interface MultiTableConfiguration extends TableConfigurationBase {
  import_type: ImportType.MultiTable;
  import_parameters: (TableImportModel & { target_collection: [string, string]; inherit_keys: string[] | null })[];
}

export type FileConfiguration = SingleTableConfiguration | MultiTableConfiguration;
