import { useState, useMemo } from 'react';
import { Input, ModalHeader, ModalBody, LabelSmall, ModalFooter, ModalButton } from '@visualfabriq/vf-ui-kit';

type RenameFileObjectProps = {
  onCancel: () => void;
  onConfirm: (newName: string) => void;
  oldName: string;
  fObjectNames: Set<string>;
};

const RenameFileObjectModal = (props: RenameFileObjectProps) => {
  const { onCancel, onConfirm, oldName, fObjectNames } = props;
  const [inputValue, setInputValue] = useState(oldName);

  const isValid = useMemo(() => !fObjectNames.has(inputValue) || inputValue === oldName, [fObjectNames, inputValue]);

  return (
    <>
      <ModalHeader>Renaming object</ModalHeader>
      <ModalBody data-testid="RenameFileObject">
        <Input
          dataTestid="RenameFileObject-rename-input"
          overrides={{
            Input: {
              style: () => ({ width: oldName.length > 15 ? '30rem' : '15rem' }),
            },
          }}
          value={inputValue}
          onChange={(event) => {
            setInputValue(event.target.value);
          }}
        />
        {!isValid && <LabelSmall>There is already a file object with the same name here!</LabelSmall>}
      </ModalBody>

      <ModalFooter>
        <ModalButton
          data-testid="RenameFileObject-rename-btn"
          onClick={() => {
            onConfirm(inputValue);
          }}
          disabled={!isValid}
        >
          Rename
        </ModalButton>
        <ModalButton kind="secondary" onClick={onCancel}>
          Cancel
        </ModalButton>
      </ModalFooter>
    </>
  );
};

export default RenameFileObjectModal;
