export function getTransformationFilterValue(value: string): string | number | boolean {
  if (value === 'true') {
    return true;
  }

  if (value === 'false') {
    return false;
  }

  const asNumber = Number(value);

  if (Number.isNaN(asNumber)) {
    return value;
  } else {
    return asNumber;
  }
}
