import { useEffect, useRef, useState } from 'react';

export function useResize<T extends HTMLDivElement | SVGLineElement>({
  initialWidth,
  maxWidths = Number.MAX_SAFE_INTEGER,
  minWidth = 0,
}: {
  initialWidth: number;
  maxWidths?: number;
  minWidth?: number;
}) {
  const offset = useRef<number>();
  const [width, setWidth] = useState(initialWidth);
  const resizeRef = useRef<T>(null);

  useEffect(() => {
    if (!resizeRef.current) return;
    resizeRef.current.addEventListener('mousedown', handleStartResize);
    return () => {
      resizeRef.current?.removeEventListener('mousedown', handleStartResize);
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', stopResize);
    };
  }, [resizeRef.current]);

  const handleStartResize = (event: Event) => {
    event.preventDefault();
    offset.current = resizeRef.current?.getBoundingClientRect().x;
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', stopResize, { once: true });
  };

  const handleMouseMove = (event: MouseEvent) => {
    if (!offset.current) return;
    const delta = event.pageX - offset.current;
    offset.current = event.pageX;
    setWidth((width) => {
      const newWidth = width + delta;
      return newWidth >= minWidth && newWidth <= maxWidths ? newWidth : width;
    });
  };

  const stopResize = () => {
    window.removeEventListener('mousemove', handleMouseMove);
  };

  return [resizeRef, width] as const;
}
