import { endpointPaths } from '../../endpointPaths';
import { makeApiRequest } from 'src/services/makeApiRequest';
import { BadResponse } from 'src/api/types';

export async function deletePipeline(id: string, getAccessToken: () => Promise<string>) {
  const response = await makeApiRequest({
    path: `${endpointPaths.pipelines}/${id}`,
    method: 'DELETE',
    getAccessToken,
  });

  if (!response.ok) {
    const { detail } = (await response.json()) as BadResponse;
    throw new Error(detail);
  }
}
