import { ModalBody, ModalHeader, Accordion, Panel, ContentWrapper } from '@visualfabriq/vf-ui-kit';

import { PipelineTemplate } from 'src/api-new/bifrost';
import { UsingTemplate } from './UsingTemplate';
import { FilledConfig } from 'src/domain/pipelines/types';
import { CreateManually } from './CreateManually';
import { ExportedPipeline } from '../../types';

type Props = {
  onCreateManually: (args: {
    name: string;
    schedule: string;
    description: string;
    importedConfiguration: ExportedPipeline | null;
    timeZone: string;
  }) => void;
  onCreateUsingTemplate: (args: {
    template: PipelineTemplate;
    filledConfig: FilledConfig;
    name: string | undefined;
  }) => Promise<void>;
};

export function AddPipelineModal(props: Props) {
  const { onCreateManually, onCreateUsingTemplate } = props;

  return (
    <>
      <ModalHeader>Create single pipeline</ModalHeader>
      <ModalBody $style={{ width: '1000px' }}>
        <Accordion initialState={{ expanded: ['using-template'] }}>
          <Panel key="using-template" title="Create using template">
            <ContentWrapper>
              <UsingTemplate onCreate={onCreateUsingTemplate} />
            </ContentWrapper>
          </Panel>
          <Panel key="create-manually" title="Create manually">
            <ContentWrapper>
              <CreateManually onCreate={onCreateManually} />
            </ContentWrapper>
          </Panel>
        </Accordion>
      </ModalBody>
    </>
  );
}
