import { Drawer } from '@visualfabriq/vf-ui-kit';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import { pathConstants } from 'src/routes';
import { useDrawerStylesOverrides } from 'src/components/styles';
import { EnvVars } from 'src/services/envVars';

export function PipelineDrawer() {
  const navigate = useNavigate();
  const { instanceName } = useParams();
  const drawerStylesOverrides = useDrawerStylesOverrides('right', EnvVars.isMFE);

  const pipelinesRootPath = `${pathConstants.ROOT}/${instanceName}/${pathConstants.PIPELINES.root}`;

  return (
    <Drawer
      overrideStyle={drawerStylesOverrides}
      isOpen
      onClose={() => navigate(pipelinesRootPath, { relative: 'path' })}
      size="auto"
    >
      <Outlet context={{ pipelinesRootPath }} />
    </Drawer>
  );
}
