import { Button, Tag, useSnackbar, FormControl, Wrapper } from '@visualfabriq/vf-ui-kit';
import { useContext, useEffect, useMemo } from 'react';
import { ExportedFromFile } from 'src/api-new/bifrost';

import { ModalContext } from 'src/components/molecules/Modal/ModalProvider';
import { ImportConfiguration } from 'src/containers/Pipelines/PipelineSteps/AddStep/ImportConfiguration';
import { DraftableDataStagingConfiguration } from '../../../drafts';
import { ConfigContentModal } from './ConfigContentModal';

type Props = {
  stepConfiguration: DraftableDataStagingConfiguration;
  onChange: (value: DraftableDataStagingConfiguration) => void;
  onValidChange: (id: string, isValid: boolean) => void;
  onApplyChanges: () => void;
  onCancel: () => void;
  onDelete: (id: string) => void;
};

export function DataStagingTemplateForm(props: Props) {
  const { stepConfiguration, onChange, onValidChange, onApplyChanges, onCancel, onDelete } = props;
  const { openModal } = useContext(ModalContext);
  const { enqueueErrorSnackbar } = useSnackbar();
  const originalConfig = useMemo(() => stepConfiguration.exported_from_file, []);

  useEffect(() => {
    onValidChange(stepConfiguration.id, !!stepConfiguration.exported_from_file);
  }, [stepConfiguration.exported_from_file]);

  const handleFileExport = (configuration: ExportedFromFile | null) => {
    if (configuration) {
      onChange({
        ...stepConfiguration,
        exported_from_file: {
          files_configuration_model: configuration.files_configuration_model ?? [],
          encoding: configuration.encoding,
          file_type_parameters: configuration.file_type_parameters,
          import_folder: configuration.import_folder,
          sap_system_name: configuration.sap_system_name,
          table_type: configuration.table_type,
        },
      });
    } else {
      onChange({ ...stepConfiguration, exported_from_file: undefined });
    }
  };

  const handleViewConfigContent = () => {
    openModal(<ConfigContentModal content={JSON.stringify(originalConfig, null, 2)} />, {
      closeable: true,
      size: 'auto',
    });
  };

  return (
    <>
      {originalConfig && (
        <>
          <Tag color={'blue'}>Config Uploaded</Tag>{' '}
          <Button onClick={handleViewConfigContent} kind="tertiary">
            View Config Content
          </Button>
        </>
      )}

      <FormControl label={originalConfig ? 'Update configuration' : 'Add configuration'}>
        <ImportConfiguration
          onChange={handleFileExport}
          onError={(error) => enqueueErrorSnackbar(`Unable to parse file ${error.message}`)}
        />
      </FormControl>

      <Wrapper gap="200">
        {stepConfiguration.draft && (
          <Button disabled={!stepConfiguration.exported_from_file} onClick={onApplyChanges}>
            Apply Changes
          </Button>
        )}
        {stepConfiguration.draft ? (
          <Button kind="secondary" onClick={onCancel}>
            Cancel
          </Button>
        ) : (
          <div style={{ marginLeft: 'auto' }}>
            <Button kind="secondary" onClick={() => onDelete(stepConfiguration.id)}>
              Delete
            </Button>
          </div>
        )}
      </Wrapper>
    </>
  );
}
