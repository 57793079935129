import { DataProcessingConfig, StepsConfigurationInner, DataStagingConfig } from 'src/api-new/bifrost';

type Draftable<T> = T & { draft?: boolean };

export type DraftableStepConfiguration = Draftable<StepsConfigurationInner>;

export type DraftableDataProcessingConfiguration = Draftable<DataProcessingConfig>;
export const DRAFT_DATA_PROCESSING_STEP: DraftableDataProcessingConfiguration = {
  id: 'draft',
  draft: true,
  type: 'data_processing',
  args: [],
  image: 'api_master',
  function_link: '',
  job_size: 'Micro (2 GB)',
  job_timeout: 36000,
  can_be_stopped: true,
};

export type DraftableDataStagingConfiguration = Draftable<DataStagingConfig>;
export const DRAFT_DATA_STAGING_STEP: DraftableDataStagingConfiguration = {
  id: 'draft',
  type: 'data_staging',
  draft: true,
};
