import React from 'react';
import { Button, HeartIcon, LabelSmall, Wrapper, useSnackbar, useStyletron } from '@visualfabriq/vf-ui-kit';
import { useNavigate } from 'react-router-dom';

import { loginButtonTestId } from './constants';
import { AUTH_PROVIDER_KEY } from '../AuthProvider/constants';
import { pathConstants } from 'src/routes';

type LoginProps = {
  onSignIn: () => void;
  'data-testid'?: string;
};

export function Login(props: LoginProps) {
  const { onSignIn } = props;
  const { enqueueErrorSnackbar } = useSnackbar();
  const dataTestId = props['data-testid'];
  const provider = localStorage.getItem(AUTH_PROVIDER_KEY);
  const navigate = useNavigate();
  const [, theme] = useStyletron();

  const handleProviderReset = () => {
    localStorage.removeItem(AUTH_PROVIDER_KEY);
    navigate(`/${pathConstants.SSO}`);
  };

  const handleSignIn = () => {
    try {
      onSignIn();
    } catch (error) {
      enqueueErrorSnackbar(error);
    }
  };

  return (
    <Wrapper direction="column" alignItems="center" justifyContent="center" height="100vh" data-testid={dataTestId}>
      <Button margin="" size="large" startEnhancer={HeartIcon} onClick={handleSignIn} dataTestid={loginButtonTestId}>
        Enter Bifrost
      </Button>
      <Wrapper marginBlock={`${theme.sizing.scale200} 0`} direction="column">
        <div>
          <LabelSmall overrides={providerInfoTextOverrides}>Selected provider:</LabelSmall>
          <LabelSmall overrides={providerOverrides}>{provider}</LabelSmall>{' '}
        </div>
        <Button kind="secondary" onClick={handleProviderReset} overrides={resetButtonOverrides}>
          Reset
        </Button>
      </Wrapper>
    </Wrapper>
  );
}

const providerInfoTextOverrides: React.ComponentProps<typeof LabelSmall>['overrides'] = {
  Block: {
    style: ({ $theme }) => ({
      padding: `0 ${$theme.sizing.scale200}`,
    }),
  },
};

const resetButtonOverrides: React.ComponentProps<typeof Button>['overrides'] = {
  BaseButton: {
    style: ({ $theme }) => ({
      padding: `0 ${$theme.sizing.scale200}`,
      alignSelf: 'center',
    }),
  },
};

const providerOverrides: React.ComponentProps<typeof LabelSmall>['overrides'] = {
  Block: {
    style: () => ({
      fontWeight: 600,
    }),
  },
};
