import { PipelineStepType } from 'src/dto/PipelineStep';

export const STEP_NAME_BY_ID: Record<PipelineStepType, string> = {
  [PipelineStepType.sftp_inbound_retriever]: 'SFTP Inbound Retriever',
  [PipelineStepType.http_inbound_retriever]: 'HTTP Inbound Retriever',
  [PipelineStepType.azure_inbound_retriever]: 'Azure Inbound Retriever',
  [PipelineStepType.azure_outbound_bearer]: 'Azure Outbound Bearer',
  [PipelineStepType.sftp_outbound_bearer]: 'SFTP Outbound Bearer',
  [PipelineStepType.http_outbound_bearer]: 'HTTP Outbound Bearer',
  [PipelineStepType.data_staging]: 'Data Staging',
  [PipelineStepType.data_processing]: 'Data Processing',
  [PipelineStepType.files_mover]: 'Move files',
};

export enum ScheduleType {
  Daily,
  Hourly,
  Minute,
  Custom,
}

export const ScheduleTypeDisplayName: { [key in ScheduleType]: string } = {
  [ScheduleType.Minute]: 'Every minute',
  [ScheduleType.Hourly]: 'Every hour',
  [ScheduleType.Daily]: 'Every day',
  [ScheduleType.Custom]: 'Custom',
};

export const scheduleTypeOptions = [ScheduleType.Minute, ScheduleType.Hourly, ScheduleType.Daily, ScheduleType.Custom];
