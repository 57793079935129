import { useContext, useMemo, useState } from 'react';
import {
  AddIcon,
  ArrowDownwardIcon,
  Button,
  DeleteIcon,
  NoContent,
  Wrapper,
  useSnackbar,
} from '@visualfabriq/vf-ui-kit';

import useSftpCredentialsState, { SftpCredentials as SftpCredentialsDTO } from './SftpCredentialsState';
import { ModalContext } from 'src/components/molecules/Modal/ModalProvider';
import { NewSftpCredentialModal } from './NewSftpCredentialModal';
import { downloadTextFile } from 'src/utils/files/downloadTextFile';
import { HttpKeysTable } from './SftpKeysTable';
import { SelectedCount } from 'src/components/atoms/SelectedCount';

import { useInstances } from 'src/components/hooks/useInstances';
import { useBifrostApi } from 'src/services/useBifrostApi';
import { SftpApi } from 'src/api-new/bifrost';
import { captureException } from 'src/services/sentry';

const SftpCredentials = () => {
  const { credentials, loading, handleDelete, fetchSftpCredentials } = useSftpCredentialsState();
  const { openModal, closeModal } = useContext(ModalContext);
  const [selectedIds, setSelectedIds] = useState(new Set<string>());
  const { selectedInstance } = useInstances();
  const { enqueueErrorSnackbar } = useSnackbar();
  const sftpApi = useBifrostApi(SftpApi);

  const selected = useMemo(() => credentials.filter(({ id }) => selectedIds.has(id)), [credentials, selectedIds]);

  const handleExportKeys = (credentialsToDelete: SftpCredentialsDTO[]) => {
    const text =
      'Date | Inbound Fingerprint | Outbound Fingerprint\n' +
      credentialsToDelete
        .map((credential) => [credential.importedDate, credential.inbound.key, credential.outbound?.key].join(' | '))
        .join('\n');
    downloadTextFile('export.txt', text);
  };

  const openCreateNewCredentialModal = () => {
    openModal(
      <NewSftpCredentialModal
        onAdd={async (pubKey) => {
          try {
            const [{ data: inboundResult }, { data: outboundResult }] = await Promise.all([
              sftpApi.importInboundSshPublicKey({
                sFTPKey: { instance_id: selectedInstance.id, ssh_public_key_body: pubKey },
              }),
              sftpApi.importOutboundSshPublicKey({
                sFTPKey: { instance_id: selectedInstance.id, ssh_public_key_body: pubKey },
              }),
            ]);
            if (inboundResult.imported && outboundResult.imported) {
              await fetchSftpCredentials();
            } else {
              throw Error('Something went wrong');
            }
          } catch (error) {
            enqueueErrorSnackbar(error.message);
            captureException(error);
          } finally {
            closeModal();
          }
        }}
        onCancel={closeModal}
      />,
    );
  };

  const handleSelect = (items: { id: string; selected: boolean }[]) => {
    setSelectedIds(
      items.reduce((ids, { selected, id }) => {
        if (selected) {
          ids.add(id);
        }
        return ids;
      }, new Set<string>()),
    );
  };

  return (
    <Wrapper direction="column" gap={400}>
      <Wrapper gap={200}>
        <Button startEnhancer={AddIcon} onClick={openCreateNewCredentialModal}>
          Add SSH Public Key
        </Button>
        <Button
          startEnhancer={ArrowDownwardIcon}
          kind="secondary"
          onClick={() => {
            handleExportKeys(selected);
          }}
          disabled={!selected.length}
        >
          Export Credential(s)
        </Button>
        <Button
          startEnhancer={DeleteIcon}
          kind="secondary"
          onClick={() => {
            handleDelete(Array.from(selected));
          }}
          disabled={!selected.length}
        >
          Delete Credential(s)
        </Button>
        {selectedIds.size >= 1 && <SelectedCount count={selectedIds.size} />}
      </Wrapper>

      {credentials.length || loading ? (
        <HttpKeysTable credentials={credentials} onSelect={handleSelect} loading={loading} />
      ) : (
        <NoContent />
      )}
    </Wrapper>
  );
};

export default SftpCredentials;
