import { useContext, useState } from 'react';
import { styled, Button, NoContent, useSnackbar, DURATION, Wrapper, useStyletron } from '@visualfabriq/vf-ui-kit';

import { PipelineTemplatesContext } from 'src/domain/PipelineTemplatesContext';

import { Template } from './Template';
import { TemplatesList } from './TemplatesList';
import { draftTemplate, DraftTemplate, TEMPLATE_DRAFT_ID } from './constants';
import { PipelineTemplateCreate, PipelineTemplateUpdate } from 'src/api-new/bifrost';
import { getErrorMessage } from 'src/api-new/bifrost/utils';
import { captureException } from 'src/services/sentry';

export function PipelineTemplates() {
  const snackbar = useSnackbar();
  const { error, templatesListItems, modules, loading, api } = useContext(PipelineTemplatesContext);
  const [selectedTemplate, setSelectedTemplate] = useState<SelectedTemplate | null>(null);
  const [draft, setDraft] = useState<DraftTemplate | null>(null);
  const [openModules, setOpenModules] = useState(new Set<string>());
  const [_, theme] = useStyletron();

  const handleCancel = () => {
    setDraft(null);
    setSelectedTemplate(null);
  };

  const handleCreateTemplate = async (template: Omit<PipelineTemplateCreate, 'id'>) => {
    try {
      const createdTemplate = await api.createTemplate(template);
      if (createdTemplate) {
        setSelectedTemplate(createdTemplate);
        setDraft(null);
      }
    } catch (error) {
      captureException(error);
      snackbar.enqueueErrorSnackbar(getErrorMessage(error), DURATION.medium);
    }
  };

  const handleTemplateUpdate = async (event: { id: string; templateUpdate: PipelineTemplateUpdate }) => {
    try {
      const updatedTemplate = await api.updateTemplate(event);
      if (updatedTemplate) {
        setSelectedTemplate(updatedTemplate);
      }
    } catch (error) {
      captureException(error);
      snackbar.enqueueErrorSnackbar(getErrorMessage(error), DURATION.medium);
    }
  };

  const handleDelete = async (idToDelete: string) => {
    await api.deleteTemplate(idToDelete);
    setSelectedTemplate(null);
  };

  const handleOpenToggle = (event: { isOpen: boolean; id: string }) => {
    if (event.isOpen) {
      openModules.add(event.id);
    } else {
      openModules.delete(event.id);
    }

    setOpenModules(new Set(openModules));
  };

  const handleCreateDraftTemplate = (moduleId: string) => {
    setDraft({ ...draftTemplate, vf_module_id: moduleId });
    setSelectedTemplate({ id: TEMPLATE_DRAFT_ID, label: 'New Template' });
  };

  if (error) {
    return (
      <ErrorMessage>
        <NoContent isSystemData hideHintMessage message={error} />
        <Button onClick={() => api.refresh()}>Try Again</Button>
      </ErrorMessage>
    );
  }

  return (
    <Wrapper height="100%" gap={200} paddingBlock={`0 ${theme.sizing.scale800}`}>
      <LeftPanel>
        <Wrapper gap="200" justifyContent="end">
          <Button onClick={api.refreshTemplates} kind="secondary" size="mini">
            Refresh
          </Button>
        </Wrapper>

        <TemplatesList
          isLoading={loading}
          templates={draft ? [...templatesListItems, draft] : templatesListItems}
          selectedId={selectedTemplate?.id}
          modules={modules}
          openModules={openModules}
          onSelect={setSelectedTemplate}
          createDraftTemplate={handleCreateDraftTemplate}
          onOpenChange={handleOpenToggle}
        />
      </LeftPanel>

      <RightPanel>
        {selectedTemplate ? (
          <Template
            action={selectedTemplate.id === draft?.id ? 'create' : 'update'}
            draftTemplate={selectedTemplate.id === draft?.id ? draft : null}
            template={selectedTemplate}
            onCreate={handleCreateTemplate}
            onUpdate={handleTemplateUpdate}
            onCancel={handleCancel}
            onDelete={handleDelete}
          />
        ) : (
          <NoContentWrapper>
            <NoContent isFilterApplied hideHintMessage message="No template selected" />
          </NoContentWrapper>
        )}
      </RightPanel>
    </Wrapper>
  );
}

const LeftPanel = styled('div', ({ $theme }) => ({
  display: 'flex',
  height: '100%',
  flexDirection: 'column',
  gap: $theme.sizing.scale200,
  paddingRight: $theme.sizing.scale400,
  borderRight: `1px solid #A0BFF8`,
  overflow: 'auto',
}));

const RightPanel = styled('div', ({ $theme }) => ({
  display: 'flex',
  height: '100%',
  flexDirection: 'column',
  padding: `0 ${$theme.sizing.scale400}`,
  overflow: 'auto',
}));

const NoContentWrapper = styled('div', () => ({ width: '30rem' }));

const ErrorMessage = styled('div', () => ({
  width: '15rem',
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
}));

type SelectedTemplate = { id: string; label: string };
