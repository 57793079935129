import { AxiosInstance } from 'axios';
import { CompleteMultipartUploadPart, FilesApi, MultipartUploadAction, S3ObjectKind } from 'src/api-new/bifrost';
import { EnvVars } from 'src/services/envVars';
import { onProgressUpdateFn } from './types';

export class FilesService {
  private filesApi: FilesApi;

  constructor(authorizedAxios: AxiosInstance) {
    this.filesApi = new FilesApi(undefined, EnvVars.apiBaseUrl, authorizedAxios);
  }

  private formatLoadingProgress(loaded: number, total: number) {
    return Math.floor((loaded / total) * 100);
  }

  private async uploadMultipartPart(chunk: Blob, url: string, onProgress: (loaded: number) => void) {
    return new Promise<XMLHttpRequest['response']>((resolve) => {
      const xhr = new XMLHttpRequest();
      xhr.open('PUT', url);

      xhr.upload.onprogress = (e) => {
        onProgress(e.loaded);
      };

      xhr.onload = () => {
        const eTag = xhr.getResponseHeader('ETag')?.replaceAll('"', '');
        resolve({ eTag });
      };

      xhr.onerror = () => {
        resolve(xhr.response);
      };

      xhr.send(chunk);
    });
  }

  private async uploadFileXhr(file: File, url: string, onProgressUpdate: (loaded: number) => void) {
    return new Promise<XMLHttpRequest['response']>((resolve) => {
      const xhr = new XMLHttpRequest();

      xhr.open('PUT', url);

      xhr.setRequestHeader('Content-type', file.type);

      xhr.upload.addEventListener('progress', (e) => {
        onProgressUpdate(e.loaded);
      });

      xhr.onload = () => {
        resolve(xhr.response);
      };

      xhr.onerror = () => {
        resolve(xhr.response);
      };

      xhr.send(file);
    });
  }

  async uploadMultipartFile({
    file,
    instanceId,
    path,
    onProgressUpdate,
  }: {
    file: File;
    instanceId: string;
    path: string;
    onProgressUpdate: onProgressUpdateFn;
  }) {
    let offset = 0;
    let partNumber = 1;
    const CHUNK_SIZE = 1.7e7; // ~17mb ;
    const parts: CompleteMultipartUploadPart[] = [];
    let totalLoaded = 0;
    let currentChunkLoaded = 0;
    const total = file.size;

    const handleProgressUpload = (loaded: number) => {
      const currentLoaded = loaded - currentChunkLoaded;
      currentChunkLoaded = loaded;
      totalLoaded = totalLoaded + currentLoaded;
      onProgressUpdate({ name: file.name, progress: this.formatLoadingProgress(totalLoaded, total) });
    };

    const {
      data: { upload_id: uploadId },
    } = await this.filesApi.multipartUploadActionForInstance({
      instanceId,
      multipartUploadActionData: {
        path,
        method: MultipartUploadAction.CreateMultipartUpload,
      },
    });
    // eslint-disable-next-line no-constant-condition
    while (true) {
      if (offset >= file.size) {
        break;
      }

      const {
        data: { upload_part_url },
      } = await this.filesApi.createMultipartUploadPresignedUrlForInstance({
        instanceId,
        path,
        uploadId,
        partNo: partNumber,
      });

      const chunk = file.slice(offset, offset + CHUNK_SIZE);

      const response = await this.uploadMultipartPart(chunk, upload_part_url, handleProgressUpload);

      parts.push({ PartNumber: partNumber, ETag: response.eTag });

      offset = offset + CHUNK_SIZE;
      partNumber = partNumber + 1;
      currentChunkLoaded = 0;
    }

    await this.filesApi.multipartUploadActionForInstance({
      instanceId,
      multipartUploadActionData: {
        path,
        method: MultipartUploadAction.CompleteMultipartUpload,
        params: { upload_id: uploadId, parts },
      },
    });
  }

  async uploadFile({
    file,
    instanceId,
    path,
    onProgressUpdate,
  }: {
    file: File;
    instanceId: string;
    path: string;
    onProgressUpdate: onProgressUpdateFn;
  }) {
    const {
      data: { presigned_urls },
    } = await this.filesApi.generatePresignedUrlForInstance({
      instanceId,
      s3PresignedURL: [
        {
          path,
          kind: S3ObjectKind.File,
          content_type: file.type,
        },
      ],
    });
    return this.uploadFileXhr(file, presigned_urls[0], (loaded) =>
      onProgressUpdate({ name: file.name, progress: this.formatLoadingProgress(loaded, file.size) }),
    );
  }
}
