import { styled, mergeOverrides, Drawer } from '@visualfabriq/vf-ui-kit';
import { AnchorType } from '@visualfabriq/vf-ui-kit/dist/components/atoms/surface/drawer/types';
import { useMemo } from 'react';

type StyleObject = Parameters<typeof styled>[1];

export const textTruncate: StyleObject = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
};

export const textBlockOverrides = { Block: { style: { ...textTruncate } } };

export const breadcrumbsOverrides = {
  Root: { style: { overflow: 'auto' } },
};

export const pipelinesBreadcrumbsOverrides = mergeOverrides(breadcrumbsOverrides, {
  Root: { style: ({ $theme }) => ({ margin: $theme.sizing.scale200 }) },
});

export const WRAPPER_TITLE_HEIGHT = '60px';

export const MFE_NAV_HEIGHT = '56px';

export function useDrawerStylesOverrides(
  anchor: AnchorType,
  isMFE: boolean,
): React.ComponentProps<typeof Drawer>['overrideStyle'] {
  return useMemo(
    () => ({
      root: {
        marginTop: isMFE ? MFE_NAV_HEIGHT : '0',
        marginLeft: isMFE && anchor === 'left' ? MFE_NAV_HEIGHT : '0',
      },
      headerWrapper: { margin: '0' },
    }),
    [anchor, isMFE],
  );
}
