import { Button, ContentWrapper, FormControl, Input, Select, Textarea, Wrapper } from '@visualfabriq/vf-ui-kit';

import { TableTransformations } from '../../TableTransformations';

import { ImportParameters } from '../Form';
import * as DataStagingStep from 'src/dto/PipelineStep/DataStagingStep';

type Props = {
  isDraft: boolean;
  state: ImportParameters;
  showTransformations?: boolean;
  onUpdate: (_: ImportParameters) => void;
  onCreate: () => void;
  onDelete: () => void;
};

const persistMethods = [
  DataStagingStep.PersistMethod.Append,
  DataStagingStep.PersistMethod.Truncate,
  DataStagingStep.PersistMethod.Upsert,
];

export function TableConfiguration(props: Props) {
  const { state, onUpdate, isDraft, onCreate, showTransformations = true, onDelete } = props;
  const { persistMethod, updateKeys, mandatoryKeys, transformations, keyFromFile, targetCollection, inheritKeys } =
    state;

  const isValid = persistMethod && keyFromFile && targetCollection;

  return (
    <>
      <FormControl label="Key From File" required>
        <Input
          value={keyFromFile}
          onChange={(event) => {
            onUpdate({
              ...state,
              keyFromFile: event.target.value,
            });
          }}
        />
      </FormControl>

      <FormControl label="Target Collection" required>
        <Input
          value={targetCollection}
          onChange={(event) => {
            onUpdate({
              ...state,
              targetCollection: event.target.value,
            });
          }}
        />
      </FormControl>

      <FormControl label="Persist Method" required>
        <Select
          options={persistMethods.map((type) => ({ id: type, label: type }))}
          value={[{ id: persistMethod }]}
          onChange={(event) => {
            if (event.type !== 'select') return;
            onUpdate({
              ...state,
              persistMethod: event.value[0].id as DataStagingStep.PersistMethod,
            });
          }}
        />
      </FormControl>

      <FormControl
        label="Update Keys"
        caption={
          <span>
            Enter a list of keys separated by comma i.e., <code>key1, key2, key3</code>{' '}
          </span>
        }
      >
        <Textarea
          value={updateKeys}
          onChange={(event) => {
            onUpdate({
              ...state,
              updateKeys: event.target.value,
            });
          }}
        />
      </FormControl>

      <FormControl label="Mandatory Keys" caption={<code>key1, key2, key3</code>}>
        <Textarea
          value={mandatoryKeys}
          onChange={(event) => {
            onUpdate({
              ...state,
              mandatoryKeys: event.target.value,
            });
          }}
        />
      </FormControl>

      <FormControl label="Inherit Keys" caption={<code>key1, key2, key3</code>}>
        <Textarea
          value={inheritKeys}
          onChange={(event) => {
            onUpdate({
              ...state,
              inheritKeys: event.target.value,
            });
          }}
        />
      </FormControl>

      {showTransformations && (
        <ContentWrapper>
          <FormControl label="Transformations" required>
            <TableTransformations
              transformations={transformations}
              onUpdate={(transformations) => {
                onUpdate({
                  ...state,
                  transformations,
                });
              }}
            />
          </FormControl>
        </ContentWrapper>
      )}

      <Wrapper gap="200" marginBlock="200">
        {isDraft ? (
          <Button disabled={!isValid} onClick={onCreate}>
            Create
          </Button>
        ) : (
          <Button onClick={onDelete}>Delete Collection</Button>
        )}
      </Wrapper>
    </>
  );
}
