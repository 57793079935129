import { useMemo, useState } from 'react';
import { Input, Button, FormLoadingBox, FormControl, Select, Checkbox, Wrapper } from '@visualfabriq/vf-ui-kit';

import { getRelativePath } from 'src/domain/pipelines/getRelativePath';
import { KeysValues } from 'src/components/molecules/KeysValues';
import { useInstances } from 'src/components/hooks/useInstances';
import { StepConfigLayout } from '../StepConfigLayout';
import * as HttpOutboundBearerStep from 'src/dto/PipelineStep/HttpOutboundBearerStep';

type Props = {
  step: HttpOutboundBearerStep.HttpOutboundBearerStep;
  defaultFieldsConfiguration: JSX.Element;
  loading: boolean;
  onUpdate: (step: HttpOutboundBearerStep.HttpOutboundBearerStep) => void;
  deleteButton: JSX.Element;
};

export function HttpOutboundBearerStepConfig(props: Props) {
  const { step, onUpdate, defaultFieldsConfiguration, loading, deleteButton } = props;
  const { configuration } = step;
  const { selectedInstance } = useInstances();

  const [filesSearchExpression, setFilesSearchExpression] = useState(
    getRelativePath({ path: configuration?.files_search_expression ?? '', instance: selectedInstance }),
  );
  const [method, setMethod] = useState(configuration?.request?.method ?? HttpOutboundBearerStep.HTTPMethod.Post);
  const [url, setUrl] = useState(configuration?.request?.url ?? '');
  const [headers, setHeaders] = useState<[string, string][]>(Object.entries(configuration?.request?.headers ?? {}));
  const [params, setParams] = useState<[string, string][]>(Object.entries(configuration?.request?.params ?? {}));
  const [moveAfterUpload, setMoveAfterUpload] = useState(!!configuration?.move_after_upload_to_processed);

  const headersKeysValues = useMemo(
    () => headers.map<[string, string, 'text']>(([key, value]) => [key, value, 'text']),
    [headers],
  );

  const paramsKeysValues = useMemo(
    () => params.map<[string, string, 'text']>(([key, value]) => [key, value, 'text']),
    [params],
  );

  const handleUpdate = () => {
    onUpdate({
      ...step,
      configuration: {
        ...configuration,
        files_search_expression: `${selectedInstance.name}/${filesSearchExpression}`,
        move_after_upload_to_processed: moveAfterUpload,
        request: {
          ...configuration.request,
          method,
          url,
          headers: Object.fromEntries(headers),
          params: Object.fromEntries(params),
        },
      },
    });
  };

  const handleHeadersChange = ({
    key,
    value,
    index,
    action,
  }: {
    key: string;
    value: string;
    index?: number;
    action: 'edit' | 'add' | 'delete';
  }) => {
    switch (action) {
      case 'delete':
        setHeaders(headers.filter((_, paramIndex) => paramIndex !== index));
        break;
      case 'edit':
        setHeaders(headers.map((param, paramIndex) => (paramIndex !== index ? param : [key, value])));
        break;
      case 'add':
        setHeaders([...headers, [key, value]]);
        break;
      default:
    }
  };

  const handleParamsChange = ({
    key,
    value,
    index,
    action,
  }: {
    key: string;
    value: string;
    index?: number;
    action: 'edit' | 'add' | 'delete';
  }) => {
    switch (action) {
      case 'delete':
        setParams(params.filter((_, paramIndex) => paramIndex !== index));
        break;
      case 'edit':
        setParams(params.map((param, paramIndex) => (paramIndex !== index ? param : [key, value])));
        break;
      case 'add':
        setParams([...params, [key, value]]);
        break;
      default:
    }
  };

  return (
    <StepConfigLayout
      footer={
        <Wrapper gap="200" direction="column">
          <Button onClick={handleUpdate}>Update</Button>
          {deleteButton}
        </Wrapper>
      }
      loading={loading}
      loader={<FormLoadingBox items={['text', 'buttonGroup', 'block', 'double']} itemsCount={6} />}
    >
      {defaultFieldsConfiguration}

      <FormControl label="Files search expression" caption={`${selectedInstance.name}/${filesSearchExpression}`}>
        <Input value={filesSearchExpression} onChange={(event) => setFilesSearchExpression(event.target.value)} />
      </FormControl>

      <FormControl label="Method">
        <Select
          options={Object.values(HttpOutboundBearerStep.HTTPMethod).map((key) => ({ id: key, label: key }))}
          value={[{ id: method }]}
          onChange={(params) => {
            if (params.type === 'select') {
              setMethod(params.value[0].id as HttpOutboundBearerStep.HTTPMethod);
            }
          }}
        />
      </FormControl>

      <FormControl label="Url">
        <Input value={url} onChange={(event) => setUrl(event.target.value)} />
      </FormControl>

      <FormControl label="Headers">
        <KeysValues value={headersKeysValues} onChange={handleHeadersChange} />
      </FormControl>

      <FormControl label="Params">
        <KeysValues value={paramsKeysValues} onChange={handleParamsChange} />
      </FormControl>

      <Checkbox
        checked={moveAfterUpload}
        onChange={(event) => {
          setMoveAfterUpload(event.target.checked);
        }}
      >
        Move to processed after upload
      </Checkbox>
    </StepConfigLayout>
  );
}
