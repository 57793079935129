import { useMemo, useState } from 'react';

type Props<TData extends Record<string, any>> = {
  tableData: TData[];
  initialSortColumn: keyof TData;
  initialSortOrder?: 'asc' | 'desc';
  customSorters?: { [key in keyof TData]?: (a: TData[key], b: TData[key], aRow: TData, bRow: TData) => number };
};

export function useSortedColumn<TData extends Record<string, any>>(props: Props<TData>) {
  const {
    tableData,
    initialSortColumn,
    initialSortOrder = 'asc',
    customSorters = {} as Required<Props<TData>>['customSorters'],
  } = props;
  const [sortColumn, setSortColumn] = useState(initialSortColumn);
  const [sortAsc, setSortAsc] = useState(initialSortOrder === 'asc');

  const sortedData = useMemo(() => {
    return tableData.slice().sort((a, b) => {
      const left = sortAsc ? a : b;
      const right = sortAsc ? b : a;
      const leftValue = left[sortColumn];
      const rightValue = right[sortColumn];

      let result = 0;

      if (customSorters[sortColumn]) {
        return customSorters[sortColumn]!(leftValue, rightValue, left, right);
      }

      if (typeof leftValue === 'string' && typeof rightValue === 'string') {
        result = leftValue.localeCompare(rightValue);
      }

      if (typeof leftValue === 'number' && typeof rightValue === 'number') {
        result = leftValue - rightValue;
      }

      if (typeof leftValue === 'boolean' && typeof rightValue === 'boolean') {
        result = +leftValue - +rightValue;
      }

      if ((leftValue as any) instanceof Date && (rightValue as any) instanceof Date) {
        result = leftValue.valueOf() - rightValue.valueOf();
      }

      return result;
    });
  }, [sortColumn, sortAsc, tableData]);

  function handleSort(id: string) {
    if (id === sortColumn) {
      setSortAsc((asc) => !asc);
    } else {
      setSortColumn(id);
      setSortAsc(true);
    }
  }

  return {
    sortedData,
    sortColumn,
    sortAsc,
    handleSort,
  };
}
