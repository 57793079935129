import { TransformationParametersMethodAsLiteral } from 'src/dto/PipelineStep/DataStagingStep';
import { ColumnsKeyValue } from './ColumnsKeyValue';
import { ColumnsTextArea } from './ColumnsTextArea';

type Props = {
  method: TransformationParametersMethodAsLiteral;
  columns: string | [string, string][];
  onChange: (prevState: string | [string, string][]) => void;
};

export function ColumnsForm({ method, columns, onChange }: Props): JSX.Element | null {
  switch (method) {
    case 'pivot_columns_values':
    case 'rename_column':
    case 'add_static_column':
      return Array.isArray(columns) ? (
        <ColumnsKeyValue columns={columns as [string, string][]} onChange={onChange} />
      ) : null;
    case 'to_date':
    case 'to_int':
    case 'to_float':
    case 'to_boolean':
    case 'strip_value':
    case 'to_lowercase':
    case 'replace_value':
    case 'replace_column_value':
    case 'concatenate_columns':
    case 'value_arithmetic_operation':
    case 'column_arithmetic_operation':
      return <ColumnsTextArea columns={columns as string} onChange={onChange} />;
  }
}
