import { useEffect, useState } from 'react';

export function useDebouncedValue<T>(
  value: T,
  delay: number,
): { value: T; setDirect: React.Dispatch<React.SetStateAction<T>> } {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return { value: debouncedValue, setDirect: setDebouncedValue };
}
