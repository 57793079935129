import { LabelMedium, Wrapper } from '@visualfabriq/vf-ui-kit';

import { toLocalStringWithTimeZone } from 'src/utils/date/toLocalStringWithTimeZone';

import { getActionDisplayName, getObjectTyoDisplayName } from './helpers';
import { UsageLogOutputItemModel } from 'src/api-new/bullis';

export function LogTitle({ log }: { log: UsageLogOutputItemModel }) {
  return (
    <Wrapper gap={1000}>
      <LabelMedium kind="tertiary">
        {toLocalStringWithTimeZone(new Date(log.timestamp), { timeZone: 'UTC' })}
      </LabelMedium>
      <Wrapper gap={200}>
        <LabelMedium>{`${getActionDisplayName(log.action)} ${getObjectTyoDisplayName(log.object_type)}`}</LabelMedium>
        <LabelMedium kind="tertiary">by</LabelMedium>
        <LabelMedium>{log.user_identifier}</LabelMedium>
      </Wrapper>
    </Wrapper>
  );
}
