import { LabelMedium, useStyletron } from '@visualfabriq/vf-ui-kit';
import { Link } from 'react-router-dom';

import { getConfigurationName } from 'src/domain/pipelines/getConfigurationName';
import * as DataStagingStep from 'src/dto//PipelineStep/DataStagingStep';

type Props = {
  tables: DataStagingStep.FileConfiguration[];
};

export function TablesList(props: Props) {
  const { tables } = props;
  const [css, $theme] = useStyletron();

  return (
    <ul
      className={css({
        paddingLeft: 0,
        paddingRight: 0,
      })}
    >
      {tables.map((table, index) => {
        const name = getConfigurationName(table);
        return (
          <Link
            className={css({
              display: 'block',
              textDecoration: 'none',
              ':hover': {
                backgroundColor: $theme.colors.backgroundAccentLight,
                cursor: 'pointer',
              },
              ':not(:last-of-type)': {
                borderBottom: `${$theme.borders.border200.borderWidth} ${$theme.borders.border200.borderStyle} ${$theme.borders.border200.borderColor}`,
              },
            })}
            key={index}
            to={index.toString()}
          >
            <LabelMedium padding="1rem">{name}</LabelMedium>
          </Link>
        );
      })}
    </ul>
  );
}
