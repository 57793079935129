import { PipelineStepBase } from './PipelineStepBase';
import { PipelineStepType } from './PipelineStepType';

export interface HttpOutboundBearerStep extends PipelineStepBase {
  type: PipelineStepType.http_outbound_bearer;
  configuration: HttpOutboundBearerStepConfiguration;
}

export enum HTTPMethod {
  Patch = 'PATCH',
  Post = 'POST',
  Put = 'PUT',
}

export type HTTPRequestConfig = {
  method: HTTPMethod;
  url: string;
  headers?: Record<string, string>;
  params?: Record<string, string>;
};

export type HttpOutboundBearerStepConfiguration = {
  files_search_expression: string;
  request: HTTPRequestConfig;
  instance_name?: string;
  move_after_upload_to_processed: boolean;
};
