import { LabelSmall, useStyletron } from '@visualfabriq/vf-ui-kit';
import { NavLink } from 'react-router-dom';

import { textTruncate } from 'src/components/styles';

type Props = {
  item: { id: string; name: string };
  testId?: string;
};

export function ListItem({ item, testId }: Props) {
  const [css, $theme] = useStyletron();
  const $colors = $theme.colors;

  return (
    <NavLink
      to={item.id}
      className={({ isActive }) => css({ color: isActive ? $colors.contentAccent : $colors.contentSecondary })}
      data-testid={testId}
    >
      <LabelSmall overrides={LabelOverrides}>{item.name}</LabelSmall>
    </NavLink>
  );
}

const LabelOverrides = {
  Block: {
    style: {
      width: '100%',
      color: 'inherit',
      textDecoration: 'none',
      ':hover': { textDecoration: 'underline' },
      ...textTruncate,
    },
  },
};
