// base on https://docs.aws.amazon.com/eventbridge/latest/userguide/eb-create-rule-schedule.html#eb-cron-expressions
// and
// https://gist.github.com/ultrasonex/e1fdb8354408a56df91aa4902d17aa6a

const minutesRegex =
  /^([*]|([0-5]?\d)|((([0-5]?\d)|(\*))\/([0-5]?\d))|(([0-5]?\d)-([0-5]?\d))|((([0-5]?\d)|(\*))(,(([0-5]?\d)|(\*)))*))$/;

const hoursRegex =
  /^([*]|[01]?\d|2[0-3]|((([01]?\d|2[0-3]?)|(\*))\/([01]?\d|2[0-3]?))|(([01]?\d|2[0-3]?)-([01]?\d|2[0-3]?))|((([01]?\d|2[0-3]?)|(\*))((,)(([01]?\d|2[0-3]?)|(\*))){0,23}))$/;

const dayOfMonthRegex =
  /^([*]|[?]|(([1-9]|[12]\d|3[01])[LW]?)|(([1-9]|[12]\d|3[01])-([1-9]|[12]\d|3[01]))|((([1-9]|[12]\d|3[01])|(\*))(\/)([1-9]|[12]\d|3[01]))|((([1-9]|[12]\d|3[01])|(\*))((,)(([1-9]|[12]\d|3[01])|(\*)))*))$/;

const monthRegex =
  /^([*]|([2-9]|1[0-2]?)|(JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)|((([2-9]|1[0-2]?)|(JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)|(\*))\/(([2-9]|1[0-2]?)|(JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)))|((([2-9]|1[0-2]?)|(JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC))-(([2-9]|1[0-2]?)|(JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)))|((([2-9]|1[0-2]?)|(JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)|(\*))((,)(([2-9]|1[0-2]?)|(JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)|(\*)))*))$/;

const dayOfWeekRegex =
  /^([*]|[?]|([1-7]L?)|(SUN|MON|TUE|WED|THU|FRI|SAT)|((([1-7])|(SUN|MON|TUE|WED|THU|FRI|SAT))(-|,|#)(([1-7])|(SUN|MON|TUE|WED|THU|FRI|SAT)))|((([1-7])|(SUN|MON|TUE|WED|THU|FRI|SAT)|(\*))\/(([1-7])|(SUN|MON|TUE|WED|THU|FRI|SAT)))|((([1-7])|(SUN|MON|TUE|WED|THU|FRI|SAT)|(\*))((,)(([1-7])|(SUN|MON|TUE|WED|THU|FRI|SAT)|(\*)))*))$/;

const yearRegex =
  /^([*]|([1-2]\d{3})|(((([1-2]\d{3})|(\*)))\/((\d{0,4})))|(([1-2]\d{3})-([1-2]\d{0,3}))|((([1-2]\d{3})|(\*))((,)(([1-2]\d{3})|(\*)))*))$/;

const createValidate = (regex: RegExp) => (value: string) => regex.test(value);

export const validateMinutes = createValidate(minutesRegex);
export const validateHours = createValidate(hoursRegex);
export const validateDayOfMonth = createValidate(dayOfMonthRegex);
export const validateMonth = createValidate(monthRegex);
export const validateDayOfWeek = createValidate(dayOfWeekRegex);
export const validateYear = (value: string) => {
  if (!yearRegex.test(value)) return false;
  const year = Number.parseInt(value);

  // will be NaN for , - * / and we already check it with yearRegex
  return Number.isNaN(year) || (year >= 1970 && +year <= 2199);
};
