export class EnvVars {
  static apiBaseUrl = process.env.API_BASE_URL;
  static authBaseUrl = process.env.AUTH_BASE_URL;
  static bullisBaseUrl = process.env.AUTH_BULLIS_URL;
  static region = process.env.REGION;

  static readonly version = process.env.VERSION;
  static readonly isDevMode = process.env.DEV === 'true';
  static readonly isMFE = process.env.IS_MFE === 'true';
  static readonly environment = process.env.ENVIRONMENT;
  static readonly isMaintenance = process.env.IS_MAINTENANCE === 'true';
  static readonly commitHash = process.env.COMMIT_HASH;
  static readonly isSentryEnabled = process.env.IS_SENTRY_ENABLED === 'true';
}
