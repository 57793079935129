import { Tooltip, WarningIcon, Wrapper, useStyletron, styled } from '@visualfabriq/vf-ui-kit';

export function DifferentInstancesWarning({ bfName, vfName }: { bfName: string; vfName: string }) {
  const [, theme] = useStyletron();

  return (
    <Wrapper width="24px" height="24px">
      <Tooltip
        content={
          <Wrapper direction="column">
            <div>
              You are currently working in a different instance than the one selected on main application level. Active
              selected instance:
            </div>
            <div>
              Bifrost: <Code>{bfName}</Code>
            </div>
            <div>
              Main Application: <Code>{vfName}</Code>
            </div>
          </Wrapper>
        }
        placement="bottom"
      >
        <WarningIcon size="24px" color={theme.colors.warning} />
      </Tooltip>
    </Wrapper>
  );
}

const Code = styled('code', ({ $theme }) => ({
  backgroundColor: $theme.colors.backgroundAlwaysDark,
  color: $theme.colors.accent100,
}));
