import { Button, styled, Tag, Wrapper } from '@visualfabriq/vf-ui-kit';

import { textTruncate } from 'src/components/styles';

type Props = {
  selected: boolean;
  draft: boolean;
  template: { id: string; label: string };
  onSelect: (template: { id: string; label: string }) => void;
};

export function TemplateListItem(props: Props) {
  const { selected, template, draft, onSelect } = props;

  return (
    <Wrapper direction="column" paddingBlock={200} gap={200} alignItems="self-start">
      <Button
        kind={selected ? 'secondary' : 'tertiary'}
        key={template.id}
        onClick={() => onSelect({ id: template.id, label: template.label })}
        overrides={{ BaseButton: { style: () => ({ maxWidth: '100%' }) } }}
      >
        <ButtonText>{template.label}</ButtonText>

        {draft && (
          <Tag color="orange" size="small">
            Draft
          </Tag>
        )}
      </Button>
    </Wrapper>
  );
}

const ButtonText = styled('span', () => ({ ...textTruncate }));
