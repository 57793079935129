import { Outlet } from 'react-router-dom';

import { PipelinesProvider } from 'src/domain/pipelines/PipelinesProvider';
import { Pipelines } from 'src/containers/Pipelines';
import { PageGuard } from 'src/pages/PageGuard';
import { DrawerProvider } from 'src/components/molecules/Drawer';
import { PAGES_CONFIG } from '../config';

export function PipelinesPage() {
  return (
    <PageGuard hasAccess={PAGES_CONFIG.pipelines.hasAccess}>
      <PipelinesProvider>
        <DrawerProvider>
          <Outlet />
          <Pipelines />
        </DrawerProvider>
      </PipelinesProvider>
    </PageGuard>
  );
}
