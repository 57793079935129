import { Button, AddIcon, NoContent, Skeleton, Wrapper } from '@visualfabriq/vf-ui-kit';

import { groupMultipleByField } from 'src/utils/groupMultipleByField';
import { Collapse } from 'src/components/organisms/Collapse';

import { TemplateListItem } from './TemplateListItem';
import { PipelineTemplateListItem, VFModule } from 'src/api-new/bifrost';

type Props = {
  isLoading: boolean;
  templates: PipelineTemplateListItem[];
  selectedId: string | undefined;
  openModules: Set<string>;
  modules: VFModule[];
  onSelect: (event: { id: string; label: string }) => void;
  createDraftTemplate: (moduleId: string) => void;
  onOpenChange: (event: { isOpen: boolean; id: string }) => void;
};
export function TemplatesList(props: Props) {
  const { templates, selectedId, openModules, modules, isLoading, createDraftTemplate, onSelect, onOpenChange } = props;

  const templatesByModule = groupMultipleByField(templates, 'vf_module_id');

  if (isLoading) {
    return <Skeleton height="50rem" width="15rem" />;
  }

  return (
    <Wrapper direction="column" width="15rem">
      {modules.map((module) => {
        const templates = templatesByModule.get(module.id);

        return (
          <Collapse
            key={module.id}
            heading={module.name}
            initialOpenState={openModules.has(module.id)}
            onOpenChange={(isOpen) => onOpenChange({ id: module.id, isOpen })}
          >
            {templates ? (
              templates.map((template) => (
                <TemplateListItem
                  key={template.id}
                  template={template}
                  selected={template.id === selectedId}
                  draft={template.id === 'draft'}
                  onSelect={onSelect}
                />
              ))
            ) : (
              <NoContent message="No templates yet" />
            )}
            <Button kind="tertiary" onClick={() => createDraftTemplate(module.id)}>
              <AddIcon /> New Template
            </Button>
          </Collapse>
        );
      })}
    </Wrapper>
  );
}
