export class DayOfWeek {
  static readonly replaceRegex = /(^\d)|([,-]\d)/g; // only digit or ","/"-" and digit

  private static extractDigitPart(value: string) {
    // extract digit part (i.e. if "-2" or ",2", just take 2)
    return value.replace(/\D/, '');
  }

  static awsToStandard(dow: string) {
    return dow.replace(DayOfWeek.replaceRegex, (match) => {
      const digit = DayOfWeek.extractDigitPart(match);
      const digitAdjusted = digit === '1' ? '7' : String(Number(digit) - 1);
      return match.replace(digit, digitAdjusted);
    });
  }

  static standardToAWS(dow: string) {
    return dow.replace(DayOfWeek.replaceRegex, (match) => {
      const digit = DayOfWeek.extractDigitPart(match);
      const digitAdjusted = digit === '7' ? '1' : String(Number(digit) + 1);
      return match.replace(digit, digitAdjusted);
    });
  }
}

type CronKeys = 'minutes' | 'hours' | 'dom' | 'month' | 'dow' | 'year';
type CronReplacementObj = Partial<Record<CronKeys, (value: string) => string>>;
type CronObj = Record<CronKeys, string>;

export function replaceCron(cron: string, replace: Partial<CronReplacementObj>) {
  const [minutes, hours, dom, month, dow, year] = cron.split(' ');
  const result: CronObj = {
    minutes: replace.minutes?.(minutes) ?? minutes,
    hours: replace.hours?.(hours) ?? hours,
    dom: replace.dom?.(dom) ?? dom,
    month: replace.month?.(month) ?? month,
    dow: replace.dow?.(dow) ?? dow,
    year: replace.year?.(year) ?? year,
  };

  return [result.minutes, result.hours, result.dom, result.month, result.dow, result.year].join(' ');
}
