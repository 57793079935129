import { ErrorPageGeneral } from '@visualfabriq/vf-ui-kit';
import image503 from '@visualfabriq/vf-ui-kit/dist/images/503.png';

export function ErrorGeneral({
  header,
  title,
  description,
}: {
  header?: string;
  title?: string;
  description?: string;
}) {
  return (
    <div style={{ display: 'flex', height: '100%', width: '100%', position: 'relative' }}>
      <ErrorPageGeneral title={title} image={image503} header={header} description={description} />
    </div>
  );
}
