import { useEffect, useState } from 'react';
import { Button, FormControl, Wrapper } from '@visualfabriq/vf-ui-kit';

import { Args } from './Args';
import { TemplateStepConfigurationForm } from './TemplateStepConfigurationForm';
import { DraftableDataProcessingConfiguration } from '../../../drafts';
import { DataProcessingArgs } from 'src/api-new/bifrost';

type Props = {
  stepConfiguration: DraftableDataProcessingConfiguration;
  onChange: (value: DraftableDataProcessingConfiguration) => void;
  onApplyChanges: () => void;
  onCancel: () => void;
  onValidChange: (id: string, isValid: boolean) => void;
  onDelete: (id: string) => void;
};

export function DataProcessingTemplateForm(props: Props) {
  const { stepConfiguration, onChange, onValidChange, onApplyChanges, onDelete, onCancel } = props;

  const [unsavedArgs, setUnsavedArgs] = useState(new Set<string>());

  const [isConfigurationValid, setIsConfigurationValid] = useState(true);
  const [isArgumentsValid, setIsArgumentsValid] = useState(true);

  useEffect(() => {
    onValidChange(stepConfiguration.id, isConfigurationValid && isArgumentsValid);
  }, [isConfigurationValid, isArgumentsValid]);

  const handleArgsChange = (args: DataProcessingArgs[], changedArgId: string) => {
    setUnsavedArgs(new Set(unsavedArgs.add(changedArgId)));
    onChange({ ...stepConfiguration, args });
  };

  const handleArgDelete = (args: DataProcessingArgs[], deletedArgId: string) => {
    if (unsavedArgs.has(deletedArgId)) {
      unsavedArgs.delete(deletedArgId);
      setUnsavedArgs(new Set(unsavedArgs));
    }
    onChange({ ...stepConfiguration, args });
  };

  return (
    <>
      <TemplateStepConfigurationForm
        key={stepConfiguration.id}
        configuration={stepConfiguration}
        onChange={onChange}
        onValidChange={setIsConfigurationValid}
      />
      <FormControl required label="Arguments">
        <Args
          unsavedArgs={unsavedArgs}
          args={stepConfiguration.args}
          onChange={handleArgsChange}
          onDelete={handleArgDelete}
          onValidChange={setIsArgumentsValid}
        />
      </FormControl>

      <Wrapper gap="200">
        {stepConfiguration.draft && (
          <Button disabled={!(isConfigurationValid && isArgumentsValid)} onClick={onApplyChanges}>
            Apply Changes
          </Button>
        )}
        {stepConfiguration.draft ? (
          <Button kind="secondary" onClick={onCancel}>
            Cancel
          </Button>
        ) : (
          <div style={{ marginLeft: 'auto' }}>
            <Button kind="secondary" onClick={() => onDelete(stepConfiguration.id)}>
              Delete
            </Button>
          </div>
        )}
      </Wrapper>
    </>
  );
}
