import { Button, ExpandLessIcon, ExpandMoreIcon, Wrapper, useStyletron } from '@visualfabriq/vf-ui-kit';
import { useState } from 'react';

import { UsageLogOutputItemModel } from 'src/api-new/bullis';
import { JsonContentViewer } from 'src/components/atoms/JsonContentViewer';
import { Field, Name, Value } from 'src/components/organisms/Fields';
import { formatContext } from './helpers';

export function LogDetails({ log }: { log: UsageLogOutputItemModel }) {
  const [css, theme] = useStyletron();
  const [expandContext, setExpandContext] = useState(false);

  return (
    <Wrapper direction="column" className={css({ backgroundColor: theme.colors.white })} padding={400}>
      <Field>
        <Name>User</Name>
        <Value>{log.user_identifier}</Value>
      </Field>
      <Field>
        <Name>Instance</Name>
        <Value>{log.instance_name}</Value>
      </Field>
      <Field>
        <Name>Module</Name>
        <Value>{log.module_name}</Value>
      </Field>
      <Field>
        <Name>Aggregate Identifier</Name>
        <Value>{log.aggregate_identifier}</Value>
      </Field>
      <Field>
        <Name>Object Identifier</Name>
        <Value>{log.object_identifier}</Value>
      </Field>
      <Field>
        <Name>Object Type</Name>
        <Value>{log.object_type}</Value>
      </Field>
      <Field>
        <Name>Object Name</Name>
        <Value>{log.object_name}</Value>
      </Field>
      <Field>
        <Name>Action</Name>
        <Value>{log.action}</Value>
      </Field>
      <Field>
        <Name>Success</Name>
        <Value>{String(log.success)}</Value>
      </Field>
      <Wrapper direction="column" gap={200}>
        <Field>
          {log.context && (
            <Button
              endEnhancer={expandContext ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              onClick={() => setExpandContext(!expandContext)}
            >
              {expandContext ? 'Hide Context' : 'Show Context'}
            </Button>
          )}
        </Field>
        {expandContext && <JsonContentViewer content={formatContext(log.context as string)} />}
      </Wrapper>
    </Wrapper>
  );
}
