import { TransformationModel } from './TransformationModel';

export interface TableImportModel {
  persist_method: PersistMethod;
  update_keys: string[];
  mandatory_keys: string[];
  transformations: TransformationModel[];
}

export enum PersistMethod {
  Truncate = 'truncate',
  Append = 'append',
  Upsert = 'upsert',
}
