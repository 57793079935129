import { ReactNode } from 'react';
import { Button, Tooltip, TetherPlacement } from '@visualfabriq/vf-ui-kit';

type Props = {
  kind?: 'secondary' | 'primary' | 'tertiary';
  icon: ReactNode;
  title: string;
  disabled?: boolean;
  placement?: TetherPlacement;
  onClick?: (_: React.SyntheticEvent<HTMLButtonElement, Event>) => unknown;
};

export function IconButton(props: Props) {
  const { icon, title, disabled = false, onClick, kind = 'primary', placement = 'auto' } = props;
  return (
    <Tooltip content={title} placement={placement}>
      <Button kind={kind} disabled={disabled} onClick={onClick}>
        {icon}
      </Button>
    </Tooltip>
  );
}
