import { Children, ReactElement, ReactNode, useState } from 'react';

export function useStepper<TState extends Record<string, any>>(initialState: TState) {
  const [step, setStep] = useState(0);
  const [state, setState] = useState(initialState);

  const nextStep = (stateUpdate?: Partial<TState>) => {
    if (stateUpdate) {
      setState({ ...state, ...stateUpdate });
    }
    setStep((step) => step + 1);
  };

  const prevStep = () => {
    setStep((step) => step - 1);
  };

  const reset = () => {
    setState(initialState);
    setStep(0);
  };

  type StepperProps = { children?: ReactNode; loading?: boolean; loader: JSX.Element };
  function Stepper(props: StepperProps) {
    const { children, loading = false, loader } = props;

    if (loading) return loader;

    return Children.toArray(children)[step] as ReactElement;
  }

  return { Stepper, nextStep, prevStep, reset, state, step };
}
