import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { pathConstants } from 'src/routes';

import { EnvVars } from 'src/services/envVars';

export const IsMaintenanceGuard = ({ element }: { element: React.ReactNode }) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (EnvVars.isMaintenance) {
      navigate(pathConstants.MAINTENANCE);
    }
  }, []);

  return <>{element}</>;
};
