import { useEffect, useMemo } from 'react';
import { Pagination, TableBuilder, TableBuilderColumn } from '@visualfabriq/vf-ui-kit';

import { Selectable, useSelectColumn } from 'src/components/hooks/table/useSelectColumn';
import { useSortedColumn } from 'src/components/hooks/table/useSortedColumn';
import { usePaginate } from 'src/components/hooks/usePaginate';
import { Copyable } from 'src/components/atoms/CopyableColumn';
import { HTTPAPIKey as HttpKey } from 'src/api-new/bifrost';

type Props = {
  keys: HttpKey[];
  onSelect: (tableData: Selectable<HttpKey>[]) => void;
  loading: boolean;
};

export function HttpKeysTable({ keys, onSelect, loading }: Props) {
  const tableData = useMemo(
    () =>
      keys.map((credential) => ({
        id: credential.id,
        label: credential.key_label,
        key: credential.shared_key,
      })),
    [keys],
  );

  const { sortedData, sortColumn, sortAsc, handleSort } = useSortedColumn({
    tableData,
    initialSortColumn: 'label',
  });
  const { currentPage, pagesNumber, paginatedItems, setCurrentPage } = usePaginate({ items: sortedData, pageSize: 10 });
  const [selectColumn, selectableTableData] = useSelectColumn(
    {
      tableData: paginatedItems,
      onSelect: (selectedIds) => {
        const newTableItems = keys.map((tableItem) => {
          return { ...tableItem, selected: selectedIds.has(tableItem.id) };
        });
        onSelect(newTableItems);
      },
    },
    [keys],
  );

  useEffect(() => {
    setCurrentPage(0);
  }, [keys]);

  return (
    <>
      <TableBuilder
        overrides={{ Root: { style: { maxHeight: '90%' } } }}
        data={selectableTableData}
        sortColumn={sortColumn}
        sortOrder={sortAsc ? 'ASC' : 'DESC'}
        onSort={handleSort}
        isLoading={loading}
      >
        {selectColumn}
        <TableBuilderColumn id="label" header="Key Label" sortable>
          {(row) => <Copyable>{row.label}</Copyable>}
        </TableBuilderColumn>
        <TableBuilderColumn id="key" header="Key Content" sortable>
          {(row) => <Copyable>{row.key}</Copyable>}
        </TableBuilderColumn>
      </TableBuilder>
      <Pagination
        currentPage={!pagesNumber ? 0 : currentPage + 1}
        numPages={pagesNumber}
        onPageChange={({ nextPage }) => setCurrentPage(nextPage - 1)}
      />
    </>
  );
}
