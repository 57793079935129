import { PipelineTemplateCreate } from 'src/api-new/bifrost';

export const STEP_CONFIGURATION_DRAFT_ID = 'STEP_CONFIGURATION_DRAFT_ID';
export const TEMPLATE_DRAFT_ID = 'TEMPLATE_DRAFT_ID';

export type DraftTemplate = PipelineTemplateCreate & { id: string };
export const draftTemplate: Omit<DraftTemplate, 'vf_module_id'> = {
  id: TEMPLATE_DRAFT_ID,
  label: 'New Pipeline',
  mandatory: false,
  description: 'Pipeline description',
  steps_configuration: [],
  zendesk_url: '',
};
