import * as DataStagingStep from 'src/dto/PipelineStep/DataStagingStep';

import { ColumnArithmeticOperationMethod } from './ColumnArithmeticOperationMethod';
import { ConcatenateColumnsMethod } from './ConcatenateColumnsMethod';
import { ReplaceColumnValueMethod } from './ReplaceColumnValueMethod';
import { ReplaceValueMethod } from './ReplaceValueMethod';
import { StripValueMethod } from './StripValueMethod';
import { ToBooleanMethod } from './ToBooleanMethod';
import { ToDateMethod } from './ToDateMethod';
import { ToFloatMethod } from './ToFloatMethod';
import { ToIntMethod } from './ToIntMethod';
import { ValueArithmeticOperationMethod } from './ValueArithmeticOperationMethod';
import { PivotColumnValuesMethod } from './PivotColumnValuesMethod';

export function MethodForm({
  fields,
  onChange,
  onValidChange,
}: {
  fields: DataStagingStep.TransformationParameters;
  onChange: (fields: DataStagingStep.TransformationParameters) => void;
  onValidChange: (value: boolean) => void;
}) {
  if (!fields) {
    return null;
  }

  switch (fields.method) {
    case DataStagingStep.TransformationParametersMethod.ToDate:
      return <ToDateMethod fields={fields} onUpdate={onChange} />;
    case DataStagingStep.TransformationParametersMethod.ToInt:
      return <ToIntMethod fields={fields} onUpdate={onChange} />;
    case DataStagingStep.TransformationParametersMethod.ToFloat:
      return <ToFloatMethod fields={fields} onUpdate={onChange} />;
    case DataStagingStep.TransformationParametersMethod.ToBoolean:
      return (
        <ToBooleanMethod
          fields={fields}
          onUpdate={({ valid, fields: { defaultValue, forceType } }) => {
            onValidChange(valid);
            if (valid && defaultValue) {
              onChange({
                ...fields,
                default: defaultValue,
                force_type: forceType,
              });
            }
          }}
        />
      );
    case DataStagingStep.TransformationParametersMethod.StripValue:
      return <StripValueMethod fields={fields} onUpdate={onChange} />;
    case DataStagingStep.TransformationParametersMethod.ReplaceColumnValue:
      return <ReplaceColumnValueMethod fields={fields} onUpdate={onChange} />;
    case DataStagingStep.TransformationParametersMethod.ReplaceValue:
      return <ReplaceValueMethod fields={fields} onUpdate={onChange} />;
    case DataStagingStep.TransformationParametersMethod.ConcatenateColumns:
      return <ConcatenateColumnsMethod fields={fields} onUpdate={onChange} />;
    case DataStagingStep.TransformationParametersMethod.ValueArithmeticOperation:
      return <ValueArithmeticOperationMethod fields={fields} onUpdate={onChange} />;
    case DataStagingStep.TransformationParametersMethod.ColumnArithmeticOperation:
      return <ColumnArithmeticOperationMethod fields={fields} onUpdate={onChange} />;
    case DataStagingStep.TransformationParametersMethod.PivotColumnValues:
      return <PivotColumnValuesMethod fields={fields} onUpdate={onChange} />;
    default:
      return null;
  }
}
