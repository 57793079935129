import { ModalBody, ModalHeader, Wrapper, Notification, useStyletron, LabelMedium } from '@visualfabriq/vf-ui-kit';
import { CodeBlock } from 'src/components/atoms/CodeBlock';

import { CreatePipelineUsingTemplateError } from 'src/domain/pipelines/PipelinesProvider/types';

export function CreateFromTemplatesErrors({ errorsMap }: { errorsMap: Map<string, CreatePipelineUsingTemplateError> }) {
  const [css] = useStyletron();
  return (
    <>
      <ModalHeader>Something went wrong</ModalHeader>
      <ModalBody>
        <Wrapper width="800px" height="600px" className={css({ overflowY: 'auto' })} direction="column" gap={200}>
          {Array.from(errorsMap.entries()).map(([templateId, templateError]) => (
            <Notification key={templateId} kind="negative">
              <Wrapper direction="column">
                <LabelMedium>
                  Error happened during creating creating <u>{templateError.template.label}</u>
                </LabelMedium>
                <CodeBlock>{templateError.error.message}</CodeBlock>
              </Wrapper>
            </Notification>
          ))}
        </Wrapper>
      </ModalBody>
    </>
  );
}
