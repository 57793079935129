import { Wrapper, useStyletron } from '@visualfabriq/vf-ui-kit';

import { MFE_NAV_HEIGHT, WRAPPER_TITLE_HEIGHT } from 'src/components/styles';

export function StepLayout({ children }: { children: React.ReactNode }) {
  const [css] = useStyletron();
  return (
    <Wrapper
      direction="column"
      padding="600"
      className={css({
        height: `calc(100vh - ${WRAPPER_TITLE_HEIGHT} - ${MFE_NAV_HEIGHT})`,
        maxWidth: '70vw',
        boxSizing: 'border-box',
      })}
    >
      {children}
    </Wrapper>
  );
}
