import { ModalBody, ModalHeader } from '@visualfabriq/vf-ui-kit';

import { bytesToSize } from 'src/utils/bytesToSize';

type UploadLimitMessageProps = {
  exceedLimitFiles: File[];
  limitInBytes: number;
};

export function UploadLimitMessageModal(props: UploadLimitMessageProps) {
  const { exceedLimitFiles, limitInBytes } = props;
  return (
    <>
      <ModalHeader>Following file(s) exceed upload file limit ({bytesToSize(limitInBytes)}):</ModalHeader>
      <ModalBody>
        {exceedLimitFiles.map((file) => (
          <p key={file.name}>
            {file.name} ({bytesToSize(file.size)})
          </p>
        ))}
      </ModalBody>
    </>
  );
}
