import { PipelineStep } from 'src/dto/PipelineStep';

import { PipelineType } from './PipelineType';
import { FSM, PipelineExecutionWithoutDetails, ContinueNotSuccessExecution } from 'src/api-new/bifrost';

export interface PipelineFilteredDTO {
  id: string;
  name: string;
  description: string;
  type: PipelineType;
  instance_id: string;
  enabled: boolean;
  blocked: boolean;
  /**
   * @deprecated The field should not be used
   */
  blocked_details: string;
  schedule: string;
  executions: PipelineExecutionWithoutDetails[];
  /**
   * @deprecated The field should not be used
   */
  subscribers_emails: string[];
  owner_email: string;
  fsm: FSM;
  continue_not_success_execution?: ContinueNotSuccessExecution;
  notifications_enabled?: boolean;
  time_zone: string;
}

export type PipelineDTO = Omit<PipelineFilteredDTO, 'executions'>;

export interface CreatePipelineDTO {
  name: string;
  schedule: string;
  description?: string;
  steps?: PipelineStep[];
  instanceId: string;
  time_zone?: string;
  owner_email?: string;
}

export const CONTINUE_NOT_SUCCESS_EXECUTION_LABELS: Record<ContinueNotSuccessExecution, string> = {
  [ContinueNotSuccessExecution.Always]: 'Always',
  [ContinueNotSuccessExecution.Never]: 'Never',
  [ContinueNotSuccessExecution.OnNoAction]: 'On no action',
};
