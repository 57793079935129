import { useContext, useState } from 'react';
import {
  Breadcrumbs,
  BreadcrumbsLoadingBox,
  Button,
  NoContent,
  styled,
  useSnackbar,
  Notification,
  ListLoadingBox,
  FormLoadingBox,
} from '@visualfabriq/vf-ui-kit';
import { useParams } from 'react-router-dom';

import { ModalContext } from 'src/components/molecules/Modal/ModalProvider';
import { PipelineStep } from 'src/dto/PipelineStep';

import { AddStepModal } from './AddStep';
import { getOrderedPipelineSteps } from 'src/domain/pipelines/getOrderedPipelineSteps';
import { moveItem } from 'src/utils/moveItem';
import { Steps } from './Steps';
import { pipelinesBreadcrumbsOverrides } from 'src/components/styles';
import { useBifrostApi } from 'src/services/useBifrostApi';
import { FsmsApi, PipelineStepsApi, PipelinesApi } from 'src/api-new/bifrost';
import { useAsync, useAsyncRetry } from 'react-use';
import { captureException } from 'src/services/sentry';

export function PipelineSteps() {
  const params = useParams();
  const pipelineId = params.pipelineId!;

  const { openModal, closeModal } = useContext(ModalContext);
  const { enqueueErrorSnackbar } = useSnackbar();
  const fsmsApi = useBifrostApi(FsmsApi);
  const pipelineStepsApi = useBifrostApi(PipelineStepsApi);
  const pipelinesApi = useBifrostApi(PipelinesApi);
  const [stepsUpdateLoading, setStepUpdateLoading] = useState(false);
  const [reorderMode, setReorderMode] = useState(false);

  const pipeline = useAsync(async () => {
    try {
      const { data } = await pipelinesApi.getPipeline({ pipelineId });
      return data;
    } catch (error) {
      captureException(error);
      throw error;
    }
  }, [pipelineId]);

  const steps = useAsyncRetry(async () => {
    if (!pipeline.value) return;
    try {
      const [{ data: steps }, { data: fsm }] = await Promise.all([
        pipelineStepsApi.getPipelineSteps({ pipelineId: pipeline.value.id }),
        fsmsApi.getFsm({ fsmId: pipeline.value.fsm!.id }),
      ]);
      return getOrderedPipelineSteps(steps as any as PipelineStep[], fsm.transitions ?? []);
    } catch (error) {
      captureException(error);
      throw error;
    }
  }, [pipeline]);

  const handleAddStep = () => {
    openModal(
      <AddStepModal
        onConfirm={async ({ type, name, configuration, description }) => {
          try {
            closeModal();
            setStepUpdateLoading(true);
            const lastStepId = steps.value ? steps[steps.value.length - 1]?.id : undefined;
            await pipelineStepsApi.createPipelineStep({
              pipelineStepCreate: {
                type,
                name,
                pipeline_id: pipelineId!,
                configuration,
                description,
                previous_step_id: lastStepId,
              },
            });
          } catch (error) {
            enqueueErrorSnackbar(error.message);
            captureException(error);
            throw error;
          } finally {
            setStepUpdateLoading(false);
            steps.retry();
          }
        }}
        onCancel={closeModal}
      />,
    );
  };

  const handleStepsReorder = async ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    if (!steps.value) return;
    try {
      setStepUpdateLoading(true);
      const newStepOrder = moveItem(steps.value, oldIndex, newIndex);
      await pipelineStepsApi.movePipelineStep({
        pipelineStepId: steps.value[oldIndex].id,
        pipelineStepMove: { previous_step_id: newStepOrder[newIndex - 1]?.id },
      });
      steps.retry();
    } catch (error) {
      enqueueErrorSnackbar(error.message);
      captureException(error);
      throw error;
    } finally {
      setStepUpdateLoading(false);
    }
  };

  if (steps.loading || pipeline.loading || stepsUpdateLoading) {
    const stepsCount = steps.value?.length ?? 4;
    return (
      <PipelineStepsWrapper>
        <ListLoadingBox itemsCount={stepsCount} />
        <FormLoadingBox items={['buttonGroup']} itemsCount={1} />
      </PipelineStepsWrapper>
    );
  }

  if (steps.error || pipeline.error) {
    return (
      <PipelineStepsWrapper>
        <NoContent message={`Can't load Steps`} />
        {steps.error && <Notification kind="negative" description={steps.error.message} closeable={false} />}
        {pipeline.error && <Notification kind="negative" description={pipeline.error.message} closeable={false} />}
      </PipelineStepsWrapper>
    );
  }

  return (
    <PipelineStepsWrapper>
      {pipeline.value ? (
        <Breadcrumbs overrides={pipelinesBreadcrumbsOverrides} steps={[{ name: pipeline.value.name }]} />
      ) : (
        <BreadcrumbsLoadingBox itemsCount={1} />
      )}
      <Steps
        steps={steps.value}
        reorderMode={reorderMode}
        onReorderModeChange={setReorderMode}
        onReorder={handleStepsReorder}
        addStepButton={<Button onClick={handleAddStep}>Add Step</Button>}
      />
    </PipelineStepsWrapper>
  );
}

const PipelineStepsWrapper = styled('div', ({ $theme }) => ({
  width: '600px',
  padding: $theme.sizing.scale100,
}));
