import { useState } from 'react';
import { ModalBody, ModalFooter, ModalHeader, ModalButton } from '@visualfabriq/vf-ui-kit';

import * as DataStagingStep from 'src/dto/PipelineStep/DataStagingStep';

type NewTransformationModalPros = {
  onAdd: (method: DataStagingStep.TransformationType) => void;
  onCancel: () => void;
};

const transformationTypes = [
  DataStagingStep.TransformationType.ColumnTransformation,
  DataStagingStep.TransformationType.TypeTransformation,
  DataStagingStep.TransformationType.ValueTransformation,
];

export function NewTransformationModal(props: NewTransformationModalPros) {
  const { onAdd, onCancel } = props;

  const [selectedMethod, setSelectedMethod] = useState(DataStagingStep.TransformationType.TypeTransformation);

  const handleAdd = () => {
    onAdd(selectedMethod);
  };

  return (
    <>
      <ModalHeader>Add Transformation</ModalHeader>
      <ModalBody>
        <select onChange={(event) => setSelectedMethod(event.target.value as DataStagingStep.TransformationType)}>
          {transformationTypes.map((method) => (
            <option key={method} value={method} selected={selectedMethod === method}>
              {method}
            </option>
          ))}
        </select>
      </ModalBody>
      <ModalFooter>
        <ModalButton onClick={handleAdd}>Add</ModalButton>
        <ModalButton kind="secondary" onClick={onCancel}>
          Cancel
        </ModalButton>
      </ModalFooter>
    </>
  );
}
