import { FormControl, Textarea } from '@visualfabriq/vf-ui-kit';

type Props = {
  columns: string;
  onChange: (columns: string) => void;
};
export function ColumnsTextArea({ columns, onChange }: Props) {
  return (
    <FormControl
      label="Columns"
      required
      caption={
        <span>
          Enter a list of columns separated by comma i.e., <code>column1, column2, column3</code>
        </span>
      }
    >
      <Textarea value={columns} onChange={(event) => onChange(event.target.value)} />
    </FormControl>
  );
}
