/* tslint:disable */
/* eslint-disable */
/**
 * Integrations
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 *
 * @export
 * @interface AddStaticColumn
 */
export interface AddStaticColumn {
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof AddStaticColumn
   */
  columns: { [key: string]: string };
  /**
   *
   * @type {string}
   * @memberof AddStaticColumn
   */
  method: AddStaticColumnMethod;
}

export const AddStaticColumnMethod = {
  AddStaticColumn: 'add_static_column',
} as const;

export type AddStaticColumnMethod = (typeof AddStaticColumnMethod)[keyof typeof AddStaticColumnMethod];

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const ArithmeticsOperators = {
  Add: 'add',
  Subtract: 'subtract',
  Multiply: 'multiply',
  Divide: 'divide',
} as const;

export type ArithmeticsOperators = (typeof ArithmeticsOperators)[keyof typeof ArithmeticsOperators];

/**
 *
 * @export
 * @interface BodyGetPipelineTemplates
 */
export interface BodyGetPipelineTemplates {
  /**
   *
   * @type {Array<string>}
   * @memberof BodyGetPipelineTemplates
   */
  ids?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof BodyGetPipelineTemplates
   */
  module_ids?: Array<string>;
  /**
   *
   * @type {Array<LabelFilter>}
   * @memberof BodyGetPipelineTemplates
   */
  filters?: Array<LabelFilter>;
}
/**
 *
 * @export
 * @interface BodyGetPipelineTemplatesShort
 */
export interface BodyGetPipelineTemplatesShort {
  /**
   *
   * @type {Array<string>}
   * @memberof BodyGetPipelineTemplatesShort
   */
  ids?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof BodyGetPipelineTemplatesShort
   */
  module_ids?: Array<string>;
  /**
   *
   * @type {Array<LabelFilter>}
   * @memberof BodyGetPipelineTemplatesShort
   */
  filters?: Array<LabelFilter>;
}
/**
 *
 * @export
 * @interface ColumnArithmeticOperation
 */
export interface ColumnArithmeticOperation {
  /**
   *
   * @type {Columns}
   * @memberof ColumnArithmeticOperation
   */
  columns: Columns;
  /**
   *
   * @type {string}
   * @memberof ColumnArithmeticOperation
   */
  method: ColumnArithmeticOperationMethod;
  /**
   *
   * @type {string}
   * @memberof ColumnArithmeticOperation
   */
  to_column: string;
  /**
   *
   * @type {ArithmeticsOperators}
   * @memberof ColumnArithmeticOperation
   */
  operator: ArithmeticsOperators;
}

export const ColumnArithmeticOperationMethod = {
  ColumnArithmeticOperation: 'column_arithmetic_operation',
} as const;

export type ColumnArithmeticOperationMethod =
  (typeof ColumnArithmeticOperationMethod)[keyof typeof ColumnArithmeticOperationMethod];

/**
 *
 * @export
 * @interface Columns
 */
export interface Columns {}
/**
 *
 * @export
 * @interface CompleteMultipartUploadParams
 */
export interface CompleteMultipartUploadParams {
  /**
   *
   * @type {string}
   * @memberof CompleteMultipartUploadParams
   */
  upload_id: string;
  /**
   *
   * @type {Array<CompleteMultipartUploadPart>}
   * @memberof CompleteMultipartUploadParams
   */
  parts: Array<CompleteMultipartUploadPart>;
}
/**
 *
 * @export
 * @interface CompleteMultipartUploadPart
 */
export interface CompleteMultipartUploadPart {
  /**
   *
   * @type {string}
   * @memberof CompleteMultipartUploadPart
   */
  ETag: string;
  /**
   *
   * @type {number}
   * @memberof CompleteMultipartUploadPart
   */
  PartNumber: number;
}
/**
 *
 * @export
 * @interface ConcatenateColumns
 */
export interface ConcatenateColumns {
  /**
   *
   * @type {Columns}
   * @memberof ConcatenateColumns
   */
  columns: Columns;
  /**
   *
   * @type {string}
   * @memberof ConcatenateColumns
   */
  method: ConcatenateColumnsMethod;
  /**
   *
   * @type {string}
   * @memberof ConcatenateColumns
   */
  to_column: string;
  /**
   *
   * @type {string}
   * @memberof ConcatenateColumns
   */
  concatenator_value?: string;
}

export const ConcatenateColumnsMethod = {
  ConcatenateColumns: 'concatenate_columns',
} as const;

export type ConcatenateColumnsMethod = (typeof ConcatenateColumnsMethod)[keyof typeof ConcatenateColumnsMethod];

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const ContinueNotSuccessExecution = {
  Always: 'always',
  Never: 'never',
  OnNoAction: 'on_no_action',
} as const;

export type ContinueNotSuccessExecution =
  (typeof ContinueNotSuccessExecution)[keyof typeof ContinueNotSuccessExecution];

/**
 * Parameters accepted by the file extractor.
 * @export
 * @interface CsvFileParameters
 */
export interface CsvFileParameters {
  /**
   *
   * @type {string}
   * @memberof CsvFileParameters
   */
  file_type: CsvFileParametersFileType;
  /**
   *
   * @type {string}
   * @memberof CsvFileParameters
   */
  delimiter?: string;
  /**
   *
   * @type {string}
   * @memberof CsvFileParameters
   */
  quotechar?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof CsvFileParameters
   */
  fieldnames?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof CsvFileParameters
   */
  escapechar?: string;
  /**
   *
   * @type {boolean}
   * @memberof CsvFileParameters
   */
  contains_header?: boolean;
}

export const CsvFileParametersFileType = {
  Csv: 'csv',
} as const;

export type CsvFileParametersFileType = (typeof CsvFileParametersFileType)[keyof typeof CsvFileParametersFileType];

/**
 *
 * @export
 * @interface DataIn
 */
export interface DataIn {
  /**
   *
   * @type {string}
   * @memberof DataIn
   */
  instance_id: string;
  /**
   *
   * @type {string}
   * @memberof DataIn
   */
  key_label: string;
  /**
   *
   * @type {string}
   * @memberof DataIn
   */
  ssh_public_key_body: string;
  /**
   *
   * @type {string}
   * @memberof DataIn
   */
  ssh_public_key_id: string;
  /**
   *
   * @type {MultipartUploadAction}
   * @memberof DataIn
   */
  method: MultipartUploadAction;
  /**
   *
   * @type {string}
   * @memberof DataIn
   */
  path: string;
  /**
   *
   * @type {CompleteMultipartUploadParams}
   * @memberof DataIn
   */
  params?: CompleteMultipartUploadParams;
}

/**
 *
 * @export
 * @interface DataIn1
 */
export interface DataIn1 {
  /**
   *
   * @type {string}
   * @memberof DataIn1
   */
  name: string;
  /**
   *
   * @type {VFModuleCodes}
   * @memberof DataIn1
   */
  code: VFModuleCodes;
  /**
   *
   * @type {string}
   * @memberof DataIn1
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof DataIn1
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof DataIn1
   */
  vf_module_id: string;
  /**
   *
   * @type {string}
   * @memberof DataIn1
   */
  label: string;
  /**
   *
   * @type {boolean}
   * @memberof DataIn1
   */
  mandatory: boolean;
  /**
   *
   * @type {string}
   * @memberof DataIn1
   */
  created_by?: string;
  /**
   *
   * @type {string}
   * @memberof DataIn1
   */
  modified_by?: string;
  /**
   *
   * @type {string}
   * @memberof DataIn1
   */
  datetime_created?: string;
  /**
   *
   * @type {string}
   * @memberof DataIn1
   */
  datetime_modified?: string;
  /**
   *
   * @type {string}
   * @memberof DataIn1
   */
  zendesk_url?: string;
  /**
   *
   * @type {Array<StepsConfigurationInner>}
   * @memberof DataIn1
   */
  steps_configuration: Array<StepsConfigurationInner>;
  /**
   *
   * @type {string}
   * @memberof DataIn1
   */
  category?: string;
}

/**
 *
 * @export
 * @interface DataProcessingArgs
 */
export interface DataProcessingArgs {
  /**
   *
   * @type {string}
   * @memberof DataProcessingArgs
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof DataProcessingArgs
   */
  description: string;
  /**
   *
   * @type {string}
   * @memberof DataProcessingArgs
   */
  key: string;
  /**
   *
   * @type {string}
   * @memberof DataProcessingArgs
   */
  default_value?: string;
  /**
   *
   * @type {DataProcessingArgsTypes}
   * @memberof DataProcessingArgs
   */
  type: DataProcessingArgsTypes;
}

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const DataProcessingArgsTypes = {
  List: 'list',
  Dict: 'dict',
  Boolean: 'boolean',
  Text: 'text',
  Number: 'number',
  Date: 'date',
} as const;

export type DataProcessingArgsTypes = (typeof DataProcessingArgsTypes)[keyof typeof DataProcessingArgsTypes];

/**
 *
 * @export
 * @interface DataProcessingConfig
 */
export interface DataProcessingConfig {
  /**
   *
   * @type {string}
   * @memberof DataProcessingConfig
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof DataProcessingConfig
   */
  type: DataProcessingConfigType;
  /**
   *
   * @type {Array<DataProcessingArgs>}
   * @memberof DataProcessingConfig
   */
  args: Array<DataProcessingArgs>;
  /**
   *
   * @type {ECRImage}
   * @memberof DataProcessingConfig
   */
  image: ECRImage;
  /**
   *
   * @type {string}
   * @memberof DataProcessingConfig
   */
  function_link: string;
  /**
   *
   * @type {DataProcessingJobSize}
   * @memberof DataProcessingConfig
   */
  job_size?: DataProcessingJobSize;
  /**
   *
   * @type {number}
   * @memberof DataProcessingConfig
   */
  job_timeout: number;
  /**
   *
   * @type {boolean}
   * @memberof DataProcessingConfig
   */
  can_be_stopped?: boolean;
}

export const DataProcessingConfigType = {
  DataProcessing: 'data_processing',
} as const;

export type DataProcessingConfigType = (typeof DataProcessingConfigType)[keyof typeof DataProcessingConfigType];

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const DataProcessingJobSize = {
  Micro2Gb: 'Micro (2 GB)',
  Small4Gb: 'Small (4 GB)',
  Medium8Gb: 'Medium (8 GB)',
  Large30Gb: 'Large (30 GB)',
  XLarge64Gb: 'XLarge (64 GB)',
  XxLarge120Gb: 'XXLarge (120 GB)',
} as const;

export type DataProcessingJobSize = (typeof DataProcessingJobSize)[keyof typeof DataProcessingJobSize];

/**
 *
 * @export
 * @interface DataStagingConfig
 */
export interface DataStagingConfig {
  /**
   *
   * @type {string}
   * @memberof DataStagingConfig
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof DataStagingConfig
   */
  type: DataStagingConfigType;
  /**
   *
   * @type {ExportedFromFile}
   * @memberof DataStagingConfig
   */
  exported_from_file?: ExportedFromFile;
}

export const DataStagingConfigType = {
  DataStaging: 'data_staging',
} as const;

export type DataStagingConfigType = (typeof DataStagingConfigType)[keyof typeof DataStagingConfigType];

/**
 *
 * @export
 * @interface DatetimeFinishedFilter
 */
export interface DatetimeFinishedFilter {
  /**
   *
   * @type {string}
   * @memberof DatetimeFinishedFilter
   */
  key: DatetimeFinishedFilterKey;
  /**
   *
   * @type {ExactValue}
   * @memberof DatetimeFinishedFilter
   */
  exact_value?: ExactValue;
  /**
   *
   * @type {string}
   * @memberof DatetimeFinishedFilter
   */
  values_above: string;
  /**
   *
   * @type {string}
   * @memberof DatetimeFinishedFilter
   */
  values_under: string;
  /**
   *
   * @type {string}
   * @memberof DatetimeFinishedFilter
   */
  condition: DatetimeFinishedFilterCondition;
}

export const DatetimeFinishedFilterKey = {
  DatetimeFinished: 'datetime_finished',
} as const;

export type DatetimeFinishedFilterKey = (typeof DatetimeFinishedFilterKey)[keyof typeof DatetimeFinishedFilterKey];
export const DatetimeFinishedFilterCondition = {
  Range: 'range',
} as const;

export type DatetimeFinishedFilterCondition =
  (typeof DatetimeFinishedFilterCondition)[keyof typeof DatetimeFinishedFilterCondition];

/**
 *
 * @export
 * @interface DatetimeStartedFilter
 */
export interface DatetimeStartedFilter {
  /**
   *
   * @type {string}
   * @memberof DatetimeStartedFilter
   */
  key: DatetimeStartedFilterKey;
  /**
   *
   * @type {ExactValue}
   * @memberof DatetimeStartedFilter
   */
  exact_value?: ExactValue;
  /**
   *
   * @type {string}
   * @memberof DatetimeStartedFilter
   */
  values_above: string;
  /**
   *
   * @type {string}
   * @memberof DatetimeStartedFilter
   */
  values_under: string;
  /**
   *
   * @type {string}
   * @memberof DatetimeStartedFilter
   */
  condition: DatetimeStartedFilterCondition;
}

export const DatetimeStartedFilterKey = {
  DatetimeStarted: 'datetime_started',
} as const;

export type DatetimeStartedFilterKey = (typeof DatetimeStartedFilterKey)[keyof typeof DatetimeStartedFilterKey];
export const DatetimeStartedFilterCondition = {
  Range: 'range',
} as const;

export type DatetimeStartedFilterCondition =
  (typeof DatetimeStartedFilterCondition)[keyof typeof DatetimeStartedFilterCondition];

/**
 *
 * @export
 * @interface DefaultSettingsDataStaging
 */
export interface DefaultSettingsDataStaging {
  /**
   *
   * @type {string}
   * @memberof DefaultSettingsDataStaging
   */
  table: string;
  /**
   *
   * @type {Array<string>}
   * @memberof DefaultSettingsDataStaging
   */
  update_keys?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof DefaultSettingsDataStaging
   */
  mandatory_keys?: Array<string>;
  /**
   *
   * @type {Array<object>}
   * @memberof DefaultSettingsDataStaging
   */
  transformations?: Array<object>;
  /**
   *
   * @type {string}
   * @memberof DefaultSettingsDataStaging
   */
  id?: string;
}
/**
 *
 * @export
 * @interface DefaultSettingsDataStagingCreate
 */
export interface DefaultSettingsDataStagingCreate {
  /**
   *
   * @type {string}
   * @memberof DefaultSettingsDataStagingCreate
   */
  table: string;
  /**
   *
   * @type {Array<string>}
   * @memberof DefaultSettingsDataStagingCreate
   */
  update_keys: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof DefaultSettingsDataStagingCreate
   */
  mandatory_keys: Array<string>;
  /**
   *
   * @type {Array<object>}
   * @memberof DefaultSettingsDataStagingCreate
   */
  transformations: Array<object>;
}
/**
 *
 * @export
 * @interface DefaultSettingsDataStagingUpdate
 */
export interface DefaultSettingsDataStagingUpdate {
  /**
   *
   * @type {Array<string>}
   * @memberof DefaultSettingsDataStagingUpdate
   */
  update_keys?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof DefaultSettingsDataStagingUpdate
   */
  mandatory_keys?: Array<string>;
  /**
   *
   * @type {Array<object>}
   * @memberof DefaultSettingsDataStagingUpdate
   */
  transformations?: Array<object>;
}
/**
 *
 * @export
 * @interface DisplayStateFilter
 */
export interface DisplayStateFilter {
  /**
   *
   * @type {string}
   * @memberof DisplayStateFilter
   */
  key: DisplayStateFilterKey;
  /**
   *
   * @type {ExactValue1}
   * @memberof DisplayStateFilter
   */
  exact_value: ExactValue1;
  /**
   *
   * @type {string}
   * @memberof DisplayStateFilter
   */
  values_above?: string;
  /**
   *
   * @type {string}
   * @memberof DisplayStateFilter
   */
  values_under?: string;
  /**
   *
   * @type {string}
   * @memberof DisplayStateFilter
   */
  condition: DisplayStateFilterCondition;
}

export const DisplayStateFilterKey = {
  DisplayState: 'display_state',
} as const;

export type DisplayStateFilterKey = (typeof DisplayStateFilterKey)[keyof typeof DisplayStateFilterKey];
export const DisplayStateFilterCondition = {
  In: 'in',
  Exact: 'exact',
  Nin: 'nin',
} as const;

export type DisplayStateFilterCondition =
  (typeof DisplayStateFilterCondition)[keyof typeof DisplayStateFilterCondition];

/**
 *
 * @export
 * @interface DocsCount
 */
export interface DocsCount {
  /**
   *
   * @type {number}
   * @memberof DocsCount
   */
  documents_count: number;
}
/**
 *
 * @export
 * @interface DocumentFilter
 */
export interface DocumentFilter {
  /**
   *
   * @type {DocumentQueryOperator}
   * @memberof DocumentFilter
   */
  operator: DocumentQueryOperator;
  /**
   *
   * @type {string}
   * @memberof DocumentFilter
   */
  field: string;
  /**
   *
   * @type {string}
   * @memberof DocumentFilter
   */
  query: string;
  /**
   *
   * @type {DocumentFilterQueryType}
   * @memberof DocumentFilter
   */
  type: DocumentFilterQueryType;
}

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const DocumentFilterQueryType = {
  String: 'string',
  Number: 'number',
  ObjectId: 'object_id',
  Date: 'date',
  Boolean: 'boolean',
} as const;

export type DocumentFilterQueryType = (typeof DocumentFilterQueryType)[keyof typeof DocumentFilterQueryType];

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const DocumentFiltersOperator = {
  And: '$and',
  Or: '$or',
} as const;

export type DocumentFiltersOperator = (typeof DocumentFiltersOperator)[keyof typeof DocumentFiltersOperator];

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const DocumentQueryOperator = {
  Gt: '$gt',
  Lt: '$lt',
  Eq: '$eq',
  Ne: '$ne',
} as const;

export type DocumentQueryOperator = (typeof DocumentQueryOperator)[keyof typeof DocumentQueryOperator];

/**
 *
 * @export
 * @interface DocumentsQueryBase
 */
export interface DocumentsQueryBase {
  /**
   *
   * @type {string}
   * @memberof DocumentsQueryBase
   */
  instance_id: string;
  /**
   *
   * @type {string}
   * @memberof DocumentsQueryBase
   */
  collection_name: string;
  /**
   *
   * @type {Array<DocumentFilter>}
   * @memberof DocumentsQueryBase
   */
  filters: Array<DocumentFilter>;
  /**
   *
   * @type {DocumentFiltersOperator}
   * @memberof DocumentsQueryBase
   */
  filters_operator?: DocumentFiltersOperator;
}

/**
 *
 * @export
 * @interface DocumentsQueryExport
 */
export interface DocumentsQueryExport {
  /**
   *
   * @type {string}
   * @memberof DocumentsQueryExport
   */
  instance_id: string;
  /**
   *
   * @type {string}
   * @memberof DocumentsQueryExport
   */
  collection_name: string;
  /**
   *
   * @type {Array<DocumentFilter>}
   * @memberof DocumentsQueryExport
   */
  filters: Array<DocumentFilter>;
  /**
   *
   * @type {DocumentFiltersOperator}
   * @memberof DocumentsQueryExport
   */
  filters_operator?: DocumentFiltersOperator;
  /**
   *
   * @type {string}
   * @memberof DocumentsQueryExport
   */
  file_name_key?: string;
}

/**
 *
 * @export
 * @interface DocumentsQueryPagination
 */
export interface DocumentsQueryPagination {
  /**
   *
   * @type {string}
   * @memberof DocumentsQueryPagination
   */
  instance_id: string;
  /**
   *
   * @type {string}
   * @memberof DocumentsQueryPagination
   */
  collection_name: string;
  /**
   *
   * @type {Array<DocumentFilter>}
   * @memberof DocumentsQueryPagination
   */
  filters: Array<DocumentFilter>;
  /**
   *
   * @type {DocumentFiltersOperator}
   * @memberof DocumentsQueryPagination
   */
  filters_operator?: DocumentFiltersOperator;
  /**
   *
   * @type {number}
   * @memberof DocumentsQueryPagination
   */
  limit: number;
  /**
   *
   * @type {number}
   * @memberof DocumentsQueryPagination
   */
  skip: number;
}

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const ECRImage = {
  ApiProject: 'api_project',
  ApiProjectPy311: 'api_project_py_3_11',
  ApiUat: 'api_uat',
  ApiUatPy311: 'api_uat_py_3_11',
  ApiMaster: 'api_master',
  ApiMasterPy311: 'api_master_py_3_11',
  EventsDevelop: 'events_develop',
  EventsMain: 'events_main',
  TargetsDevelop: 'targets_develop',
  TargetsMain: 'targets_main',
  VfStandardLoadsDev: 'vf_standard_loads_dev',
  VfStandardLoadsMain: 'vf_standard_loads_main',
} as const;

export type ECRImage = (typeof ECRImage)[keyof typeof ECRImage];

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const EmailType = {
  User: 'user',
  Service: 'service',
} as const;

export type EmailType = (typeof EmailType)[keyof typeof EmailType];

/**
 *
 * @export
 * @interface EnabledFilter
 */
export interface EnabledFilter {
  /**
   *
   * @type {string}
   * @memberof EnabledFilter
   */
  key: EnabledFilterKey;
  /**
   *
   * @type {boolean}
   * @memberof EnabledFilter
   */
  exact_value: boolean;
  /**
   *
   * @type {string}
   * @memberof EnabledFilter
   */
  values_above?: string;
  /**
   *
   * @type {string}
   * @memberof EnabledFilter
   */
  values_under?: string;
  /**
   *
   * @type {string}
   * @memberof EnabledFilter
   */
  condition: EnabledFilterCondition;
}

export const EnabledFilterKey = {
  Enabled: 'enabled',
} as const;

export type EnabledFilterKey = (typeof EnabledFilterKey)[keyof typeof EnabledFilterKey];
export const EnabledFilterCondition = {
  Exact: 'exact',
} as const;

export type EnabledFilterCondition = (typeof EnabledFilterCondition)[keyof typeof EnabledFilterCondition];

/**
 *
 * @export
 * @interface ExactValue
 */
export interface ExactValue {}
/**
 *
 * @export
 * @interface ExactValue1
 */
export interface ExactValue1 {}
/**
 * Parameters accepted by file extractor
 * @export
 * @interface ExcelFileParameters
 */
export interface ExcelFileParameters {
  /**
   *
   * @type {string}
   * @memberof ExcelFileParameters
   */
  file_type: ExcelFileParametersFileType;
  /**
   *
   * @type {string}
   * @memberof ExcelFileParameters
   */
  sheet?: string;
}

export const ExcelFileParametersFileType = {
  Excel: 'excel',
} as const;

export type ExcelFileParametersFileType =
  (typeof ExcelFileParametersFileType)[keyof typeof ExcelFileParametersFileType];

/**
 *
 * @export
 * @interface ExecutionsPipelineIdFilter
 */
export interface ExecutionsPipelineIdFilter {
  /**
   *
   * @type {string}
   * @memberof ExecutionsPipelineIdFilter
   */
  key: ExecutionsPipelineIdFilterKey;
  /**
   *
   * @type {string}
   * @memberof ExecutionsPipelineIdFilter
   */
  exact_value: string;
  /**
   *
   * @type {string}
   * @memberof ExecutionsPipelineIdFilter
   */
  values_above?: string;
  /**
   *
   * @type {string}
   * @memberof ExecutionsPipelineIdFilter
   */
  values_under?: string;
  /**
   *
   * @type {string}
   * @memberof ExecutionsPipelineIdFilter
   */
  condition: ExecutionsPipelineIdFilterCondition;
}

export const ExecutionsPipelineIdFilterKey = {
  PipelineId: 'pipeline_id',
} as const;

export type ExecutionsPipelineIdFilterKey =
  (typeof ExecutionsPipelineIdFilterKey)[keyof typeof ExecutionsPipelineIdFilterKey];
export const ExecutionsPipelineIdFilterCondition = {
  Exact: 'exact',
} as const;

export type ExecutionsPipelineIdFilterCondition =
  (typeof ExecutionsPipelineIdFilterCondition)[keyof typeof ExecutionsPipelineIdFilterCondition];

/**
 *
 * @export
 * @interface ExportCollectionStatusDataResponse
 */
export interface ExportCollectionStatusDataResponse {
  /**
   *
   * @type {ExportStatus}
   * @memberof ExportCollectionStatusDataResponse
   */
  status: ExportStatus;
  /**
   *
   * @type {string}
   * @memberof ExportCollectionStatusDataResponse
   */
  export_file_name: string;
  /**
   *
   * @type {string}
   * @memberof ExportCollectionStatusDataResponse
   */
  file_name_key: string;
  /**
   *
   * @type {string}
   * @memberof ExportCollectionStatusDataResponse
   */
  presigned_url?: string;
}

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const ExportStatus = {
  New: 'new',
  InProgress: 'in_progress',
  Finished: 'finished',
  Failed: 'failed',
} as const;

export type ExportStatus = (typeof ExportStatus)[keyof typeof ExportStatus];

/**
 *
 * @export
 * @interface ExportedFromFile
 */
export interface ExportedFromFile {
  /**
   *
   * @type {Array<FilesConfigurationModelInner>}
   * @memberof ExportedFromFile
   */
  files_configuration_model: Array<FilesConfigurationModelInner>;
  /**
   *
   * @type {string}
   * @memberof ExportedFromFile
   */
  encoding?: string;
  /**
   *
   * @type {string}
   * @memberof ExportedFromFile
   */
  import_folder?: string;
  /**
   *
   * @type {string}
   * @memberof ExportedFromFile
   */
  sap_system_name?: string;
  /**
   *
   * @type {FileTypeParameters}
   * @memberof ExportedFromFile
   */
  file_type_parameters: FileTypeParameters;
  /**
   *
   * @type {TableType}
   * @memberof ExportedFromFile
   */
  table_type: TableType;
}

/**
 *
 * @export
 * @interface FSM
 */
export interface FSM {
  /**
   *
   * @type {string}
   * @memberof FSM
   */
  pipeline_id: string;
  /**
   *
   * @type {string}
   * @memberof FSM
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof FSM
   */
  datetime_created: string;
  /**
   *
   * @type {Array<FSMTransition>}
   * @memberof FSM
   */
  transitions?: Array<FSMTransition>;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const FSMState = {
  Failed: 'failed',
  Started: 'started',
  Finished: 'finished',
  NoAction: 'no_action',
  Stopping: 'stopping',
  Stopped: 'stopped',
  RetrievingFromAzure: 'retrieving_from_azure',
  RetrievingFromSftp: 'retrieving_from_sftp',
  RetrievingFromHttp: 'retrieving_from_http',
  SendingToAzure: 'sending_to_azure',
  SendingToSftp: 'sending_to_sftp',
  SendingToHttp: 'sending_to_http',
  StagingData: 'staging_data',
  ProcessingData: 'processing_data',
  MovingFiles: 'moving_files',
} as const;

export type FSMState = (typeof FSMState)[keyof typeof FSMState];

/**
 *
 * @export
 * @interface FSMTransition
 */
export interface FSMTransition {
  /**
   *
   * @type {string}
   * @memberof FSMTransition
   */
  fsm_id: string;
  /**
   *
   * @type {string}
   * @memberof FSMTransition
   */
  source_state?: string;
  /**
   *
   * @type {string}
   * @memberof FSMTransition
   */
  target_state: string;
  /**
   *
   * @type {string}
   * @memberof FSMTransition
   */
  pipeline_step_id?: string;
  /**
   *
   * @type {string}
   * @memberof FSMTransition
   */
  input_event: string;
  /**
   *
   * @type {string}
   * @memberof FSMTransition
   */
  output_event?: string;
  /**
   *
   * @type {string}
   * @memberof FSMTransition
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof FSMTransition
   */
  datetime_created: string;
  /**
   *
   * @type {string}
   * @memberof FSMTransition
   */
  datetime_modified: string;
}
/**
 *
 * @export
 * @interface FileConfigurationModel
 */
export interface FileConfigurationModel {
  /**
   *
   * @type {string}
   * @memberof FileConfigurationModel
   */
  import_type: FileConfigurationModelImportType;
  /**
   *
   * @type {string}
   * @memberof FileConfigurationModel
   */
  fnmatch_expression?: string;
  /**
   *
   * @type {TableImportModel}
   * @memberof FileConfigurationModel
   */
  import_parameters: TableImportModel;
  /**
   *
   * @type {string}
   * @memberof FileConfigurationModel
   */
  key?: string;
  /**
   *
   * @type {string}
   * @memberof FileConfigurationModel
   */
  lower_level_key?: string;
}

export const FileConfigurationModelImportType = {
  Table: 'table',
} as const;

export type FileConfigurationModelImportType =
  (typeof FileConfigurationModelImportType)[keyof typeof FileConfigurationModelImportType];

/**
 *
 * @export
 * @interface FileConfigurationModelMultiTable
 */
export interface FileConfigurationModelMultiTable {
  /**
   *
   * @type {string}
   * @memberof FileConfigurationModelMultiTable
   */
  import_type: FileConfigurationModelMultiTableImportType;
  /**
   *
   * @type {string}
   * @memberof FileConfigurationModelMultiTable
   */
  fnmatch_expression?: string;
  /**
   *
   * @type {Array<TableImportModel>}
   * @memberof FileConfigurationModelMultiTable
   */
  import_parameters: Array<TableImportModel>;
  /**
   *
   * @type {string}
   * @memberof FileConfigurationModelMultiTable
   */
  key?: string;
  /**
   *
   * @type {string}
   * @memberof FileConfigurationModelMultiTable
   */
  lower_level_key?: string;
}

export const FileConfigurationModelMultiTableImportType = {
  MultiTable: 'multi_table',
} as const;

export type FileConfigurationModelMultiTableImportType =
  (typeof FileConfigurationModelMultiTableImportType)[keyof typeof FileConfigurationModelMultiTableImportType];

/**
 *
 * @export
 * @interface FileTypeParameters
 */
export interface FileTypeParameters {
  /**
   *
   * @type {string}
   * @memberof FileTypeParameters
   */
  file_type: FileTypeParametersFileType;
  /**
   *
   * @type {string}
   * @memberof FileTypeParameters
   */
  delimiter?: string;
  /**
   *
   * @type {string}
   * @memberof FileTypeParameters
   */
  quotechar?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof FileTypeParameters
   */
  fieldnames?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof FileTypeParameters
   */
  escapechar?: string;
  /**
   *
   * @type {boolean}
   * @memberof FileTypeParameters
   */
  contains_header?: boolean;
  /**
   *
   * @type {string}
   * @memberof FileTypeParameters
   */
  sheet?: string;
}

export const FileTypeParametersFileType = {
  Parquet: 'parquet',
} as const;

export type FileTypeParametersFileType = (typeof FileTypeParametersFileType)[keyof typeof FileTypeParametersFileType];

/**
 *
 * @export
 * @interface FilesConfigurationModelInner
 */
export interface FilesConfigurationModelInner {
  /**
   *
   * @type {string}
   * @memberof FilesConfigurationModelInner
   */
  import_type: FilesConfigurationModelInnerImportType;
  /**
   *
   * @type {string}
   * @memberof FilesConfigurationModelInner
   */
  fnmatch_expression?: string;
  /**
   *
   * @type {Array<TableImportModel>}
   * @memberof FilesConfigurationModelInner
   */
  import_parameters: Array<TableImportModel>;
  /**
   *
   * @type {string}
   * @memberof FilesConfigurationModelInner
   */
  key?: string;
  /**
   *
   * @type {string}
   * @memberof FilesConfigurationModelInner
   */
  lower_level_key?: string;
}

export const FilesConfigurationModelInnerImportType = {
  MultiTable: 'multi_table',
} as const;

export type FilesConfigurationModelInnerImportType =
  (typeof FilesConfigurationModelInnerImportType)[keyof typeof FilesConfigurationModelInnerImportType];

/**
 *
 * @export
 * @interface FilesReplication
 */
export interface FilesReplication {
  /**
   *
   * @type {string}
   * @memberof FilesReplication
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof FilesReplication
   */
  replicator_id: string;
  /**
   *
   * @type {object}
   * @memberof FilesReplication
   */
  details: object;
  /**
   *
   * @type {string}
   * @memberof FilesReplication
   */
  s3_key: string;
  /**
   *
   * @type {string}
   * @memberof FilesReplication
   */
  datetime_started: string;
  /**
   *
   * @type {string}
   * @memberof FilesReplication
   */
  datetime_finished?: string;
}
/**
 *
 * @export
 * @interface FilesReplicationCreate
 */
export interface FilesReplicationCreate {
  /**
   *
   * @type {string}
   * @memberof FilesReplicationCreate
   */
  replicator_id: string;
  /**
   *
   * @type {object}
   * @memberof FilesReplicationCreate
   */
  details?: object;
  /**
   *
   * @type {string}
   * @memberof FilesReplicationCreate
   */
  s3_key: string;
}
/**
 *
 * @export
 * @interface FilesReplicationDeleteResponse
 */
export interface FilesReplicationDeleteResponse {
  /**
   *
   * @type {string}
   * @memberof FilesReplicationDeleteResponse
   */
  id: string;
}
/**
 *
 * @export
 * @interface FilesReplicationUpdate
 */
export interface FilesReplicationUpdate {
  /**
   *
   * @type {object}
   * @memberof FilesReplicationUpdate
   */
  details?: object;
  /**
   *
   * @type {string}
   * @memberof FilesReplicationUpdate
   */
  datetime_finished?: string;
}
/**
 *
 * @export
 * @interface FilesReplicator
 */
export interface FilesReplicator {
  /**
   *
   * @type {string}
   * @memberof FilesReplicator
   */
  id: string;
  /**
   *
   * @type {InstanceToDisplay}
   * @memberof FilesReplicator
   */
  source_instance: InstanceToDisplay;
  /**
   *
   * @type {Array<InstanceToDisplay>}
   * @memberof FilesReplicator
   */
  target_instances: Array<InstanceToDisplay>;
  /**
   *
   * @type {boolean}
   * @memberof FilesReplicator
   */
  enabled: boolean;
  /**
   *
   * @type {string}
   * @memberof FilesReplicator
   */
  datetime_created: string;
  /**
   *
   * @type {string}
   * @memberof FilesReplicator
   */
  datetime_modified: string;
}
/**
 *
 * @export
 * @interface FilesReplicatorCreate
 */
export interface FilesReplicatorCreate {
  /**
   *
   * @type {string}
   * @memberof FilesReplicatorCreate
   */
  source_instance_id: string;
  /**
   *
   * @type {Array<string>}
   * @memberof FilesReplicatorCreate
   */
  target_instances?: Array<string>;
  /**
   *
   * @type {boolean}
   * @memberof FilesReplicatorCreate
   */
  enabled?: boolean;
}
/**
 *
 * @export
 * @interface FilesReplicatorDeleteResponse
 */
export interface FilesReplicatorDeleteResponse {
  /**
   *
   * @type {string}
   * @memberof FilesReplicatorDeleteResponse
   */
  id: string;
}
/**
 *
 * @export
 * @interface FilesReplicatorUpdate
 */
export interface FilesReplicatorUpdate {
  /**
   *
   * @type {Array<string>}
   * @memberof FilesReplicatorUpdate
   */
  target_instances?: Array<string>;
  /**
   *
   * @type {boolean}
   * @memberof FilesReplicatorUpdate
   */
  enabled?: boolean;
}
/**
 *
 * @export
 * @interface Filter
 */
export interface Filter {
  /**
   *
   * @type {string}
   * @memberof Filter
   */
  column: string;
  /**
   *
   * @type {FilterOptions}
   * @memberof Filter
   */
  option: FilterOptions;
  /**
   *
   * @type {string}
   * @memberof Filter
   */
  value: string;
}

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const FilterOptions = {
  Equals: 'equals',
} as const;

export type FilterOptions = (typeof FilterOptions)[keyof typeof FilterOptions];

/**
 * @type FiltersInner
 * @export
 */
export type FiltersInner =
  | ({ key: 'enabled' } & EnabledFilter)
  | ({ key: 'id' } & PipelineIdFilter)
  | ({ key: 'name' } & NameFilter);

/**
 *
 * @export
 * @interface HTTPAPIKey
 */
export interface HTTPAPIKey {
  /**
   *
   * @type {string}
   * @memberof HTTPAPIKey
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof HTTPAPIKey
   */
  instance_id: string;
  /**
   *
   * @type {string}
   * @memberof HTTPAPIKey
   */
  key_label: string;
  /**
   *
   * @type {string}
   * @memberof HTTPAPIKey
   */
  shared_key: string;
  /**
   *
   * @type {string}
   * @memberof HTTPAPIKey
   */
  datetime_created: string;
}
/**
 *
 * @export
 * @interface HTTPAPIKeyCreate
 */
export interface HTTPAPIKeyCreate {
  /**
   *
   * @type {string}
   * @memberof HTTPAPIKeyCreate
   */
  instance_id: string;
  /**
   *
   * @type {string}
   * @memberof HTTPAPIKeyCreate
   */
  key_label: string;
}
/**
 *
 * @export
 * @interface HTTPInboundInterfaceRequestBody
 */
export interface HTTPInboundInterfaceRequestBody {
  /**
   *
   * @type {string}
   * @memberof HTTPInboundInterfaceRequestBody
   */
  path: string;
  /**
   *
   * @type {string}
   * @memberof HTTPInboundInterfaceRequestBody
   */
  file_name: string;
  /**
   *
   * @type {string}
   * @memberof HTTPInboundInterfaceRequestBody
   */
  file_content: string;
  /**
   *
   * @type {string}
   * @memberof HTTPInboundInterfaceRequestBody
   */
  key_label: string;
}
/**
 *
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
  /**
   *
   * @type {Array<ValidationError>}
   * @memberof HTTPValidationError
   */
  detail?: Array<ValidationError>;
}
/**
 * Instance schema
 * @export
 * @interface Instance
 */
export interface Instance {
  /**
   *
   * @type {string}
   * @memberof Instance
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof Instance
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof Instance
   */
  date_created: string;
  /**
   *
   * @type {string}
   * @memberof Instance
   */
  date_modified: string;
}
/**
 *
 * @export
 * @interface InstanceCreateOrUpdate
 */
export interface InstanceCreateOrUpdate {
  /**
   *
   * @type {string}
   * @memberof InstanceCreateOrUpdate
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof InstanceCreateOrUpdate
   */
  date_created: string;
  /**
   *
   * @type {string}
   * @memberof InstanceCreateOrUpdate
   */
  date_modified?: string;
}
/**
 *
 * @export
 * @interface InstanceToDisplay
 */
export interface InstanceToDisplay {
  /**
   *
   * @type {string}
   * @memberof InstanceToDisplay
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof InstanceToDisplay
   */
  name: string;
}
/**
 *
 * @export
 * @interface InstanceUpdate
 */
export interface InstanceUpdate {
  /**
   *
   * @type {string}
   * @memberof InstanceUpdate
   */
  name: string;
}
/**
 * Parameters accepted by file extractor
 * @export
 * @interface JsonFileParameters
 */
export interface JsonFileParameters {
  /**
   *
   * @type {string}
   * @memberof JsonFileParameters
   */
  file_type: JsonFileParametersFileType;
}

export const JsonFileParametersFileType = {
  Json: 'json',
} as const;

export type JsonFileParametersFileType = (typeof JsonFileParametersFileType)[keyof typeof JsonFileParametersFileType];

/**
 *
 * @export
 * @interface LabelFilter
 */
export interface LabelFilter {
  /**
   *
   * @type {string}
   * @memberof LabelFilter
   */
  key: LabelFilterKey;
  /**
   *
   * @type {string}
   * @memberof LabelFilter
   */
  exact_value: string;
  /**
   *
   * @type {string}
   * @memberof LabelFilter
   */
  values_above?: string;
  /**
   *
   * @type {string}
   * @memberof LabelFilter
   */
  values_under?: string;
  /**
   *
   * @type {string}
   * @memberof LabelFilter
   */
  condition: LabelFilterCondition;
}

export const LabelFilterKey = {
  Label: 'label',
} as const;

export type LabelFilterKey = (typeof LabelFilterKey)[keyof typeof LabelFilterKey];
export const LabelFilterCondition = {
  Contains: 'contains',
  Exact: 'exact',
} as const;

export type LabelFilterCondition = (typeof LabelFilterCondition)[keyof typeof LabelFilterCondition];

/**
 *
 * @export
 * @interface LocationInner
 */
export interface LocationInner {}
/**
 * Status code message
 * @export
 * @interface Message
 */
export interface Message {
  /**
   *
   * @type {string}
   * @memberof Message
   */
  detail: string;
}
/**
 *
 * @export
 * @interface MongoDBCollection
 */
export interface MongoDBCollection {
  /**
   *
   * @type {string}
   * @memberof MongoDBCollection
   */
  name: string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const MultipartUploadAction = {
  CompleteMultipartUpload: 'completeMultipartUpload',
  CreateMultipartUpload: 'createMultipartUpload',
} as const;

export type MultipartUploadAction = (typeof MultipartUploadAction)[keyof typeof MultipartUploadAction];

/**
 *
 * @export
 * @interface MultipartUploadActionData
 */
export interface MultipartUploadActionData {
  /**
   *
   * @type {MultipartUploadAction}
   * @memberof MultipartUploadActionData
   */
  method: MultipartUploadAction;
  /**
   *
   * @type {string}
   * @memberof MultipartUploadActionData
   */
  path: string;
  /**
   *
   * @type {CompleteMultipartUploadParams}
   * @memberof MultipartUploadActionData
   */
  params?: CompleteMultipartUploadParams;
}

/**
 *
 * @export
 * @interface NameFilter
 */
export interface NameFilter {
  /**
   *
   * @type {string}
   * @memberof NameFilter
   */
  key: NameFilterKey;
  /**
   *
   * @type {string}
   * @memberof NameFilter
   */
  exact_value: string;
  /**
   *
   * @type {string}
   * @memberof NameFilter
   */
  values_above?: string;
  /**
   *
   * @type {string}
   * @memberof NameFilter
   */
  values_under?: string;
  /**
   *
   * @type {string}
   * @memberof NameFilter
   */
  condition: NameFilterCondition;
}

export const NameFilterKey = {
  Name: 'name',
} as const;

export type NameFilterKey = (typeof NameFilterKey)[keyof typeof NameFilterKey];
export const NameFilterCondition = {
  Contains: 'contains',
} as const;

export type NameFilterCondition = (typeof NameFilterCondition)[keyof typeof NameFilterCondition];

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const NotificationMode = {
  Always: 'always',
  Failed: 'failed',
} as const;

export type NotificationMode = (typeof NotificationMode)[keyof typeof NotificationMode];

/**
 *
 * @export
 * @interface Operand
 */
export interface Operand {}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const OrderBy = {
  Desc: 'desc',
  Asc: 'asc',
} as const;

export type OrderBy = (typeof OrderBy)[keyof typeof OrderBy];

/**
 *
 * @export
 * @interface OutputDetails
 */
export interface OutputDetails {
  /**
   *
   * @type {string}
   * @memberof OutputDetails
   */
  step_name: string;
  /**
   *
   * @type {string}
   * @memberof OutputDetails
   */
  log: string;
  /**
   *
   * @type {string}
   * @memberof OutputDetails
   */
  full_log_path?: string;
}
/**
 *
 * @export
 * @interface ParquetFileParameters
 */
export interface ParquetFileParameters {
  /**
   *
   * @type {string}
   * @memberof ParquetFileParameters
   */
  file_type: ParquetFileParametersFileType;
}

export const ParquetFileParametersFileType = {
  Parquet: 'parquet',
} as const;

export type ParquetFileParametersFileType =
  (typeof ParquetFileParametersFileType)[keyof typeof ParquetFileParametersFileType];

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const PersistMethodTypes = {
  Truncate: 'truncate',
  Append: 'append',
  Upsert: 'upsert',
} as const;

export type PersistMethodTypes = (typeof PersistMethodTypes)[keyof typeof PersistMethodTypes];

/**
 *
 * @export
 * @interface Pipeline
 */
export interface Pipeline {
  /**
   *
   * @type {string}
   * @memberof Pipeline
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof Pipeline
   */
  description?: string;
  /**
   *
   * @type {PipelineType}
   * @memberof Pipeline
   */
  type?: PipelineType;
  /**
   *
   * @type {string}
   * @memberof Pipeline
   */
  instance_id?: string;
  /**
   *
   * @type {boolean}
   * @memberof Pipeline
   */
  enabled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Pipeline
   */
  blocked?: boolean;
  /**
   *
   * @type {string}
   * @memberof Pipeline
   */
  blocked_details?: string;
  /**
   *
   * @type {string}
   * @memberof Pipeline
   */
  schedule?: string;
  /**
   *
   * @type {string}
   * @memberof Pipeline
   */
  time_zone?: string;
  /**
   *
   * @type {string}
   * @memberof Pipeline
   */
  owner_email: string;
  /**
   *
   * @type {ContinueNotSuccessExecution}
   * @memberof Pipeline
   */
  continue_not_success_execution?: ContinueNotSuccessExecution;
  /**
   *
   * @type {string}
   * @memberof Pipeline
   */
  id: string;
  /**
   *
   * @type {FSM}
   * @memberof Pipeline
   */
  fsm?: FSM;
  /**
   *
   * @type {string}
   * @memberof Pipeline
   */
  datetime_created: string;
  /**
   *
   * @type {string}
   * @memberof Pipeline
   */
  datetime_modified: string;
  /**
   *
   * @type {boolean}
   * @memberof Pipeline
   */
  notifications_enabled: boolean;
}

/**
 *
 * @export
 * @interface PipelineAverageExecutionTimeRequestBody
 */
export interface PipelineAverageExecutionTimeRequestBody {
  /**
   *
   * @type {string}
   * @memberof PipelineAverageExecutionTimeRequestBody
   */
  instance_id: string;
  /**
   *
   * @type {PipelinesIdsFilter}
   * @memberof PipelineAverageExecutionTimeRequestBody
   */
  pipelines_ids_filter: PipelinesIdsFilter;
  /**
   *
   * @type {DatetimeStartedFilter}
   * @memberof PipelineAverageExecutionTimeRequestBody
   */
  datetime_started_filter: DatetimeStartedFilter;
  /**
   *
   * @type {DatetimeFinishedFilter}
   * @memberof PipelineAverageExecutionTimeRequestBody
   */
  datetime_finished_filter: DatetimeFinishedFilter;
  /**
   *
   * @type {Array<string>}
   * @memberof PipelineAverageExecutionTimeRequestBody
   */
  or_filters_keys?: Array<string>;
}
/**
 *
 * @export
 * @interface PipelineCreate
 */
export interface PipelineCreate {
  /**
   *
   * @type {string}
   * @memberof PipelineCreate
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof PipelineCreate
   */
  description: string;
  /**
   *
   * @type {PipelineType}
   * @memberof PipelineCreate
   */
  type: PipelineType;
  /**
   *
   * @type {string}
   * @memberof PipelineCreate
   */
  instance_id: string;
  /**
   *
   * @type {boolean}
   * @memberof PipelineCreate
   */
  enabled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PipelineCreate
   */
  blocked?: boolean;
  /**
   *
   * @type {string}
   * @memberof PipelineCreate
   */
  blocked_details?: string;
  /**
   *
   * @type {string}
   * @memberof PipelineCreate
   */
  schedule: string;
  /**
   *
   * @type {string}
   * @memberof PipelineCreate
   */
  time_zone?: string;
  /**
   *
   * @type {string}
   * @memberof PipelineCreate
   */
  owner_email: string;
  /**
   *
   * @type {ContinueNotSuccessExecution}
   * @memberof PipelineCreate
   */
  continue_not_success_execution?: ContinueNotSuccessExecution;
  /**
   *
   * @type {boolean}
   * @memberof PipelineCreate
   */
  notifications_enabled?: boolean;
}

/**
 *
 * @export
 * @interface PipelineExecution
 */
export interface PipelineExecution {
  /**
   *
   * @type {string}
   * @memberof PipelineExecution
   */
  pipeline_id?: string;
  /**
   *
   * @type {string}
   * @memberof PipelineExecution
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof PipelineExecution
   */
  state: string;
  /**
   *
   * @type {FSMState}
   * @memberof PipelineExecution
   */
  display_state?: FSMState;
  /**
   *
   * @type {object}
   * @memberof PipelineExecution
   */
  details?: object;
  /**
   *
   * @type {string}
   * @memberof PipelineExecution
   */
  datetime_started: string;
  /**
   *
   * @type {string}
   * @memberof PipelineExecution
   */
  datetime_finished?: string;
}

/**
 *
 * @export
 * @interface PipelineExecutionBase
 */
export interface PipelineExecutionBase {
  /**
   *
   * @type {string}
   * @memberof PipelineExecutionBase
   */
  pipeline_id?: string;
}
/**
 *
 * @export
 * @interface PipelineExecutionOutput
 */
export interface PipelineExecutionOutput {
  /**
   *
   * @type {string}
   * @memberof PipelineExecutionOutput
   */
  pipeline_id?: string;
  /**
   *
   * @type {string}
   * @memberof PipelineExecutionOutput
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof PipelineExecutionOutput
   */
  state: string;
  /**
   *
   * @type {FSMState}
   * @memberof PipelineExecutionOutput
   */
  display_state?: FSMState;
  /**
   *
   * @type {Array<OutputDetails>}
   * @memberof PipelineExecutionOutput
   */
  details?: Array<OutputDetails>;
  /**
   *
   * @type {string}
   * @memberof PipelineExecutionOutput
   */
  datetime_started: string;
  /**
   *
   * @type {string}
   * @memberof PipelineExecutionOutput
   */
  datetime_finished?: string;
}

/**
 *
 * @export
 * @interface PipelineExecutionRequestBody
 */
export interface PipelineExecutionRequestBody {
  /**
   *
   * @type {ExecutionsPipelineIdFilter}
   * @memberof PipelineExecutionRequestBody
   */
  pipeline_id_filter: ExecutionsPipelineIdFilter;
  /**
   *
   * @type {DisplayStateFilter}
   * @memberof PipelineExecutionRequestBody
   */
  display_state_filter?: DisplayStateFilter;
  /**
   *
   * @type {DatetimeStartedFilter}
   * @memberof PipelineExecutionRequestBody
   */
  datetime_started_filter?: DatetimeStartedFilter;
  /**
   *
   * @type {DatetimeFinishedFilter}
   * @memberof PipelineExecutionRequestBody
   */
  datetime_finished_filter?: DatetimeFinishedFilter;
  /**
   *
   * @type {Array<string>}
   * @memberof PipelineExecutionRequestBody
   */
  or_filters_keys?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof PipelineExecutionRequestBody
   */
  limit?: number;
  /**
   *
   * @type {number}
   * @memberof PipelineExecutionRequestBody
   */
  skip?: number;
}
/**
 *
 * @export
 * @interface PipelineExecutionWithoutDetails
 */
export interface PipelineExecutionWithoutDetails {
  /**
   *
   * @type {string}
   * @memberof PipelineExecutionWithoutDetails
   */
  pipeline_id?: string;
  /**
   *
   * @type {string}
   * @memberof PipelineExecutionWithoutDetails
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof PipelineExecutionWithoutDetails
   */
  state: string;
  /**
   *
   * @type {FSMState}
   * @memberof PipelineExecutionWithoutDetails
   */
  display_state?: FSMState;
  /**
   *
   * @type {string}
   * @memberof PipelineExecutionWithoutDetails
   */
  datetime_started: string;
  /**
   *
   * @type {string}
   * @memberof PipelineExecutionWithoutDetails
   */
  datetime_finished?: string;
}

/**
 *
 * @export
 * @interface PipelineFiltered
 */
export interface PipelineFiltered {
  /**
   *
   * @type {string}
   * @memberof PipelineFiltered
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof PipelineFiltered
   */
  description?: string;
  /**
   *
   * @type {PipelineType}
   * @memberof PipelineFiltered
   */
  type?: PipelineType;
  /**
   *
   * @type {string}
   * @memberof PipelineFiltered
   */
  instance_id?: string;
  /**
   *
   * @type {boolean}
   * @memberof PipelineFiltered
   */
  enabled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PipelineFiltered
   */
  blocked?: boolean;
  /**
   *
   * @type {string}
   * @memberof PipelineFiltered
   */
  blocked_details?: string;
  /**
   *
   * @type {string}
   * @memberof PipelineFiltered
   */
  schedule?: string;
  /**
   *
   * @type {string}
   * @memberof PipelineFiltered
   */
  time_zone?: string;
  /**
   *
   * @type {string}
   * @memberof PipelineFiltered
   */
  owner_email: string;
  /**
   *
   * @type {ContinueNotSuccessExecution}
   * @memberof PipelineFiltered
   */
  continue_not_success_execution?: ContinueNotSuccessExecution;
  /**
   *
   * @type {string}
   * @memberof PipelineFiltered
   */
  id: string;
  /**
   *
   * @type {FSM}
   * @memberof PipelineFiltered
   */
  fsm?: FSM;
  /**
   *
   * @type {Array<PipelineExecutionWithoutDetails>}
   * @memberof PipelineFiltered
   */
  executions: Array<PipelineExecutionWithoutDetails>;
  /**
   *
   * @type {string}
   * @memberof PipelineFiltered
   */
  datetime_created: string;
  /**
   *
   * @type {string}
   * @memberof PipelineFiltered
   */
  datetime_modified: string;
  /**
   *
   * @type {boolean}
   * @memberof PipelineFiltered
   */
  notifications_enabled: boolean;
}

/**
 *
 * @export
 * @interface PipelineFilteredListRequestBody
 */
export interface PipelineFilteredListRequestBody {
  /**
   *
   * @type {string}
   * @memberof PipelineFilteredListRequestBody
   */
  instance_id: string;
  /**
   *
   * @type {Array<FiltersInner>}
   * @memberof PipelineFilteredListRequestBody
   */
  filters?: Array<FiltersInner>;
  /**
   *
   * @type {number}
   * @memberof PipelineFilteredListRequestBody
   */
  limit: number;
  /**
   *
   * @type {number}
   * @memberof PipelineFilteredListRequestBody
   */
  skip: number;
}
/**
 *
 * @export
 * @interface PipelineFilteredRequestBody
 */
export interface PipelineFilteredRequestBody {
  /**
   *
   * @type {string}
   * @memberof PipelineFilteredRequestBody
   */
  instance_id: string;
  /**
   *
   * @type {number}
   * @memberof PipelineFilteredRequestBody
   */
  limit?: number;
  /**
   *
   * @type {number}
   * @memberof PipelineFilteredRequestBody
   */
  skip?: number;
  /**
   *
   * @type {SortBy}
   * @memberof PipelineFilteredRequestBody
   */
  sort?: SortBy;
  /**
   *
   * @type {OrderBy}
   * @memberof PipelineFilteredRequestBody
   */
  order?: OrderBy;
  /**
   *
   * @type {Array<FiltersInner>}
   * @memberof PipelineFilteredRequestBody
   */
  filters?: Array<FiltersInner>;
}

/**
 *
 * @export
 * @interface PipelineIdFilter
 */
export interface PipelineIdFilter {
  /**
   *
   * @type {string}
   * @memberof PipelineIdFilter
   */
  key: PipelineIdFilterKey;
  /**
   *
   * @type {Array<string>}
   * @memberof PipelineIdFilter
   */
  exact_value: Array<string>;
  /**
   *
   * @type {string}
   * @memberof PipelineIdFilter
   */
  values_above?: string;
  /**
   *
   * @type {string}
   * @memberof PipelineIdFilter
   */
  values_under?: string;
  /**
   *
   * @type {string}
   * @memberof PipelineIdFilter
   */
  condition: PipelineIdFilterCondition;
}

export const PipelineIdFilterKey = {
  Id: 'id',
} as const;

export type PipelineIdFilterKey = (typeof PipelineIdFilterKey)[keyof typeof PipelineIdFilterKey];
export const PipelineIdFilterCondition = {
  In: 'in',
  Nin: 'nin',
} as const;

export type PipelineIdFilterCondition = (typeof PipelineIdFilterCondition)[keyof typeof PipelineIdFilterCondition];

/**
 *
 * @export
 * @interface PipelineListItem
 */
export interface PipelineListItem {
  /**
   *
   * @type {string}
   * @memberof PipelineListItem
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof PipelineListItem
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof PipelineListItem
   */
  schedule: string;
  /**
   *
   * @type {string}
   * @memberof PipelineListItem
   */
  description: string;
  /**
   *
   * @type {PipelineType}
   * @memberof PipelineListItem
   */
  type: PipelineType;
  /**
   *
   * @type {string}
   * @memberof PipelineListItem
   */
  datetime_created: string;
  /**
   *
   * @type {string}
   * @memberof PipelineListItem
   */
  datetime_modified: string;
  /**
   *
   * @type {string}
   * @memberof PipelineListItem
   */
  instance_id: string;
  /**
   *
   * @type {boolean}
   * @memberof PipelineListItem
   */
  enabled: boolean;
  /**
   *
   * @type {string}
   * @memberof PipelineListItem
   */
  blocked_details: string;
  /**
   *
   * @type {string}
   * @memberof PipelineListItem
   */
  time_zone: string;
  /**
   *
   * @type {string}
   * @memberof PipelineListItem
   */
  owner_email: string;
  /**
   *
   * @type {ContinueNotSuccessExecution}
   * @memberof PipelineListItem
   */
  continue_not_success_execution: ContinueNotSuccessExecution;
  /**
   *
   * @type {boolean}
   * @memberof PipelineListItem
   */
  notifications_enabled: boolean;
}

/**
 *
 * @export
 * @interface PipelineNotification
 */
export interface PipelineNotification {
  /**
   *
   * @type {string}
   * @memberof PipelineNotification
   */
  pipeline_id: string;
  /**
   *
   * @type {NotificationMode}
   * @memberof PipelineNotification
   */
  mode: NotificationMode;
  /**
   *
   * @type {string}
   * @memberof PipelineNotification
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof PipelineNotification
   */
  user_email: string;
  /**
   *
   * @type {EmailType}
   * @memberof PipelineNotification
   */
  email_type: EmailType;
}

/**
 *
 * @export
 * @interface PipelineNotificationCreate
 */
export interface PipelineNotificationCreate {
  /**
   *
   * @type {string}
   * @memberof PipelineNotificationCreate
   */
  pipeline_id: string;
  /**
   *
   * @type {NotificationMode}
   * @memberof PipelineNotificationCreate
   */
  mode: NotificationMode;
}

/**
 *
 * @export
 * @interface PipelineNotificationList
 */
export interface PipelineNotificationList {
  /**
   *
   * @type {string}
   * @memberof PipelineNotificationList
   */
  pipeline_id: string;
  /**
   *
   * @type {Array<string>}
   * @memberof PipelineNotificationList
   */
  emails: Array<string>;
  /**
   *
   * @type {NotificationMode}
   * @memberof PipelineNotificationList
   */
  mode?: NotificationMode;
}

/**
 *
 * @export
 * @interface PipelineNotificationUpdate
 */
export interface PipelineNotificationUpdate {
  /**
   *
   * @type {NotificationMode}
   * @memberof PipelineNotificationUpdate
   */
  mode: NotificationMode;
}

/**
 *
 * @export
 * @interface PipelineStep
 */
export interface PipelineStep {
  /**
   *
   * @type {string}
   * @memberof PipelineStep
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof PipelineStep
   */
  description?: string;
  /**
   *
   * @type {PipelineStepType}
   * @memberof PipelineStep
   */
  type?: PipelineStepType;
  /**
   *
   * @type {object}
   * @memberof PipelineStep
   */
  configuration?: object;
  /**
   *
   * @type {string}
   * @memberof PipelineStep
   */
  pipeline_id?: string;
  /**
   *
   * @type {string}
   * @memberof PipelineStep
   */
  id: string;
  /**
   *
   * @type {FSMTransition}
   * @memberof PipelineStep
   */
  fsm_transition?: FSMTransition;
  /**
   *
   * @type {string}
   * @memberof PipelineStep
   */
  datetime_created: string;
  /**
   *
   * @type {string}
   * @memberof PipelineStep
   */
  datetime_modified: string;
}

/**
 *
 * @export
 * @interface PipelineStepCreate
 */
export interface PipelineStepCreate {
  /**
   *
   * @type {string}
   * @memberof PipelineStepCreate
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof PipelineStepCreate
   */
  description: string;
  /**
   *
   * @type {PipelineStepType}
   * @memberof PipelineStepCreate
   */
  type: PipelineStepType;
  /**
   *
   * @type {object}
   * @memberof PipelineStepCreate
   */
  configuration: object;
  /**
   *
   * @type {string}
   * @memberof PipelineStepCreate
   */
  pipeline_id: string;
  /**
   *
   * @type {string}
   * @memberof PipelineStepCreate
   */
  previous_step_id?: string;
}

/**
 *
 * @export
 * @interface PipelineStepMove
 */
export interface PipelineStepMove {
  /**
   *
   * @type {string}
   * @memberof PipelineStepMove
   */
  previous_step_id?: string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const PipelineStepType = {
  AzureInboundRetriever: 'azure_inbound_retriever',
  SftpInboundRetriever: 'sftp_inbound_retriever',
  HttpInboundRetriever: 'http_inbound_retriever',
  AzureOutboundBearer: 'azure_outbound_bearer',
  SftpOutboundBearer: 'sftp_outbound_bearer',
  HttpOutboundBearer: 'http_outbound_bearer',
  DataStaging: 'data_staging',
  DataProcessing: 'data_processing',
  FilesMover: 'files_mover',
} as const;

export type PipelineStepType = (typeof PipelineStepType)[keyof typeof PipelineStepType];

/**
 *
 * @export
 * @interface PipelineStepUpdate
 */
export interface PipelineStepUpdate {
  /**
   *
   * @type {string}
   * @memberof PipelineStepUpdate
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof PipelineStepUpdate
   */
  description?: string;
  /**
   *
   * @type {PipelineStepType}
   * @memberof PipelineStepUpdate
   */
  type?: PipelineStepType;
  /**
   *
   * @type {object}
   * @memberof PipelineStepUpdate
   */
  configuration?: object;
  /**
   *
   * @type {string}
   * @memberof PipelineStepUpdate
   */
  pipeline_id?: string;
}

/**
 *
 * @export
 * @interface PipelineTemplate
 */
export interface PipelineTemplate {
  /**
   *
   * @type {string}
   * @memberof PipelineTemplate
   */
  vf_module_id: string;
  /**
   *
   * @type {string}
   * @memberof PipelineTemplate
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof PipelineTemplate
   */
  label: string;
  /**
   *
   * @type {string}
   * @memberof PipelineTemplate
   */
  description: string;
  /**
   *
   * @type {boolean}
   * @memberof PipelineTemplate
   */
  mandatory: boolean;
  /**
   *
   * @type {Array<StepsConfigurationInner>}
   * @memberof PipelineTemplate
   */
  steps_configuration: Array<StepsConfigurationInner>;
  /**
   *
   * @type {string}
   * @memberof PipelineTemplate
   */
  datetime_created: string;
  /**
   *
   * @type {string}
   * @memberof PipelineTemplate
   */
  datetime_modified: string;
  /**
   *
   * @type {string}
   * @memberof PipelineTemplate
   */
  created_by: string;
  /**
   *
   * @type {string}
   * @memberof PipelineTemplate
   */
  modified_by?: string;
  /**
   *
   * @type {string}
   * @memberof PipelineTemplate
   */
  zendesk_url?: string;
  /**
   *
   * @type {string}
   * @memberof PipelineTemplate
   */
  category?: string;
}
/**
 *
 * @export
 * @interface PipelineTemplateCreate
 */
export interface PipelineTemplateCreate {
  /**
   *
   * @type {string}
   * @memberof PipelineTemplateCreate
   */
  vf_module_id: string;
  /**
   *
   * @type {string}
   * @memberof PipelineTemplateCreate
   */
  label: string;
  /**
   *
   * @type {string}
   * @memberof PipelineTemplateCreate
   */
  description: string;
  /**
   *
   * @type {boolean}
   * @memberof PipelineTemplateCreate
   */
  mandatory: boolean;
  /**
   *
   * @type {string}
   * @memberof PipelineTemplateCreate
   */
  created_by?: string;
  /**
   *
   * @type {string}
   * @memberof PipelineTemplateCreate
   */
  modified_by?: string;
  /**
   *
   * @type {string}
   * @memberof PipelineTemplateCreate
   */
  datetime_created?: string;
  /**
   *
   * @type {string}
   * @memberof PipelineTemplateCreate
   */
  datetime_modified?: string;
  /**
   *
   * @type {string}
   * @memberof PipelineTemplateCreate
   */
  zendesk_url?: string;
  /**
   *
   * @type {Array<StepsConfigurationInner>}
   * @memberof PipelineTemplateCreate
   */
  steps_configuration: Array<StepsConfigurationInner>;
  /**
   *
   * @type {string}
   * @memberof PipelineTemplateCreate
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof PipelineTemplateCreate
   */
  category?: string;
}
/**
 *
 * @export
 * @interface PipelineTemplateListItem
 */
export interface PipelineTemplateListItem {
  /**
   *
   * @type {string}
   * @memberof PipelineTemplateListItem
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof PipelineTemplateListItem
   */
  vf_module_id: string;
  /**
   *
   * @type {boolean}
   * @memberof PipelineTemplateListItem
   */
  mandatory: boolean;
  /**
   *
   * @type {string}
   * @memberof PipelineTemplateListItem
   */
  label: string;
  /**
   *
   * @type {string}
   * @memberof PipelineTemplateListItem
   */
  category?: string;
}
/**
 *
 * @export
 * @interface PipelineTemplateUpdate
 */
export interface PipelineTemplateUpdate {
  /**
   *
   * @type {string}
   * @memberof PipelineTemplateUpdate
   */
  vf_module_id?: string;
  /**
   *
   * @type {string}
   * @memberof PipelineTemplateUpdate
   */
  label?: string;
  /**
   *
   * @type {string}
   * @memberof PipelineTemplateUpdate
   */
  description?: string;
  /**
   *
   * @type {boolean}
   * @memberof PipelineTemplateUpdate
   */
  mandatory?: boolean;
  /**
   *
   * @type {string}
   * @memberof PipelineTemplateUpdate
   */
  modified_by?: string;
  /**
   *
   * @type {string}
   * @memberof PipelineTemplateUpdate
   */
  datetime_modified?: string;
  /**
   *
   * @type {Array<StepsConfigurationInner>}
   * @memberof PipelineTemplateUpdate
   */
  steps_configuration?: Array<StepsConfigurationInner>;
  /**
   *
   * @type {string}
   * @memberof PipelineTemplateUpdate
   */
  zendesk_url?: string;
  /**
   *
   * @type {string}
   * @memberof PipelineTemplateUpdate
   */
  category?: string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const PipelineType = {
  Inbound: 'inbound',
  Outbound: 'outbound',
  Mixed: 'mixed',
} as const;

export type PipelineType = (typeof PipelineType)[keyof typeof PipelineType];

/**
 *
 * @export
 * @interface PipelineUpdate
 */
export interface PipelineUpdate {
  /**
   *
   * @type {string}
   * @memberof PipelineUpdate
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof PipelineUpdate
   */
  description?: string;
  /**
   *
   * @type {PipelineType}
   * @memberof PipelineUpdate
   */
  type?: PipelineType;
  /**
   *
   * @type {string}
   * @memberof PipelineUpdate
   */
  instance_id?: string;
  /**
   *
   * @type {boolean}
   * @memberof PipelineUpdate
   */
  enabled?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PipelineUpdate
   */
  blocked?: boolean;
  /**
   *
   * @type {string}
   * @memberof PipelineUpdate
   */
  blocked_details?: string;
  /**
   *
   * @type {string}
   * @memberof PipelineUpdate
   */
  schedule?: string;
  /**
   *
   * @type {string}
   * @memberof PipelineUpdate
   */
  time_zone?: string;
  /**
   *
   * @type {string}
   * @memberof PipelineUpdate
   */
  owner_email?: string;
  /**
   *
   * @type {ContinueNotSuccessExecution}
   * @memberof PipelineUpdate
   */
  continue_not_success_execution?: ContinueNotSuccessExecution;
  /**
   *
   * @type {boolean}
   * @memberof PipelineUpdate
   */
  notifications_enabled?: boolean;
}

/**
 *
 * @export
 * @interface PipelinesIdsFilter
 */
export interface PipelinesIdsFilter {
  /**
   *
   * @type {string}
   * @memberof PipelinesIdsFilter
   */
  key: PipelinesIdsFilterKey;
  /**
   *
   * @type {Array<string>}
   * @memberof PipelinesIdsFilter
   */
  exact_value: Array<string>;
  /**
   *
   * @type {string}
   * @memberof PipelinesIdsFilter
   */
  values_above?: string;
  /**
   *
   * @type {string}
   * @memberof PipelinesIdsFilter
   */
  values_under?: string;
  /**
   *
   * @type {string}
   * @memberof PipelinesIdsFilter
   */
  condition: PipelinesIdsFilterCondition;
}

export const PipelinesIdsFilterKey = {
  PipelineId: 'pipeline_id',
} as const;

export type PipelinesIdsFilterKey = (typeof PipelinesIdsFilterKey)[keyof typeof PipelinesIdsFilterKey];
export const PipelinesIdsFilterCondition = {
  In: 'in',
} as const;

export type PipelinesIdsFilterCondition =
  (typeof PipelinesIdsFilterCondition)[keyof typeof PipelinesIdsFilterCondition];

/**
 *
 * @export
 * @interface PivotColumnValues
 */
export interface PivotColumnValues {
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof PivotColumnValues
   */
  columns: { [key: string]: string };
  /**
   *
   * @type {string}
   * @memberof PivotColumnValues
   */
  method: PivotColumnValuesMethod;
  /**
   *
   * @type {boolean}
   * @memberof PivotColumnValues
   */
  keep_columns?: boolean;
}

export const PivotColumnValuesMethod = {
  PivotColumnsValues: 'pivot_columns_values',
} as const;

export type PivotColumnValuesMethod = (typeof PivotColumnValuesMethod)[keyof typeof PivotColumnValuesMethod];

/**
 *
 * @export
 * @interface RenameColumn
 */
export interface RenameColumn {
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof RenameColumn
   */
  columns: { [key: string]: string };
  /**
   *
   * @type {string}
   * @memberof RenameColumn
   */
  method: RenameColumnMethod;
}

export const RenameColumnMethod = {
  RenameColumn: 'rename_column',
} as const;

export type RenameColumnMethod = (typeof RenameColumnMethod)[keyof typeof RenameColumnMethod];

/**
 *
 * @export
 * @interface ReplaceColumnValue
 */
export interface ReplaceColumnValue {
  /**
   *
   * @type {Columns}
   * @memberof ReplaceColumnValue
   */
  columns: Columns;
  /**
   *
   * @type {string}
   * @memberof ReplaceColumnValue
   */
  method: ReplaceColumnValueMethod;
  /**
   *
   * @type {string}
   * @memberof ReplaceColumnValue
   */
  from_replace: string;
  /**
   *
   * @type {string}
   * @memberof ReplaceColumnValue
   */
  to_replace: string;
}

export const ReplaceColumnValueMethod = {
  ReplaceColumnValue: 'replace_column_value',
} as const;

export type ReplaceColumnValueMethod = (typeof ReplaceColumnValueMethod)[keyof typeof ReplaceColumnValueMethod];

/**
 *
 * @export
 * @interface ReplaceValue
 */
export interface ReplaceValue {
  /**
   *
   * @type {Columns}
   * @memberof ReplaceValue
   */
  columns: Columns;
  /**
   *
   * @type {string}
   * @memberof ReplaceValue
   */
  method: ReplaceValueMethod;
  /**
   *
   * @type {string}
   * @memberof ReplaceValue
   */
  from_replace: string;
  /**
   *
   * @type {string}
   * @memberof ReplaceValue
   */
  to_replace: string;
}

export const ReplaceValueMethod = {
  ReplaceValue: 'replace_value',
} as const;

export type ReplaceValueMethod = (typeof ReplaceValueMethod)[keyof typeof ReplaceValueMethod];

/**
 *
 * @export
 * @interface S3DeleteObject
 */
export interface S3DeleteObject {
  /**
   *
   * @type {S3Path}
   * @memberof S3DeleteObject
   */
  path: S3Path;
  /**
   *
   * @type {S3ObjectKind}
   * @memberof S3DeleteObject
   */
  kind: S3ObjectKind;
}

/**
 *
 * @export
 * @interface S3Object
 */
export interface S3Object {
  /**
   *
   * @type {string}
   * @memberof S3Object
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof S3Object
   */
  name: string;
  /**
   *
   * @type {S3ObjectKind}
   * @memberof S3Object
   */
  kind: S3ObjectKind;
  /**
   *
   * @type {string}
   * @memberof S3Object
   */
  size: string;
  /**
   *
   * @type {string}
   * @memberof S3Object
   */
  date_modified?: string;
  /**
   *
   * @type {string}
   * @memberof S3Object
   */
  path: string;
  /**
   *
   * @type {Array<S3Object>}
   * @memberof S3Object
   */
  contents: Array<S3Object>;
}

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const S3ObjectKind = {
  Folder: 'Folder',
  File: 'File',
} as const;

export type S3ObjectKind = (typeof S3ObjectKind)[keyof typeof S3ObjectKind];

/**
 *
 * @export
 * @interface S3ObjectNew
 */
export interface S3ObjectNew {
  /**
   *
   * @type {string}
   * @memberof S3ObjectNew
   */
  name: string;
  /**
   *
   * @type {S3ObjectKind}
   * @memberof S3ObjectNew
   */
  kind: S3ObjectKind;
  /**
   *
   * @type {string}
   * @memberof S3ObjectNew
   */
  size?: string;
  /**
   *
   * @type {string}
   * @memberof S3ObjectNew
   */
  date_modified?: string;
}

/**
 *
 * @export
 * @interface S3ObjectPath
 */
export interface S3ObjectPath {
  /**
   *
   * @type {S3Path}
   * @memberof S3ObjectPath
   */
  path: S3Path;
  /**
   *
   * @type {S3ObjectKind}
   * @memberof S3ObjectPath
   */
  kind: S3ObjectKind;
}

/**
 *
 * @export
 * @interface S3Path
 */
export interface S3Path {
  /**
   *
   * @type {string}
   * @memberof S3Path
   */
  old?: string;
  /**
   *
   * @type {string}
   * @memberof S3Path
   */
  new?: string;
}
/**
 *
 * @export
 * @interface S3PresignedURL
 */
export interface S3PresignedURL {
  /**
   *
   * @type {string}
   * @memberof S3PresignedURL
   */
  path: string;
  /**
   *
   * @type {S3ObjectKind}
   * @memberof S3PresignedURL
   */
  kind: S3ObjectKind;
  /**
   *
   * @type {string}
   * @memberof S3PresignedURL
   */
  content_type?: string;
}

/**
 *
 * @export
 * @interface S3UploadObject
 */
export interface S3UploadObject {
  /**
   *
   * @type {string}
   * @memberof S3UploadObject
   */
  binary?: string;
  /**
   *
   * @type {S3Path}
   * @memberof S3UploadObject
   */
  path: S3Path;
  /**
   *
   * @type {S3ObjectKind}
   * @memberof S3UploadObject
   */
  kind: S3ObjectKind;
}

/**
 *
 * @export
 * @interface SFTPAccount
 */
export interface SFTPAccount {
  /**
   *
   * @type {string}
   * @memberof SFTPAccount
   */
  user_name: string;
  /**
   *
   * @type {Array<{ [key: string]: string; }>}
   * @memberof SFTPAccount
   */
  home_directory_mappings: Array<{ [key: string]: string }>;
  /**
   *
   * @type {Array<any>}
   * @memberof SFTPAccount
   */
  ssh_public_keys: Array<any>;
}
/**
 *
 * @export
 * @interface SFTPAccountCreate
 */
export interface SFTPAccountCreate {
  /**
   *
   * @type {string}
   * @memberof SFTPAccountCreate
   */
  instance_id: string;
}
/**
 *
 * @export
 * @interface SFTPKey
 */
export interface SFTPKey {
  /**
   *
   * @type {string}
   * @memberof SFTPKey
   */
  ssh_public_key_body: string;
  /**
   *
   * @type {string}
   * @memberof SFTPKey
   */
  instance_id: string;
}
/**
 *
 * @export
 * @interface SFTPKeyID
 */
export interface SFTPKeyID {
  /**
   *
   * @type {string}
   * @memberof SFTPKeyID
   */
  ssh_public_key_id: string;
  /**
   *
   * @type {string}
   * @memberof SFTPKeyID
   */
  instance_id: string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const SortBy = {
  Enabled: 'enabled',
  Name: 'name',
  Description: 'description',
  Schedule: 'schedule',
  DatetimeCreated: 'datetime_created',
} as const;

export type SortBy = (typeof SortBy)[keyof typeof SortBy];

/**
 * @type StepsConfigurationInner
 * @export
 */
export type StepsConfigurationInner =
  | ({ type: 'data_processing' } & DataProcessingConfig)
  | ({ type: 'data_staging' } & DataStagingConfig);

/**
 *
 * @export
 * @interface StripValue
 */
export interface StripValue {
  /**
   *
   * @type {Columns}
   * @memberof StripValue
   */
  columns: Columns;
  /**
   *
   * @type {string}
   * @memberof StripValue
   */
  method: StripValueMethod;
  /**
   *
   * @type {string}
   * @memberof StripValue
   */
  to_strip: string;
  /**
   *
   * @type {StripValueHowTypes}
   * @memberof StripValue
   */
  how: StripValueHowTypes;
}

export const StripValueMethod = {
  StripValue: 'strip_value',
} as const;

export type StripValueMethod = (typeof StripValueMethod)[keyof typeof StripValueMethod];

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const StripValueHowTypes = {
  Left: 'left',
  Right: 'right',
  Both: 'both',
} as const;

export type StripValueHowTypes = (typeof StripValueHowTypes)[keyof typeof StripValueHowTypes];

/**
 * Configuration when the import is table type (not nested data in the files).
 * @export
 * @interface TableImportModel
 */
export interface TableImportModel {
  /**
   *
   * @type {PersistMethodTypes}
   * @memberof TableImportModel
   */
  persist_method?: PersistMethodTypes;
  /**
   *
   * @type {Array<string>}
   * @memberof TableImportModel
   */
  update_keys?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof TableImportModel
   */
  mandatory_keys?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof TableImportModel
   */
  inherit_keys?: Array<string>;
  /**
   *
   * @type {Array<TransformationModel>}
   * @memberof TableImportModel
   */
  transformations?: Array<TransformationModel>;
  /**
   *
   * @type {TargetCollection}
   * @memberof TableImportModel
   */
  target_collection: TargetCollection;
}

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const TableType = {
  Sap: 'sap',
  Custom: 'custom',
} as const;

export type TableType = (typeof TableType)[keyof typeof TableType];

/**
 *
 * @export
 * @interface TargetCollection
 */
export interface TargetCollection {}
/**
 *
 * @export
 * @interface ToBoolean
 */
export interface ToBoolean {
  /**
   *
   * @type {Columns}
   * @memberof ToBoolean
   */
  columns: Columns;
  /**
   *
   * @type {string}
   * @memberof ToBoolean
   */
  method: ToBooleanMethod;
  /**
   *
   * @type {boolean}
   * @memberof ToBoolean
   */
  default: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ToBoolean
   */
  force_type: boolean;
}

export const ToBooleanMethod = {
  ToBoolean: 'to_boolean',
} as const;

export type ToBooleanMethod = (typeof ToBooleanMethod)[keyof typeof ToBooleanMethod];

/**
 * Parameters to_date function
 * @export
 * @interface ToDate
 */
export interface ToDate {
  /**
   *
   * @type {Columns}
   * @memberof ToDate
   */
  columns: Columns;
  /**
   *
   * @type {string}
   * @memberof ToDate
   */
  method: ToDateMethod;
  /**
   *
   * @type {string}
   * @memberof ToDate
   */
  date_format: string;
  /**
   *
   * @type {boolean}
   * @memberof ToDate
   */
  errors_as_none?: boolean;
}

export const ToDateMethod = {
  ToDate: 'to_date',
} as const;

export type ToDateMethod = (typeof ToDateMethod)[keyof typeof ToDateMethod];

/**
 * Parameters to_boolean function.
 * @export
 * @interface ToFloat
 */
export interface ToFloat {
  /**
   *
   * @type {Columns}
   * @memberof ToFloat
   */
  columns: Columns;
  /**
   *
   * @type {string}
   * @memberof ToFloat
   */
  method: ToFloatMethod;
  /**
   *
   * @type {number}
   * @memberof ToFloat
   */
  default: number;
  /**
   *
   * @type {boolean}
   * @memberof ToFloat
   */
  force_type: boolean;
}

export const ToFloatMethod = {
  ToFloat: 'to_float',
} as const;

export type ToFloatMethod = (typeof ToFloatMethod)[keyof typeof ToFloatMethod];

/**
 * Parameters to_int function
 * @export
 * @interface ToInt
 */
export interface ToInt {
  /**
   *
   * @type {Columns}
   * @memberof ToInt
   */
  columns: Columns;
  /**
   *
   * @type {string}
   * @memberof ToInt
   */
  method: ToIntMethod;
  /**
   *
   * @type {number}
   * @memberof ToInt
   */
  default: number;
  /**
   *
   * @type {boolean}
   * @memberof ToInt
   */
  force_type: boolean;
}

export const ToIntMethod = {
  ToInt: 'to_int',
} as const;

export type ToIntMethod = (typeof ToIntMethod)[keyof typeof ToIntMethod];

/**
 *
 * @export
 * @interface ToLowercaseValue
 */
export interface ToLowercaseValue {
  /**
   *
   * @type {Columns}
   * @memberof ToLowercaseValue
   */
  columns: Columns;
  /**
   *
   * @type {string}
   * @memberof ToLowercaseValue
   */
  method: ToLowercaseValueMethod;
}

export const ToLowercaseValueMethod = {
  ToLowercase: 'to_lowercase',
} as const;

export type ToLowercaseValueMethod = (typeof ToLowercaseValueMethod)[keyof typeof ToLowercaseValueMethod];

/**
 *
 * @export
 * @interface TransformationModel
 */
export interface TransformationModel {
  /**
   *
   * @type {TransformationType}
   * @memberof TransformationModel
   */
  transformation_type: TransformationType;
  /**
   *
   * @type {Filter}
   * @memberof TransformationModel
   */
  filter?: Filter;
  /**
   *
   * @type {TransformationParameters}
   * @memberof TransformationModel
   */
  transformation_parameters: TransformationParameters;
}

/**
 *
 * @export
 * @interface TransformationParameters
 */
export interface TransformationParameters {
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof TransformationParameters
   */
  columns: { [key: string]: string };
  /**
   *
   * @type {string}
   * @memberof TransformationParameters
   */
  method: TransformationParametersMethod;
  /**
   *
   * @type {string}
   * @memberof TransformationParameters
   */
  date_format: string;
  /**
   *
   * @type {boolean}
   * @memberof TransformationParameters
   */
  errors_as_none?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TransformationParameters
   */
  default: boolean;
  /**
   *
   * @type {boolean}
   * @memberof TransformationParameters
   */
  force_type: boolean;
  /**
   *
   * @type {string}
   * @memberof TransformationParameters
   */
  to_strip: string;
  /**
   *
   * @type {StripValueHowTypes}
   * @memberof TransformationParameters
   */
  how: StripValueHowTypes;
  /**
   *
   * @type {string}
   * @memberof TransformationParameters
   */
  from_replace: string;
  /**
   *
   * @type {string}
   * @memberof TransformationParameters
   */
  to_replace: string;
  /**
   *
   * @type {string}
   * @memberof TransformationParameters
   */
  to_column: string;
  /**
   *
   * @type {string}
   * @memberof TransformationParameters
   */
  concatenator_value?: string;
  /**
   *
   * @type {ArithmeticsOperators}
   * @memberof TransformationParameters
   */
  operator: ArithmeticsOperators;
  /**
   *
   * @type {Operand}
   * @memberof TransformationParameters
   */
  operand: Operand;
  /**
   *
   * @type {boolean}
   * @memberof TransformationParameters
   */
  keep_columns?: boolean;
}

export const TransformationParametersMethod = {
  PivotColumnsValues: 'pivot_columns_values',
} as const;

export type TransformationParametersMethod =
  (typeof TransformationParametersMethod)[keyof typeof TransformationParametersMethod];

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const TransformationType = {
  TypeTransformation: 'type_transformation',
  ColumnTransformation: 'column_transformation',
  ValueTransformation: 'value_transformation',
} as const;

export type TransformationType = (typeof TransformationType)[keyof typeof TransformationType];

/**
 *
 * @export
 * @interface VFModule
 */
export interface VFModule {
  /**
   *
   * @type {string}
   * @memberof VFModule
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof VFModule
   */
  name: string;
  /**
   *
   * @type {VFModuleCodes}
   * @memberof VFModule
   */
  code: VFModuleCodes;
  /**
   *
   * @type {string}
   * @memberof VFModule
   */
  description?: string;
}

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export const VFModuleCodes = {
  AccrualsClaims: 'accruals_claims',
  Demand: 'demand',
  TradePromotions: 'trade_promotions',
  MarketingEvents: 'marketing_events',
  Plc: 'plc',
  RevenuePlan: 'revenue_plan',
  RiskAndOpportunities: 'risk_and_opportunities',
  TradePricing: 'trade_pricing',
  TargetsAndFunds: 'targets_and_funds',
  Core: 'core',
} as const;

export type VFModuleCodes = (typeof VFModuleCodes)[keyof typeof VFModuleCodes];

/**
 *
 * @export
 * @interface VFModuleCreate
 */
export interface VFModuleCreate {
  /**
   *
   * @type {string}
   * @memberof VFModuleCreate
   */
  name: string;
  /**
   *
   * @type {VFModuleCodes}
   * @memberof VFModuleCreate
   */
  code: VFModuleCodes;
  /**
   *
   * @type {string}
   * @memberof VFModuleCreate
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof VFModuleCreate
   */
  description?: string;
}

/**
 *
 * @export
 * @interface VFModuleUpdate
 */
export interface VFModuleUpdate {
  /**
   *
   * @type {string}
   * @memberof VFModuleUpdate
   */
  name: string;
  /**
   *
   * @type {VFModuleCodes}
   * @memberof VFModuleUpdate
   */
  code?: VFModuleCodes;
  /**
   *
   * @type {string}
   * @memberof VFModuleUpdate
   */
  description?: string;
}

/**
 *
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
  /**
   *
   * @type {Array<LocationInner>}
   * @memberof ValidationError
   */
  loc: Array<LocationInner>;
  /**
   *
   * @type {string}
   * @memberof ValidationError
   */
  msg: string;
  /**
   *
   * @type {string}
   * @memberof ValidationError
   */
  type: string;
}
/**
 *
 * @export
 * @interface ValueArithmeticOperation
 */
export interface ValueArithmeticOperation {
  /**
   *
   * @type {Columns}
   * @memberof ValueArithmeticOperation
   */
  columns: Columns;
  /**
   *
   * @type {string}
   * @memberof ValueArithmeticOperation
   */
  method: ValueArithmeticOperationMethod;
  /**
   *
   * @type {ArithmeticsOperators}
   * @memberof ValueArithmeticOperation
   */
  operator: ArithmeticsOperators;
  /**
   *
   * @type {Operand}
   * @memberof ValueArithmeticOperation
   */
  operand: Operand;
}

export const ValueArithmeticOperationMethod = {
  ValueArithmeticOperation: 'value_arithmetic_operation',
} as const;

export type ValueArithmeticOperationMethod =
  (typeof ValueArithmeticOperationMethod)[keyof typeof ValueArithmeticOperationMethod];

/**
 * Parameters accepted by file extractor
 * @export
 * @interface XMLFileParameters
 */
export interface XMLFileParameters {
  /**
   *
   * @type {string}
   * @memberof XMLFileParameters
   */
  file_type: XMLFileParametersFileType;
}

export const XMLFileParametersFileType = {
  Xml: 'xml',
} as const;

export type XMLFileParametersFileType = (typeof XMLFileParametersFileType)[keyof typeof XMLFileParametersFileType];

/**
 * DbCollectionsApi - axios parameter creator
 * @export
 */
export const DbCollectionsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Get all mongo db collection names for specified instance
     * @summary Get Collections
     * @param {string} instanceId
     * @param {string} [nameQuery]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCollections: async (
      instanceId: string,
      nameQuery?: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'instanceId' is not null or undefined
      assertParamExists('getCollections', 'instanceId', instanceId);
      const localVarPath = `/api/v1/db-collections`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (instanceId !== undefined) {
        localVarQueryParameter['instance_id'] = instanceId;
      }

      if (nameQuery !== undefined) {
        localVarQueryParameter['name_query'] = nameQuery;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DbCollectionsApi - functional programming interface
 * @export
 */
export const DbCollectionsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DbCollectionsApiAxiosParamCreator(configuration);
  return {
    /**
     * Get all mongo db collection names for specified instance
     * @summary Get Collections
     * @param {string} instanceId
     * @param {string} [nameQuery]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCollections(
      instanceId: string,
      nameQuery?: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MongoDBCollection>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCollections(instanceId, nameQuery, options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['DbCollectionsApi.getCollections']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * DbCollectionsApi - factory interface
 * @export
 */
export const DbCollectionsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = DbCollectionsApiFp(configuration);
  return {
    /**
     * Get all mongo db collection names for specified instance
     * @summary Get Collections
     * @param {DbCollectionsApiGetCollectionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCollections(
      requestParameters: DbCollectionsApiGetCollectionsRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<MongoDBCollection>> {
      return localVarFp
        .getCollections(requestParameters.instanceId, requestParameters.nameQuery, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for getCollections operation in DbCollectionsApi.
 * @export
 * @interface DbCollectionsApiGetCollectionsRequest
 */
export interface DbCollectionsApiGetCollectionsRequest {
  /**
   *
   * @type {string}
   * @memberof DbCollectionsApiGetCollections
   */
  readonly instanceId: string;

  /**
   *
   * @type {string}
   * @memberof DbCollectionsApiGetCollections
   */
  readonly nameQuery?: string;
}

/**
 * DbCollectionsApi - object-oriented interface
 * @export
 * @class DbCollectionsApi
 * @extends {BaseAPI}
 */
export class DbCollectionsApi extends BaseAPI {
  /**
   * Get all mongo db collection names for specified instance
   * @summary Get Collections
   * @param {DbCollectionsApiGetCollectionsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DbCollectionsApi
   */
  public getCollections(requestParameters: DbCollectionsApiGetCollectionsRequest, options?: RawAxiosRequestConfig) {
    return DbCollectionsApiFp(this.configuration)
      .getCollections(requestParameters.instanceId, requestParameters.nameQuery, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * DbDocumentsApi - axios parameter creator
 * @export
 */
export const DbDocumentsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Export Documents
     * @param {DocumentsQueryExport} documentsQueryExport
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportDocuments: async (
      documentsQueryExport: DocumentsQueryExport,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'documentsQueryExport' is not null or undefined
      assertParamExists('exportDocuments', 'documentsQueryExport', documentsQueryExport);
      const localVarPath = `/api/v1/db-documents/export`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(documentsQueryExport, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Export Documents By File Name Key
     * @param {string} fileNameKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportDocumentsByFileNameKey: async (
      fileNameKey: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'fileNameKey' is not null or undefined
      assertParamExists('exportDocumentsByFileNameKey', 'fileNameKey', fileNameKey);
      const localVarPath = `/api/v1/db-documents/export/{file_name_key}`.replace(
        `{${'file_name_key'}}`,
        encodeURIComponent(String(fileNameKey)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get all documents from specified collection for specified instance
     * @summary Get Documents
     * @param {DocumentsQueryPagination} documentsQueryPagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDocuments: async (
      documentsQueryPagination: DocumentsQueryPagination,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'documentsQueryPagination' is not null or undefined
      assertParamExists('getDocuments', 'documentsQueryPagination', documentsQueryPagination);
      const localVarPath = `/api/v1/db-documents`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        documentsQueryPagination,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get all documents from specified collection for specified instance
     * @summary Get Documents Count
     * @param {DocumentsQueryBase} documentsQueryBase
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDocumentsCount: async (
      documentsQueryBase: DocumentsQueryBase,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'documentsQueryBase' is not null or undefined
      assertParamExists('getDocumentsCount', 'documentsQueryBase', documentsQueryBase);
      const localVarPath = `/api/v1/db-documents/count`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(documentsQueryBase, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DbDocumentsApi - functional programming interface
 * @export
 */
export const DbDocumentsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DbDocumentsApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Export Documents
     * @param {DocumentsQueryExport} documentsQueryExport
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async exportDocuments(
      documentsQueryExport: DocumentsQueryExport,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExportCollectionStatusDataResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.exportDocuments(documentsQueryExport, options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['DbDocumentsApi.exportDocuments']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Export Documents By File Name Key
     * @param {string} fileNameKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async exportDocumentsByFileNameKey(
      fileNameKey: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ExportCollectionStatusDataResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.exportDocumentsByFileNameKey(fileNameKey, options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['DbDocumentsApi.exportDocumentsByFileNameKey']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Get all documents from specified collection for specified instance
     * @summary Get Documents
     * @param {DocumentsQueryPagination} documentsQueryPagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDocuments(
      documentsQueryPagination: DocumentsQueryPagination,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDocuments(documentsQueryPagination, options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['DbDocumentsApi.getDocuments']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Get all documents from specified collection for specified instance
     * @summary Get Documents Count
     * @param {DocumentsQueryBase} documentsQueryBase
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDocumentsCount(
      documentsQueryBase: DocumentsQueryBase,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocsCount>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDocumentsCount(documentsQueryBase, options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['DbDocumentsApi.getDocumentsCount']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * DbDocumentsApi - factory interface
 * @export
 */
export const DbDocumentsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = DbDocumentsApiFp(configuration);
  return {
    /**
     *
     * @summary Export Documents
     * @param {DbDocumentsApiExportDocumentsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportDocuments(
      requestParameters: DbDocumentsApiExportDocumentsRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<ExportCollectionStatusDataResponse> {
      return localVarFp
        .exportDocuments(requestParameters.documentsQueryExport, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Export Documents By File Name Key
     * @param {DbDocumentsApiExportDocumentsByFileNameKeyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportDocumentsByFileNameKey(
      requestParameters: DbDocumentsApiExportDocumentsByFileNameKeyRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<ExportCollectionStatusDataResponse> {
      return localVarFp
        .exportDocumentsByFileNameKey(requestParameters.fileNameKey, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get all documents from specified collection for specified instance
     * @summary Get Documents
     * @param {DbDocumentsApiGetDocumentsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDocuments(
      requestParameters: DbDocumentsApiGetDocumentsRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .getDocuments(requestParameters.documentsQueryPagination, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get all documents from specified collection for specified instance
     * @summary Get Documents Count
     * @param {DbDocumentsApiGetDocumentsCountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDocumentsCount(
      requestParameters: DbDocumentsApiGetDocumentsCountRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<DocsCount> {
      return localVarFp
        .getDocumentsCount(requestParameters.documentsQueryBase, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for exportDocuments operation in DbDocumentsApi.
 * @export
 * @interface DbDocumentsApiExportDocumentsRequest
 */
export interface DbDocumentsApiExportDocumentsRequest {
  /**
   *
   * @type {DocumentsQueryExport}
   * @memberof DbDocumentsApiExportDocuments
   */
  readonly documentsQueryExport: DocumentsQueryExport;
}

/**
 * Request parameters for exportDocumentsByFileNameKey operation in DbDocumentsApi.
 * @export
 * @interface DbDocumentsApiExportDocumentsByFileNameKeyRequest
 */
export interface DbDocumentsApiExportDocumentsByFileNameKeyRequest {
  /**
   *
   * @type {string}
   * @memberof DbDocumentsApiExportDocumentsByFileNameKey
   */
  readonly fileNameKey: string;
}

/**
 * Request parameters for getDocuments operation in DbDocumentsApi.
 * @export
 * @interface DbDocumentsApiGetDocumentsRequest
 */
export interface DbDocumentsApiGetDocumentsRequest {
  /**
   *
   * @type {DocumentsQueryPagination}
   * @memberof DbDocumentsApiGetDocuments
   */
  readonly documentsQueryPagination: DocumentsQueryPagination;
}

/**
 * Request parameters for getDocumentsCount operation in DbDocumentsApi.
 * @export
 * @interface DbDocumentsApiGetDocumentsCountRequest
 */
export interface DbDocumentsApiGetDocumentsCountRequest {
  /**
   *
   * @type {DocumentsQueryBase}
   * @memberof DbDocumentsApiGetDocumentsCount
   */
  readonly documentsQueryBase: DocumentsQueryBase;
}

/**
 * DbDocumentsApi - object-oriented interface
 * @export
 * @class DbDocumentsApi
 * @extends {BaseAPI}
 */
export class DbDocumentsApi extends BaseAPI {
  /**
   *
   * @summary Export Documents
   * @param {DbDocumentsApiExportDocumentsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DbDocumentsApi
   */
  public exportDocuments(requestParameters: DbDocumentsApiExportDocumentsRequest, options?: RawAxiosRequestConfig) {
    return DbDocumentsApiFp(this.configuration)
      .exportDocuments(requestParameters.documentsQueryExport, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Export Documents By File Name Key
   * @param {DbDocumentsApiExportDocumentsByFileNameKeyRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DbDocumentsApi
   */
  public exportDocumentsByFileNameKey(
    requestParameters: DbDocumentsApiExportDocumentsByFileNameKeyRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DbDocumentsApiFp(this.configuration)
      .exportDocumentsByFileNameKey(requestParameters.fileNameKey, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get all documents from specified collection for specified instance
   * @summary Get Documents
   * @param {DbDocumentsApiGetDocumentsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DbDocumentsApi
   */
  public getDocuments(requestParameters: DbDocumentsApiGetDocumentsRequest, options?: RawAxiosRequestConfig) {
    return DbDocumentsApiFp(this.configuration)
      .getDocuments(requestParameters.documentsQueryPagination, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get all documents from specified collection for specified instance
   * @summary Get Documents Count
   * @param {DbDocumentsApiGetDocumentsCountRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DbDocumentsApi
   */
  public getDocumentsCount(requestParameters: DbDocumentsApiGetDocumentsCountRequest, options?: RawAxiosRequestConfig) {
    return DbDocumentsApiFp(this.configuration)
      .getDocumentsCount(requestParameters.documentsQueryBase, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Read Root
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readRootGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Read Root
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async readRootGet(
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.readRootGet(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['DefaultApi.readRootGet']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = DefaultApiFp(configuration);
  return {
    /**
     *
     * @summary Read Root
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readRootGet(options?: RawAxiosRequestConfig): AxiosPromise<any> {
      return localVarFp.readRootGet(options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
  /**
   *
   * @summary Read Root
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public readRootGet(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .readRootGet(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * DefaultSettingsDataStatingApi - axios parameter creator
 * @export
 */
export const DefaultSettingsDataStatingApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Create a default settings for data staging.
     * @summary Create Default Settings Data Staging
     * @param {DefaultSettingsDataStagingCreate} defaultSettingsDataStagingCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createDefaultSettingsDataStaging: async (
      defaultSettingsDataStagingCreate: DefaultSettingsDataStagingCreate,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'defaultSettingsDataStagingCreate' is not null or undefined
      assertParamExists(
        'createDefaultSettingsDataStaging',
        'defaultSettingsDataStagingCreate',
        defaultSettingsDataStagingCreate,
      );
      const localVarPath = `/api/v1/default-settings-data-staging`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        defaultSettingsDataStagingCreate,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Delete a default settings for data staging.
     * @summary Delete Default Settings Data Staging
     * @param {string} table
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteDefaultSettingsDataStagingByName: async (
      table: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'table' is not null or undefined
      assertParamExists('deleteDefaultSettingsDataStagingByName', 'table', table);
      const localVarPath = `/api/v1/default-settings-data-staging/{table}`.replace(
        `{${'table'}}`,
        encodeURIComponent(String(table)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get a default setting for data staging by table name.
     * @summary Get Default Settings Data Staging
     * @param {string} table
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDefaultSettingsDataStagingByName: async (
      table: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'table' is not null or undefined
      assertParamExists('getDefaultSettingsDataStagingByName', 'table', table);
      const localVarPath = `/api/v1/default-settings-data-staging/{table}`.replace(
        `{${'table'}}`,
        encodeURIComponent(String(table)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get all default settings for data staging by the specified filtering parameters.
     * @summary Get Default Settings Data Stagings
     * @param {number} [limit]
     * @param {number} [skip]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDefaultSettingsDataStagings: async (
      limit?: number,
      skip?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/default-settings-data-staging`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (skip !== undefined) {
        localVarQueryParameter['skip'] = skip;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update a default settings for data staging.
     * @summary Update Default Settings Data Staging
     * @param {string} table
     * @param {DefaultSettingsDataStagingUpdate} defaultSettingsDataStagingUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDefaultSettingsDataStagingByName: async (
      table: string,
      defaultSettingsDataStagingUpdate: DefaultSettingsDataStagingUpdate,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'table' is not null or undefined
      assertParamExists('updateDefaultSettingsDataStagingByName', 'table', table);
      // verify required parameter 'defaultSettingsDataStagingUpdate' is not null or undefined
      assertParamExists(
        'updateDefaultSettingsDataStagingByName',
        'defaultSettingsDataStagingUpdate',
        defaultSettingsDataStagingUpdate,
      );
      const localVarPath = `/api/v1/default-settings-data-staging/{table}`.replace(
        `{${'table'}}`,
        encodeURIComponent(String(table)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        defaultSettingsDataStagingUpdate,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DefaultSettingsDataStatingApi - functional programming interface
 * @export
 */
export const DefaultSettingsDataStatingApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DefaultSettingsDataStatingApiAxiosParamCreator(configuration);
  return {
    /**
     * Create a default settings for data staging.
     * @summary Create Default Settings Data Staging
     * @param {DefaultSettingsDataStagingCreate} defaultSettingsDataStagingCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createDefaultSettingsDataStaging(
      defaultSettingsDataStagingCreate: DefaultSettingsDataStagingCreate,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefaultSettingsDataStaging>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createDefaultSettingsDataStaging(
        defaultSettingsDataStagingCreate,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['DefaultSettingsDataStatingApi.createDefaultSettingsDataStaging']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Delete a default settings for data staging.
     * @summary Delete Default Settings Data Staging
     * @param {string} table
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteDefaultSettingsDataStagingByName(
      table: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefaultSettingsDataStaging>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDefaultSettingsDataStagingByName(table, options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['DefaultSettingsDataStatingApi.deleteDefaultSettingsDataStagingByName']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Get a default setting for data staging by table name.
     * @summary Get Default Settings Data Staging
     * @param {string} table
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDefaultSettingsDataStagingByName(
      table: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefaultSettingsDataStaging>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDefaultSettingsDataStagingByName(table, options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['DefaultSettingsDataStatingApi.getDefaultSettingsDataStagingByName']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Get all default settings for data staging by the specified filtering parameters.
     * @summary Get Default Settings Data Stagings
     * @param {number} [limit]
     * @param {number} [skip]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDefaultSettingsDataStagings(
      limit?: number,
      skip?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DefaultSettingsDataStaging>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDefaultSettingsDataStagings(limit, skip, options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['DefaultSettingsDataStatingApi.getDefaultSettingsDataStagings']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Update a default settings for data staging.
     * @summary Update Default Settings Data Staging
     * @param {string} table
     * @param {DefaultSettingsDataStagingUpdate} defaultSettingsDataStagingUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateDefaultSettingsDataStagingByName(
      table: string,
      defaultSettingsDataStagingUpdate: DefaultSettingsDataStagingUpdate,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DefaultSettingsDataStaging>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateDefaultSettingsDataStagingByName(
        table,
        defaultSettingsDataStagingUpdate,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['DefaultSettingsDataStatingApi.updateDefaultSettingsDataStagingByName']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * DefaultSettingsDataStatingApi - factory interface
 * @export
 */
export const DefaultSettingsDataStatingApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = DefaultSettingsDataStatingApiFp(configuration);
  return {
    /**
     * Create a default settings for data staging.
     * @summary Create Default Settings Data Staging
     * @param {DefaultSettingsDataStatingApiCreateDefaultSettingsDataStagingRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createDefaultSettingsDataStaging(
      requestParameters: DefaultSettingsDataStatingApiCreateDefaultSettingsDataStagingRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<DefaultSettingsDataStaging> {
      return localVarFp
        .createDefaultSettingsDataStaging(requestParameters.defaultSettingsDataStagingCreate, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Delete a default settings for data staging.
     * @summary Delete Default Settings Data Staging
     * @param {DefaultSettingsDataStatingApiDeleteDefaultSettingsDataStagingByNameRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteDefaultSettingsDataStagingByName(
      requestParameters: DefaultSettingsDataStatingApiDeleteDefaultSettingsDataStagingByNameRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<DefaultSettingsDataStaging> {
      return localVarFp
        .deleteDefaultSettingsDataStagingByName(requestParameters.table, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get a default setting for data staging by table name.
     * @summary Get Default Settings Data Staging
     * @param {DefaultSettingsDataStatingApiGetDefaultSettingsDataStagingByNameRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDefaultSettingsDataStagingByName(
      requestParameters: DefaultSettingsDataStatingApiGetDefaultSettingsDataStagingByNameRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<DefaultSettingsDataStaging> {
      return localVarFp
        .getDefaultSettingsDataStagingByName(requestParameters.table, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get all default settings for data staging by the specified filtering parameters.
     * @summary Get Default Settings Data Stagings
     * @param {DefaultSettingsDataStatingApiGetDefaultSettingsDataStagingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDefaultSettingsDataStagings(
      requestParameters: DefaultSettingsDataStatingApiGetDefaultSettingsDataStagingsRequest = {},
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<DefaultSettingsDataStaging>> {
      return localVarFp
        .getDefaultSettingsDataStagings(requestParameters.limit, requestParameters.skip, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Update a default settings for data staging.
     * @summary Update Default Settings Data Staging
     * @param {DefaultSettingsDataStatingApiUpdateDefaultSettingsDataStagingByNameRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDefaultSettingsDataStagingByName(
      requestParameters: DefaultSettingsDataStatingApiUpdateDefaultSettingsDataStagingByNameRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<DefaultSettingsDataStaging> {
      return localVarFp
        .updateDefaultSettingsDataStagingByName(
          requestParameters.table,
          requestParameters.defaultSettingsDataStagingUpdate,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for createDefaultSettingsDataStaging operation in DefaultSettingsDataStatingApi.
 * @export
 * @interface DefaultSettingsDataStatingApiCreateDefaultSettingsDataStagingRequest
 */
export interface DefaultSettingsDataStatingApiCreateDefaultSettingsDataStagingRequest {
  /**
   *
   * @type {DefaultSettingsDataStagingCreate}
   * @memberof DefaultSettingsDataStatingApiCreateDefaultSettingsDataStaging
   */
  readonly defaultSettingsDataStagingCreate: DefaultSettingsDataStagingCreate;
}

/**
 * Request parameters for deleteDefaultSettingsDataStagingByName operation in DefaultSettingsDataStatingApi.
 * @export
 * @interface DefaultSettingsDataStatingApiDeleteDefaultSettingsDataStagingByNameRequest
 */
export interface DefaultSettingsDataStatingApiDeleteDefaultSettingsDataStagingByNameRequest {
  /**
   *
   * @type {string}
   * @memberof DefaultSettingsDataStatingApiDeleteDefaultSettingsDataStagingByName
   */
  readonly table: string;
}

/**
 * Request parameters for getDefaultSettingsDataStagingByName operation in DefaultSettingsDataStatingApi.
 * @export
 * @interface DefaultSettingsDataStatingApiGetDefaultSettingsDataStagingByNameRequest
 */
export interface DefaultSettingsDataStatingApiGetDefaultSettingsDataStagingByNameRequest {
  /**
   *
   * @type {string}
   * @memberof DefaultSettingsDataStatingApiGetDefaultSettingsDataStagingByName
   */
  readonly table: string;
}

/**
 * Request parameters for getDefaultSettingsDataStagings operation in DefaultSettingsDataStatingApi.
 * @export
 * @interface DefaultSettingsDataStatingApiGetDefaultSettingsDataStagingsRequest
 */
export interface DefaultSettingsDataStatingApiGetDefaultSettingsDataStagingsRequest {
  /**
   *
   * @type {number}
   * @memberof DefaultSettingsDataStatingApiGetDefaultSettingsDataStagings
   */
  readonly limit?: number;

  /**
   *
   * @type {number}
   * @memberof DefaultSettingsDataStatingApiGetDefaultSettingsDataStagings
   */
  readonly skip?: number;
}

/**
 * Request parameters for updateDefaultSettingsDataStagingByName operation in DefaultSettingsDataStatingApi.
 * @export
 * @interface DefaultSettingsDataStatingApiUpdateDefaultSettingsDataStagingByNameRequest
 */
export interface DefaultSettingsDataStatingApiUpdateDefaultSettingsDataStagingByNameRequest {
  /**
   *
   * @type {string}
   * @memberof DefaultSettingsDataStatingApiUpdateDefaultSettingsDataStagingByName
   */
  readonly table: string;

  /**
   *
   * @type {DefaultSettingsDataStagingUpdate}
   * @memberof DefaultSettingsDataStatingApiUpdateDefaultSettingsDataStagingByName
   */
  readonly defaultSettingsDataStagingUpdate: DefaultSettingsDataStagingUpdate;
}

/**
 * DefaultSettingsDataStatingApi - object-oriented interface
 * @export
 * @class DefaultSettingsDataStatingApi
 * @extends {BaseAPI}
 */
export class DefaultSettingsDataStatingApi extends BaseAPI {
  /**
   * Create a default settings for data staging.
   * @summary Create Default Settings Data Staging
   * @param {DefaultSettingsDataStatingApiCreateDefaultSettingsDataStagingRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultSettingsDataStatingApi
   */
  public createDefaultSettingsDataStaging(
    requestParameters: DefaultSettingsDataStatingApiCreateDefaultSettingsDataStagingRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DefaultSettingsDataStatingApiFp(this.configuration)
      .createDefaultSettingsDataStaging(requestParameters.defaultSettingsDataStagingCreate, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Delete a default settings for data staging.
   * @summary Delete Default Settings Data Staging
   * @param {DefaultSettingsDataStatingApiDeleteDefaultSettingsDataStagingByNameRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultSettingsDataStatingApi
   */
  public deleteDefaultSettingsDataStagingByName(
    requestParameters: DefaultSettingsDataStatingApiDeleteDefaultSettingsDataStagingByNameRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DefaultSettingsDataStatingApiFp(this.configuration)
      .deleteDefaultSettingsDataStagingByName(requestParameters.table, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get a default setting for data staging by table name.
   * @summary Get Default Settings Data Staging
   * @param {DefaultSettingsDataStatingApiGetDefaultSettingsDataStagingByNameRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultSettingsDataStatingApi
   */
  public getDefaultSettingsDataStagingByName(
    requestParameters: DefaultSettingsDataStatingApiGetDefaultSettingsDataStagingByNameRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DefaultSettingsDataStatingApiFp(this.configuration)
      .getDefaultSettingsDataStagingByName(requestParameters.table, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get all default settings for data staging by the specified filtering parameters.
   * @summary Get Default Settings Data Stagings
   * @param {DefaultSettingsDataStatingApiGetDefaultSettingsDataStagingsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultSettingsDataStatingApi
   */
  public getDefaultSettingsDataStagings(
    requestParameters: DefaultSettingsDataStatingApiGetDefaultSettingsDataStagingsRequest = {},
    options?: RawAxiosRequestConfig,
  ) {
    return DefaultSettingsDataStatingApiFp(this.configuration)
      .getDefaultSettingsDataStagings(requestParameters.limit, requestParameters.skip, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update a default settings for data staging.
   * @summary Update Default Settings Data Staging
   * @param {DefaultSettingsDataStatingApiUpdateDefaultSettingsDataStagingByNameRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultSettingsDataStatingApi
   */
  public updateDefaultSettingsDataStagingByName(
    requestParameters: DefaultSettingsDataStatingApiUpdateDefaultSettingsDataStagingByNameRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return DefaultSettingsDataStatingApiFp(this.configuration)
      .updateDefaultSettingsDataStagingByName(
        requestParameters.table,
        requestParameters.defaultSettingsDataStagingUpdate,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * FilesApi - axios parameter creator
 * @export
 */
export const FilesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Create Multipart Upload Presigned Url
     * @param {string} instanceId
     * @param {string} path
     * @param {string} uploadId
     * @param {number} partNo
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {DataIn | null} [dataIn]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createMultipartUploadPresignedUrlForInstance: async (
      instanceId: string,
      path: string,
      uploadId: string,
      partNo: number,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      dataIn?: DataIn | null,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'instanceId' is not null or undefined
      assertParamExists('createMultipartUploadPresignedUrlForInstance', 'instanceId', instanceId);
      // verify required parameter 'path' is not null or undefined
      assertParamExists('createMultipartUploadPresignedUrlForInstance', 'path', path);
      // verify required parameter 'uploadId' is not null or undefined
      assertParamExists('createMultipartUploadPresignedUrlForInstance', 'uploadId', uploadId);
      // verify required parameter 'partNo' is not null or undefined
      assertParamExists('createMultipartUploadPresignedUrlForInstance', 'partNo', partNo);
      const localVarPath = `/api/v1/files/multipart_upload/presigned_url/{instance_id}`.replace(
        `{${'instance_id'}}`,
        encodeURIComponent(String(instanceId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (path !== undefined) {
        localVarQueryParameter['path'] = path;
      }

      if (uploadId !== undefined) {
        localVarQueryParameter['upload_id'] = uploadId;
      }

      if (partNo !== undefined) {
        localVarQueryParameter['part_no'] = partNo;
      }

      if (httpApiKeyId !== undefined) {
        localVarQueryParameter['http_api_key_id'] = httpApiKeyId;
      }

      if (pipelineId !== undefined) {
        localVarQueryParameter['pipeline_id'] = pipelineId;
      }

      if (pipelineStepId !== undefined) {
        localVarQueryParameter['pipeline_step_id'] = pipelineStepId;
      }

      if (pipelineExecutionId !== undefined) {
        localVarQueryParameter['pipeline_execution_id'] = pipelineExecutionId;
      }

      if (pipelineNotificationId !== undefined) {
        localVarQueryParameter['pipeline_notification_id'] = pipelineNotificationId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(dataIn, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete Files
     * @param {string} instanceId
     * @param {Array<S3DeleteObject>} s3DeleteObject
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteFilesForInstance: async (
      instanceId: string,
      s3DeleteObject: Array<S3DeleteObject>,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'instanceId' is not null or undefined
      assertParamExists('deleteFilesForInstance', 'instanceId', instanceId);
      // verify required parameter 's3DeleteObject' is not null or undefined
      assertParamExists('deleteFilesForInstance', 's3DeleteObject', s3DeleteObject);
      const localVarPath = `/api/v1/files/{instance_id}`.replace(
        `{${'instance_id'}}`,
        encodeURIComponent(String(instanceId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (httpApiKeyId !== undefined) {
        localVarQueryParameter['http_api_key_id'] = httpApiKeyId;
      }

      if (pipelineId !== undefined) {
        localVarQueryParameter['pipeline_id'] = pipelineId;
      }

      if (pipelineStepId !== undefined) {
        localVarQueryParameter['pipeline_step_id'] = pipelineStepId;
      }

      if (pipelineExecutionId !== undefined) {
        localVarQueryParameter['pipeline_execution_id'] = pipelineExecutionId;
      }

      if (pipelineNotificationId !== undefined) {
        localVarQueryParameter['pipeline_notification_id'] = pipelineNotificationId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(s3DeleteObject, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Download File
     * @param {string} instanceId
     * @param {string} path
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {DataIn | null} [dataIn]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadFileForInstance: async (
      instanceId: string,
      path: string,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      dataIn?: DataIn | null,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'instanceId' is not null or undefined
      assertParamExists('downloadFileForInstance', 'instanceId', instanceId);
      // verify required parameter 'path' is not null or undefined
      assertParamExists('downloadFileForInstance', 'path', path);
      const localVarPath = `/api/v1/files/download/{instance_id}`.replace(
        `{${'instance_id'}}`,
        encodeURIComponent(String(instanceId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (path !== undefined) {
        localVarQueryParameter['path'] = path;
      }

      if (httpApiKeyId !== undefined) {
        localVarQueryParameter['http_api_key_id'] = httpApiKeyId;
      }

      if (pipelineId !== undefined) {
        localVarQueryParameter['pipeline_id'] = pipelineId;
      }

      if (pipelineStepId !== undefined) {
        localVarQueryParameter['pipeline_step_id'] = pipelineStepId;
      }

      if (pipelineExecutionId !== undefined) {
        localVarQueryParameter['pipeline_execution_id'] = pipelineExecutionId;
      }

      if (pipelineNotificationId !== undefined) {
        localVarQueryParameter['pipeline_notification_id'] = pipelineNotificationId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(dataIn, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Generate Presigned Url
     * @param {string} instanceId
     * @param {Array<S3PresignedURL>} s3PresignedURL
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generatePresignedUrlForInstance: async (
      instanceId: string,
      s3PresignedURL: Array<S3PresignedURL>,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'instanceId' is not null or undefined
      assertParamExists('generatePresignedUrlForInstance', 'instanceId', instanceId);
      // verify required parameter 's3PresignedURL' is not null or undefined
      assertParamExists('generatePresignedUrlForInstance', 's3PresignedURL', s3PresignedURL);
      const localVarPath = `/api/v1/files/presigned_url/{instance_id}`.replace(
        `{${'instance_id'}}`,
        encodeURIComponent(String(instanceId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (httpApiKeyId !== undefined) {
        localVarQueryParameter['http_api_key_id'] = httpApiKeyId;
      }

      if (pipelineId !== undefined) {
        localVarQueryParameter['pipeline_id'] = pipelineId;
      }

      if (pipelineStepId !== undefined) {
        localVarQueryParameter['pipeline_step_id'] = pipelineStepId;
      }

      if (pipelineExecutionId !== undefined) {
        localVarQueryParameter['pipeline_execution_id'] = pipelineExecutionId;
      }

      if (pipelineNotificationId !== undefined) {
        localVarQueryParameter['pipeline_notification_id'] = pipelineNotificationId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(s3PresignedURL, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Instance Files
     * @param {string} instanceId
     * @param {string} [bucket]
     * @param {string} [prefix]
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {DataIn | null} [dataIn]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFilesForInstance: async (
      instanceId: string,
      bucket?: string,
      prefix?: string,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      dataIn?: DataIn | null,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'instanceId' is not null or undefined
      assertParamExists('getFilesForInstance', 'instanceId', instanceId);
      const localVarPath = `/api/v1/files/{instance_id}`.replace(
        `{${'instance_id'}}`,
        encodeURIComponent(String(instanceId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (bucket !== undefined) {
        localVarQueryParameter['bucket'] = bucket;
      }

      if (prefix !== undefined) {
        localVarQueryParameter['prefix'] = prefix;
      }

      if (httpApiKeyId !== undefined) {
        localVarQueryParameter['http_api_key_id'] = httpApiKeyId;
      }

      if (pipelineId !== undefined) {
        localVarQueryParameter['pipeline_id'] = pipelineId;
      }

      if (pipelineStepId !== undefined) {
        localVarQueryParameter['pipeline_step_id'] = pipelineStepId;
      }

      if (pipelineExecutionId !== undefined) {
        localVarQueryParameter['pipeline_execution_id'] = pipelineExecutionId;
      }

      if (pipelineNotificationId !== undefined) {
        localVarQueryParameter['pipeline_notification_id'] = pipelineNotificationId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(dataIn, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Root Files
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRootFiles: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/files/roots`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Move Files
     * @param {string} instanceId
     * @param {Array<S3ObjectPath>} s3ObjectPath
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    moveFilesForInstance: async (
      instanceId: string,
      s3ObjectPath: Array<S3ObjectPath>,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'instanceId' is not null or undefined
      assertParamExists('moveFilesForInstance', 'instanceId', instanceId);
      // verify required parameter 's3ObjectPath' is not null or undefined
      assertParamExists('moveFilesForInstance', 's3ObjectPath', s3ObjectPath);
      const localVarPath = `/api/v1/files/{instance_id}`.replace(
        `{${'instance_id'}}`,
        encodeURIComponent(String(instanceId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (httpApiKeyId !== undefined) {
        localVarQueryParameter['http_api_key_id'] = httpApiKeyId;
      }

      if (pipelineId !== undefined) {
        localVarQueryParameter['pipeline_id'] = pipelineId;
      }

      if (pipelineStepId !== undefined) {
        localVarQueryParameter['pipeline_step_id'] = pipelineStepId;
      }

      if (pipelineExecutionId !== undefined) {
        localVarQueryParameter['pipeline_execution_id'] = pipelineExecutionId;
      }

      if (pipelineNotificationId !== undefined) {
        localVarQueryParameter['pipeline_notification_id'] = pipelineNotificationId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(s3ObjectPath, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Multipart Upload Action
     * @param {string} instanceId
     * @param {MultipartUploadActionData} multipartUploadActionData
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    multipartUploadActionForInstance: async (
      instanceId: string,
      multipartUploadActionData: MultipartUploadActionData,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'instanceId' is not null or undefined
      assertParamExists('multipartUploadActionForInstance', 'instanceId', instanceId);
      // verify required parameter 'multipartUploadActionData' is not null or undefined
      assertParamExists('multipartUploadActionForInstance', 'multipartUploadActionData', multipartUploadActionData);
      const localVarPath = `/api/v1/files/multipart_upload/action/{instance_id}`.replace(
        `{${'instance_id'}}`,
        encodeURIComponent(String(instanceId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (httpApiKeyId !== undefined) {
        localVarQueryParameter['http_api_key_id'] = httpApiKeyId;
      }

      if (pipelineId !== undefined) {
        localVarQueryParameter['pipeline_id'] = pipelineId;
      }

      if (pipelineStepId !== undefined) {
        localVarQueryParameter['pipeline_step_id'] = pipelineStepId;
      }

      if (pipelineExecutionId !== undefined) {
        localVarQueryParameter['pipeline_execution_id'] = pipelineExecutionId;
      }

      if (pipelineNotificationId !== undefined) {
        localVarQueryParameter['pipeline_notification_id'] = pipelineNotificationId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        multipartUploadActionData,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Upload Files
     * @param {string} instanceId
     * @param {Array<S3UploadObject>} s3UploadObject
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadFilesForInstance: async (
      instanceId: string,
      s3UploadObject: Array<S3UploadObject>,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'instanceId' is not null or undefined
      assertParamExists('uploadFilesForInstance', 'instanceId', instanceId);
      // verify required parameter 's3UploadObject' is not null or undefined
      assertParamExists('uploadFilesForInstance', 's3UploadObject', s3UploadObject);
      const localVarPath = `/api/v1/files/{instance_id}`.replace(
        `{${'instance_id'}}`,
        encodeURIComponent(String(instanceId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (httpApiKeyId !== undefined) {
        localVarQueryParameter['http_api_key_id'] = httpApiKeyId;
      }

      if (pipelineId !== undefined) {
        localVarQueryParameter['pipeline_id'] = pipelineId;
      }

      if (pipelineStepId !== undefined) {
        localVarQueryParameter['pipeline_step_id'] = pipelineStepId;
      }

      if (pipelineExecutionId !== undefined) {
        localVarQueryParameter['pipeline_execution_id'] = pipelineExecutionId;
      }

      if (pipelineNotificationId !== undefined) {
        localVarQueryParameter['pipeline_notification_id'] = pipelineNotificationId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(s3UploadObject, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * FilesApi - functional programming interface
 * @export
 */
export const FilesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = FilesApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Create Multipart Upload Presigned Url
     * @param {string} instanceId
     * @param {string} path
     * @param {string} uploadId
     * @param {number} partNo
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {DataIn | null} [dataIn]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createMultipartUploadPresignedUrlForInstance(
      instanceId: string,
      path: string,
      uploadId: string,
      partNo: number,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      dataIn?: DataIn | null,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createMultipartUploadPresignedUrlForInstance(
        instanceId,
        path,
        uploadId,
        partNo,
        httpApiKeyId,
        pipelineId,
        pipelineStepId,
        pipelineExecutionId,
        pipelineNotificationId,
        dataIn,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['FilesApi.createMultipartUploadPresignedUrlForInstance']?.[localVarOperationServerIndex]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Delete Files
     * @param {string} instanceId
     * @param {Array<S3DeleteObject>} s3DeleteObject
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteFilesForInstance(
      instanceId: string,
      s3DeleteObject: Array<S3DeleteObject>,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFilesForInstance(
        instanceId,
        s3DeleteObject,
        httpApiKeyId,
        pipelineId,
        pipelineStepId,
        pipelineExecutionId,
        pipelineNotificationId,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['FilesApi.deleteFilesForInstance']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Download File
     * @param {string} instanceId
     * @param {string} path
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {DataIn | null} [dataIn]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async downloadFileForInstance(
      instanceId: string,
      path: string,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      dataIn?: DataIn | null,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.downloadFileForInstance(
        instanceId,
        path,
        httpApiKeyId,
        pipelineId,
        pipelineStepId,
        pipelineExecutionId,
        pipelineNotificationId,
        dataIn,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['FilesApi.downloadFileForInstance']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Generate Presigned Url
     * @param {string} instanceId
     * @param {Array<S3PresignedURL>} s3PresignedURL
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async generatePresignedUrlForInstance(
      instanceId: string,
      s3PresignedURL: Array<S3PresignedURL>,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.generatePresignedUrlForInstance(
        instanceId,
        s3PresignedURL,
        httpApiKeyId,
        pipelineId,
        pipelineStepId,
        pipelineExecutionId,
        pipelineNotificationId,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['FilesApi.generatePresignedUrlForInstance']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Instance Files
     * @param {string} instanceId
     * @param {string} [bucket]
     * @param {string} [prefix]
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {DataIn | null} [dataIn]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFilesForInstance(
      instanceId: string,
      bucket?: string,
      prefix?: string,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      dataIn?: DataIn | null,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<S3Object>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getFilesForInstance(
        instanceId,
        bucket,
        prefix,
        httpApiKeyId,
        pipelineId,
        pipelineStepId,
        pipelineExecutionId,
        pipelineNotificationId,
        dataIn,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['FilesApi.getFilesForInstance']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Root Files
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRootFiles(
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string }>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getRootFiles(options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['FilesApi.getRootFiles']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Move Files
     * @param {string} instanceId
     * @param {Array<S3ObjectPath>} s3ObjectPath
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async moveFilesForInstance(
      instanceId: string,
      s3ObjectPath: Array<S3ObjectPath>,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.moveFilesForInstance(
        instanceId,
        s3ObjectPath,
        httpApiKeyId,
        pipelineId,
        pipelineStepId,
        pipelineExecutionId,
        pipelineNotificationId,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['FilesApi.moveFilesForInstance']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Multipart Upload Action
     * @param {string} instanceId
     * @param {MultipartUploadActionData} multipartUploadActionData
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async multipartUploadActionForInstance(
      instanceId: string,
      multipartUploadActionData: MultipartUploadActionData,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.multipartUploadActionForInstance(
        instanceId,
        multipartUploadActionData,
        httpApiKeyId,
        pipelineId,
        pipelineStepId,
        pipelineExecutionId,
        pipelineNotificationId,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['FilesApi.multipartUploadActionForInstance']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Upload Files
     * @param {string} instanceId
     * @param {Array<S3UploadObject>} s3UploadObject
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async uploadFilesForInstance(
      instanceId: string,
      s3UploadObject: Array<S3UploadObject>,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.uploadFilesForInstance(
        instanceId,
        s3UploadObject,
        httpApiKeyId,
        pipelineId,
        pipelineStepId,
        pipelineExecutionId,
        pipelineNotificationId,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['FilesApi.uploadFilesForInstance']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * FilesApi - factory interface
 * @export
 */
export const FilesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = FilesApiFp(configuration);
  return {
    /**
     *
     * @summary Create Multipart Upload Presigned Url
     * @param {FilesApiCreateMultipartUploadPresignedUrlForInstanceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createMultipartUploadPresignedUrlForInstance(
      requestParameters: FilesApiCreateMultipartUploadPresignedUrlForInstanceRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .createMultipartUploadPresignedUrlForInstance(
          requestParameters.instanceId,
          requestParameters.path,
          requestParameters.uploadId,
          requestParameters.partNo,
          requestParameters.httpApiKeyId,
          requestParameters.pipelineId,
          requestParameters.pipelineStepId,
          requestParameters.pipelineExecutionId,
          requestParameters.pipelineNotificationId,
          requestParameters.dataIn,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Delete Files
     * @param {FilesApiDeleteFilesForInstanceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteFilesForInstance(
      requestParameters: FilesApiDeleteFilesForInstanceRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .deleteFilesForInstance(
          requestParameters.instanceId,
          requestParameters.s3DeleteObject,
          requestParameters.httpApiKeyId,
          requestParameters.pipelineId,
          requestParameters.pipelineStepId,
          requestParameters.pipelineExecutionId,
          requestParameters.pipelineNotificationId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Download File
     * @param {FilesApiDownloadFileForInstanceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    downloadFileForInstance(
      requestParameters: FilesApiDownloadFileForInstanceRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .downloadFileForInstance(
          requestParameters.instanceId,
          requestParameters.path,
          requestParameters.httpApiKeyId,
          requestParameters.pipelineId,
          requestParameters.pipelineStepId,
          requestParameters.pipelineExecutionId,
          requestParameters.pipelineNotificationId,
          requestParameters.dataIn,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Generate Presigned Url
     * @param {FilesApiGeneratePresignedUrlForInstanceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    generatePresignedUrlForInstance(
      requestParameters: FilesApiGeneratePresignedUrlForInstanceRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .generatePresignedUrlForInstance(
          requestParameters.instanceId,
          requestParameters.s3PresignedURL,
          requestParameters.httpApiKeyId,
          requestParameters.pipelineId,
          requestParameters.pipelineStepId,
          requestParameters.pipelineExecutionId,
          requestParameters.pipelineNotificationId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Instance Files
     * @param {FilesApiGetFilesForInstanceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFilesForInstance(
      requestParameters: FilesApiGetFilesForInstanceRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<S3Object>> {
      return localVarFp
        .getFilesForInstance(
          requestParameters.instanceId,
          requestParameters.bucket,
          requestParameters.prefix,
          requestParameters.httpApiKeyId,
          requestParameters.pipelineId,
          requestParameters.pipelineStepId,
          requestParameters.pipelineExecutionId,
          requestParameters.pipelineNotificationId,
          requestParameters.dataIn,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Root Files
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRootFiles(options?: RawAxiosRequestConfig): AxiosPromise<{ [key: string]: string }> {
      return localVarFp.getRootFiles(options).then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Move Files
     * @param {FilesApiMoveFilesForInstanceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    moveFilesForInstance(
      requestParameters: FilesApiMoveFilesForInstanceRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .moveFilesForInstance(
          requestParameters.instanceId,
          requestParameters.s3ObjectPath,
          requestParameters.httpApiKeyId,
          requestParameters.pipelineId,
          requestParameters.pipelineStepId,
          requestParameters.pipelineExecutionId,
          requestParameters.pipelineNotificationId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Multipart Upload Action
     * @param {FilesApiMultipartUploadActionForInstanceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    multipartUploadActionForInstance(
      requestParameters: FilesApiMultipartUploadActionForInstanceRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .multipartUploadActionForInstance(
          requestParameters.instanceId,
          requestParameters.multipartUploadActionData,
          requestParameters.httpApiKeyId,
          requestParameters.pipelineId,
          requestParameters.pipelineStepId,
          requestParameters.pipelineExecutionId,
          requestParameters.pipelineNotificationId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Upload Files
     * @param {FilesApiUploadFilesForInstanceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadFilesForInstance(
      requestParameters: FilesApiUploadFilesForInstanceRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .uploadFilesForInstance(
          requestParameters.instanceId,
          requestParameters.s3UploadObject,
          requestParameters.httpApiKeyId,
          requestParameters.pipelineId,
          requestParameters.pipelineStepId,
          requestParameters.pipelineExecutionId,
          requestParameters.pipelineNotificationId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for createMultipartUploadPresignedUrlForInstance operation in FilesApi.
 * @export
 * @interface FilesApiCreateMultipartUploadPresignedUrlForInstanceRequest
 */
export interface FilesApiCreateMultipartUploadPresignedUrlForInstanceRequest {
  /**
   *
   * @type {string}
   * @memberof FilesApiCreateMultipartUploadPresignedUrlForInstance
   */
  readonly instanceId: string;

  /**
   *
   * @type {string}
   * @memberof FilesApiCreateMultipartUploadPresignedUrlForInstance
   */
  readonly path: string;

  /**
   *
   * @type {string}
   * @memberof FilesApiCreateMultipartUploadPresignedUrlForInstance
   */
  readonly uploadId: string;

  /**
   *
   * @type {number}
   * @memberof FilesApiCreateMultipartUploadPresignedUrlForInstance
   */
  readonly partNo: number;

  /**
   *
   * @type {string}
   * @memberof FilesApiCreateMultipartUploadPresignedUrlForInstance
   */
  readonly httpApiKeyId?: string;

  /**
   *
   * @type {string}
   * @memberof FilesApiCreateMultipartUploadPresignedUrlForInstance
   */
  readonly pipelineId?: string;

  /**
   *
   * @type {string}
   * @memberof FilesApiCreateMultipartUploadPresignedUrlForInstance
   */
  readonly pipelineStepId?: string;

  /**
   *
   * @type {string}
   * @memberof FilesApiCreateMultipartUploadPresignedUrlForInstance
   */
  readonly pipelineExecutionId?: string;

  /**
   *
   * @type {string}
   * @memberof FilesApiCreateMultipartUploadPresignedUrlForInstance
   */
  readonly pipelineNotificationId?: string;

  /**
   *
   * @type {DataIn}
   * @memberof FilesApiCreateMultipartUploadPresignedUrlForInstance
   */
  readonly dataIn?: DataIn | null;
}

/**
 * Request parameters for deleteFilesForInstance operation in FilesApi.
 * @export
 * @interface FilesApiDeleteFilesForInstanceRequest
 */
export interface FilesApiDeleteFilesForInstanceRequest {
  /**
   *
   * @type {string}
   * @memberof FilesApiDeleteFilesForInstance
   */
  readonly instanceId: string;

  /**
   *
   * @type {Array<S3DeleteObject>}
   * @memberof FilesApiDeleteFilesForInstance
   */
  readonly s3DeleteObject: Array<S3DeleteObject>;

  /**
   *
   * @type {string}
   * @memberof FilesApiDeleteFilesForInstance
   */
  readonly httpApiKeyId?: string;

  /**
   *
   * @type {string}
   * @memberof FilesApiDeleteFilesForInstance
   */
  readonly pipelineId?: string;

  /**
   *
   * @type {string}
   * @memberof FilesApiDeleteFilesForInstance
   */
  readonly pipelineStepId?: string;

  /**
   *
   * @type {string}
   * @memberof FilesApiDeleteFilesForInstance
   */
  readonly pipelineExecutionId?: string;

  /**
   *
   * @type {string}
   * @memberof FilesApiDeleteFilesForInstance
   */
  readonly pipelineNotificationId?: string;
}

/**
 * Request parameters for downloadFileForInstance operation in FilesApi.
 * @export
 * @interface FilesApiDownloadFileForInstanceRequest
 */
export interface FilesApiDownloadFileForInstanceRequest {
  /**
   *
   * @type {string}
   * @memberof FilesApiDownloadFileForInstance
   */
  readonly instanceId: string;

  /**
   *
   * @type {string}
   * @memberof FilesApiDownloadFileForInstance
   */
  readonly path: string;

  /**
   *
   * @type {string}
   * @memberof FilesApiDownloadFileForInstance
   */
  readonly httpApiKeyId?: string;

  /**
   *
   * @type {string}
   * @memberof FilesApiDownloadFileForInstance
   */
  readonly pipelineId?: string;

  /**
   *
   * @type {string}
   * @memberof FilesApiDownloadFileForInstance
   */
  readonly pipelineStepId?: string;

  /**
   *
   * @type {string}
   * @memberof FilesApiDownloadFileForInstance
   */
  readonly pipelineExecutionId?: string;

  /**
   *
   * @type {string}
   * @memberof FilesApiDownloadFileForInstance
   */
  readonly pipelineNotificationId?: string;

  /**
   *
   * @type {DataIn}
   * @memberof FilesApiDownloadFileForInstance
   */
  readonly dataIn?: DataIn | null;
}

/**
 * Request parameters for generatePresignedUrlForInstance operation in FilesApi.
 * @export
 * @interface FilesApiGeneratePresignedUrlForInstanceRequest
 */
export interface FilesApiGeneratePresignedUrlForInstanceRequest {
  /**
   *
   * @type {string}
   * @memberof FilesApiGeneratePresignedUrlForInstance
   */
  readonly instanceId: string;

  /**
   *
   * @type {Array<S3PresignedURL>}
   * @memberof FilesApiGeneratePresignedUrlForInstance
   */
  readonly s3PresignedURL: Array<S3PresignedURL>;

  /**
   *
   * @type {string}
   * @memberof FilesApiGeneratePresignedUrlForInstance
   */
  readonly httpApiKeyId?: string;

  /**
   *
   * @type {string}
   * @memberof FilesApiGeneratePresignedUrlForInstance
   */
  readonly pipelineId?: string;

  /**
   *
   * @type {string}
   * @memberof FilesApiGeneratePresignedUrlForInstance
   */
  readonly pipelineStepId?: string;

  /**
   *
   * @type {string}
   * @memberof FilesApiGeneratePresignedUrlForInstance
   */
  readonly pipelineExecutionId?: string;

  /**
   *
   * @type {string}
   * @memberof FilesApiGeneratePresignedUrlForInstance
   */
  readonly pipelineNotificationId?: string;
}

/**
 * Request parameters for getFilesForInstance operation in FilesApi.
 * @export
 * @interface FilesApiGetFilesForInstanceRequest
 */
export interface FilesApiGetFilesForInstanceRequest {
  /**
   *
   * @type {string}
   * @memberof FilesApiGetFilesForInstance
   */
  readonly instanceId: string;

  /**
   *
   * @type {string}
   * @memberof FilesApiGetFilesForInstance
   */
  readonly bucket?: string;

  /**
   *
   * @type {string}
   * @memberof FilesApiGetFilesForInstance
   */
  readonly prefix?: string;

  /**
   *
   * @type {string}
   * @memberof FilesApiGetFilesForInstance
   */
  readonly httpApiKeyId?: string;

  /**
   *
   * @type {string}
   * @memberof FilesApiGetFilesForInstance
   */
  readonly pipelineId?: string;

  /**
   *
   * @type {string}
   * @memberof FilesApiGetFilesForInstance
   */
  readonly pipelineStepId?: string;

  /**
   *
   * @type {string}
   * @memberof FilesApiGetFilesForInstance
   */
  readonly pipelineExecutionId?: string;

  /**
   *
   * @type {string}
   * @memberof FilesApiGetFilesForInstance
   */
  readonly pipelineNotificationId?: string;

  /**
   *
   * @type {DataIn}
   * @memberof FilesApiGetFilesForInstance
   */
  readonly dataIn?: DataIn | null;
}

/**
 * Request parameters for moveFilesForInstance operation in FilesApi.
 * @export
 * @interface FilesApiMoveFilesForInstanceRequest
 */
export interface FilesApiMoveFilesForInstanceRequest {
  /**
   *
   * @type {string}
   * @memberof FilesApiMoveFilesForInstance
   */
  readonly instanceId: string;

  /**
   *
   * @type {Array<S3ObjectPath>}
   * @memberof FilesApiMoveFilesForInstance
   */
  readonly s3ObjectPath: Array<S3ObjectPath>;

  /**
   *
   * @type {string}
   * @memberof FilesApiMoveFilesForInstance
   */
  readonly httpApiKeyId?: string;

  /**
   *
   * @type {string}
   * @memberof FilesApiMoveFilesForInstance
   */
  readonly pipelineId?: string;

  /**
   *
   * @type {string}
   * @memberof FilesApiMoveFilesForInstance
   */
  readonly pipelineStepId?: string;

  /**
   *
   * @type {string}
   * @memberof FilesApiMoveFilesForInstance
   */
  readonly pipelineExecutionId?: string;

  /**
   *
   * @type {string}
   * @memberof FilesApiMoveFilesForInstance
   */
  readonly pipelineNotificationId?: string;
}

/**
 * Request parameters for multipartUploadActionForInstance operation in FilesApi.
 * @export
 * @interface FilesApiMultipartUploadActionForInstanceRequest
 */
export interface FilesApiMultipartUploadActionForInstanceRequest {
  /**
   *
   * @type {string}
   * @memberof FilesApiMultipartUploadActionForInstance
   */
  readonly instanceId: string;

  /**
   *
   * @type {MultipartUploadActionData}
   * @memberof FilesApiMultipartUploadActionForInstance
   */
  readonly multipartUploadActionData: MultipartUploadActionData;

  /**
   *
   * @type {string}
   * @memberof FilesApiMultipartUploadActionForInstance
   */
  readonly httpApiKeyId?: string;

  /**
   *
   * @type {string}
   * @memberof FilesApiMultipartUploadActionForInstance
   */
  readonly pipelineId?: string;

  /**
   *
   * @type {string}
   * @memberof FilesApiMultipartUploadActionForInstance
   */
  readonly pipelineStepId?: string;

  /**
   *
   * @type {string}
   * @memberof FilesApiMultipartUploadActionForInstance
   */
  readonly pipelineExecutionId?: string;

  /**
   *
   * @type {string}
   * @memberof FilesApiMultipartUploadActionForInstance
   */
  readonly pipelineNotificationId?: string;
}

/**
 * Request parameters for uploadFilesForInstance operation in FilesApi.
 * @export
 * @interface FilesApiUploadFilesForInstanceRequest
 */
export interface FilesApiUploadFilesForInstanceRequest {
  /**
   *
   * @type {string}
   * @memberof FilesApiUploadFilesForInstance
   */
  readonly instanceId: string;

  /**
   *
   * @type {Array<S3UploadObject>}
   * @memberof FilesApiUploadFilesForInstance
   */
  readonly s3UploadObject: Array<S3UploadObject>;

  /**
   *
   * @type {string}
   * @memberof FilesApiUploadFilesForInstance
   */
  readonly httpApiKeyId?: string;

  /**
   *
   * @type {string}
   * @memberof FilesApiUploadFilesForInstance
   */
  readonly pipelineId?: string;

  /**
   *
   * @type {string}
   * @memberof FilesApiUploadFilesForInstance
   */
  readonly pipelineStepId?: string;

  /**
   *
   * @type {string}
   * @memberof FilesApiUploadFilesForInstance
   */
  readonly pipelineExecutionId?: string;

  /**
   *
   * @type {string}
   * @memberof FilesApiUploadFilesForInstance
   */
  readonly pipelineNotificationId?: string;
}

/**
 * FilesApi - object-oriented interface
 * @export
 * @class FilesApi
 * @extends {BaseAPI}
 */
export class FilesApi extends BaseAPI {
  /**
   *
   * @summary Create Multipart Upload Presigned Url
   * @param {FilesApiCreateMultipartUploadPresignedUrlForInstanceRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FilesApi
   */
  public createMultipartUploadPresignedUrlForInstance(
    requestParameters: FilesApiCreateMultipartUploadPresignedUrlForInstanceRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return FilesApiFp(this.configuration)
      .createMultipartUploadPresignedUrlForInstance(
        requestParameters.instanceId,
        requestParameters.path,
        requestParameters.uploadId,
        requestParameters.partNo,
        requestParameters.httpApiKeyId,
        requestParameters.pipelineId,
        requestParameters.pipelineStepId,
        requestParameters.pipelineExecutionId,
        requestParameters.pipelineNotificationId,
        requestParameters.dataIn,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete Files
   * @param {FilesApiDeleteFilesForInstanceRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FilesApi
   */
  public deleteFilesForInstance(
    requestParameters: FilesApiDeleteFilesForInstanceRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return FilesApiFp(this.configuration)
      .deleteFilesForInstance(
        requestParameters.instanceId,
        requestParameters.s3DeleteObject,
        requestParameters.httpApiKeyId,
        requestParameters.pipelineId,
        requestParameters.pipelineStepId,
        requestParameters.pipelineExecutionId,
        requestParameters.pipelineNotificationId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Download File
   * @param {FilesApiDownloadFileForInstanceRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FilesApi
   */
  public downloadFileForInstance(
    requestParameters: FilesApiDownloadFileForInstanceRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return FilesApiFp(this.configuration)
      .downloadFileForInstance(
        requestParameters.instanceId,
        requestParameters.path,
        requestParameters.httpApiKeyId,
        requestParameters.pipelineId,
        requestParameters.pipelineStepId,
        requestParameters.pipelineExecutionId,
        requestParameters.pipelineNotificationId,
        requestParameters.dataIn,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Generate Presigned Url
   * @param {FilesApiGeneratePresignedUrlForInstanceRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FilesApi
   */
  public generatePresignedUrlForInstance(
    requestParameters: FilesApiGeneratePresignedUrlForInstanceRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return FilesApiFp(this.configuration)
      .generatePresignedUrlForInstance(
        requestParameters.instanceId,
        requestParameters.s3PresignedURL,
        requestParameters.httpApiKeyId,
        requestParameters.pipelineId,
        requestParameters.pipelineStepId,
        requestParameters.pipelineExecutionId,
        requestParameters.pipelineNotificationId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Instance Files
   * @param {FilesApiGetFilesForInstanceRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FilesApi
   */
  public getFilesForInstance(requestParameters: FilesApiGetFilesForInstanceRequest, options?: RawAxiosRequestConfig) {
    return FilesApiFp(this.configuration)
      .getFilesForInstance(
        requestParameters.instanceId,
        requestParameters.bucket,
        requestParameters.prefix,
        requestParameters.httpApiKeyId,
        requestParameters.pipelineId,
        requestParameters.pipelineStepId,
        requestParameters.pipelineExecutionId,
        requestParameters.pipelineNotificationId,
        requestParameters.dataIn,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Root Files
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FilesApi
   */
  public getRootFiles(options?: RawAxiosRequestConfig) {
    return FilesApiFp(this.configuration)
      .getRootFiles(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Move Files
   * @param {FilesApiMoveFilesForInstanceRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FilesApi
   */
  public moveFilesForInstance(requestParameters: FilesApiMoveFilesForInstanceRequest, options?: RawAxiosRequestConfig) {
    return FilesApiFp(this.configuration)
      .moveFilesForInstance(
        requestParameters.instanceId,
        requestParameters.s3ObjectPath,
        requestParameters.httpApiKeyId,
        requestParameters.pipelineId,
        requestParameters.pipelineStepId,
        requestParameters.pipelineExecutionId,
        requestParameters.pipelineNotificationId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Multipart Upload Action
   * @param {FilesApiMultipartUploadActionForInstanceRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FilesApi
   */
  public multipartUploadActionForInstance(
    requestParameters: FilesApiMultipartUploadActionForInstanceRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return FilesApiFp(this.configuration)
      .multipartUploadActionForInstance(
        requestParameters.instanceId,
        requestParameters.multipartUploadActionData,
        requestParameters.httpApiKeyId,
        requestParameters.pipelineId,
        requestParameters.pipelineStepId,
        requestParameters.pipelineExecutionId,
        requestParameters.pipelineNotificationId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Upload Files
   * @param {FilesApiUploadFilesForInstanceRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FilesApi
   */
  public uploadFilesForInstance(
    requestParameters: FilesApiUploadFilesForInstanceRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return FilesApiFp(this.configuration)
      .uploadFilesForInstance(
        requestParameters.instanceId,
        requestParameters.s3UploadObject,
        requestParameters.httpApiKeyId,
        requestParameters.pipelineId,
        requestParameters.pipelineStepId,
        requestParameters.pipelineExecutionId,
        requestParameters.pipelineNotificationId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * FilesFlatApi - axios parameter creator
 * @export
 */
export const FilesFlatApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get Instance Files
     * @param {string} instanceId
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {string} [path]
     * @param {DataIn | null} [dataIn]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInstanceFilesFlat: async (
      instanceId: string,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      path?: string,
      dataIn?: DataIn | null,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'instanceId' is not null or undefined
      assertParamExists('getInstanceFilesFlat', 'instanceId', instanceId);
      const localVarPath = `/api/v1/files_flat`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (instanceId !== undefined) {
        localVarQueryParameter['instance_id'] = instanceId;
      }

      if (httpApiKeyId !== undefined) {
        localVarQueryParameter['http_api_key_id'] = httpApiKeyId;
      }

      if (pipelineId !== undefined) {
        localVarQueryParameter['pipeline_id'] = pipelineId;
      }

      if (pipelineStepId !== undefined) {
        localVarQueryParameter['pipeline_step_id'] = pipelineStepId;
      }

      if (pipelineExecutionId !== undefined) {
        localVarQueryParameter['pipeline_execution_id'] = pipelineExecutionId;
      }

      if (pipelineNotificationId !== undefined) {
        localVarQueryParameter['pipeline_notification_id'] = pipelineNotificationId;
      }

      if (path !== undefined) {
        localVarQueryParameter['path'] = path;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(dataIn, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * FilesFlatApi - functional programming interface
 * @export
 */
export const FilesFlatApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = FilesFlatApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Get Instance Files
     * @param {string} instanceId
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {string} [path]
     * @param {DataIn | null} [dataIn]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getInstanceFilesFlat(
      instanceId: string,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      path?: string,
      dataIn?: DataIn | null,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<S3ObjectNew>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getInstanceFilesFlat(
        instanceId,
        httpApiKeyId,
        pipelineId,
        pipelineStepId,
        pipelineExecutionId,
        pipelineNotificationId,
        path,
        dataIn,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['FilesFlatApi.getInstanceFilesFlat']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * FilesFlatApi - factory interface
 * @export
 */
export const FilesFlatApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = FilesFlatApiFp(configuration);
  return {
    /**
     *
     * @summary Get Instance Files
     * @param {FilesFlatApiGetInstanceFilesFlatRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInstanceFilesFlat(
      requestParameters: FilesFlatApiGetInstanceFilesFlatRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<S3ObjectNew>> {
      return localVarFp
        .getInstanceFilesFlat(
          requestParameters.instanceId,
          requestParameters.httpApiKeyId,
          requestParameters.pipelineId,
          requestParameters.pipelineStepId,
          requestParameters.pipelineExecutionId,
          requestParameters.pipelineNotificationId,
          requestParameters.path,
          requestParameters.dataIn,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for getInstanceFilesFlat operation in FilesFlatApi.
 * @export
 * @interface FilesFlatApiGetInstanceFilesFlatRequest
 */
export interface FilesFlatApiGetInstanceFilesFlatRequest {
  /**
   *
   * @type {string}
   * @memberof FilesFlatApiGetInstanceFilesFlat
   */
  readonly instanceId: string;

  /**
   *
   * @type {string}
   * @memberof FilesFlatApiGetInstanceFilesFlat
   */
  readonly httpApiKeyId?: string;

  /**
   *
   * @type {string}
   * @memberof FilesFlatApiGetInstanceFilesFlat
   */
  readonly pipelineId?: string;

  /**
   *
   * @type {string}
   * @memberof FilesFlatApiGetInstanceFilesFlat
   */
  readonly pipelineStepId?: string;

  /**
   *
   * @type {string}
   * @memberof FilesFlatApiGetInstanceFilesFlat
   */
  readonly pipelineExecutionId?: string;

  /**
   *
   * @type {string}
   * @memberof FilesFlatApiGetInstanceFilesFlat
   */
  readonly pipelineNotificationId?: string;

  /**
   *
   * @type {string}
   * @memberof FilesFlatApiGetInstanceFilesFlat
   */
  readonly path?: string;

  /**
   *
   * @type {DataIn}
   * @memberof FilesFlatApiGetInstanceFilesFlat
   */
  readonly dataIn?: DataIn | null;
}

/**
 * FilesFlatApi - object-oriented interface
 * @export
 * @class FilesFlatApi
 * @extends {BaseAPI}
 */
export class FilesFlatApi extends BaseAPI {
  /**
   *
   * @summary Get Instance Files
   * @param {FilesFlatApiGetInstanceFilesFlatRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FilesFlatApi
   */
  public getInstanceFilesFlat(
    requestParameters: FilesFlatApiGetInstanceFilesFlatRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return FilesFlatApiFp(this.configuration)
      .getInstanceFilesFlat(
        requestParameters.instanceId,
        requestParameters.httpApiKeyId,
        requestParameters.pipelineId,
        requestParameters.pipelineStepId,
        requestParameters.pipelineExecutionId,
        requestParameters.pipelineNotificationId,
        requestParameters.path,
        requestParameters.dataIn,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * FilesReplicationApi - axios parameter creator
 * @export
 */
export const FilesReplicationApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Create a files replication.
     * @summary Create Files Replication
     * @param {FilesReplicationCreate} filesReplicationCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createFilesReplication: async (
      filesReplicationCreate: FilesReplicationCreate,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'filesReplicationCreate' is not null or undefined
      assertParamExists('createFilesReplication', 'filesReplicationCreate', filesReplicationCreate);
      const localVarPath = `/api/v1/files-replications`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        filesReplicationCreate,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Delete a files replication.
     * @summary Delete Pipeline Execution
     * @param {string} replicationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteFilesReplication: async (
      replicationId: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'replicationId' is not null or undefined
      assertParamExists('deleteFilesReplication', 'replicationId', replicationId);
      const localVarPath = `/api/v1/files-replications/{replication_id}`.replace(
        `{${'replication_id'}}`,
        encodeURIComponent(String(replicationId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get a files replication by id.
     * @summary Get Files Replication
     * @param {string} replicationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFilesReplication: async (replicationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'replicationId' is not null or undefined
      assertParamExists('getFilesReplication', 'replicationId', replicationId);
      const localVarPath = `/api/v1/files-replications/{replication_id}`.replace(
        `{${'replication_id'}}`,
        encodeURIComponent(String(replicationId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get all files replications satisfying the specified filtering parameters.
     * @summary Get Files Replications
     * @param {string} [replicatorId]
     * @param {number} [limit]
     * @param {number} [skip]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFilesReplications: async (
      replicatorId?: string,
      limit?: number,
      skip?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/files-replications`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (replicatorId !== undefined) {
        localVarQueryParameter['replicator_id'] = replicatorId;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (skip !== undefined) {
        localVarQueryParameter['skip'] = skip;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update a files replication.
     * @summary Update Pipeline Execution
     * @param {string} replicationId
     * @param {FilesReplicationUpdate} filesReplicationUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateFilesReplication: async (
      replicationId: string,
      filesReplicationUpdate: FilesReplicationUpdate,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'replicationId' is not null or undefined
      assertParamExists('updateFilesReplication', 'replicationId', replicationId);
      // verify required parameter 'filesReplicationUpdate' is not null or undefined
      assertParamExists('updateFilesReplication', 'filesReplicationUpdate', filesReplicationUpdate);
      const localVarPath = `/api/v1/files-replications/{replication_id}`.replace(
        `{${'replication_id'}}`,
        encodeURIComponent(String(replicationId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        filesReplicationUpdate,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * FilesReplicationApi - functional programming interface
 * @export
 */
export const FilesReplicationApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = FilesReplicationApiAxiosParamCreator(configuration);
  return {
    /**
     * Create a files replication.
     * @summary Create Files Replication
     * @param {FilesReplicationCreate} filesReplicationCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createFilesReplication(
      filesReplicationCreate: FilesReplicationCreate,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FilesReplication>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createFilesReplication(filesReplicationCreate, options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['FilesReplicationApi.createFilesReplication']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Delete a files replication.
     * @summary Delete Pipeline Execution
     * @param {string} replicationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteFilesReplication(
      replicationId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FilesReplicationDeleteResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFilesReplication(replicationId, options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['FilesReplicationApi.deleteFilesReplication']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Get a files replication by id.
     * @summary Get Files Replication
     * @param {string} replicationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFilesReplication(
      replicationId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FilesReplication>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getFilesReplication(replicationId, options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['FilesReplicationApi.getFilesReplication']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Get all files replications satisfying the specified filtering parameters.
     * @summary Get Files Replications
     * @param {string} [replicatorId]
     * @param {number} [limit]
     * @param {number} [skip]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFilesReplications(
      replicatorId?: string,
      limit?: number,
      skip?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FilesReplication>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getFilesReplications(
        replicatorId,
        limit,
        skip,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['FilesReplicationApi.getFilesReplications']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Update a files replication.
     * @summary Update Pipeline Execution
     * @param {string} replicationId
     * @param {FilesReplicationUpdate} filesReplicationUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateFilesReplication(
      replicationId: string,
      filesReplicationUpdate: FilesReplicationUpdate,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FilesReplication>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateFilesReplication(
        replicationId,
        filesReplicationUpdate,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['FilesReplicationApi.updateFilesReplication']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * FilesReplicationApi - factory interface
 * @export
 */
export const FilesReplicationApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = FilesReplicationApiFp(configuration);
  return {
    /**
     * Create a files replication.
     * @summary Create Files Replication
     * @param {FilesReplicationApiCreateFilesReplicationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createFilesReplication(
      requestParameters: FilesReplicationApiCreateFilesReplicationRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<FilesReplication> {
      return localVarFp
        .createFilesReplication(requestParameters.filesReplicationCreate, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Delete a files replication.
     * @summary Delete Pipeline Execution
     * @param {FilesReplicationApiDeleteFilesReplicationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteFilesReplication(
      requestParameters: FilesReplicationApiDeleteFilesReplicationRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<FilesReplicationDeleteResponse> {
      return localVarFp
        .deleteFilesReplication(requestParameters.replicationId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get a files replication by id.
     * @summary Get Files Replication
     * @param {FilesReplicationApiGetFilesReplicationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFilesReplication(
      requestParameters: FilesReplicationApiGetFilesReplicationRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<FilesReplication> {
      return localVarFp
        .getFilesReplication(requestParameters.replicationId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get all files replications satisfying the specified filtering parameters.
     * @summary Get Files Replications
     * @param {FilesReplicationApiGetFilesReplicationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFilesReplications(
      requestParameters: FilesReplicationApiGetFilesReplicationsRequest = {},
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<FilesReplication>> {
      return localVarFp
        .getFilesReplications(requestParameters.replicatorId, requestParameters.limit, requestParameters.skip, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Update a files replication.
     * @summary Update Pipeline Execution
     * @param {FilesReplicationApiUpdateFilesReplicationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateFilesReplication(
      requestParameters: FilesReplicationApiUpdateFilesReplicationRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<FilesReplication> {
      return localVarFp
        .updateFilesReplication(requestParameters.replicationId, requestParameters.filesReplicationUpdate, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for createFilesReplication operation in FilesReplicationApi.
 * @export
 * @interface FilesReplicationApiCreateFilesReplicationRequest
 */
export interface FilesReplicationApiCreateFilesReplicationRequest {
  /**
   *
   * @type {FilesReplicationCreate}
   * @memberof FilesReplicationApiCreateFilesReplication
   */
  readonly filesReplicationCreate: FilesReplicationCreate;
}

/**
 * Request parameters for deleteFilesReplication operation in FilesReplicationApi.
 * @export
 * @interface FilesReplicationApiDeleteFilesReplicationRequest
 */
export interface FilesReplicationApiDeleteFilesReplicationRequest {
  /**
   *
   * @type {string}
   * @memberof FilesReplicationApiDeleteFilesReplication
   */
  readonly replicationId: string;
}

/**
 * Request parameters for getFilesReplication operation in FilesReplicationApi.
 * @export
 * @interface FilesReplicationApiGetFilesReplicationRequest
 */
export interface FilesReplicationApiGetFilesReplicationRequest {
  /**
   *
   * @type {string}
   * @memberof FilesReplicationApiGetFilesReplication
   */
  readonly replicationId: string;
}

/**
 * Request parameters for getFilesReplications operation in FilesReplicationApi.
 * @export
 * @interface FilesReplicationApiGetFilesReplicationsRequest
 */
export interface FilesReplicationApiGetFilesReplicationsRequest {
  /**
   *
   * @type {string}
   * @memberof FilesReplicationApiGetFilesReplications
   */
  readonly replicatorId?: string;

  /**
   *
   * @type {number}
   * @memberof FilesReplicationApiGetFilesReplications
   */
  readonly limit?: number;

  /**
   *
   * @type {number}
   * @memberof FilesReplicationApiGetFilesReplications
   */
  readonly skip?: number;
}

/**
 * Request parameters for updateFilesReplication operation in FilesReplicationApi.
 * @export
 * @interface FilesReplicationApiUpdateFilesReplicationRequest
 */
export interface FilesReplicationApiUpdateFilesReplicationRequest {
  /**
   *
   * @type {string}
   * @memberof FilesReplicationApiUpdateFilesReplication
   */
  readonly replicationId: string;

  /**
   *
   * @type {FilesReplicationUpdate}
   * @memberof FilesReplicationApiUpdateFilesReplication
   */
  readonly filesReplicationUpdate: FilesReplicationUpdate;
}

/**
 * FilesReplicationApi - object-oriented interface
 * @export
 * @class FilesReplicationApi
 * @extends {BaseAPI}
 */
export class FilesReplicationApi extends BaseAPI {
  /**
   * Create a files replication.
   * @summary Create Files Replication
   * @param {FilesReplicationApiCreateFilesReplicationRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FilesReplicationApi
   */
  public createFilesReplication(
    requestParameters: FilesReplicationApiCreateFilesReplicationRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return FilesReplicationApiFp(this.configuration)
      .createFilesReplication(requestParameters.filesReplicationCreate, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Delete a files replication.
   * @summary Delete Pipeline Execution
   * @param {FilesReplicationApiDeleteFilesReplicationRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FilesReplicationApi
   */
  public deleteFilesReplication(
    requestParameters: FilesReplicationApiDeleteFilesReplicationRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return FilesReplicationApiFp(this.configuration)
      .deleteFilesReplication(requestParameters.replicationId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get a files replication by id.
   * @summary Get Files Replication
   * @param {FilesReplicationApiGetFilesReplicationRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FilesReplicationApi
   */
  public getFilesReplication(
    requestParameters: FilesReplicationApiGetFilesReplicationRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return FilesReplicationApiFp(this.configuration)
      .getFilesReplication(requestParameters.replicationId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get all files replications satisfying the specified filtering parameters.
   * @summary Get Files Replications
   * @param {FilesReplicationApiGetFilesReplicationsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FilesReplicationApi
   */
  public getFilesReplications(
    requestParameters: FilesReplicationApiGetFilesReplicationsRequest = {},
    options?: RawAxiosRequestConfig,
  ) {
    return FilesReplicationApiFp(this.configuration)
      .getFilesReplications(requestParameters.replicatorId, requestParameters.limit, requestParameters.skip, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update a files replication.
   * @summary Update Pipeline Execution
   * @param {FilesReplicationApiUpdateFilesReplicationRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FilesReplicationApi
   */
  public updateFilesReplication(
    requestParameters: FilesReplicationApiUpdateFilesReplicationRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return FilesReplicationApiFp(this.configuration)
      .updateFilesReplication(requestParameters.replicationId, requestParameters.filesReplicationUpdate, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * FilesReplicatorApi - axios parameter creator
 * @export
 */
export const FilesReplicatorApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Create a files replicator.
     * @summary Create Replicator
     * @param {FilesReplicatorCreate} filesReplicatorCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createReplicator: async (
      filesReplicatorCreate: FilesReplicatorCreate,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'filesReplicatorCreate' is not null or undefined
      assertParamExists('createReplicator', 'filesReplicatorCreate', filesReplicatorCreate);
      const localVarPath = `/api/v1/files-replicators`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(filesReplicatorCreate, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Delete a files replicator.
     * @summary Delete Replicator
     * @param {string} replicatorId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteReplicator: async (replicatorId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'replicatorId' is not null or undefined
      assertParamExists('deleteReplicator', 'replicatorId', replicatorId);
      const localVarPath = `/api/v1/files-replicators/{replicator_id}`.replace(
        `{${'replicator_id'}}`,
        encodeURIComponent(String(replicatorId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get a files replicator by id.
     * @summary Get Replicator
     * @param {string} replicatorId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReplicator: async (replicatorId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'replicatorId' is not null or undefined
      assertParamExists('getReplicator', 'replicatorId', replicatorId);
      const localVarPath = `/api/v1/files-replicators/{replicator_id}`.replace(
        `{${'replicator_id'}}`,
        encodeURIComponent(String(replicatorId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get all files replicators satisfying the specified filtering parameters.
     * @summary Get Replicators
     * @param {string} [sourceInstanceId]
     * @param {boolean} [enabled]
     * @param {number} [limit]
     * @param {number} [skip]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReplicators: async (
      sourceInstanceId?: string,
      enabled?: boolean,
      limit?: number,
      skip?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/files-replicators`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (sourceInstanceId !== undefined) {
        localVarQueryParameter['source_instance_id'] = sourceInstanceId;
      }

      if (enabled !== undefined) {
        localVarQueryParameter['enabled'] = enabled;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (skip !== undefined) {
        localVarQueryParameter['skip'] = skip;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update a files replicator.
     * @summary Update Replicator
     * @param {string} replicatorId
     * @param {FilesReplicatorUpdate} filesReplicatorUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateReplicator: async (
      replicatorId: string,
      filesReplicatorUpdate: FilesReplicatorUpdate,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'replicatorId' is not null or undefined
      assertParamExists('updateReplicator', 'replicatorId', replicatorId);
      // verify required parameter 'filesReplicatorUpdate' is not null or undefined
      assertParamExists('updateReplicator', 'filesReplicatorUpdate', filesReplicatorUpdate);
      const localVarPath = `/api/v1/files-replicators/{replicator_id}`.replace(
        `{${'replicator_id'}}`,
        encodeURIComponent(String(replicatorId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(filesReplicatorUpdate, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * FilesReplicatorApi - functional programming interface
 * @export
 */
export const FilesReplicatorApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = FilesReplicatorApiAxiosParamCreator(configuration);
  return {
    /**
     * Create a files replicator.
     * @summary Create Replicator
     * @param {FilesReplicatorCreate} filesReplicatorCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createReplicator(
      filesReplicatorCreate: FilesReplicatorCreate,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FilesReplicator>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createReplicator(filesReplicatorCreate, options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['FilesReplicatorApi.createReplicator']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Delete a files replicator.
     * @summary Delete Replicator
     * @param {string} replicatorId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteReplicator(
      replicatorId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FilesReplicatorDeleteResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteReplicator(replicatorId, options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['FilesReplicatorApi.deleteReplicator']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Get a files replicator by id.
     * @summary Get Replicator
     * @param {string} replicatorId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getReplicator(
      replicatorId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FilesReplicator>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getReplicator(replicatorId, options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['FilesReplicatorApi.getReplicator']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Get all files replicators satisfying the specified filtering parameters.
     * @summary Get Replicators
     * @param {string} [sourceInstanceId]
     * @param {boolean} [enabled]
     * @param {number} [limit]
     * @param {number} [skip]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getReplicators(
      sourceInstanceId?: string,
      enabled?: boolean,
      limit?: number,
      skip?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FilesReplicator>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getReplicators(
        sourceInstanceId,
        enabled,
        limit,
        skip,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['FilesReplicatorApi.getReplicators']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Update a files replicator.
     * @summary Update Replicator
     * @param {string} replicatorId
     * @param {FilesReplicatorUpdate} filesReplicatorUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateReplicator(
      replicatorId: string,
      filesReplicatorUpdate: FilesReplicatorUpdate,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FilesReplicator>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateReplicator(
        replicatorId,
        filesReplicatorUpdate,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['FilesReplicatorApi.updateReplicator']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * FilesReplicatorApi - factory interface
 * @export
 */
export const FilesReplicatorApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = FilesReplicatorApiFp(configuration);
  return {
    /**
     * Create a files replicator.
     * @summary Create Replicator
     * @param {FilesReplicatorApiCreateReplicatorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createReplicator(
      requestParameters: FilesReplicatorApiCreateReplicatorRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<FilesReplicator> {
      return localVarFp
        .createReplicator(requestParameters.filesReplicatorCreate, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Delete a files replicator.
     * @summary Delete Replicator
     * @param {FilesReplicatorApiDeleteReplicatorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteReplicator(
      requestParameters: FilesReplicatorApiDeleteReplicatorRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<FilesReplicatorDeleteResponse> {
      return localVarFp
        .deleteReplicator(requestParameters.replicatorId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get a files replicator by id.
     * @summary Get Replicator
     * @param {FilesReplicatorApiGetReplicatorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReplicator(
      requestParameters: FilesReplicatorApiGetReplicatorRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<FilesReplicator> {
      return localVarFp
        .getReplicator(requestParameters.replicatorId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get all files replicators satisfying the specified filtering parameters.
     * @summary Get Replicators
     * @param {FilesReplicatorApiGetReplicatorsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReplicators(
      requestParameters: FilesReplicatorApiGetReplicatorsRequest = {},
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<FilesReplicator>> {
      return localVarFp
        .getReplicators(
          requestParameters.sourceInstanceId,
          requestParameters.enabled,
          requestParameters.limit,
          requestParameters.skip,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Update a files replicator.
     * @summary Update Replicator
     * @param {FilesReplicatorApiUpdateReplicatorRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateReplicator(
      requestParameters: FilesReplicatorApiUpdateReplicatorRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<FilesReplicator> {
      return localVarFp
        .updateReplicator(requestParameters.replicatorId, requestParameters.filesReplicatorUpdate, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for createReplicator operation in FilesReplicatorApi.
 * @export
 * @interface FilesReplicatorApiCreateReplicatorRequest
 */
export interface FilesReplicatorApiCreateReplicatorRequest {
  /**
   *
   * @type {FilesReplicatorCreate}
   * @memberof FilesReplicatorApiCreateReplicator
   */
  readonly filesReplicatorCreate: FilesReplicatorCreate;
}

/**
 * Request parameters for deleteReplicator operation in FilesReplicatorApi.
 * @export
 * @interface FilesReplicatorApiDeleteReplicatorRequest
 */
export interface FilesReplicatorApiDeleteReplicatorRequest {
  /**
   *
   * @type {string}
   * @memberof FilesReplicatorApiDeleteReplicator
   */
  readonly replicatorId: string;
}

/**
 * Request parameters for getReplicator operation in FilesReplicatorApi.
 * @export
 * @interface FilesReplicatorApiGetReplicatorRequest
 */
export interface FilesReplicatorApiGetReplicatorRequest {
  /**
   *
   * @type {string}
   * @memberof FilesReplicatorApiGetReplicator
   */
  readonly replicatorId: string;
}

/**
 * Request parameters for getReplicators operation in FilesReplicatorApi.
 * @export
 * @interface FilesReplicatorApiGetReplicatorsRequest
 */
export interface FilesReplicatorApiGetReplicatorsRequest {
  /**
   *
   * @type {string}
   * @memberof FilesReplicatorApiGetReplicators
   */
  readonly sourceInstanceId?: string;

  /**
   *
   * @type {boolean}
   * @memberof FilesReplicatorApiGetReplicators
   */
  readonly enabled?: boolean;

  /**
   *
   * @type {number}
   * @memberof FilesReplicatorApiGetReplicators
   */
  readonly limit?: number;

  /**
   *
   * @type {number}
   * @memberof FilesReplicatorApiGetReplicators
   */
  readonly skip?: number;
}

/**
 * Request parameters for updateReplicator operation in FilesReplicatorApi.
 * @export
 * @interface FilesReplicatorApiUpdateReplicatorRequest
 */
export interface FilesReplicatorApiUpdateReplicatorRequest {
  /**
   *
   * @type {string}
   * @memberof FilesReplicatorApiUpdateReplicator
   */
  readonly replicatorId: string;

  /**
   *
   * @type {FilesReplicatorUpdate}
   * @memberof FilesReplicatorApiUpdateReplicator
   */
  readonly filesReplicatorUpdate: FilesReplicatorUpdate;
}

/**
 * FilesReplicatorApi - object-oriented interface
 * @export
 * @class FilesReplicatorApi
 * @extends {BaseAPI}
 */
export class FilesReplicatorApi extends BaseAPI {
  /**
   * Create a files replicator.
   * @summary Create Replicator
   * @param {FilesReplicatorApiCreateReplicatorRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FilesReplicatorApi
   */
  public createReplicator(
    requestParameters: FilesReplicatorApiCreateReplicatorRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return FilesReplicatorApiFp(this.configuration)
      .createReplicator(requestParameters.filesReplicatorCreate, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Delete a files replicator.
   * @summary Delete Replicator
   * @param {FilesReplicatorApiDeleteReplicatorRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FilesReplicatorApi
   */
  public deleteReplicator(
    requestParameters: FilesReplicatorApiDeleteReplicatorRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return FilesReplicatorApiFp(this.configuration)
      .deleteReplicator(requestParameters.replicatorId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get a files replicator by id.
   * @summary Get Replicator
   * @param {FilesReplicatorApiGetReplicatorRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FilesReplicatorApi
   */
  public getReplicator(requestParameters: FilesReplicatorApiGetReplicatorRequest, options?: RawAxiosRequestConfig) {
    return FilesReplicatorApiFp(this.configuration)
      .getReplicator(requestParameters.replicatorId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get all files replicators satisfying the specified filtering parameters.
   * @summary Get Replicators
   * @param {FilesReplicatorApiGetReplicatorsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FilesReplicatorApi
   */
  public getReplicators(
    requestParameters: FilesReplicatorApiGetReplicatorsRequest = {},
    options?: RawAxiosRequestConfig,
  ) {
    return FilesReplicatorApiFp(this.configuration)
      .getReplicators(
        requestParameters.sourceInstanceId,
        requestParameters.enabled,
        requestParameters.limit,
        requestParameters.skip,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update a files replicator.
   * @summary Update Replicator
   * @param {FilesReplicatorApiUpdateReplicatorRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FilesReplicatorApi
   */
  public updateReplicator(
    requestParameters: FilesReplicatorApiUpdateReplicatorRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return FilesReplicatorApiFp(this.configuration)
      .updateReplicator(requestParameters.replicatorId, requestParameters.filesReplicatorUpdate, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * FsmsApi - axios parameter creator
 * @export
 */
export const FsmsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Get all fsms satisfying the specified filtering parameters.
     * @summary Get Fsms
     * @param {string} [pipelineId]
     * @param {number} [limit]
     * @param {number} [skip]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFilteredFsms: async (
      pipelineId?: string,
      limit?: number,
      skip?: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/fsms`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (pipelineId !== undefined) {
        localVarQueryParameter['pipeline_id'] = pipelineId;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (skip !== undefined) {
        localVarQueryParameter['skip'] = skip;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get a fsm by id.
     * @summary Get Fsm
     * @param {string} fsmId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFsm: async (fsmId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'fsmId' is not null or undefined
      assertParamExists('getFsm', 'fsmId', fsmId);
      const localVarPath = `/api/v1/fsms/{fsm_id}`.replace(`{${'fsm_id'}}`, encodeURIComponent(String(fsmId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * FsmsApi - functional programming interface
 * @export
 */
export const FsmsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = FsmsApiAxiosParamCreator(configuration);
  return {
    /**
     * Get all fsms satisfying the specified filtering parameters.
     * @summary Get Fsms
     * @param {string} [pipelineId]
     * @param {number} [limit]
     * @param {number} [skip]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFilteredFsms(
      pipelineId?: string,
      limit?: number,
      skip?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FSM>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getFilteredFsms(pipelineId, limit, skip, options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['FsmsApi.getFilteredFsms']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Get a fsm by id.
     * @summary Get Fsm
     * @param {string} fsmId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFsm(
      fsmId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FSM>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getFsm(fsmId, options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath = operationServerMap['FsmsApi.getFsm']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * FsmsApi - factory interface
 * @export
 */
export const FsmsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = FsmsApiFp(configuration);
  return {
    /**
     * Get all fsms satisfying the specified filtering parameters.
     * @summary Get Fsms
     * @param {FsmsApiGetFilteredFsmsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFilteredFsms(
      requestParameters: FsmsApiGetFilteredFsmsRequest = {},
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<FSM>> {
      return localVarFp
        .getFilteredFsms(requestParameters.pipelineId, requestParameters.limit, requestParameters.skip, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get a fsm by id.
     * @summary Get Fsm
     * @param {FsmsApiGetFsmRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFsm(requestParameters: FsmsApiGetFsmRequest, options?: RawAxiosRequestConfig): AxiosPromise<FSM> {
      return localVarFp.getFsm(requestParameters.fsmId, options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for getFilteredFsms operation in FsmsApi.
 * @export
 * @interface FsmsApiGetFilteredFsmsRequest
 */
export interface FsmsApiGetFilteredFsmsRequest {
  /**
   *
   * @type {string}
   * @memberof FsmsApiGetFilteredFsms
   */
  readonly pipelineId?: string;

  /**
   *
   * @type {number}
   * @memberof FsmsApiGetFilteredFsms
   */
  readonly limit?: number;

  /**
   *
   * @type {number}
   * @memberof FsmsApiGetFilteredFsms
   */
  readonly skip?: number;
}

/**
 * Request parameters for getFsm operation in FsmsApi.
 * @export
 * @interface FsmsApiGetFsmRequest
 */
export interface FsmsApiGetFsmRequest {
  /**
   *
   * @type {string}
   * @memberof FsmsApiGetFsm
   */
  readonly fsmId: string;
}

/**
 * FsmsApi - object-oriented interface
 * @export
 * @class FsmsApi
 * @extends {BaseAPI}
 */
export class FsmsApi extends BaseAPI {
  /**
   * Get all fsms satisfying the specified filtering parameters.
   * @summary Get Fsms
   * @param {FsmsApiGetFilteredFsmsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FsmsApi
   */
  public getFilteredFsms(requestParameters: FsmsApiGetFilteredFsmsRequest = {}, options?: RawAxiosRequestConfig) {
    return FsmsApiFp(this.configuration)
      .getFilteredFsms(requestParameters.pipelineId, requestParameters.limit, requestParameters.skip, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get a fsm by id.
   * @summary Get Fsm
   * @param {FsmsApiGetFsmRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FsmsApi
   */
  public getFsm(requestParameters: FsmsApiGetFsmRequest, options?: RawAxiosRequestConfig) {
    return FsmsApiFp(this.configuration)
      .getFsm(requestParameters.fsmId, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * HttpApiKeyApi - axios parameter creator
 * @export
 */
export const HttpApiKeyApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Creates a new HTTP API key. The value will be randomly generated.
     * @summary Create Key
     * @param {HTTPAPIKeyCreate} hTTPAPIKeyCreate
     * @param {string} [instanceId]
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createHttpKey: async (
      hTTPAPIKeyCreate: HTTPAPIKeyCreate,
      instanceId?: string,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'hTTPAPIKeyCreate' is not null or undefined
      assertParamExists('createHttpKey', 'hTTPAPIKeyCreate', hTTPAPIKeyCreate);
      const localVarPath = `/api/v1/http-api-keys`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (instanceId !== undefined) {
        localVarQueryParameter['instance_id'] = instanceId;
      }

      if (httpApiKeyId !== undefined) {
        localVarQueryParameter['http_api_key_id'] = httpApiKeyId;
      }

      if (pipelineId !== undefined) {
        localVarQueryParameter['pipeline_id'] = pipelineId;
      }

      if (pipelineStepId !== undefined) {
        localVarQueryParameter['pipeline_step_id'] = pipelineStepId;
      }

      if (pipelineExecutionId !== undefined) {
        localVarQueryParameter['pipeline_execution_id'] = pipelineExecutionId;
      }

      if (pipelineNotificationId !== undefined) {
        localVarQueryParameter['pipeline_notification_id'] = pipelineNotificationId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(hTTPAPIKeyCreate, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Deletes an HTTP API key.
     * @summary Delete Key
     * @param {string} httpApiKeyId
     * @param {string} [instanceId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {DataIn | null} [dataIn]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteHttpKey: async (
      httpApiKeyId: string,
      instanceId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      dataIn?: DataIn | null,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'httpApiKeyId' is not null or undefined
      assertParamExists('deleteHttpKey', 'httpApiKeyId', httpApiKeyId);
      const localVarPath = `/api/v1/http-api-keys/{http_api_key_id}`.replace(
        `{${'http_api_key_id'}}`,
        encodeURIComponent(String(httpApiKeyId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (instanceId !== undefined) {
        localVarQueryParameter['instance_id'] = instanceId;
      }

      if (pipelineId !== undefined) {
        localVarQueryParameter['pipeline_id'] = pipelineId;
      }

      if (pipelineStepId !== undefined) {
        localVarQueryParameter['pipeline_step_id'] = pipelineStepId;
      }

      if (pipelineExecutionId !== undefined) {
        localVarQueryParameter['pipeline_execution_id'] = pipelineExecutionId;
      }

      if (pipelineNotificationId !== undefined) {
        localVarQueryParameter['pipeline_notification_id'] = pipelineNotificationId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(dataIn, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get all HTTP API keys satisfying the specified filtering parameters.
     * @summary Get Keys
     * @param {string} instanceId
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {string} [keyLabel]
     * @param {number} [limit]
     * @param {number} [skip]
     * @param {DataIn | null} [dataIn]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFilteredHttpKeys: async (
      instanceId: string,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      keyLabel?: string,
      limit?: number,
      skip?: number,
      dataIn?: DataIn | null,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'instanceId' is not null or undefined
      assertParamExists('getFilteredHttpKeys', 'instanceId', instanceId);
      const localVarPath = `/api/v1/http-api-keys`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (instanceId !== undefined) {
        localVarQueryParameter['instance_id'] = instanceId;
      }

      if (httpApiKeyId !== undefined) {
        localVarQueryParameter['http_api_key_id'] = httpApiKeyId;
      }

      if (pipelineId !== undefined) {
        localVarQueryParameter['pipeline_id'] = pipelineId;
      }

      if (pipelineStepId !== undefined) {
        localVarQueryParameter['pipeline_step_id'] = pipelineStepId;
      }

      if (pipelineExecutionId !== undefined) {
        localVarQueryParameter['pipeline_execution_id'] = pipelineExecutionId;
      }

      if (pipelineNotificationId !== undefined) {
        localVarQueryParameter['pipeline_notification_id'] = pipelineNotificationId;
      }

      if (keyLabel !== undefined) {
        localVarQueryParameter['key_label'] = keyLabel;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (skip !== undefined) {
        localVarQueryParameter['skip'] = skip;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(dataIn, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get HTTP API key by id.
     * @summary Get Key
     * @param {string} httpApiKeyId
     * @param {string} [instanceId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {DataIn | null} [dataIn]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getHttpKey: async (
      httpApiKeyId: string,
      instanceId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      dataIn?: DataIn | null,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'httpApiKeyId' is not null or undefined
      assertParamExists('getHttpKey', 'httpApiKeyId', httpApiKeyId);
      const localVarPath = `/api/v1/http-api-keys/{http_api_key_id}`.replace(
        `{${'http_api_key_id'}}`,
        encodeURIComponent(String(httpApiKeyId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (instanceId !== undefined) {
        localVarQueryParameter['instance_id'] = instanceId;
      }

      if (pipelineId !== undefined) {
        localVarQueryParameter['pipeline_id'] = pipelineId;
      }

      if (pipelineStepId !== undefined) {
        localVarQueryParameter['pipeline_step_id'] = pipelineStepId;
      }

      if (pipelineExecutionId !== undefined) {
        localVarQueryParameter['pipeline_execution_id'] = pipelineExecutionId;
      }

      if (pipelineNotificationId !== undefined) {
        localVarQueryParameter['pipeline_notification_id'] = pipelineNotificationId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(dataIn, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * HttpApiKeyApi - functional programming interface
 * @export
 */
export const HttpApiKeyApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = HttpApiKeyApiAxiosParamCreator(configuration);
  return {
    /**
     * Creates a new HTTP API key. The value will be randomly generated.
     * @summary Create Key
     * @param {HTTPAPIKeyCreate} hTTPAPIKeyCreate
     * @param {string} [instanceId]
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createHttpKey(
      hTTPAPIKeyCreate: HTTPAPIKeyCreate,
      instanceId?: string,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HTTPAPIKey>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createHttpKey(
        hTTPAPIKeyCreate,
        instanceId,
        httpApiKeyId,
        pipelineId,
        pipelineStepId,
        pipelineExecutionId,
        pipelineNotificationId,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['HttpApiKeyApi.createHttpKey']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Deletes an HTTP API key.
     * @summary Delete Key
     * @param {string} httpApiKeyId
     * @param {string} [instanceId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {DataIn | null} [dataIn]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteHttpKey(
      httpApiKeyId: string,
      instanceId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      dataIn?: DataIn | null,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HTTPAPIKey>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteHttpKey(
        httpApiKeyId,
        instanceId,
        pipelineId,
        pipelineStepId,
        pipelineExecutionId,
        pipelineNotificationId,
        dataIn,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['HttpApiKeyApi.deleteHttpKey']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Get all HTTP API keys satisfying the specified filtering parameters.
     * @summary Get Keys
     * @param {string} instanceId
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {string} [keyLabel]
     * @param {number} [limit]
     * @param {number} [skip]
     * @param {DataIn | null} [dataIn]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFilteredHttpKeys(
      instanceId: string,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      keyLabel?: string,
      limit?: number,
      skip?: number,
      dataIn?: DataIn | null,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HTTPAPIKey>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getFilteredHttpKeys(
        instanceId,
        httpApiKeyId,
        pipelineId,
        pipelineStepId,
        pipelineExecutionId,
        pipelineNotificationId,
        keyLabel,
        limit,
        skip,
        dataIn,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['HttpApiKeyApi.getFilteredHttpKeys']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Get HTTP API key by id.
     * @summary Get Key
     * @param {string} httpApiKeyId
     * @param {string} [instanceId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {DataIn | null} [dataIn]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getHttpKey(
      httpApiKeyId: string,
      instanceId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      dataIn?: DataIn | null,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HTTPAPIKey>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getHttpKey(
        httpApiKeyId,
        instanceId,
        pipelineId,
        pipelineStepId,
        pipelineExecutionId,
        pipelineNotificationId,
        dataIn,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['HttpApiKeyApi.getHttpKey']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * HttpApiKeyApi - factory interface
 * @export
 */
export const HttpApiKeyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = HttpApiKeyApiFp(configuration);
  return {
    /**
     * Creates a new HTTP API key. The value will be randomly generated.
     * @summary Create Key
     * @param {HttpApiKeyApiCreateHttpKeyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createHttpKey(
      requestParameters: HttpApiKeyApiCreateHttpKeyRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<HTTPAPIKey> {
      return localVarFp
        .createHttpKey(
          requestParameters.hTTPAPIKeyCreate,
          requestParameters.instanceId,
          requestParameters.httpApiKeyId,
          requestParameters.pipelineId,
          requestParameters.pipelineStepId,
          requestParameters.pipelineExecutionId,
          requestParameters.pipelineNotificationId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Deletes an HTTP API key.
     * @summary Delete Key
     * @param {HttpApiKeyApiDeleteHttpKeyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteHttpKey(
      requestParameters: HttpApiKeyApiDeleteHttpKeyRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<HTTPAPIKey> {
      return localVarFp
        .deleteHttpKey(
          requestParameters.httpApiKeyId,
          requestParameters.instanceId,
          requestParameters.pipelineId,
          requestParameters.pipelineStepId,
          requestParameters.pipelineExecutionId,
          requestParameters.pipelineNotificationId,
          requestParameters.dataIn,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Get all HTTP API keys satisfying the specified filtering parameters.
     * @summary Get Keys
     * @param {HttpApiKeyApiGetFilteredHttpKeysRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFilteredHttpKeys(
      requestParameters: HttpApiKeyApiGetFilteredHttpKeysRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<HTTPAPIKey>> {
      return localVarFp
        .getFilteredHttpKeys(
          requestParameters.instanceId,
          requestParameters.httpApiKeyId,
          requestParameters.pipelineId,
          requestParameters.pipelineStepId,
          requestParameters.pipelineExecutionId,
          requestParameters.pipelineNotificationId,
          requestParameters.keyLabel,
          requestParameters.limit,
          requestParameters.skip,
          requestParameters.dataIn,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Get HTTP API key by id.
     * @summary Get Key
     * @param {HttpApiKeyApiGetHttpKeyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getHttpKey(
      requestParameters: HttpApiKeyApiGetHttpKeyRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<HTTPAPIKey> {
      return localVarFp
        .getHttpKey(
          requestParameters.httpApiKeyId,
          requestParameters.instanceId,
          requestParameters.pipelineId,
          requestParameters.pipelineStepId,
          requestParameters.pipelineExecutionId,
          requestParameters.pipelineNotificationId,
          requestParameters.dataIn,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for createHttpKey operation in HttpApiKeyApi.
 * @export
 * @interface HttpApiKeyApiCreateHttpKeyRequest
 */
export interface HttpApiKeyApiCreateHttpKeyRequest {
  /**
   *
   * @type {HTTPAPIKeyCreate}
   * @memberof HttpApiKeyApiCreateHttpKey
   */
  readonly hTTPAPIKeyCreate: HTTPAPIKeyCreate;

  /**
   *
   * @type {string}
   * @memberof HttpApiKeyApiCreateHttpKey
   */
  readonly instanceId?: string;

  /**
   *
   * @type {string}
   * @memberof HttpApiKeyApiCreateHttpKey
   */
  readonly httpApiKeyId?: string;

  /**
   *
   * @type {string}
   * @memberof HttpApiKeyApiCreateHttpKey
   */
  readonly pipelineId?: string;

  /**
   *
   * @type {string}
   * @memberof HttpApiKeyApiCreateHttpKey
   */
  readonly pipelineStepId?: string;

  /**
   *
   * @type {string}
   * @memberof HttpApiKeyApiCreateHttpKey
   */
  readonly pipelineExecutionId?: string;

  /**
   *
   * @type {string}
   * @memberof HttpApiKeyApiCreateHttpKey
   */
  readonly pipelineNotificationId?: string;
}

/**
 * Request parameters for deleteHttpKey operation in HttpApiKeyApi.
 * @export
 * @interface HttpApiKeyApiDeleteHttpKeyRequest
 */
export interface HttpApiKeyApiDeleteHttpKeyRequest {
  /**
   *
   * @type {string}
   * @memberof HttpApiKeyApiDeleteHttpKey
   */
  readonly httpApiKeyId: string;

  /**
   *
   * @type {string}
   * @memberof HttpApiKeyApiDeleteHttpKey
   */
  readonly instanceId?: string;

  /**
   *
   * @type {string}
   * @memberof HttpApiKeyApiDeleteHttpKey
   */
  readonly pipelineId?: string;

  /**
   *
   * @type {string}
   * @memberof HttpApiKeyApiDeleteHttpKey
   */
  readonly pipelineStepId?: string;

  /**
   *
   * @type {string}
   * @memberof HttpApiKeyApiDeleteHttpKey
   */
  readonly pipelineExecutionId?: string;

  /**
   *
   * @type {string}
   * @memberof HttpApiKeyApiDeleteHttpKey
   */
  readonly pipelineNotificationId?: string;

  /**
   *
   * @type {DataIn}
   * @memberof HttpApiKeyApiDeleteHttpKey
   */
  readonly dataIn?: DataIn | null;
}

/**
 * Request parameters for getFilteredHttpKeys operation in HttpApiKeyApi.
 * @export
 * @interface HttpApiKeyApiGetFilteredHttpKeysRequest
 */
export interface HttpApiKeyApiGetFilteredHttpKeysRequest {
  /**
   *
   * @type {string}
   * @memberof HttpApiKeyApiGetFilteredHttpKeys
   */
  readonly instanceId: string;

  /**
   *
   * @type {string}
   * @memberof HttpApiKeyApiGetFilteredHttpKeys
   */
  readonly httpApiKeyId?: string;

  /**
   *
   * @type {string}
   * @memberof HttpApiKeyApiGetFilteredHttpKeys
   */
  readonly pipelineId?: string;

  /**
   *
   * @type {string}
   * @memberof HttpApiKeyApiGetFilteredHttpKeys
   */
  readonly pipelineStepId?: string;

  /**
   *
   * @type {string}
   * @memberof HttpApiKeyApiGetFilteredHttpKeys
   */
  readonly pipelineExecutionId?: string;

  /**
   *
   * @type {string}
   * @memberof HttpApiKeyApiGetFilteredHttpKeys
   */
  readonly pipelineNotificationId?: string;

  /**
   *
   * @type {string}
   * @memberof HttpApiKeyApiGetFilteredHttpKeys
   */
  readonly keyLabel?: string;

  /**
   *
   * @type {number}
   * @memberof HttpApiKeyApiGetFilteredHttpKeys
   */
  readonly limit?: number;

  /**
   *
   * @type {number}
   * @memberof HttpApiKeyApiGetFilteredHttpKeys
   */
  readonly skip?: number;

  /**
   *
   * @type {DataIn}
   * @memberof HttpApiKeyApiGetFilteredHttpKeys
   */
  readonly dataIn?: DataIn | null;
}

/**
 * Request parameters for getHttpKey operation in HttpApiKeyApi.
 * @export
 * @interface HttpApiKeyApiGetHttpKeyRequest
 */
export interface HttpApiKeyApiGetHttpKeyRequest {
  /**
   *
   * @type {string}
   * @memberof HttpApiKeyApiGetHttpKey
   */
  readonly httpApiKeyId: string;

  /**
   *
   * @type {string}
   * @memberof HttpApiKeyApiGetHttpKey
   */
  readonly instanceId?: string;

  /**
   *
   * @type {string}
   * @memberof HttpApiKeyApiGetHttpKey
   */
  readonly pipelineId?: string;

  /**
   *
   * @type {string}
   * @memberof HttpApiKeyApiGetHttpKey
   */
  readonly pipelineStepId?: string;

  /**
   *
   * @type {string}
   * @memberof HttpApiKeyApiGetHttpKey
   */
  readonly pipelineExecutionId?: string;

  /**
   *
   * @type {string}
   * @memberof HttpApiKeyApiGetHttpKey
   */
  readonly pipelineNotificationId?: string;

  /**
   *
   * @type {DataIn}
   * @memberof HttpApiKeyApiGetHttpKey
   */
  readonly dataIn?: DataIn | null;
}

/**
 * HttpApiKeyApi - object-oriented interface
 * @export
 * @class HttpApiKeyApi
 * @extends {BaseAPI}
 */
export class HttpApiKeyApi extends BaseAPI {
  /**
   * Creates a new HTTP API key. The value will be randomly generated.
   * @summary Create Key
   * @param {HttpApiKeyApiCreateHttpKeyRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HttpApiKeyApi
   */
  public createHttpKey(requestParameters: HttpApiKeyApiCreateHttpKeyRequest, options?: RawAxiosRequestConfig) {
    return HttpApiKeyApiFp(this.configuration)
      .createHttpKey(
        requestParameters.hTTPAPIKeyCreate,
        requestParameters.instanceId,
        requestParameters.httpApiKeyId,
        requestParameters.pipelineId,
        requestParameters.pipelineStepId,
        requestParameters.pipelineExecutionId,
        requestParameters.pipelineNotificationId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Deletes an HTTP API key.
   * @summary Delete Key
   * @param {HttpApiKeyApiDeleteHttpKeyRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HttpApiKeyApi
   */
  public deleteHttpKey(requestParameters: HttpApiKeyApiDeleteHttpKeyRequest, options?: RawAxiosRequestConfig) {
    return HttpApiKeyApiFp(this.configuration)
      .deleteHttpKey(
        requestParameters.httpApiKeyId,
        requestParameters.instanceId,
        requestParameters.pipelineId,
        requestParameters.pipelineStepId,
        requestParameters.pipelineExecutionId,
        requestParameters.pipelineNotificationId,
        requestParameters.dataIn,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get all HTTP API keys satisfying the specified filtering parameters.
   * @summary Get Keys
   * @param {HttpApiKeyApiGetFilteredHttpKeysRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HttpApiKeyApi
   */
  public getFilteredHttpKeys(
    requestParameters: HttpApiKeyApiGetFilteredHttpKeysRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return HttpApiKeyApiFp(this.configuration)
      .getFilteredHttpKeys(
        requestParameters.instanceId,
        requestParameters.httpApiKeyId,
        requestParameters.pipelineId,
        requestParameters.pipelineStepId,
        requestParameters.pipelineExecutionId,
        requestParameters.pipelineNotificationId,
        requestParameters.keyLabel,
        requestParameters.limit,
        requestParameters.skip,
        requestParameters.dataIn,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get HTTP API key by id.
   * @summary Get Key
   * @param {HttpApiKeyApiGetHttpKeyRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HttpApiKeyApi
   */
  public getHttpKey(requestParameters: HttpApiKeyApiGetHttpKeyRequest, options?: RawAxiosRequestConfig) {
    return HttpApiKeyApiFp(this.configuration)
      .getHttpKey(
        requestParameters.httpApiKeyId,
        requestParameters.instanceId,
        requestParameters.pipelineId,
        requestParameters.pipelineStepId,
        requestParameters.pipelineExecutionId,
        requestParameters.pipelineNotificationId,
        requestParameters.dataIn,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * HttpInboundInterfaceApi - axios parameter creator
 * @export
 */
export const HttpInboundInterfaceApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * External endpoint for uploading file to S3 with authorization.
     * @summary Upload File
     * @param {HTTPInboundInterfaceRequestBody} hTTPInboundInterfaceRequestBody
     * @param {string} [xVfSignature]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadFileToS3WithAuthorization: async (
      hTTPInboundInterfaceRequestBody: HTTPInboundInterfaceRequestBody,
      xVfSignature?: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'hTTPInboundInterfaceRequestBody' is not null or undefined
      assertParamExists(
        'uploadFileToS3WithAuthorization',
        'hTTPInboundInterfaceRequestBody',
        hTTPInboundInterfaceRequestBody,
      );
      const localVarPath = `/api/v1/http-inbound-interface/upload`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (xVfSignature != null) {
        localVarHeaderParameter['x-vf-signature'] = String(xVfSignature);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        hTTPInboundInterfaceRequestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * HttpInboundInterfaceApi - functional programming interface
 * @export
 */
export const HttpInboundInterfaceApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = HttpInboundInterfaceApiAxiosParamCreator(configuration);
  return {
    /**
     * External endpoint for uploading file to S3 with authorization.
     * @summary Upload File
     * @param {HTTPInboundInterfaceRequestBody} hTTPInboundInterfaceRequestBody
     * @param {string} [xVfSignature]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async uploadFileToS3WithAuthorization(
      hTTPInboundInterfaceRequestBody: HTTPInboundInterfaceRequestBody,
      xVfSignature?: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.uploadFileToS3WithAuthorization(
        hTTPInboundInterfaceRequestBody,
        xVfSignature,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['HttpInboundInterfaceApi.uploadFileToS3WithAuthorization']?.[localVarOperationServerIndex]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * HttpInboundInterfaceApi - factory interface
 * @export
 */
export const HttpInboundInterfaceApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = HttpInboundInterfaceApiFp(configuration);
  return {
    /**
     * External endpoint for uploading file to S3 with authorization.
     * @summary Upload File
     * @param {HttpInboundInterfaceApiUploadFileToS3WithAuthorizationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadFileToS3WithAuthorization(
      requestParameters: HttpInboundInterfaceApiUploadFileToS3WithAuthorizationRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .uploadFileToS3WithAuthorization(
          requestParameters.hTTPInboundInterfaceRequestBody,
          requestParameters.xVfSignature,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for uploadFileToS3WithAuthorization operation in HttpInboundInterfaceApi.
 * @export
 * @interface HttpInboundInterfaceApiUploadFileToS3WithAuthorizationRequest
 */
export interface HttpInboundInterfaceApiUploadFileToS3WithAuthorizationRequest {
  /**
   *
   * @type {HTTPInboundInterfaceRequestBody}
   * @memberof HttpInboundInterfaceApiUploadFileToS3WithAuthorization
   */
  readonly hTTPInboundInterfaceRequestBody: HTTPInboundInterfaceRequestBody;

  /**
   *
   * @type {string}
   * @memberof HttpInboundInterfaceApiUploadFileToS3WithAuthorization
   */
  readonly xVfSignature?: string;
}

/**
 * HttpInboundInterfaceApi - object-oriented interface
 * @export
 * @class HttpInboundInterfaceApi
 * @extends {BaseAPI}
 */
export class HttpInboundInterfaceApi extends BaseAPI {
  /**
   * External endpoint for uploading file to S3 with authorization.
   * @summary Upload File
   * @param {HttpInboundInterfaceApiUploadFileToS3WithAuthorizationRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HttpInboundInterfaceApi
   */
  public uploadFileToS3WithAuthorization(
    requestParameters: HttpInboundInterfaceApiUploadFileToS3WithAuthorizationRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return HttpInboundInterfaceApiFp(this.configuration)
      .uploadFileToS3WithAuthorization(
        requestParameters.hTTPInboundInterfaceRequestBody,
        requestParameters.xVfSignature,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * InstancesApi - axios parameter creator
 * @export
 */
export const InstancesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Create one instance.
     * @summary Create Instance
     * @param {InstanceCreateOrUpdate} instanceCreateOrUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createInstance: async (
      instanceCreateOrUpdate: InstanceCreateOrUpdate,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'instanceCreateOrUpdate' is not null or undefined
      assertParamExists('createInstance', 'instanceCreateOrUpdate', instanceCreateOrUpdate);
      const localVarPath = `/api/v1/instances/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        instanceCreateOrUpdate,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Create multiple instances.
     * @summary Create Instances
     * @param {Array<InstanceCreateOrUpdate>} instanceCreateOrUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createInstances: async (
      instanceCreateOrUpdate: Array<InstanceCreateOrUpdate>,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'instanceCreateOrUpdate' is not null or undefined
      assertParamExists('createInstances', 'instanceCreateOrUpdate', instanceCreateOrUpdate);
      const localVarPath = `/api/v1/instances/all`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        instanceCreateOrUpdate,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Delete instance.
     * @summary Delete Instance
     * @param {string} instanceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteInstance: async (instanceId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'instanceId' is not null or undefined
      assertParamExists('deleteInstance', 'instanceId', instanceId);
      const localVarPath = `/api/v1/instances/{instance_id}`.replace(
        `{${'instance_id'}}`,
        encodeURIComponent(String(instanceId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieve one instance.
     * @summary Get Instance
     * @param {string} instanceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInstance: async (instanceId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'instanceId' is not null or undefined
      assertParamExists('getInstance', 'instanceId', instanceId);
      const localVarPath = `/api/v1/instances/{instance_id}`.replace(
        `{${'instance_id'}}`,
        encodeURIComponent(String(instanceId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Retrieve all instances.
     * @summary Read Instances
     * @param {number} [skip]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readInstances: async (skip?: number, limit?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/instances/all`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (skip !== undefined) {
        localVarQueryParameter['skip'] = skip;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update instance.
     * @summary Update Instance
     * @param {string} instanceId
     * @param {InstanceUpdate} instanceUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateInstance: async (
      instanceId: string,
      instanceUpdate: InstanceUpdate,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'instanceId' is not null or undefined
      assertParamExists('updateInstance', 'instanceId', instanceId);
      // verify required parameter 'instanceUpdate' is not null or undefined
      assertParamExists('updateInstance', 'instanceUpdate', instanceUpdate);
      const localVarPath = `/api/v1/instances/{instance_id}`.replace(
        `{${'instance_id'}}`,
        encodeURIComponent(String(instanceId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(instanceUpdate, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * InstancesApi - functional programming interface
 * @export
 */
export const InstancesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = InstancesApiAxiosParamCreator(configuration);
  return {
    /**
     * Create one instance.
     * @summary Create Instance
     * @param {InstanceCreateOrUpdate} instanceCreateOrUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createInstance(
      instanceCreateOrUpdate: InstanceCreateOrUpdate,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Instance>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createInstance(instanceCreateOrUpdate, options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['InstancesApi.createInstance']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Create multiple instances.
     * @summary Create Instances
     * @param {Array<InstanceCreateOrUpdate>} instanceCreateOrUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createInstances(
      instanceCreateOrUpdate: Array<InstanceCreateOrUpdate>,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Instance>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createInstances(instanceCreateOrUpdate, options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['InstancesApi.createInstances']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Delete instance.
     * @summary Delete Instance
     * @param {string} instanceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteInstance(
      instanceId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteInstance(instanceId, options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['InstancesApi.deleteInstance']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Retrieve one instance.
     * @summary Get Instance
     * @param {string} instanceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getInstance(
      instanceId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Instance>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getInstance(instanceId, options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['InstancesApi.getInstance']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Retrieve all instances.
     * @summary Read Instances
     * @param {number} [skip]
     * @param {number} [limit]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async readInstances(
      skip?: number,
      limit?: number,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Instance>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.readInstances(skip, limit, options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['InstancesApi.readInstances']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Update instance.
     * @summary Update Instance
     * @param {string} instanceId
     * @param {InstanceUpdate} instanceUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateInstance(
      instanceId: string,
      instanceUpdate: InstanceUpdate,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Instance>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateInstance(instanceId, instanceUpdate, options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['InstancesApi.updateInstance']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * InstancesApi - factory interface
 * @export
 */
export const InstancesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = InstancesApiFp(configuration);
  return {
    /**
     * Create one instance.
     * @summary Create Instance
     * @param {InstancesApiCreateInstanceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createInstance(
      requestParameters: InstancesApiCreateInstanceRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Instance> {
      return localVarFp
        .createInstance(requestParameters.instanceCreateOrUpdate, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Create multiple instances.
     * @summary Create Instances
     * @param {InstancesApiCreateInstancesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createInstances(
      requestParameters: InstancesApiCreateInstancesRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<Instance>> {
      return localVarFp
        .createInstances(requestParameters.instanceCreateOrUpdate, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Delete instance.
     * @summary Delete Instance
     * @param {InstancesApiDeleteInstanceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteInstance(
      requestParameters: InstancesApiDeleteInstanceRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .deleteInstance(requestParameters.instanceId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Retrieve one instance.
     * @summary Get Instance
     * @param {InstancesApiGetInstanceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInstance(
      requestParameters: InstancesApiGetInstanceRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Instance> {
      return localVarFp.getInstance(requestParameters.instanceId, options).then((request) => request(axios, basePath));
    },
    /**
     * Retrieve all instances.
     * @summary Read Instances
     * @param {InstancesApiReadInstancesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    readInstances(
      requestParameters: InstancesApiReadInstancesRequest = {},
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<Instance>> {
      return localVarFp
        .readInstances(requestParameters.skip, requestParameters.limit, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Update instance.
     * @summary Update Instance
     * @param {InstancesApiUpdateInstanceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateInstance(
      requestParameters: InstancesApiUpdateInstanceRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Instance> {
      return localVarFp
        .updateInstance(requestParameters.instanceId, requestParameters.instanceUpdate, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for createInstance operation in InstancesApi.
 * @export
 * @interface InstancesApiCreateInstanceRequest
 */
export interface InstancesApiCreateInstanceRequest {
  /**
   *
   * @type {InstanceCreateOrUpdate}
   * @memberof InstancesApiCreateInstance
   */
  readonly instanceCreateOrUpdate: InstanceCreateOrUpdate;
}

/**
 * Request parameters for createInstances operation in InstancesApi.
 * @export
 * @interface InstancesApiCreateInstancesRequest
 */
export interface InstancesApiCreateInstancesRequest {
  /**
   *
   * @type {Array<InstanceCreateOrUpdate>}
   * @memberof InstancesApiCreateInstances
   */
  readonly instanceCreateOrUpdate: Array<InstanceCreateOrUpdate>;
}

/**
 * Request parameters for deleteInstance operation in InstancesApi.
 * @export
 * @interface InstancesApiDeleteInstanceRequest
 */
export interface InstancesApiDeleteInstanceRequest {
  /**
   *
   * @type {string}
   * @memberof InstancesApiDeleteInstance
   */
  readonly instanceId: string;
}

/**
 * Request parameters for getInstance operation in InstancesApi.
 * @export
 * @interface InstancesApiGetInstanceRequest
 */
export interface InstancesApiGetInstanceRequest {
  /**
   *
   * @type {string}
   * @memberof InstancesApiGetInstance
   */
  readonly instanceId: string;
}

/**
 * Request parameters for readInstances operation in InstancesApi.
 * @export
 * @interface InstancesApiReadInstancesRequest
 */
export interface InstancesApiReadInstancesRequest {
  /**
   *
   * @type {number}
   * @memberof InstancesApiReadInstances
   */
  readonly skip?: number;

  /**
   *
   * @type {number}
   * @memberof InstancesApiReadInstances
   */
  readonly limit?: number;
}

/**
 * Request parameters for updateInstance operation in InstancesApi.
 * @export
 * @interface InstancesApiUpdateInstanceRequest
 */
export interface InstancesApiUpdateInstanceRequest {
  /**
   *
   * @type {string}
   * @memberof InstancesApiUpdateInstance
   */
  readonly instanceId: string;

  /**
   *
   * @type {InstanceUpdate}
   * @memberof InstancesApiUpdateInstance
   */
  readonly instanceUpdate: InstanceUpdate;
}

/**
 * InstancesApi - object-oriented interface
 * @export
 * @class InstancesApi
 * @extends {BaseAPI}
 */
export class InstancesApi extends BaseAPI {
  /**
   * Create one instance.
   * @summary Create Instance
   * @param {InstancesApiCreateInstanceRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InstancesApi
   */
  public createInstance(requestParameters: InstancesApiCreateInstanceRequest, options?: RawAxiosRequestConfig) {
    return InstancesApiFp(this.configuration)
      .createInstance(requestParameters.instanceCreateOrUpdate, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Create multiple instances.
   * @summary Create Instances
   * @param {InstancesApiCreateInstancesRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InstancesApi
   */
  public createInstances(requestParameters: InstancesApiCreateInstancesRequest, options?: RawAxiosRequestConfig) {
    return InstancesApiFp(this.configuration)
      .createInstances(requestParameters.instanceCreateOrUpdate, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Delete instance.
   * @summary Delete Instance
   * @param {InstancesApiDeleteInstanceRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InstancesApi
   */
  public deleteInstance(requestParameters: InstancesApiDeleteInstanceRequest, options?: RawAxiosRequestConfig) {
    return InstancesApiFp(this.configuration)
      .deleteInstance(requestParameters.instanceId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Retrieve one instance.
   * @summary Get Instance
   * @param {InstancesApiGetInstanceRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InstancesApi
   */
  public getInstance(requestParameters: InstancesApiGetInstanceRequest, options?: RawAxiosRequestConfig) {
    return InstancesApiFp(this.configuration)
      .getInstance(requestParameters.instanceId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Retrieve all instances.
   * @summary Read Instances
   * @param {InstancesApiReadInstancesRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InstancesApi
   */
  public readInstances(requestParameters: InstancesApiReadInstancesRequest = {}, options?: RawAxiosRequestConfig) {
    return InstancesApiFp(this.configuration)
      .readInstances(requestParameters.skip, requestParameters.limit, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update instance.
   * @summary Update Instance
   * @param {InstancesApiUpdateInstanceRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof InstancesApi
   */
  public updateInstance(requestParameters: InstancesApiUpdateInstanceRequest, options?: RawAxiosRequestConfig) {
    return InstancesApiFp(this.configuration)
      .updateInstance(requestParameters.instanceId, requestParameters.instanceUpdate, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * PipelineExecutionsApi - axios parameter creator
 * @export
 */
export const PipelineExecutionsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Create a pipeline execution.
     * @summary Create Pipeline Execution
     * @param {PipelineExecutionBase} pipelineExecutionBase
     * @param {string} [instanceId]
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPipelineExecution: async (
      pipelineExecutionBase: PipelineExecutionBase,
      instanceId?: string,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pipelineExecutionBase' is not null or undefined
      assertParamExists('createPipelineExecution', 'pipelineExecutionBase', pipelineExecutionBase);
      const localVarPath = `/api/v1/pipeline-executions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (instanceId !== undefined) {
        localVarQueryParameter['instance_id'] = instanceId;
      }

      if (httpApiKeyId !== undefined) {
        localVarQueryParameter['http_api_key_id'] = httpApiKeyId;
      }

      if (pipelineId !== undefined) {
        localVarQueryParameter['pipeline_id'] = pipelineId;
      }

      if (pipelineStepId !== undefined) {
        localVarQueryParameter['pipeline_step_id'] = pipelineStepId;
      }

      if (pipelineExecutionId !== undefined) {
        localVarQueryParameter['pipeline_execution_id'] = pipelineExecutionId;
      }

      if (pipelineNotificationId !== undefined) {
        localVarQueryParameter['pipeline_notification_id'] = pipelineNotificationId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(pipelineExecutionBase, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Delete a pipeline execution.
     * @summary Delete Pipeline Execution
     * @param {string} pipelineExecutionId
     * @param {string} [instanceId]
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineNotificationId]
     * @param {DataIn | null} [dataIn]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePipelineExecution: async (
      pipelineExecutionId: string,
      instanceId?: string,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineNotificationId?: string,
      dataIn?: DataIn | null,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pipelineExecutionId' is not null or undefined
      assertParamExists('deletePipelineExecution', 'pipelineExecutionId', pipelineExecutionId);
      const localVarPath = `/api/v1/pipeline-executions/{pipeline_execution_id}`.replace(
        `{${'pipeline_execution_id'}}`,
        encodeURIComponent(String(pipelineExecutionId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (instanceId !== undefined) {
        localVarQueryParameter['instance_id'] = instanceId;
      }

      if (httpApiKeyId !== undefined) {
        localVarQueryParameter['http_api_key_id'] = httpApiKeyId;
      }

      if (pipelineId !== undefined) {
        localVarQueryParameter['pipeline_id'] = pipelineId;
      }

      if (pipelineStepId !== undefined) {
        localVarQueryParameter['pipeline_step_id'] = pipelineStepId;
      }

      if (pipelineNotificationId !== undefined) {
        localVarQueryParameter['pipeline_notification_id'] = pipelineNotificationId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(dataIn, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Average Execution Time
     * @param {PipelineAverageExecutionTimeRequestBody} pipelineAverageExecutionTimeRequestBody
     * @param {string} [instanceId]
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAverageExecutionTime: async (
      pipelineAverageExecutionTimeRequestBody: PipelineAverageExecutionTimeRequestBody,
      instanceId?: string,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pipelineAverageExecutionTimeRequestBody' is not null or undefined
      assertParamExists(
        'getAverageExecutionTime',
        'pipelineAverageExecutionTimeRequestBody',
        pipelineAverageExecutionTimeRequestBody,
      );
      const localVarPath = `/api/v1/pipeline-executions/average`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (instanceId !== undefined) {
        localVarQueryParameter['instance_id'] = instanceId;
      }

      if (httpApiKeyId !== undefined) {
        localVarQueryParameter['http_api_key_id'] = httpApiKeyId;
      }

      if (pipelineId !== undefined) {
        localVarQueryParameter['pipeline_id'] = pipelineId;
      }

      if (pipelineStepId !== undefined) {
        localVarQueryParameter['pipeline_step_id'] = pipelineStepId;
      }

      if (pipelineExecutionId !== undefined) {
        localVarQueryParameter['pipeline_execution_id'] = pipelineExecutionId;
      }

      if (pipelineNotificationId !== undefined) {
        localVarQueryParameter['pipeline_notification_id'] = pipelineNotificationId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        pipelineAverageExecutionTimeRequestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get a pipeline execution by id.
     * @summary Get Pipeline Execution
     * @param {string} pipelineExecutionId
     * @param {string} [instanceId]
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineNotificationId]
     * @param {DataIn | null} [dataIn]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPipelineExecution: async (
      pipelineExecutionId: string,
      instanceId?: string,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineNotificationId?: string,
      dataIn?: DataIn | null,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pipelineExecutionId' is not null or undefined
      assertParamExists('getPipelineExecution', 'pipelineExecutionId', pipelineExecutionId);
      const localVarPath = `/api/v1/pipeline-executions/{pipeline_execution_id}`.replace(
        `{${'pipeline_execution_id'}}`,
        encodeURIComponent(String(pipelineExecutionId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (instanceId !== undefined) {
        localVarQueryParameter['instance_id'] = instanceId;
      }

      if (httpApiKeyId !== undefined) {
        localVarQueryParameter['http_api_key_id'] = httpApiKeyId;
      }

      if (pipelineId !== undefined) {
        localVarQueryParameter['pipeline_id'] = pipelineId;
      }

      if (pipelineStepId !== undefined) {
        localVarQueryParameter['pipeline_step_id'] = pipelineStepId;
      }

      if (pipelineNotificationId !== undefined) {
        localVarQueryParameter['pipeline_notification_id'] = pipelineNotificationId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(dataIn, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get pipeline execution step full log presigned url
     * @summary Get Pipeline Execution Step Full Log Presigned Url
     * @param {string} pipelineExecutionId
     * @param {string} fullLogPath
     * @param {string} [instanceId]
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineNotificationId]
     * @param {DataIn | null} [dataIn]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPipelineExecutionStepFullLogPresignedUrl: async (
      pipelineExecutionId: string,
      fullLogPath: string,
      instanceId?: string,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineNotificationId?: string,
      dataIn?: DataIn | null,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pipelineExecutionId' is not null or undefined
      assertParamExists('getPipelineExecutionStepFullLogPresignedUrl', 'pipelineExecutionId', pipelineExecutionId);
      // verify required parameter 'fullLogPath' is not null or undefined
      assertParamExists('getPipelineExecutionStepFullLogPresignedUrl', 'fullLogPath', fullLogPath);
      const localVarPath = `/api/v1/pipeline-executions/{pipeline_execution_id}/full_log`.replace(
        `{${'pipeline_execution_id'}}`,
        encodeURIComponent(String(pipelineExecutionId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (fullLogPath !== undefined) {
        localVarQueryParameter['full_log_path'] = fullLogPath;
      }

      if (instanceId !== undefined) {
        localVarQueryParameter['instance_id'] = instanceId;
      }

      if (httpApiKeyId !== undefined) {
        localVarQueryParameter['http_api_key_id'] = httpApiKeyId;
      }

      if (pipelineId !== undefined) {
        localVarQueryParameter['pipeline_id'] = pipelineId;
      }

      if (pipelineStepId !== undefined) {
        localVarQueryParameter['pipeline_step_id'] = pipelineStepId;
      }

      if (pipelineNotificationId !== undefined) {
        localVarQueryParameter['pipeline_notification_id'] = pipelineNotificationId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(dataIn, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get all pipeline executions satisfying the specified filtering parameters.
     * @summary Get Pipeline Executions
     * @param {PipelineExecutionRequestBody} pipelineExecutionRequestBody
     * @param {string} [instanceId]
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPipelineExecutions: async (
      pipelineExecutionRequestBody: PipelineExecutionRequestBody,
      instanceId?: string,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pipelineExecutionRequestBody' is not null or undefined
      assertParamExists('getPipelineExecutions', 'pipelineExecutionRequestBody', pipelineExecutionRequestBody);
      const localVarPath = `/api/v1/pipeline-executions/filtered`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (instanceId !== undefined) {
        localVarQueryParameter['instance_id'] = instanceId;
      }

      if (httpApiKeyId !== undefined) {
        localVarQueryParameter['http_api_key_id'] = httpApiKeyId;
      }

      if (pipelineId !== undefined) {
        localVarQueryParameter['pipeline_id'] = pipelineId;
      }

      if (pipelineStepId !== undefined) {
        localVarQueryParameter['pipeline_step_id'] = pipelineStepId;
      }

      if (pipelineExecutionId !== undefined) {
        localVarQueryParameter['pipeline_execution_id'] = pipelineExecutionId;
      }

      if (pipelineNotificationId !== undefined) {
        localVarQueryParameter['pipeline_notification_id'] = pipelineNotificationId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        pipelineExecutionRequestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Stop a pipeline execution.
     * @summary Stop Pipeline Execution
     * @param {string} pipelineExecutionId
     * @param {string} [instanceId]
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineNotificationId]
     * @param {DataIn | null} [dataIn]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stopPipelineExecution: async (
      pipelineExecutionId: string,
      instanceId?: string,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineNotificationId?: string,
      dataIn?: DataIn | null,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pipelineExecutionId' is not null or undefined
      assertParamExists('stopPipelineExecution', 'pipelineExecutionId', pipelineExecutionId);
      const localVarPath = `/api/v1/pipeline-executions/{pipeline_execution_id}/stop`.replace(
        `{${'pipeline_execution_id'}}`,
        encodeURIComponent(String(pipelineExecutionId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (instanceId !== undefined) {
        localVarQueryParameter['instance_id'] = instanceId;
      }

      if (httpApiKeyId !== undefined) {
        localVarQueryParameter['http_api_key_id'] = httpApiKeyId;
      }

      if (pipelineId !== undefined) {
        localVarQueryParameter['pipeline_id'] = pipelineId;
      }

      if (pipelineStepId !== undefined) {
        localVarQueryParameter['pipeline_step_id'] = pipelineStepId;
      }

      if (pipelineNotificationId !== undefined) {
        localVarQueryParameter['pipeline_notification_id'] = pipelineNotificationId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(dataIn, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PipelineExecutionsApi - functional programming interface
 * @export
 */
export const PipelineExecutionsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = PipelineExecutionsApiAxiosParamCreator(configuration);
  return {
    /**
     * Create a pipeline execution.
     * @summary Create Pipeline Execution
     * @param {PipelineExecutionBase} pipelineExecutionBase
     * @param {string} [instanceId]
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createPipelineExecution(
      pipelineExecutionBase: PipelineExecutionBase,
      instanceId?: string,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PipelineExecution>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createPipelineExecution(
        pipelineExecutionBase,
        instanceId,
        httpApiKeyId,
        pipelineId,
        pipelineStepId,
        pipelineExecutionId,
        pipelineNotificationId,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['PipelineExecutionsApi.createPipelineExecution']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Delete a pipeline execution.
     * @summary Delete Pipeline Execution
     * @param {string} pipelineExecutionId
     * @param {string} [instanceId]
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineNotificationId]
     * @param {DataIn | null} [dataIn]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deletePipelineExecution(
      pipelineExecutionId: string,
      instanceId?: string,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineNotificationId?: string,
      dataIn?: DataIn | null,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deletePipelineExecution(
        pipelineExecutionId,
        instanceId,
        httpApiKeyId,
        pipelineId,
        pipelineStepId,
        pipelineNotificationId,
        dataIn,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['PipelineExecutionsApi.deletePipelineExecution']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Average Execution Time
     * @param {PipelineAverageExecutionTimeRequestBody} pipelineAverageExecutionTimeRequestBody
     * @param {string} [instanceId]
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAverageExecutionTime(
      pipelineAverageExecutionTimeRequestBody: PipelineAverageExecutionTimeRequestBody,
      instanceId?: string,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: number }>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAverageExecutionTime(
        pipelineAverageExecutionTimeRequestBody,
        instanceId,
        httpApiKeyId,
        pipelineId,
        pipelineStepId,
        pipelineExecutionId,
        pipelineNotificationId,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['PipelineExecutionsApi.getAverageExecutionTime']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Get a pipeline execution by id.
     * @summary Get Pipeline Execution
     * @param {string} pipelineExecutionId
     * @param {string} [instanceId]
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineNotificationId]
     * @param {DataIn | null} [dataIn]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPipelineExecution(
      pipelineExecutionId: string,
      instanceId?: string,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineNotificationId?: string,
      dataIn?: DataIn | null,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PipelineExecutionOutput>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPipelineExecution(
        pipelineExecutionId,
        instanceId,
        httpApiKeyId,
        pipelineId,
        pipelineStepId,
        pipelineNotificationId,
        dataIn,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['PipelineExecutionsApi.getPipelineExecution']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Get pipeline execution step full log presigned url
     * @summary Get Pipeline Execution Step Full Log Presigned Url
     * @param {string} pipelineExecutionId
     * @param {string} fullLogPath
     * @param {string} [instanceId]
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineNotificationId]
     * @param {DataIn | null} [dataIn]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPipelineExecutionStepFullLogPresignedUrl(
      pipelineExecutionId: string,
      fullLogPath: string,
      instanceId?: string,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineNotificationId?: string,
      dataIn?: DataIn | null,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPipelineExecutionStepFullLogPresignedUrl(
        pipelineExecutionId,
        fullLogPath,
        instanceId,
        httpApiKeyId,
        pipelineId,
        pipelineStepId,
        pipelineNotificationId,
        dataIn,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['PipelineExecutionsApi.getPipelineExecutionStepFullLogPresignedUrl']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Get all pipeline executions satisfying the specified filtering parameters.
     * @summary Get Pipeline Executions
     * @param {PipelineExecutionRequestBody} pipelineExecutionRequestBody
     * @param {string} [instanceId]
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPipelineExecutions(
      pipelineExecutionRequestBody: PipelineExecutionRequestBody,
      instanceId?: string,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PipelineExecutionOutput>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPipelineExecutions(
        pipelineExecutionRequestBody,
        instanceId,
        httpApiKeyId,
        pipelineId,
        pipelineStepId,
        pipelineExecutionId,
        pipelineNotificationId,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['PipelineExecutionsApi.getPipelineExecutions']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Stop a pipeline execution.
     * @summary Stop Pipeline Execution
     * @param {string} pipelineExecutionId
     * @param {string} [instanceId]
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineNotificationId]
     * @param {DataIn | null} [dataIn]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async stopPipelineExecution(
      pipelineExecutionId: string,
      instanceId?: string,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineNotificationId?: string,
      dataIn?: DataIn | null,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PipelineExecutionOutput>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.stopPipelineExecution(
        pipelineExecutionId,
        instanceId,
        httpApiKeyId,
        pipelineId,
        pipelineStepId,
        pipelineNotificationId,
        dataIn,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['PipelineExecutionsApi.stopPipelineExecution']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * PipelineExecutionsApi - factory interface
 * @export
 */
export const PipelineExecutionsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = PipelineExecutionsApiFp(configuration);
  return {
    /**
     * Create a pipeline execution.
     * @summary Create Pipeline Execution
     * @param {PipelineExecutionsApiCreatePipelineExecutionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPipelineExecution(
      requestParameters: PipelineExecutionsApiCreatePipelineExecutionRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<PipelineExecution> {
      return localVarFp
        .createPipelineExecution(
          requestParameters.pipelineExecutionBase,
          requestParameters.instanceId,
          requestParameters.httpApiKeyId,
          requestParameters.pipelineId,
          requestParameters.pipelineStepId,
          requestParameters.pipelineExecutionId,
          requestParameters.pipelineNotificationId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Delete a pipeline execution.
     * @summary Delete Pipeline Execution
     * @param {PipelineExecutionsApiDeletePipelineExecutionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePipelineExecution(
      requestParameters: PipelineExecutionsApiDeletePipelineExecutionRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .deletePipelineExecution(
          requestParameters.pipelineExecutionId,
          requestParameters.instanceId,
          requestParameters.httpApiKeyId,
          requestParameters.pipelineId,
          requestParameters.pipelineStepId,
          requestParameters.pipelineNotificationId,
          requestParameters.dataIn,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Average Execution Time
     * @param {PipelineExecutionsApiGetAverageExecutionTimeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAverageExecutionTime(
      requestParameters: PipelineExecutionsApiGetAverageExecutionTimeRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<{ [key: string]: number }> {
      return localVarFp
        .getAverageExecutionTime(
          requestParameters.pipelineAverageExecutionTimeRequestBody,
          requestParameters.instanceId,
          requestParameters.httpApiKeyId,
          requestParameters.pipelineId,
          requestParameters.pipelineStepId,
          requestParameters.pipelineExecutionId,
          requestParameters.pipelineNotificationId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Get a pipeline execution by id.
     * @summary Get Pipeline Execution
     * @param {PipelineExecutionsApiGetPipelineExecutionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPipelineExecution(
      requestParameters: PipelineExecutionsApiGetPipelineExecutionRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<PipelineExecutionOutput> {
      return localVarFp
        .getPipelineExecution(
          requestParameters.pipelineExecutionId,
          requestParameters.instanceId,
          requestParameters.httpApiKeyId,
          requestParameters.pipelineId,
          requestParameters.pipelineStepId,
          requestParameters.pipelineNotificationId,
          requestParameters.dataIn,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Get pipeline execution step full log presigned url
     * @summary Get Pipeline Execution Step Full Log Presigned Url
     * @param {PipelineExecutionsApiGetPipelineExecutionStepFullLogPresignedUrlRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPipelineExecutionStepFullLogPresignedUrl(
      requestParameters: PipelineExecutionsApiGetPipelineExecutionStepFullLogPresignedUrlRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .getPipelineExecutionStepFullLogPresignedUrl(
          requestParameters.pipelineExecutionId,
          requestParameters.fullLogPath,
          requestParameters.instanceId,
          requestParameters.httpApiKeyId,
          requestParameters.pipelineId,
          requestParameters.pipelineStepId,
          requestParameters.pipelineNotificationId,
          requestParameters.dataIn,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Get all pipeline executions satisfying the specified filtering parameters.
     * @summary Get Pipeline Executions
     * @param {PipelineExecutionsApiGetPipelineExecutionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPipelineExecutions(
      requestParameters: PipelineExecutionsApiGetPipelineExecutionsRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<PipelineExecutionOutput>> {
      return localVarFp
        .getPipelineExecutions(
          requestParameters.pipelineExecutionRequestBody,
          requestParameters.instanceId,
          requestParameters.httpApiKeyId,
          requestParameters.pipelineId,
          requestParameters.pipelineStepId,
          requestParameters.pipelineExecutionId,
          requestParameters.pipelineNotificationId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Stop a pipeline execution.
     * @summary Stop Pipeline Execution
     * @param {PipelineExecutionsApiStopPipelineExecutionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stopPipelineExecution(
      requestParameters: PipelineExecutionsApiStopPipelineExecutionRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<PipelineExecutionOutput> {
      return localVarFp
        .stopPipelineExecution(
          requestParameters.pipelineExecutionId,
          requestParameters.instanceId,
          requestParameters.httpApiKeyId,
          requestParameters.pipelineId,
          requestParameters.pipelineStepId,
          requestParameters.pipelineNotificationId,
          requestParameters.dataIn,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for createPipelineExecution operation in PipelineExecutionsApi.
 * @export
 * @interface PipelineExecutionsApiCreatePipelineExecutionRequest
 */
export interface PipelineExecutionsApiCreatePipelineExecutionRequest {
  /**
   *
   * @type {PipelineExecutionBase}
   * @memberof PipelineExecutionsApiCreatePipelineExecution
   */
  readonly pipelineExecutionBase: PipelineExecutionBase;

  /**
   *
   * @type {string}
   * @memberof PipelineExecutionsApiCreatePipelineExecution
   */
  readonly instanceId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineExecutionsApiCreatePipelineExecution
   */
  readonly httpApiKeyId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineExecutionsApiCreatePipelineExecution
   */
  readonly pipelineId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineExecutionsApiCreatePipelineExecution
   */
  readonly pipelineStepId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineExecutionsApiCreatePipelineExecution
   */
  readonly pipelineExecutionId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineExecutionsApiCreatePipelineExecution
   */
  readonly pipelineNotificationId?: string;
}

/**
 * Request parameters for deletePipelineExecution operation in PipelineExecutionsApi.
 * @export
 * @interface PipelineExecutionsApiDeletePipelineExecutionRequest
 */
export interface PipelineExecutionsApiDeletePipelineExecutionRequest {
  /**
   *
   * @type {string}
   * @memberof PipelineExecutionsApiDeletePipelineExecution
   */
  readonly pipelineExecutionId: string;

  /**
   *
   * @type {string}
   * @memberof PipelineExecutionsApiDeletePipelineExecution
   */
  readonly instanceId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineExecutionsApiDeletePipelineExecution
   */
  readonly httpApiKeyId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineExecutionsApiDeletePipelineExecution
   */
  readonly pipelineId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineExecutionsApiDeletePipelineExecution
   */
  readonly pipelineStepId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineExecutionsApiDeletePipelineExecution
   */
  readonly pipelineNotificationId?: string;

  /**
   *
   * @type {DataIn}
   * @memberof PipelineExecutionsApiDeletePipelineExecution
   */
  readonly dataIn?: DataIn | null;
}

/**
 * Request parameters for getAverageExecutionTime operation in PipelineExecutionsApi.
 * @export
 * @interface PipelineExecutionsApiGetAverageExecutionTimeRequest
 */
export interface PipelineExecutionsApiGetAverageExecutionTimeRequest {
  /**
   *
   * @type {PipelineAverageExecutionTimeRequestBody}
   * @memberof PipelineExecutionsApiGetAverageExecutionTime
   */
  readonly pipelineAverageExecutionTimeRequestBody: PipelineAverageExecutionTimeRequestBody;

  /**
   *
   * @type {string}
   * @memberof PipelineExecutionsApiGetAverageExecutionTime
   */
  readonly instanceId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineExecutionsApiGetAverageExecutionTime
   */
  readonly httpApiKeyId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineExecutionsApiGetAverageExecutionTime
   */
  readonly pipelineId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineExecutionsApiGetAverageExecutionTime
   */
  readonly pipelineStepId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineExecutionsApiGetAverageExecutionTime
   */
  readonly pipelineExecutionId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineExecutionsApiGetAverageExecutionTime
   */
  readonly pipelineNotificationId?: string;
}

/**
 * Request parameters for getPipelineExecution operation in PipelineExecutionsApi.
 * @export
 * @interface PipelineExecutionsApiGetPipelineExecutionRequest
 */
export interface PipelineExecutionsApiGetPipelineExecutionRequest {
  /**
   *
   * @type {string}
   * @memberof PipelineExecutionsApiGetPipelineExecution
   */
  readonly pipelineExecutionId: string;

  /**
   *
   * @type {string}
   * @memberof PipelineExecutionsApiGetPipelineExecution
   */
  readonly instanceId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineExecutionsApiGetPipelineExecution
   */
  readonly httpApiKeyId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineExecutionsApiGetPipelineExecution
   */
  readonly pipelineId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineExecutionsApiGetPipelineExecution
   */
  readonly pipelineStepId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineExecutionsApiGetPipelineExecution
   */
  readonly pipelineNotificationId?: string;

  /**
   *
   * @type {DataIn}
   * @memberof PipelineExecutionsApiGetPipelineExecution
   */
  readonly dataIn?: DataIn | null;
}

/**
 * Request parameters for getPipelineExecutionStepFullLogPresignedUrl operation in PipelineExecutionsApi.
 * @export
 * @interface PipelineExecutionsApiGetPipelineExecutionStepFullLogPresignedUrlRequest
 */
export interface PipelineExecutionsApiGetPipelineExecutionStepFullLogPresignedUrlRequest {
  /**
   *
   * @type {string}
   * @memberof PipelineExecutionsApiGetPipelineExecutionStepFullLogPresignedUrl
   */
  readonly pipelineExecutionId: string;

  /**
   *
   * @type {string}
   * @memberof PipelineExecutionsApiGetPipelineExecutionStepFullLogPresignedUrl
   */
  readonly fullLogPath: string;

  /**
   *
   * @type {string}
   * @memberof PipelineExecutionsApiGetPipelineExecutionStepFullLogPresignedUrl
   */
  readonly instanceId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineExecutionsApiGetPipelineExecutionStepFullLogPresignedUrl
   */
  readonly httpApiKeyId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineExecutionsApiGetPipelineExecutionStepFullLogPresignedUrl
   */
  readonly pipelineId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineExecutionsApiGetPipelineExecutionStepFullLogPresignedUrl
   */
  readonly pipelineStepId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineExecutionsApiGetPipelineExecutionStepFullLogPresignedUrl
   */
  readonly pipelineNotificationId?: string;

  /**
   *
   * @type {DataIn}
   * @memberof PipelineExecutionsApiGetPipelineExecutionStepFullLogPresignedUrl
   */
  readonly dataIn?: DataIn | null;
}

/**
 * Request parameters for getPipelineExecutions operation in PipelineExecutionsApi.
 * @export
 * @interface PipelineExecutionsApiGetPipelineExecutionsRequest
 */
export interface PipelineExecutionsApiGetPipelineExecutionsRequest {
  /**
   *
   * @type {PipelineExecutionRequestBody}
   * @memberof PipelineExecutionsApiGetPipelineExecutions
   */
  readonly pipelineExecutionRequestBody: PipelineExecutionRequestBody;

  /**
   *
   * @type {string}
   * @memberof PipelineExecutionsApiGetPipelineExecutions
   */
  readonly instanceId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineExecutionsApiGetPipelineExecutions
   */
  readonly httpApiKeyId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineExecutionsApiGetPipelineExecutions
   */
  readonly pipelineId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineExecutionsApiGetPipelineExecutions
   */
  readonly pipelineStepId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineExecutionsApiGetPipelineExecutions
   */
  readonly pipelineExecutionId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineExecutionsApiGetPipelineExecutions
   */
  readonly pipelineNotificationId?: string;
}

/**
 * Request parameters for stopPipelineExecution operation in PipelineExecutionsApi.
 * @export
 * @interface PipelineExecutionsApiStopPipelineExecutionRequest
 */
export interface PipelineExecutionsApiStopPipelineExecutionRequest {
  /**
   *
   * @type {string}
   * @memberof PipelineExecutionsApiStopPipelineExecution
   */
  readonly pipelineExecutionId: string;

  /**
   *
   * @type {string}
   * @memberof PipelineExecutionsApiStopPipelineExecution
   */
  readonly instanceId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineExecutionsApiStopPipelineExecution
   */
  readonly httpApiKeyId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineExecutionsApiStopPipelineExecution
   */
  readonly pipelineId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineExecutionsApiStopPipelineExecution
   */
  readonly pipelineStepId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineExecutionsApiStopPipelineExecution
   */
  readonly pipelineNotificationId?: string;

  /**
   *
   * @type {DataIn}
   * @memberof PipelineExecutionsApiStopPipelineExecution
   */
  readonly dataIn?: DataIn | null;
}

/**
 * PipelineExecutionsApi - object-oriented interface
 * @export
 * @class PipelineExecutionsApi
 * @extends {BaseAPI}
 */
export class PipelineExecutionsApi extends BaseAPI {
  /**
   * Create a pipeline execution.
   * @summary Create Pipeline Execution
   * @param {PipelineExecutionsApiCreatePipelineExecutionRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PipelineExecutionsApi
   */
  public createPipelineExecution(
    requestParameters: PipelineExecutionsApiCreatePipelineExecutionRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return PipelineExecutionsApiFp(this.configuration)
      .createPipelineExecution(
        requestParameters.pipelineExecutionBase,
        requestParameters.instanceId,
        requestParameters.httpApiKeyId,
        requestParameters.pipelineId,
        requestParameters.pipelineStepId,
        requestParameters.pipelineExecutionId,
        requestParameters.pipelineNotificationId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Delete a pipeline execution.
   * @summary Delete Pipeline Execution
   * @param {PipelineExecutionsApiDeletePipelineExecutionRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PipelineExecutionsApi
   */
  public deletePipelineExecution(
    requestParameters: PipelineExecutionsApiDeletePipelineExecutionRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return PipelineExecutionsApiFp(this.configuration)
      .deletePipelineExecution(
        requestParameters.pipelineExecutionId,
        requestParameters.instanceId,
        requestParameters.httpApiKeyId,
        requestParameters.pipelineId,
        requestParameters.pipelineStepId,
        requestParameters.pipelineNotificationId,
        requestParameters.dataIn,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Average Execution Time
   * @param {PipelineExecutionsApiGetAverageExecutionTimeRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PipelineExecutionsApi
   */
  public getAverageExecutionTime(
    requestParameters: PipelineExecutionsApiGetAverageExecutionTimeRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return PipelineExecutionsApiFp(this.configuration)
      .getAverageExecutionTime(
        requestParameters.pipelineAverageExecutionTimeRequestBody,
        requestParameters.instanceId,
        requestParameters.httpApiKeyId,
        requestParameters.pipelineId,
        requestParameters.pipelineStepId,
        requestParameters.pipelineExecutionId,
        requestParameters.pipelineNotificationId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get a pipeline execution by id.
   * @summary Get Pipeline Execution
   * @param {PipelineExecutionsApiGetPipelineExecutionRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PipelineExecutionsApi
   */
  public getPipelineExecution(
    requestParameters: PipelineExecutionsApiGetPipelineExecutionRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return PipelineExecutionsApiFp(this.configuration)
      .getPipelineExecution(
        requestParameters.pipelineExecutionId,
        requestParameters.instanceId,
        requestParameters.httpApiKeyId,
        requestParameters.pipelineId,
        requestParameters.pipelineStepId,
        requestParameters.pipelineNotificationId,
        requestParameters.dataIn,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get pipeline execution step full log presigned url
   * @summary Get Pipeline Execution Step Full Log Presigned Url
   * @param {PipelineExecutionsApiGetPipelineExecutionStepFullLogPresignedUrlRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PipelineExecutionsApi
   */
  public getPipelineExecutionStepFullLogPresignedUrl(
    requestParameters: PipelineExecutionsApiGetPipelineExecutionStepFullLogPresignedUrlRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return PipelineExecutionsApiFp(this.configuration)
      .getPipelineExecutionStepFullLogPresignedUrl(
        requestParameters.pipelineExecutionId,
        requestParameters.fullLogPath,
        requestParameters.instanceId,
        requestParameters.httpApiKeyId,
        requestParameters.pipelineId,
        requestParameters.pipelineStepId,
        requestParameters.pipelineNotificationId,
        requestParameters.dataIn,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get all pipeline executions satisfying the specified filtering parameters.
   * @summary Get Pipeline Executions
   * @param {PipelineExecutionsApiGetPipelineExecutionsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PipelineExecutionsApi
   */
  public getPipelineExecutions(
    requestParameters: PipelineExecutionsApiGetPipelineExecutionsRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return PipelineExecutionsApiFp(this.configuration)
      .getPipelineExecutions(
        requestParameters.pipelineExecutionRequestBody,
        requestParameters.instanceId,
        requestParameters.httpApiKeyId,
        requestParameters.pipelineId,
        requestParameters.pipelineStepId,
        requestParameters.pipelineExecutionId,
        requestParameters.pipelineNotificationId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Stop a pipeline execution.
   * @summary Stop Pipeline Execution
   * @param {PipelineExecutionsApiStopPipelineExecutionRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PipelineExecutionsApi
   */
  public stopPipelineExecution(
    requestParameters: PipelineExecutionsApiStopPipelineExecutionRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return PipelineExecutionsApiFp(this.configuration)
      .stopPipelineExecution(
        requestParameters.pipelineExecutionId,
        requestParameters.instanceId,
        requestParameters.httpApiKeyId,
        requestParameters.pipelineId,
        requestParameters.pipelineStepId,
        requestParameters.pipelineNotificationId,
        requestParameters.dataIn,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * PipelineNotificationsApi - axios parameter creator
 * @export
 */
export const PipelineNotificationsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Create a pipeline notification for current user.
     * @summary Create Pipeline Notification
     * @param {PipelineNotificationCreate} pipelineNotificationCreate
     * @param {string} [instanceId]
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPipelineNotification: async (
      pipelineNotificationCreate: PipelineNotificationCreate,
      instanceId?: string,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pipelineNotificationCreate' is not null or undefined
      assertParamExists('createPipelineNotification', 'pipelineNotificationCreate', pipelineNotificationCreate);
      const localVarPath = `/api/v1/pipeline-notifications/user`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (instanceId !== undefined) {
        localVarQueryParameter['instance_id'] = instanceId;
      }

      if (httpApiKeyId !== undefined) {
        localVarQueryParameter['http_api_key_id'] = httpApiKeyId;
      }

      if (pipelineId !== undefined) {
        localVarQueryParameter['pipeline_id'] = pipelineId;
      }

      if (pipelineStepId !== undefined) {
        localVarQueryParameter['pipeline_step_id'] = pipelineStepId;
      }

      if (pipelineExecutionId !== undefined) {
        localVarQueryParameter['pipeline_execution_id'] = pipelineExecutionId;
      }

      if (pipelineNotificationId !== undefined) {
        localVarQueryParameter['pipeline_notification_id'] = pipelineNotificationId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        pipelineNotificationCreate,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Delete a pipeline notification.
     * @summary Delete Pipeline Notification
     * @param {string} pipelineNotificationId
     * @param {string} [instanceId]
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {DataIn | null} [dataIn]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePipelineNotification: async (
      pipelineNotificationId: string,
      instanceId?: string,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      dataIn?: DataIn | null,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pipelineNotificationId' is not null or undefined
      assertParamExists('deletePipelineNotification', 'pipelineNotificationId', pipelineNotificationId);
      const localVarPath = `/api/v1/pipeline-notifications/user/{pipeline_notification_id}`.replace(
        `{${'pipeline_notification_id'}}`,
        encodeURIComponent(String(pipelineNotificationId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (instanceId !== undefined) {
        localVarQueryParameter['instance_id'] = instanceId;
      }

      if (httpApiKeyId !== undefined) {
        localVarQueryParameter['http_api_key_id'] = httpApiKeyId;
      }

      if (pipelineId !== undefined) {
        localVarQueryParameter['pipeline_id'] = pipelineId;
      }

      if (pipelineStepId !== undefined) {
        localVarQueryParameter['pipeline_step_id'] = pipelineStepId;
      }

      if (pipelineExecutionId !== undefined) {
        localVarQueryParameter['pipeline_execution_id'] = pipelineExecutionId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(dataIn, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get a pipeline notification by pipeline_id.
     * @summary Get Pipeline Notification By Pipeline Id
     * @param {string} pipelineId
     * @param {string} [instanceId]
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {DataIn | null} [dataIn]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPipelineNotificationForUserByPipelineId: async (
      pipelineId: string,
      instanceId?: string,
      httpApiKeyId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      dataIn?: DataIn | null,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pipelineId' is not null or undefined
      assertParamExists('getPipelineNotificationForUserByPipelineId', 'pipelineId', pipelineId);
      const localVarPath = `/api/v1/pipeline-notifications/user`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (pipelineId !== undefined) {
        localVarQueryParameter['pipeline_id'] = pipelineId;
      }

      if (instanceId !== undefined) {
        localVarQueryParameter['instance_id'] = instanceId;
      }

      if (httpApiKeyId !== undefined) {
        localVarQueryParameter['http_api_key_id'] = httpApiKeyId;
      }

      if (pipelineStepId !== undefined) {
        localVarQueryParameter['pipeline_step_id'] = pipelineStepId;
      }

      if (pipelineExecutionId !== undefined) {
        localVarQueryParameter['pipeline_execution_id'] = pipelineExecutionId;
      }

      if (pipelineNotificationId !== undefined) {
        localVarQueryParameter['pipeline_notification_id'] = pipelineNotificationId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(dataIn, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get a pipeline notification by id.
     * @summary Get Pipeline Notification
     * @param {string} pipelineNotificationId
     * @param {string} [instanceId]
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {DataIn | null} [dataIn]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPipelineNotificationForUserByPipelineNotificationId: async (
      pipelineNotificationId: string,
      instanceId?: string,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      dataIn?: DataIn | null,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pipelineNotificationId' is not null or undefined
      assertParamExists(
        'getPipelineNotificationForUserByPipelineNotificationId',
        'pipelineNotificationId',
        pipelineNotificationId,
      );
      const localVarPath = `/api/v1/pipeline-notifications/user/{pipeline_notification_id}`.replace(
        `{${'pipeline_notification_id'}}`,
        encodeURIComponent(String(pipelineNotificationId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (instanceId !== undefined) {
        localVarQueryParameter['instance_id'] = instanceId;
      }

      if (httpApiKeyId !== undefined) {
        localVarQueryParameter['http_api_key_id'] = httpApiKeyId;
      }

      if (pipelineId !== undefined) {
        localVarQueryParameter['pipeline_id'] = pipelineId;
      }

      if (pipelineStepId !== undefined) {
        localVarQueryParameter['pipeline_step_id'] = pipelineStepId;
      }

      if (pipelineExecutionId !== undefined) {
        localVarQueryParameter['pipeline_execution_id'] = pipelineExecutionId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(dataIn, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get a pipeline notification by pipeline_id.
     * @summary Get Pipeline Notifications
     * @param {FSMState} pipelineExecutionState
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineId]
     * @param {DataIn | null} [dataIn]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPipelineNotificationListByPipelineId: async (
      pipelineExecutionState: FSMState,
      pipelineStepId?: string,
      pipelineId?: string,
      dataIn?: DataIn | null,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pipelineExecutionState' is not null or undefined
      assertParamExists('getPipelineNotificationListByPipelineId', 'pipelineExecutionState', pipelineExecutionState);
      const localVarPath = `/api_internal/v1/pipeline-notifications`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (pipelineExecutionState !== undefined) {
        localVarQueryParameter['pipeline_execution_state'] = pipelineExecutionState;
      }

      if (pipelineStepId !== undefined) {
        localVarQueryParameter['pipeline_step_id'] = pipelineStepId;
      }

      if (pipelineId !== undefined) {
        localVarQueryParameter['pipeline_id'] = pipelineId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(dataIn, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get pipeline notifications with email_type ==  EmailType.service
     * @summary Get Pipeline Service Notifications
     * @param {string} [pipelineId]
     * @param {string} [instanceId]
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {DataIn | null} [dataIn]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPipelineServiceNotifications: async (
      pipelineId?: string,
      instanceId?: string,
      httpApiKeyId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      dataIn?: DataIn | null,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/pipeline-notifications/admin`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (pipelineId !== undefined) {
        localVarQueryParameter['pipeline_id'] = pipelineId;
      }

      if (instanceId !== undefined) {
        localVarQueryParameter['instance_id'] = instanceId;
      }

      if (httpApiKeyId !== undefined) {
        localVarQueryParameter['http_api_key_id'] = httpApiKeyId;
      }

      if (pipelineStepId !== undefined) {
        localVarQueryParameter['pipeline_step_id'] = pipelineStepId;
      }

      if (pipelineExecutionId !== undefined) {
        localVarQueryParameter['pipeline_execution_id'] = pipelineExecutionId;
      }

      if (pipelineNotificationId !== undefined) {
        localVarQueryParameter['pipeline_notification_id'] = pipelineNotificationId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(dataIn, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update a pipeline notification.
     * @summary Update Pipeline Notification
     * @param {string} pipelineNotificationId
     * @param {PipelineNotificationUpdate} pipelineNotificationUpdate
     * @param {string} [instanceId]
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePipelineNotification: async (
      pipelineNotificationId: string,
      pipelineNotificationUpdate: PipelineNotificationUpdate,
      instanceId?: string,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pipelineNotificationId' is not null or undefined
      assertParamExists('updatePipelineNotification', 'pipelineNotificationId', pipelineNotificationId);
      // verify required parameter 'pipelineNotificationUpdate' is not null or undefined
      assertParamExists('updatePipelineNotification', 'pipelineNotificationUpdate', pipelineNotificationUpdate);
      const localVarPath = `/api/v1/pipeline-notifications/user/{pipeline_notification_id}`.replace(
        `{${'pipeline_notification_id'}}`,
        encodeURIComponent(String(pipelineNotificationId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (instanceId !== undefined) {
        localVarQueryParameter['instance_id'] = instanceId;
      }

      if (httpApiKeyId !== undefined) {
        localVarQueryParameter['http_api_key_id'] = httpApiKeyId;
      }

      if (pipelineId !== undefined) {
        localVarQueryParameter['pipeline_id'] = pipelineId;
      }

      if (pipelineStepId !== undefined) {
        localVarQueryParameter['pipeline_step_id'] = pipelineStepId;
      }

      if (pipelineExecutionId !== undefined) {
        localVarQueryParameter['pipeline_execution_id'] = pipelineExecutionId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        pipelineNotificationUpdate,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update pipeline notification subscribers list
     * @summary Update Pipeline Service Notifications
     * @param {PipelineNotificationList} pipelineNotificationList
     * @param {string} [instanceId]
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePipelineServiceNotifications: async (
      pipelineNotificationList: PipelineNotificationList,
      instanceId?: string,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pipelineNotificationList' is not null or undefined
      assertParamExists('updatePipelineServiceNotifications', 'pipelineNotificationList', pipelineNotificationList);
      const localVarPath = `/api/v1/pipeline-notifications/admin`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (instanceId !== undefined) {
        localVarQueryParameter['instance_id'] = instanceId;
      }

      if (httpApiKeyId !== undefined) {
        localVarQueryParameter['http_api_key_id'] = httpApiKeyId;
      }

      if (pipelineId !== undefined) {
        localVarQueryParameter['pipeline_id'] = pipelineId;
      }

      if (pipelineStepId !== undefined) {
        localVarQueryParameter['pipeline_step_id'] = pipelineStepId;
      }

      if (pipelineExecutionId !== undefined) {
        localVarQueryParameter['pipeline_execution_id'] = pipelineExecutionId;
      }

      if (pipelineNotificationId !== undefined) {
        localVarQueryParameter['pipeline_notification_id'] = pipelineNotificationId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        pipelineNotificationList,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PipelineNotificationsApi - functional programming interface
 * @export
 */
export const PipelineNotificationsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = PipelineNotificationsApiAxiosParamCreator(configuration);
  return {
    /**
     * Create a pipeline notification for current user.
     * @summary Create Pipeline Notification
     * @param {PipelineNotificationCreate} pipelineNotificationCreate
     * @param {string} [instanceId]
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createPipelineNotification(
      pipelineNotificationCreate: PipelineNotificationCreate,
      instanceId?: string,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PipelineNotification>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createPipelineNotification(
        pipelineNotificationCreate,
        instanceId,
        httpApiKeyId,
        pipelineId,
        pipelineStepId,
        pipelineExecutionId,
        pipelineNotificationId,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['PipelineNotificationsApi.createPipelineNotification']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Delete a pipeline notification.
     * @summary Delete Pipeline Notification
     * @param {string} pipelineNotificationId
     * @param {string} [instanceId]
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {DataIn | null} [dataIn]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deletePipelineNotification(
      pipelineNotificationId: string,
      instanceId?: string,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      dataIn?: DataIn | null,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deletePipelineNotification(
        pipelineNotificationId,
        instanceId,
        httpApiKeyId,
        pipelineId,
        pipelineStepId,
        pipelineExecutionId,
        dataIn,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['PipelineNotificationsApi.deletePipelineNotification']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Get a pipeline notification by pipeline_id.
     * @summary Get Pipeline Notification By Pipeline Id
     * @param {string} pipelineId
     * @param {string} [instanceId]
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {DataIn | null} [dataIn]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPipelineNotificationForUserByPipelineId(
      pipelineId: string,
      instanceId?: string,
      httpApiKeyId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      dataIn?: DataIn | null,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PipelineNotification>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPipelineNotificationForUserByPipelineId(
        pipelineId,
        instanceId,
        httpApiKeyId,
        pipelineStepId,
        pipelineExecutionId,
        pipelineNotificationId,
        dataIn,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['PipelineNotificationsApi.getPipelineNotificationForUserByPipelineId']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Get a pipeline notification by id.
     * @summary Get Pipeline Notification
     * @param {string} pipelineNotificationId
     * @param {string} [instanceId]
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {DataIn | null} [dataIn]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPipelineNotificationForUserByPipelineNotificationId(
      pipelineNotificationId: string,
      instanceId?: string,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      dataIn?: DataIn | null,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PipelineNotification>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPipelineNotificationForUserByPipelineNotificationId(
        pipelineNotificationId,
        instanceId,
        httpApiKeyId,
        pipelineId,
        pipelineStepId,
        pipelineExecutionId,
        dataIn,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['PipelineNotificationsApi.getPipelineNotificationForUserByPipelineNotificationId']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Get a pipeline notification by pipeline_id.
     * @summary Get Pipeline Notifications
     * @param {FSMState} pipelineExecutionState
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineId]
     * @param {DataIn | null} [dataIn]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPipelineNotificationListByPipelineId(
      pipelineExecutionState: FSMState,
      pipelineStepId?: string,
      pipelineId?: string,
      dataIn?: DataIn | null,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PipelineNotification>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPipelineNotificationListByPipelineId(
        pipelineExecutionState,
        pipelineStepId,
        pipelineId,
        dataIn,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['PipelineNotificationsApi.getPipelineNotificationListByPipelineId']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Get pipeline notifications with email_type ==  EmailType.service
     * @summary Get Pipeline Service Notifications
     * @param {string} [pipelineId]
     * @param {string} [instanceId]
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {DataIn | null} [dataIn]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPipelineServiceNotifications(
      pipelineId?: string,
      instanceId?: string,
      httpApiKeyId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      dataIn?: DataIn | null,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PipelineNotification>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPipelineServiceNotifications(
        pipelineId,
        instanceId,
        httpApiKeyId,
        pipelineStepId,
        pipelineExecutionId,
        pipelineNotificationId,
        dataIn,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['PipelineNotificationsApi.getPipelineServiceNotifications']?.[localVarOperationServerIndex]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Update a pipeline notification.
     * @summary Update Pipeline Notification
     * @param {string} pipelineNotificationId
     * @param {PipelineNotificationUpdate} pipelineNotificationUpdate
     * @param {string} [instanceId]
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updatePipelineNotification(
      pipelineNotificationId: string,
      pipelineNotificationUpdate: PipelineNotificationUpdate,
      instanceId?: string,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PipelineNotification>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updatePipelineNotification(
        pipelineNotificationId,
        pipelineNotificationUpdate,
        instanceId,
        httpApiKeyId,
        pipelineId,
        pipelineStepId,
        pipelineExecutionId,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['PipelineNotificationsApi.updatePipelineNotification']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Update pipeline notification subscribers list
     * @summary Update Pipeline Service Notifications
     * @param {PipelineNotificationList} pipelineNotificationList
     * @param {string} [instanceId]
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updatePipelineServiceNotifications(
      pipelineNotificationList: PipelineNotificationList,
      instanceId?: string,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updatePipelineServiceNotifications(
        pipelineNotificationList,
        instanceId,
        httpApiKeyId,
        pipelineId,
        pipelineStepId,
        pipelineExecutionId,
        pipelineNotificationId,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['PipelineNotificationsApi.updatePipelineServiceNotifications']?.[
          localVarOperationServerIndex
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * PipelineNotificationsApi - factory interface
 * @export
 */
export const PipelineNotificationsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = PipelineNotificationsApiFp(configuration);
  return {
    /**
     * Create a pipeline notification for current user.
     * @summary Create Pipeline Notification
     * @param {PipelineNotificationsApiCreatePipelineNotificationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPipelineNotification(
      requestParameters: PipelineNotificationsApiCreatePipelineNotificationRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<PipelineNotification> {
      return localVarFp
        .createPipelineNotification(
          requestParameters.pipelineNotificationCreate,
          requestParameters.instanceId,
          requestParameters.httpApiKeyId,
          requestParameters.pipelineId,
          requestParameters.pipelineStepId,
          requestParameters.pipelineExecutionId,
          requestParameters.pipelineNotificationId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Delete a pipeline notification.
     * @summary Delete Pipeline Notification
     * @param {PipelineNotificationsApiDeletePipelineNotificationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePipelineNotification(
      requestParameters: PipelineNotificationsApiDeletePipelineNotificationRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .deletePipelineNotification(
          requestParameters.pipelineNotificationId,
          requestParameters.instanceId,
          requestParameters.httpApiKeyId,
          requestParameters.pipelineId,
          requestParameters.pipelineStepId,
          requestParameters.pipelineExecutionId,
          requestParameters.dataIn,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Get a pipeline notification by pipeline_id.
     * @summary Get Pipeline Notification By Pipeline Id
     * @param {PipelineNotificationsApiGetPipelineNotificationForUserByPipelineIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPipelineNotificationForUserByPipelineId(
      requestParameters: PipelineNotificationsApiGetPipelineNotificationForUserByPipelineIdRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<PipelineNotification> {
      return localVarFp
        .getPipelineNotificationForUserByPipelineId(
          requestParameters.pipelineId,
          requestParameters.instanceId,
          requestParameters.httpApiKeyId,
          requestParameters.pipelineStepId,
          requestParameters.pipelineExecutionId,
          requestParameters.pipelineNotificationId,
          requestParameters.dataIn,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Get a pipeline notification by id.
     * @summary Get Pipeline Notification
     * @param {PipelineNotificationsApiGetPipelineNotificationForUserByPipelineNotificationIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPipelineNotificationForUserByPipelineNotificationId(
      requestParameters: PipelineNotificationsApiGetPipelineNotificationForUserByPipelineNotificationIdRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<PipelineNotification> {
      return localVarFp
        .getPipelineNotificationForUserByPipelineNotificationId(
          requestParameters.pipelineNotificationId,
          requestParameters.instanceId,
          requestParameters.httpApiKeyId,
          requestParameters.pipelineId,
          requestParameters.pipelineStepId,
          requestParameters.pipelineExecutionId,
          requestParameters.dataIn,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Get a pipeline notification by pipeline_id.
     * @summary Get Pipeline Notifications
     * @param {PipelineNotificationsApiGetPipelineNotificationListByPipelineIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPipelineNotificationListByPipelineId(
      requestParameters: PipelineNotificationsApiGetPipelineNotificationListByPipelineIdRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<PipelineNotification>> {
      return localVarFp
        .getPipelineNotificationListByPipelineId(
          requestParameters.pipelineExecutionState,
          requestParameters.pipelineStepId,
          requestParameters.pipelineId,
          requestParameters.dataIn,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Get pipeline notifications with email_type ==  EmailType.service
     * @summary Get Pipeline Service Notifications
     * @param {PipelineNotificationsApiGetPipelineServiceNotificationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPipelineServiceNotifications(
      requestParameters: PipelineNotificationsApiGetPipelineServiceNotificationsRequest = {},
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<PipelineNotification>> {
      return localVarFp
        .getPipelineServiceNotifications(
          requestParameters.pipelineId,
          requestParameters.instanceId,
          requestParameters.httpApiKeyId,
          requestParameters.pipelineStepId,
          requestParameters.pipelineExecutionId,
          requestParameters.pipelineNotificationId,
          requestParameters.dataIn,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Update a pipeline notification.
     * @summary Update Pipeline Notification
     * @param {PipelineNotificationsApiUpdatePipelineNotificationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePipelineNotification(
      requestParameters: PipelineNotificationsApiUpdatePipelineNotificationRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<PipelineNotification> {
      return localVarFp
        .updatePipelineNotification(
          requestParameters.pipelineNotificationId,
          requestParameters.pipelineNotificationUpdate,
          requestParameters.instanceId,
          requestParameters.httpApiKeyId,
          requestParameters.pipelineId,
          requestParameters.pipelineStepId,
          requestParameters.pipelineExecutionId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Update pipeline notification subscribers list
     * @summary Update Pipeline Service Notifications
     * @param {PipelineNotificationsApiUpdatePipelineServiceNotificationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePipelineServiceNotifications(
      requestParameters: PipelineNotificationsApiUpdatePipelineServiceNotificationsRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .updatePipelineServiceNotifications(
          requestParameters.pipelineNotificationList,
          requestParameters.instanceId,
          requestParameters.httpApiKeyId,
          requestParameters.pipelineId,
          requestParameters.pipelineStepId,
          requestParameters.pipelineExecutionId,
          requestParameters.pipelineNotificationId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for createPipelineNotification operation in PipelineNotificationsApi.
 * @export
 * @interface PipelineNotificationsApiCreatePipelineNotificationRequest
 */
export interface PipelineNotificationsApiCreatePipelineNotificationRequest {
  /**
   *
   * @type {PipelineNotificationCreate}
   * @memberof PipelineNotificationsApiCreatePipelineNotification
   */
  readonly pipelineNotificationCreate: PipelineNotificationCreate;

  /**
   *
   * @type {string}
   * @memberof PipelineNotificationsApiCreatePipelineNotification
   */
  readonly instanceId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineNotificationsApiCreatePipelineNotification
   */
  readonly httpApiKeyId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineNotificationsApiCreatePipelineNotification
   */
  readonly pipelineId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineNotificationsApiCreatePipelineNotification
   */
  readonly pipelineStepId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineNotificationsApiCreatePipelineNotification
   */
  readonly pipelineExecutionId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineNotificationsApiCreatePipelineNotification
   */
  readonly pipelineNotificationId?: string;
}

/**
 * Request parameters for deletePipelineNotification operation in PipelineNotificationsApi.
 * @export
 * @interface PipelineNotificationsApiDeletePipelineNotificationRequest
 */
export interface PipelineNotificationsApiDeletePipelineNotificationRequest {
  /**
   *
   * @type {string}
   * @memberof PipelineNotificationsApiDeletePipelineNotification
   */
  readonly pipelineNotificationId: string;

  /**
   *
   * @type {string}
   * @memberof PipelineNotificationsApiDeletePipelineNotification
   */
  readonly instanceId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineNotificationsApiDeletePipelineNotification
   */
  readonly httpApiKeyId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineNotificationsApiDeletePipelineNotification
   */
  readonly pipelineId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineNotificationsApiDeletePipelineNotification
   */
  readonly pipelineStepId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineNotificationsApiDeletePipelineNotification
   */
  readonly pipelineExecutionId?: string;

  /**
   *
   * @type {DataIn}
   * @memberof PipelineNotificationsApiDeletePipelineNotification
   */
  readonly dataIn?: DataIn | null;
}

/**
 * Request parameters for getPipelineNotificationForUserByPipelineId operation in PipelineNotificationsApi.
 * @export
 * @interface PipelineNotificationsApiGetPipelineNotificationForUserByPipelineIdRequest
 */
export interface PipelineNotificationsApiGetPipelineNotificationForUserByPipelineIdRequest {
  /**
   *
   * @type {string}
   * @memberof PipelineNotificationsApiGetPipelineNotificationForUserByPipelineId
   */
  readonly pipelineId: string;

  /**
   *
   * @type {string}
   * @memberof PipelineNotificationsApiGetPipelineNotificationForUserByPipelineId
   */
  readonly instanceId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineNotificationsApiGetPipelineNotificationForUserByPipelineId
   */
  readonly httpApiKeyId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineNotificationsApiGetPipelineNotificationForUserByPipelineId
   */
  readonly pipelineStepId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineNotificationsApiGetPipelineNotificationForUserByPipelineId
   */
  readonly pipelineExecutionId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineNotificationsApiGetPipelineNotificationForUserByPipelineId
   */
  readonly pipelineNotificationId?: string;

  /**
   *
   * @type {DataIn}
   * @memberof PipelineNotificationsApiGetPipelineNotificationForUserByPipelineId
   */
  readonly dataIn?: DataIn | null;
}

/**
 * Request parameters for getPipelineNotificationForUserByPipelineNotificationId operation in PipelineNotificationsApi.
 * @export
 * @interface PipelineNotificationsApiGetPipelineNotificationForUserByPipelineNotificationIdRequest
 */
export interface PipelineNotificationsApiGetPipelineNotificationForUserByPipelineNotificationIdRequest {
  /**
   *
   * @type {string}
   * @memberof PipelineNotificationsApiGetPipelineNotificationForUserByPipelineNotificationId
   */
  readonly pipelineNotificationId: string;

  /**
   *
   * @type {string}
   * @memberof PipelineNotificationsApiGetPipelineNotificationForUserByPipelineNotificationId
   */
  readonly instanceId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineNotificationsApiGetPipelineNotificationForUserByPipelineNotificationId
   */
  readonly httpApiKeyId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineNotificationsApiGetPipelineNotificationForUserByPipelineNotificationId
   */
  readonly pipelineId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineNotificationsApiGetPipelineNotificationForUserByPipelineNotificationId
   */
  readonly pipelineStepId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineNotificationsApiGetPipelineNotificationForUserByPipelineNotificationId
   */
  readonly pipelineExecutionId?: string;

  /**
   *
   * @type {DataIn}
   * @memberof PipelineNotificationsApiGetPipelineNotificationForUserByPipelineNotificationId
   */
  readonly dataIn?: DataIn | null;
}

/**
 * Request parameters for getPipelineNotificationListByPipelineId operation in PipelineNotificationsApi.
 * @export
 * @interface PipelineNotificationsApiGetPipelineNotificationListByPipelineIdRequest
 */
export interface PipelineNotificationsApiGetPipelineNotificationListByPipelineIdRequest {
  /**
   *
   * @type {FSMState}
   * @memberof PipelineNotificationsApiGetPipelineNotificationListByPipelineId
   */
  readonly pipelineExecutionState: FSMState;

  /**
   *
   * @type {string}
   * @memberof PipelineNotificationsApiGetPipelineNotificationListByPipelineId
   */
  readonly pipelineStepId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineNotificationsApiGetPipelineNotificationListByPipelineId
   */
  readonly pipelineId?: string;

  /**
   *
   * @type {DataIn}
   * @memberof PipelineNotificationsApiGetPipelineNotificationListByPipelineId
   */
  readonly dataIn?: DataIn | null;
}

/**
 * Request parameters for getPipelineServiceNotifications operation in PipelineNotificationsApi.
 * @export
 * @interface PipelineNotificationsApiGetPipelineServiceNotificationsRequest
 */
export interface PipelineNotificationsApiGetPipelineServiceNotificationsRequest {
  /**
   *
   * @type {string}
   * @memberof PipelineNotificationsApiGetPipelineServiceNotifications
   */
  readonly pipelineId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineNotificationsApiGetPipelineServiceNotifications
   */
  readonly instanceId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineNotificationsApiGetPipelineServiceNotifications
   */
  readonly httpApiKeyId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineNotificationsApiGetPipelineServiceNotifications
   */
  readonly pipelineStepId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineNotificationsApiGetPipelineServiceNotifications
   */
  readonly pipelineExecutionId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineNotificationsApiGetPipelineServiceNotifications
   */
  readonly pipelineNotificationId?: string;

  /**
   *
   * @type {DataIn}
   * @memberof PipelineNotificationsApiGetPipelineServiceNotifications
   */
  readonly dataIn?: DataIn | null;
}

/**
 * Request parameters for updatePipelineNotification operation in PipelineNotificationsApi.
 * @export
 * @interface PipelineNotificationsApiUpdatePipelineNotificationRequest
 */
export interface PipelineNotificationsApiUpdatePipelineNotificationRequest {
  /**
   *
   * @type {string}
   * @memberof PipelineNotificationsApiUpdatePipelineNotification
   */
  readonly pipelineNotificationId: string;

  /**
   *
   * @type {PipelineNotificationUpdate}
   * @memberof PipelineNotificationsApiUpdatePipelineNotification
   */
  readonly pipelineNotificationUpdate: PipelineNotificationUpdate;

  /**
   *
   * @type {string}
   * @memberof PipelineNotificationsApiUpdatePipelineNotification
   */
  readonly instanceId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineNotificationsApiUpdatePipelineNotification
   */
  readonly httpApiKeyId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineNotificationsApiUpdatePipelineNotification
   */
  readonly pipelineId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineNotificationsApiUpdatePipelineNotification
   */
  readonly pipelineStepId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineNotificationsApiUpdatePipelineNotification
   */
  readonly pipelineExecutionId?: string;
}

/**
 * Request parameters for updatePipelineServiceNotifications operation in PipelineNotificationsApi.
 * @export
 * @interface PipelineNotificationsApiUpdatePipelineServiceNotificationsRequest
 */
export interface PipelineNotificationsApiUpdatePipelineServiceNotificationsRequest {
  /**
   *
   * @type {PipelineNotificationList}
   * @memberof PipelineNotificationsApiUpdatePipelineServiceNotifications
   */
  readonly pipelineNotificationList: PipelineNotificationList;

  /**
   *
   * @type {string}
   * @memberof PipelineNotificationsApiUpdatePipelineServiceNotifications
   */
  readonly instanceId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineNotificationsApiUpdatePipelineServiceNotifications
   */
  readonly httpApiKeyId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineNotificationsApiUpdatePipelineServiceNotifications
   */
  readonly pipelineId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineNotificationsApiUpdatePipelineServiceNotifications
   */
  readonly pipelineStepId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineNotificationsApiUpdatePipelineServiceNotifications
   */
  readonly pipelineExecutionId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineNotificationsApiUpdatePipelineServiceNotifications
   */
  readonly pipelineNotificationId?: string;
}

/**
 * PipelineNotificationsApi - object-oriented interface
 * @export
 * @class PipelineNotificationsApi
 * @extends {BaseAPI}
 */
export class PipelineNotificationsApi extends BaseAPI {
  /**
   * Create a pipeline notification for current user.
   * @summary Create Pipeline Notification
   * @param {PipelineNotificationsApiCreatePipelineNotificationRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PipelineNotificationsApi
   */
  public createPipelineNotification(
    requestParameters: PipelineNotificationsApiCreatePipelineNotificationRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return PipelineNotificationsApiFp(this.configuration)
      .createPipelineNotification(
        requestParameters.pipelineNotificationCreate,
        requestParameters.instanceId,
        requestParameters.httpApiKeyId,
        requestParameters.pipelineId,
        requestParameters.pipelineStepId,
        requestParameters.pipelineExecutionId,
        requestParameters.pipelineNotificationId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Delete a pipeline notification.
   * @summary Delete Pipeline Notification
   * @param {PipelineNotificationsApiDeletePipelineNotificationRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PipelineNotificationsApi
   */
  public deletePipelineNotification(
    requestParameters: PipelineNotificationsApiDeletePipelineNotificationRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return PipelineNotificationsApiFp(this.configuration)
      .deletePipelineNotification(
        requestParameters.pipelineNotificationId,
        requestParameters.instanceId,
        requestParameters.httpApiKeyId,
        requestParameters.pipelineId,
        requestParameters.pipelineStepId,
        requestParameters.pipelineExecutionId,
        requestParameters.dataIn,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get a pipeline notification by pipeline_id.
   * @summary Get Pipeline Notification By Pipeline Id
   * @param {PipelineNotificationsApiGetPipelineNotificationForUserByPipelineIdRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PipelineNotificationsApi
   */
  public getPipelineNotificationForUserByPipelineId(
    requestParameters: PipelineNotificationsApiGetPipelineNotificationForUserByPipelineIdRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return PipelineNotificationsApiFp(this.configuration)
      .getPipelineNotificationForUserByPipelineId(
        requestParameters.pipelineId,
        requestParameters.instanceId,
        requestParameters.httpApiKeyId,
        requestParameters.pipelineStepId,
        requestParameters.pipelineExecutionId,
        requestParameters.pipelineNotificationId,
        requestParameters.dataIn,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get a pipeline notification by id.
   * @summary Get Pipeline Notification
   * @param {PipelineNotificationsApiGetPipelineNotificationForUserByPipelineNotificationIdRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PipelineNotificationsApi
   */
  public getPipelineNotificationForUserByPipelineNotificationId(
    requestParameters: PipelineNotificationsApiGetPipelineNotificationForUserByPipelineNotificationIdRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return PipelineNotificationsApiFp(this.configuration)
      .getPipelineNotificationForUserByPipelineNotificationId(
        requestParameters.pipelineNotificationId,
        requestParameters.instanceId,
        requestParameters.httpApiKeyId,
        requestParameters.pipelineId,
        requestParameters.pipelineStepId,
        requestParameters.pipelineExecutionId,
        requestParameters.dataIn,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get a pipeline notification by pipeline_id.
   * @summary Get Pipeline Notifications
   * @param {PipelineNotificationsApiGetPipelineNotificationListByPipelineIdRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PipelineNotificationsApi
   */
  public getPipelineNotificationListByPipelineId(
    requestParameters: PipelineNotificationsApiGetPipelineNotificationListByPipelineIdRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return PipelineNotificationsApiFp(this.configuration)
      .getPipelineNotificationListByPipelineId(
        requestParameters.pipelineExecutionState,
        requestParameters.pipelineStepId,
        requestParameters.pipelineId,
        requestParameters.dataIn,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get pipeline notifications with email_type ==  EmailType.service
   * @summary Get Pipeline Service Notifications
   * @param {PipelineNotificationsApiGetPipelineServiceNotificationsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PipelineNotificationsApi
   */
  public getPipelineServiceNotifications(
    requestParameters: PipelineNotificationsApiGetPipelineServiceNotificationsRequest = {},
    options?: RawAxiosRequestConfig,
  ) {
    return PipelineNotificationsApiFp(this.configuration)
      .getPipelineServiceNotifications(
        requestParameters.pipelineId,
        requestParameters.instanceId,
        requestParameters.httpApiKeyId,
        requestParameters.pipelineStepId,
        requestParameters.pipelineExecutionId,
        requestParameters.pipelineNotificationId,
        requestParameters.dataIn,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update a pipeline notification.
   * @summary Update Pipeline Notification
   * @param {PipelineNotificationsApiUpdatePipelineNotificationRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PipelineNotificationsApi
   */
  public updatePipelineNotification(
    requestParameters: PipelineNotificationsApiUpdatePipelineNotificationRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return PipelineNotificationsApiFp(this.configuration)
      .updatePipelineNotification(
        requestParameters.pipelineNotificationId,
        requestParameters.pipelineNotificationUpdate,
        requestParameters.instanceId,
        requestParameters.httpApiKeyId,
        requestParameters.pipelineId,
        requestParameters.pipelineStepId,
        requestParameters.pipelineExecutionId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update pipeline notification subscribers list
   * @summary Update Pipeline Service Notifications
   * @param {PipelineNotificationsApiUpdatePipelineServiceNotificationsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PipelineNotificationsApi
   */
  public updatePipelineServiceNotifications(
    requestParameters: PipelineNotificationsApiUpdatePipelineServiceNotificationsRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return PipelineNotificationsApiFp(this.configuration)
      .updatePipelineServiceNotifications(
        requestParameters.pipelineNotificationList,
        requestParameters.instanceId,
        requestParameters.httpApiKeyId,
        requestParameters.pipelineId,
        requestParameters.pipelineStepId,
        requestParameters.pipelineExecutionId,
        requestParameters.pipelineNotificationId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * PipelineStepsApi - axios parameter creator
 * @export
 */
export const PipelineStepsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Create a pipeline step.
     * @summary Create Pipeline Step
     * @param {PipelineStepCreate} pipelineStepCreate
     * @param {string} [instanceId]
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPipelineStep: async (
      pipelineStepCreate: PipelineStepCreate,
      instanceId?: string,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pipelineStepCreate' is not null or undefined
      assertParamExists('createPipelineStep', 'pipelineStepCreate', pipelineStepCreate);
      const localVarPath = `/api/v1/pipeline-steps`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (instanceId !== undefined) {
        localVarQueryParameter['instance_id'] = instanceId;
      }

      if (httpApiKeyId !== undefined) {
        localVarQueryParameter['http_api_key_id'] = httpApiKeyId;
      }

      if (pipelineId !== undefined) {
        localVarQueryParameter['pipeline_id'] = pipelineId;
      }

      if (pipelineStepId !== undefined) {
        localVarQueryParameter['pipeline_step_id'] = pipelineStepId;
      }

      if (pipelineExecutionId !== undefined) {
        localVarQueryParameter['pipeline_execution_id'] = pipelineExecutionId;
      }

      if (pipelineNotificationId !== undefined) {
        localVarQueryParameter['pipeline_notification_id'] = pipelineNotificationId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(pipelineStepCreate, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Delete a pipeline step.
     * @summary Delete Pipeline Step
     * @param {string} pipelineStepId
     * @param {string} [instanceId]
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {DataIn | null} [dataIn]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePipelineStep: async (
      pipelineStepId: string,
      instanceId?: string,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      dataIn?: DataIn | null,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pipelineStepId' is not null or undefined
      assertParamExists('deletePipelineStep', 'pipelineStepId', pipelineStepId);
      const localVarPath = `/api/v1/pipeline-steps/{pipeline_step_id}`.replace(
        `{${'pipeline_step_id'}}`,
        encodeURIComponent(String(pipelineStepId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (instanceId !== undefined) {
        localVarQueryParameter['instance_id'] = instanceId;
      }

      if (httpApiKeyId !== undefined) {
        localVarQueryParameter['http_api_key_id'] = httpApiKeyId;
      }

      if (pipelineId !== undefined) {
        localVarQueryParameter['pipeline_id'] = pipelineId;
      }

      if (pipelineExecutionId !== undefined) {
        localVarQueryParameter['pipeline_execution_id'] = pipelineExecutionId;
      }

      if (pipelineNotificationId !== undefined) {
        localVarQueryParameter['pipeline_notification_id'] = pipelineNotificationId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(dataIn, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get a pipeline step by id.
     * @summary Get Pipeline Step Endpoint
     * @param {string} pipelineStepId
     * @param {string} [instanceId]
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {DataIn | null} [dataIn]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPipelineStep: async (
      pipelineStepId: string,
      instanceId?: string,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      dataIn?: DataIn | null,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pipelineStepId' is not null or undefined
      assertParamExists('getPipelineStep', 'pipelineStepId', pipelineStepId);
      const localVarPath = `/api/v1/pipeline-steps/{pipeline_step_id}`.replace(
        `{${'pipeline_step_id'}}`,
        encodeURIComponent(String(pipelineStepId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (instanceId !== undefined) {
        localVarQueryParameter['instance_id'] = instanceId;
      }

      if (httpApiKeyId !== undefined) {
        localVarQueryParameter['http_api_key_id'] = httpApiKeyId;
      }

      if (pipelineId !== undefined) {
        localVarQueryParameter['pipeline_id'] = pipelineId;
      }

      if (pipelineExecutionId !== undefined) {
        localVarQueryParameter['pipeline_execution_id'] = pipelineExecutionId;
      }

      if (pipelineNotificationId !== undefined) {
        localVarQueryParameter['pipeline_notification_id'] = pipelineNotificationId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(dataIn, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get all pipeline steps satisfying the specified filtering parameters.
     * @summary Get Pipeline Steps
     * @param {string} pipelineId
     * @param {string} [instanceId]
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {PipelineStepType} [type]
     * @param {number} [limit]
     * @param {number} [skip]
     * @param {DataIn | null} [dataIn]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPipelineSteps: async (
      pipelineId: string,
      instanceId?: string,
      httpApiKeyId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      type?: PipelineStepType,
      limit?: number,
      skip?: number,
      dataIn?: DataIn | null,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pipelineId' is not null or undefined
      assertParamExists('getPipelineSteps', 'pipelineId', pipelineId);
      const localVarPath = `/api/v1/pipeline-steps`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (instanceId !== undefined) {
        localVarQueryParameter['instance_id'] = instanceId;
      }

      if (httpApiKeyId !== undefined) {
        localVarQueryParameter['http_api_key_id'] = httpApiKeyId;
      }

      if (pipelineId !== undefined) {
        localVarQueryParameter['pipeline_id'] = pipelineId;
      }

      if (pipelineStepId !== undefined) {
        localVarQueryParameter['pipeline_step_id'] = pipelineStepId;
      }

      if (pipelineExecutionId !== undefined) {
        localVarQueryParameter['pipeline_execution_id'] = pipelineExecutionId;
      }

      if (pipelineNotificationId !== undefined) {
        localVarQueryParameter['pipeline_notification_id'] = pipelineNotificationId;
      }

      if (type !== undefined) {
        localVarQueryParameter['type'] = type;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (skip !== undefined) {
        localVarQueryParameter['skip'] = skip;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(dataIn, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Move a pipeline step.
     * @summary Move Pipeline Step
     * @param {string} pipelineStepId
     * @param {PipelineStepMove} pipelineStepMove
     * @param {string} [instanceId]
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    movePipelineStep: async (
      pipelineStepId: string,
      pipelineStepMove: PipelineStepMove,
      instanceId?: string,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pipelineStepId' is not null or undefined
      assertParamExists('movePipelineStep', 'pipelineStepId', pipelineStepId);
      // verify required parameter 'pipelineStepMove' is not null or undefined
      assertParamExists('movePipelineStep', 'pipelineStepMove', pipelineStepMove);
      const localVarPath = `/api/v1/pipeline-steps/{pipeline_step_id}/move`.replace(
        `{${'pipeline_step_id'}}`,
        encodeURIComponent(String(pipelineStepId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (instanceId !== undefined) {
        localVarQueryParameter['instance_id'] = instanceId;
      }

      if (httpApiKeyId !== undefined) {
        localVarQueryParameter['http_api_key_id'] = httpApiKeyId;
      }

      if (pipelineId !== undefined) {
        localVarQueryParameter['pipeline_id'] = pipelineId;
      }

      if (pipelineExecutionId !== undefined) {
        localVarQueryParameter['pipeline_execution_id'] = pipelineExecutionId;
      }

      if (pipelineNotificationId !== undefined) {
        localVarQueryParameter['pipeline_notification_id'] = pipelineNotificationId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(pipelineStepMove, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update a pipeline step.
     * @summary Update Pipeline Steps
     * @param {string} pipelineStepId
     * @param {PipelineStepUpdate} pipelineStepUpdate
     * @param {string} [instanceId]
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePipelineStep: async (
      pipelineStepId: string,
      pipelineStepUpdate: PipelineStepUpdate,
      instanceId?: string,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pipelineStepId' is not null or undefined
      assertParamExists('updatePipelineStep', 'pipelineStepId', pipelineStepId);
      // verify required parameter 'pipelineStepUpdate' is not null or undefined
      assertParamExists('updatePipelineStep', 'pipelineStepUpdate', pipelineStepUpdate);
      const localVarPath = `/api/v1/pipeline-steps/{pipeline_step_id}`.replace(
        `{${'pipeline_step_id'}}`,
        encodeURIComponent(String(pipelineStepId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (instanceId !== undefined) {
        localVarQueryParameter['instance_id'] = instanceId;
      }

      if (httpApiKeyId !== undefined) {
        localVarQueryParameter['http_api_key_id'] = httpApiKeyId;
      }

      if (pipelineId !== undefined) {
        localVarQueryParameter['pipeline_id'] = pipelineId;
      }

      if (pipelineExecutionId !== undefined) {
        localVarQueryParameter['pipeline_execution_id'] = pipelineExecutionId;
      }

      if (pipelineNotificationId !== undefined) {
        localVarQueryParameter['pipeline_notification_id'] = pipelineNotificationId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(pipelineStepUpdate, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PipelineStepsApi - functional programming interface
 * @export
 */
export const PipelineStepsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = PipelineStepsApiAxiosParamCreator(configuration);
  return {
    /**
     * Create a pipeline step.
     * @summary Create Pipeline Step
     * @param {PipelineStepCreate} pipelineStepCreate
     * @param {string} [instanceId]
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createPipelineStep(
      pipelineStepCreate: PipelineStepCreate,
      instanceId?: string,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PipelineStep>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createPipelineStep(
        pipelineStepCreate,
        instanceId,
        httpApiKeyId,
        pipelineId,
        pipelineStepId,
        pipelineExecutionId,
        pipelineNotificationId,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['PipelineStepsApi.createPipelineStep']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Delete a pipeline step.
     * @summary Delete Pipeline Step
     * @param {string} pipelineStepId
     * @param {string} [instanceId]
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {DataIn | null} [dataIn]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deletePipelineStep(
      pipelineStepId: string,
      instanceId?: string,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      dataIn?: DataIn | null,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deletePipelineStep(
        pipelineStepId,
        instanceId,
        httpApiKeyId,
        pipelineId,
        pipelineExecutionId,
        pipelineNotificationId,
        dataIn,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['PipelineStepsApi.deletePipelineStep']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Get a pipeline step by id.
     * @summary Get Pipeline Step Endpoint
     * @param {string} pipelineStepId
     * @param {string} [instanceId]
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {DataIn | null} [dataIn]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPipelineStep(
      pipelineStepId: string,
      instanceId?: string,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      dataIn?: DataIn | null,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PipelineStep>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPipelineStep(
        pipelineStepId,
        instanceId,
        httpApiKeyId,
        pipelineId,
        pipelineExecutionId,
        pipelineNotificationId,
        dataIn,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['PipelineStepsApi.getPipelineStep']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Get all pipeline steps satisfying the specified filtering parameters.
     * @summary Get Pipeline Steps
     * @param {string} pipelineId
     * @param {string} [instanceId]
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {PipelineStepType} [type]
     * @param {number} [limit]
     * @param {number} [skip]
     * @param {DataIn | null} [dataIn]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPipelineSteps(
      pipelineId: string,
      instanceId?: string,
      httpApiKeyId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      type?: PipelineStepType,
      limit?: number,
      skip?: number,
      dataIn?: DataIn | null,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PipelineStep>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPipelineSteps(
        pipelineId,
        instanceId,
        httpApiKeyId,
        pipelineStepId,
        pipelineExecutionId,
        pipelineNotificationId,
        type,
        limit,
        skip,
        dataIn,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['PipelineStepsApi.getPipelineSteps']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Move a pipeline step.
     * @summary Move Pipeline Step
     * @param {string} pipelineStepId
     * @param {PipelineStepMove} pipelineStepMove
     * @param {string} [instanceId]
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async movePipelineStep(
      pipelineStepId: string,
      pipelineStepMove: PipelineStepMove,
      instanceId?: string,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.movePipelineStep(
        pipelineStepId,
        pipelineStepMove,
        instanceId,
        httpApiKeyId,
        pipelineId,
        pipelineExecutionId,
        pipelineNotificationId,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['PipelineStepsApi.movePipelineStep']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Update a pipeline step.
     * @summary Update Pipeline Steps
     * @param {string} pipelineStepId
     * @param {PipelineStepUpdate} pipelineStepUpdate
     * @param {string} [instanceId]
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updatePipelineStep(
      pipelineStepId: string,
      pipelineStepUpdate: PipelineStepUpdate,
      instanceId?: string,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PipelineStep>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updatePipelineStep(
        pipelineStepId,
        pipelineStepUpdate,
        instanceId,
        httpApiKeyId,
        pipelineId,
        pipelineExecutionId,
        pipelineNotificationId,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['PipelineStepsApi.updatePipelineStep']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * PipelineStepsApi - factory interface
 * @export
 */
export const PipelineStepsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = PipelineStepsApiFp(configuration);
  return {
    /**
     * Create a pipeline step.
     * @summary Create Pipeline Step
     * @param {PipelineStepsApiCreatePipelineStepRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPipelineStep(
      requestParameters: PipelineStepsApiCreatePipelineStepRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<PipelineStep> {
      return localVarFp
        .createPipelineStep(
          requestParameters.pipelineStepCreate,
          requestParameters.instanceId,
          requestParameters.httpApiKeyId,
          requestParameters.pipelineId,
          requestParameters.pipelineStepId,
          requestParameters.pipelineExecutionId,
          requestParameters.pipelineNotificationId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Delete a pipeline step.
     * @summary Delete Pipeline Step
     * @param {PipelineStepsApiDeletePipelineStepRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePipelineStep(
      requestParameters: PipelineStepsApiDeletePipelineStepRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .deletePipelineStep(
          requestParameters.pipelineStepId,
          requestParameters.instanceId,
          requestParameters.httpApiKeyId,
          requestParameters.pipelineId,
          requestParameters.pipelineExecutionId,
          requestParameters.pipelineNotificationId,
          requestParameters.dataIn,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Get a pipeline step by id.
     * @summary Get Pipeline Step Endpoint
     * @param {PipelineStepsApiGetPipelineStepRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPipelineStep(
      requestParameters: PipelineStepsApiGetPipelineStepRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<PipelineStep> {
      return localVarFp
        .getPipelineStep(
          requestParameters.pipelineStepId,
          requestParameters.instanceId,
          requestParameters.httpApiKeyId,
          requestParameters.pipelineId,
          requestParameters.pipelineExecutionId,
          requestParameters.pipelineNotificationId,
          requestParameters.dataIn,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Get all pipeline steps satisfying the specified filtering parameters.
     * @summary Get Pipeline Steps
     * @param {PipelineStepsApiGetPipelineStepsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPipelineSteps(
      requestParameters: PipelineStepsApiGetPipelineStepsRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<PipelineStep>> {
      return localVarFp
        .getPipelineSteps(
          requestParameters.pipelineId,
          requestParameters.instanceId,
          requestParameters.httpApiKeyId,
          requestParameters.pipelineStepId,
          requestParameters.pipelineExecutionId,
          requestParameters.pipelineNotificationId,
          requestParameters.type,
          requestParameters.limit,
          requestParameters.skip,
          requestParameters.dataIn,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Move a pipeline step.
     * @summary Move Pipeline Step
     * @param {PipelineStepsApiMovePipelineStepRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    movePipelineStep(
      requestParameters: PipelineStepsApiMovePipelineStepRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .movePipelineStep(
          requestParameters.pipelineStepId,
          requestParameters.pipelineStepMove,
          requestParameters.instanceId,
          requestParameters.httpApiKeyId,
          requestParameters.pipelineId,
          requestParameters.pipelineExecutionId,
          requestParameters.pipelineNotificationId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Update a pipeline step.
     * @summary Update Pipeline Steps
     * @param {PipelineStepsApiUpdatePipelineStepRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePipelineStep(
      requestParameters: PipelineStepsApiUpdatePipelineStepRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<PipelineStep> {
      return localVarFp
        .updatePipelineStep(
          requestParameters.pipelineStepId,
          requestParameters.pipelineStepUpdate,
          requestParameters.instanceId,
          requestParameters.httpApiKeyId,
          requestParameters.pipelineId,
          requestParameters.pipelineExecutionId,
          requestParameters.pipelineNotificationId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for createPipelineStep operation in PipelineStepsApi.
 * @export
 * @interface PipelineStepsApiCreatePipelineStepRequest
 */
export interface PipelineStepsApiCreatePipelineStepRequest {
  /**
   *
   * @type {PipelineStepCreate}
   * @memberof PipelineStepsApiCreatePipelineStep
   */
  readonly pipelineStepCreate: PipelineStepCreate;

  /**
   *
   * @type {string}
   * @memberof PipelineStepsApiCreatePipelineStep
   */
  readonly instanceId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineStepsApiCreatePipelineStep
   */
  readonly httpApiKeyId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineStepsApiCreatePipelineStep
   */
  readonly pipelineId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineStepsApiCreatePipelineStep
   */
  readonly pipelineStepId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineStepsApiCreatePipelineStep
   */
  readonly pipelineExecutionId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineStepsApiCreatePipelineStep
   */
  readonly pipelineNotificationId?: string;
}

/**
 * Request parameters for deletePipelineStep operation in PipelineStepsApi.
 * @export
 * @interface PipelineStepsApiDeletePipelineStepRequest
 */
export interface PipelineStepsApiDeletePipelineStepRequest {
  /**
   *
   * @type {string}
   * @memberof PipelineStepsApiDeletePipelineStep
   */
  readonly pipelineStepId: string;

  /**
   *
   * @type {string}
   * @memberof PipelineStepsApiDeletePipelineStep
   */
  readonly instanceId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineStepsApiDeletePipelineStep
   */
  readonly httpApiKeyId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineStepsApiDeletePipelineStep
   */
  readonly pipelineId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineStepsApiDeletePipelineStep
   */
  readonly pipelineExecutionId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineStepsApiDeletePipelineStep
   */
  readonly pipelineNotificationId?: string;

  /**
   *
   * @type {DataIn}
   * @memberof PipelineStepsApiDeletePipelineStep
   */
  readonly dataIn?: DataIn | null;
}

/**
 * Request parameters for getPipelineStep operation in PipelineStepsApi.
 * @export
 * @interface PipelineStepsApiGetPipelineStepRequest
 */
export interface PipelineStepsApiGetPipelineStepRequest {
  /**
   *
   * @type {string}
   * @memberof PipelineStepsApiGetPipelineStep
   */
  readonly pipelineStepId: string;

  /**
   *
   * @type {string}
   * @memberof PipelineStepsApiGetPipelineStep
   */
  readonly instanceId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineStepsApiGetPipelineStep
   */
  readonly httpApiKeyId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineStepsApiGetPipelineStep
   */
  readonly pipelineId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineStepsApiGetPipelineStep
   */
  readonly pipelineExecutionId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineStepsApiGetPipelineStep
   */
  readonly pipelineNotificationId?: string;

  /**
   *
   * @type {DataIn}
   * @memberof PipelineStepsApiGetPipelineStep
   */
  readonly dataIn?: DataIn | null;
}

/**
 * Request parameters for getPipelineSteps operation in PipelineStepsApi.
 * @export
 * @interface PipelineStepsApiGetPipelineStepsRequest
 */
export interface PipelineStepsApiGetPipelineStepsRequest {
  /**
   *
   * @type {string}
   * @memberof PipelineStepsApiGetPipelineSteps
   */
  readonly pipelineId: string;

  /**
   *
   * @type {string}
   * @memberof PipelineStepsApiGetPipelineSteps
   */
  readonly instanceId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineStepsApiGetPipelineSteps
   */
  readonly httpApiKeyId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineStepsApiGetPipelineSteps
   */
  readonly pipelineStepId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineStepsApiGetPipelineSteps
   */
  readonly pipelineExecutionId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineStepsApiGetPipelineSteps
   */
  readonly pipelineNotificationId?: string;

  /**
   *
   * @type {PipelineStepType}
   * @memberof PipelineStepsApiGetPipelineSteps
   */
  readonly type?: PipelineStepType;

  /**
   *
   * @type {number}
   * @memberof PipelineStepsApiGetPipelineSteps
   */
  readonly limit?: number;

  /**
   *
   * @type {number}
   * @memberof PipelineStepsApiGetPipelineSteps
   */
  readonly skip?: number;

  /**
   *
   * @type {DataIn}
   * @memberof PipelineStepsApiGetPipelineSteps
   */
  readonly dataIn?: DataIn | null;
}

/**
 * Request parameters for movePipelineStep operation in PipelineStepsApi.
 * @export
 * @interface PipelineStepsApiMovePipelineStepRequest
 */
export interface PipelineStepsApiMovePipelineStepRequest {
  /**
   *
   * @type {string}
   * @memberof PipelineStepsApiMovePipelineStep
   */
  readonly pipelineStepId: string;

  /**
   *
   * @type {PipelineStepMove}
   * @memberof PipelineStepsApiMovePipelineStep
   */
  readonly pipelineStepMove: PipelineStepMove;

  /**
   *
   * @type {string}
   * @memberof PipelineStepsApiMovePipelineStep
   */
  readonly instanceId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineStepsApiMovePipelineStep
   */
  readonly httpApiKeyId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineStepsApiMovePipelineStep
   */
  readonly pipelineId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineStepsApiMovePipelineStep
   */
  readonly pipelineExecutionId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineStepsApiMovePipelineStep
   */
  readonly pipelineNotificationId?: string;
}

/**
 * Request parameters for updatePipelineStep operation in PipelineStepsApi.
 * @export
 * @interface PipelineStepsApiUpdatePipelineStepRequest
 */
export interface PipelineStepsApiUpdatePipelineStepRequest {
  /**
   *
   * @type {string}
   * @memberof PipelineStepsApiUpdatePipelineStep
   */
  readonly pipelineStepId: string;

  /**
   *
   * @type {PipelineStepUpdate}
   * @memberof PipelineStepsApiUpdatePipelineStep
   */
  readonly pipelineStepUpdate: PipelineStepUpdate;

  /**
   *
   * @type {string}
   * @memberof PipelineStepsApiUpdatePipelineStep
   */
  readonly instanceId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineStepsApiUpdatePipelineStep
   */
  readonly httpApiKeyId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineStepsApiUpdatePipelineStep
   */
  readonly pipelineId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineStepsApiUpdatePipelineStep
   */
  readonly pipelineExecutionId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineStepsApiUpdatePipelineStep
   */
  readonly pipelineNotificationId?: string;
}

/**
 * PipelineStepsApi - object-oriented interface
 * @export
 * @class PipelineStepsApi
 * @extends {BaseAPI}
 */
export class PipelineStepsApi extends BaseAPI {
  /**
   * Create a pipeline step.
   * @summary Create Pipeline Step
   * @param {PipelineStepsApiCreatePipelineStepRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PipelineStepsApi
   */
  public createPipelineStep(
    requestParameters: PipelineStepsApiCreatePipelineStepRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return PipelineStepsApiFp(this.configuration)
      .createPipelineStep(
        requestParameters.pipelineStepCreate,
        requestParameters.instanceId,
        requestParameters.httpApiKeyId,
        requestParameters.pipelineId,
        requestParameters.pipelineStepId,
        requestParameters.pipelineExecutionId,
        requestParameters.pipelineNotificationId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Delete a pipeline step.
   * @summary Delete Pipeline Step
   * @param {PipelineStepsApiDeletePipelineStepRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PipelineStepsApi
   */
  public deletePipelineStep(
    requestParameters: PipelineStepsApiDeletePipelineStepRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return PipelineStepsApiFp(this.configuration)
      .deletePipelineStep(
        requestParameters.pipelineStepId,
        requestParameters.instanceId,
        requestParameters.httpApiKeyId,
        requestParameters.pipelineId,
        requestParameters.pipelineExecutionId,
        requestParameters.pipelineNotificationId,
        requestParameters.dataIn,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get a pipeline step by id.
   * @summary Get Pipeline Step Endpoint
   * @param {PipelineStepsApiGetPipelineStepRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PipelineStepsApi
   */
  public getPipelineStep(requestParameters: PipelineStepsApiGetPipelineStepRequest, options?: RawAxiosRequestConfig) {
    return PipelineStepsApiFp(this.configuration)
      .getPipelineStep(
        requestParameters.pipelineStepId,
        requestParameters.instanceId,
        requestParameters.httpApiKeyId,
        requestParameters.pipelineId,
        requestParameters.pipelineExecutionId,
        requestParameters.pipelineNotificationId,
        requestParameters.dataIn,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get all pipeline steps satisfying the specified filtering parameters.
   * @summary Get Pipeline Steps
   * @param {PipelineStepsApiGetPipelineStepsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PipelineStepsApi
   */
  public getPipelineSteps(requestParameters: PipelineStepsApiGetPipelineStepsRequest, options?: RawAxiosRequestConfig) {
    return PipelineStepsApiFp(this.configuration)
      .getPipelineSteps(
        requestParameters.pipelineId,
        requestParameters.instanceId,
        requestParameters.httpApiKeyId,
        requestParameters.pipelineStepId,
        requestParameters.pipelineExecutionId,
        requestParameters.pipelineNotificationId,
        requestParameters.type,
        requestParameters.limit,
        requestParameters.skip,
        requestParameters.dataIn,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Move a pipeline step.
   * @summary Move Pipeline Step
   * @param {PipelineStepsApiMovePipelineStepRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PipelineStepsApi
   */
  public movePipelineStep(requestParameters: PipelineStepsApiMovePipelineStepRequest, options?: RawAxiosRequestConfig) {
    return PipelineStepsApiFp(this.configuration)
      .movePipelineStep(
        requestParameters.pipelineStepId,
        requestParameters.pipelineStepMove,
        requestParameters.instanceId,
        requestParameters.httpApiKeyId,
        requestParameters.pipelineId,
        requestParameters.pipelineExecutionId,
        requestParameters.pipelineNotificationId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update a pipeline step.
   * @summary Update Pipeline Steps
   * @param {PipelineStepsApiUpdatePipelineStepRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PipelineStepsApi
   */
  public updatePipelineStep(
    requestParameters: PipelineStepsApiUpdatePipelineStepRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return PipelineStepsApiFp(this.configuration)
      .updatePipelineStep(
        requestParameters.pipelineStepId,
        requestParameters.pipelineStepUpdate,
        requestParameters.instanceId,
        requestParameters.httpApiKeyId,
        requestParameters.pipelineId,
        requestParameters.pipelineExecutionId,
        requestParameters.pipelineNotificationId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * PipelineTemplatesApi - axios parameter creator
 * @export
 */
export const PipelineTemplatesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Create a pipeline.
     * @summary Create Pipeline Template
     * @param {PipelineTemplateCreate} pipelineTemplateCreate
     * @param {string} [vfModuleId]
     * @param {string} [pipelineTemplateId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPipelineTemplate: async (
      pipelineTemplateCreate: PipelineTemplateCreate,
      vfModuleId?: string,
      pipelineTemplateId?: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pipelineTemplateCreate' is not null or undefined
      assertParamExists('createPipelineTemplate', 'pipelineTemplateCreate', pipelineTemplateCreate);
      const localVarPath = `/api/v1/pipeline-templates`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (vfModuleId !== undefined) {
        localVarQueryParameter['vf_module_id'] = vfModuleId;
      }

      if (pipelineTemplateId !== undefined) {
        localVarQueryParameter['pipeline_template_id'] = pipelineTemplateId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        pipelineTemplateCreate,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Delete a pipeline.
     * @summary Delete Pipeline Template
     * @param {string} pipelineTemplateId
     * @param {string} [vfModuleId]
     * @param {DataIn1 | null} [dataIn1]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePipelineTemplate: async (
      pipelineTemplateId: string,
      vfModuleId?: string,
      dataIn1?: DataIn1 | null,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pipelineTemplateId' is not null or undefined
      assertParamExists('deletePipelineTemplate', 'pipelineTemplateId', pipelineTemplateId);
      const localVarPath = `/api/v1/pipeline-templates/{pipeline_template_id}`.replace(
        `{${'pipeline_template_id'}}`,
        encodeURIComponent(String(pipelineTemplateId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (vfModuleId !== undefined) {
        localVarQueryParameter['vf_module_id'] = vfModuleId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(dataIn1, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get a pipeline template by id.
     * @summary Get Pipeline Template
     * @param {string} pipelineTemplateId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPipelineTemplate: async (
      pipelineTemplateId: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pipelineTemplateId' is not null or undefined
      assertParamExists('getPipelineTemplate', 'pipelineTemplateId', pipelineTemplateId);
      const localVarPath = `/api/v1/pipeline-templates/{pipeline_template_id}`.replace(
        `{${'pipeline_template_id'}}`,
        encodeURIComponent(String(pipelineTemplateId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get pipeline templates satisfies query filter
     * @summary Get Pipeline Templates
     * @param {Array<string>} [moduleId]
     * @param {Array<string>} [id]
     * @param {number} [limit]
     * @param {string} [lastEvaluatedKey]
     * @param {BodyGetPipelineTemplates} [bodyGetPipelineTemplates]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPipelineTemplates: async (
      moduleId?: Array<string>,
      id?: Array<string>,
      limit?: number,
      lastEvaluatedKey?: string,
      bodyGetPipelineTemplates?: BodyGetPipelineTemplates,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/pipeline-templates`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (moduleId) {
        localVarQueryParameter['module_id'] = moduleId;
      }

      if (id) {
        localVarQueryParameter['id'] = id;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (lastEvaluatedKey !== undefined) {
        localVarQueryParameter['last_evaluated_key'] = lastEvaluatedKey;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        bodyGetPipelineTemplates,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get pipeline templates satisfies query filter
     * @summary Get Pipeline Templates Short
     * @param {Array<string>} [moduleId]
     * @param {Array<string>} [id]
     * @param {number} [limit]
     * @param {string} [lastEvaluatedKey]
     * @param {BodyGetPipelineTemplatesShort} [bodyGetPipelineTemplatesShort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPipelineTemplatesShort: async (
      moduleId?: Array<string>,
      id?: Array<string>,
      limit?: number,
      lastEvaluatedKey?: string,
      bodyGetPipelineTemplatesShort?: BodyGetPipelineTemplatesShort,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/pipeline-templates/short`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (moduleId) {
        localVarQueryParameter['module_id'] = moduleId;
      }

      if (id) {
        localVarQueryParameter['id'] = id;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (lastEvaluatedKey !== undefined) {
        localVarQueryParameter['last_evaluated_key'] = lastEvaluatedKey;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        bodyGetPipelineTemplatesShort,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update a pipeline template.
     * @summary Update Pipeline Template
     * @param {string} pipelineTemplateId
     * @param {PipelineTemplateUpdate} pipelineTemplateUpdate
     * @param {string} [vfModuleId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePipelineTemplate: async (
      pipelineTemplateId: string,
      pipelineTemplateUpdate: PipelineTemplateUpdate,
      vfModuleId?: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pipelineTemplateId' is not null or undefined
      assertParamExists('updatePipelineTemplate', 'pipelineTemplateId', pipelineTemplateId);
      // verify required parameter 'pipelineTemplateUpdate' is not null or undefined
      assertParamExists('updatePipelineTemplate', 'pipelineTemplateUpdate', pipelineTemplateUpdate);
      const localVarPath = `/api/v1/pipeline-templates/{pipeline_template_id}`.replace(
        `{${'pipeline_template_id'}}`,
        encodeURIComponent(String(pipelineTemplateId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (vfModuleId !== undefined) {
        localVarQueryParameter['vf_module_id'] = vfModuleId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        pipelineTemplateUpdate,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PipelineTemplatesApi - functional programming interface
 * @export
 */
export const PipelineTemplatesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = PipelineTemplatesApiAxiosParamCreator(configuration);
  return {
    /**
     * Create a pipeline.
     * @summary Create Pipeline Template
     * @param {PipelineTemplateCreate} pipelineTemplateCreate
     * @param {string} [vfModuleId]
     * @param {string} [pipelineTemplateId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createPipelineTemplate(
      pipelineTemplateCreate: PipelineTemplateCreate,
      vfModuleId?: string,
      pipelineTemplateId?: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PipelineTemplate>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createPipelineTemplate(
        pipelineTemplateCreate,
        vfModuleId,
        pipelineTemplateId,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['PipelineTemplatesApi.createPipelineTemplate']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Delete a pipeline.
     * @summary Delete Pipeline Template
     * @param {string} pipelineTemplateId
     * @param {string} [vfModuleId]
     * @param {DataIn1 | null} [dataIn1]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deletePipelineTemplate(
      pipelineTemplateId: string,
      vfModuleId?: string,
      dataIn1?: DataIn1 | null,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deletePipelineTemplate(
        pipelineTemplateId,
        vfModuleId,
        dataIn1,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['PipelineTemplatesApi.deletePipelineTemplate']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Get a pipeline template by id.
     * @summary Get Pipeline Template
     * @param {string} pipelineTemplateId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPipelineTemplate(
      pipelineTemplateId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PipelineTemplate>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPipelineTemplate(pipelineTemplateId, options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['PipelineTemplatesApi.getPipelineTemplate']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Get pipeline templates satisfies query filter
     * @summary Get Pipeline Templates
     * @param {Array<string>} [moduleId]
     * @param {Array<string>} [id]
     * @param {number} [limit]
     * @param {string} [lastEvaluatedKey]
     * @param {BodyGetPipelineTemplates} [bodyGetPipelineTemplates]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPipelineTemplates(
      moduleId?: Array<string>,
      id?: Array<string>,
      limit?: number,
      lastEvaluatedKey?: string,
      bodyGetPipelineTemplates?: BodyGetPipelineTemplates,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PipelineTemplate>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPipelineTemplates(
        moduleId,
        id,
        limit,
        lastEvaluatedKey,
        bodyGetPipelineTemplates,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['PipelineTemplatesApi.getPipelineTemplates']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Get pipeline templates satisfies query filter
     * @summary Get Pipeline Templates Short
     * @param {Array<string>} [moduleId]
     * @param {Array<string>} [id]
     * @param {number} [limit]
     * @param {string} [lastEvaluatedKey]
     * @param {BodyGetPipelineTemplatesShort} [bodyGetPipelineTemplatesShort]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPipelineTemplatesShort(
      moduleId?: Array<string>,
      id?: Array<string>,
      limit?: number,
      lastEvaluatedKey?: string,
      bodyGetPipelineTemplatesShort?: BodyGetPipelineTemplatesShort,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PipelineTemplateListItem>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPipelineTemplatesShort(
        moduleId,
        id,
        limit,
        lastEvaluatedKey,
        bodyGetPipelineTemplatesShort,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['PipelineTemplatesApi.getPipelineTemplatesShort']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Update a pipeline template.
     * @summary Update Pipeline Template
     * @param {string} pipelineTemplateId
     * @param {PipelineTemplateUpdate} pipelineTemplateUpdate
     * @param {string} [vfModuleId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updatePipelineTemplate(
      pipelineTemplateId: string,
      pipelineTemplateUpdate: PipelineTemplateUpdate,
      vfModuleId?: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PipelineTemplate>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updatePipelineTemplate(
        pipelineTemplateId,
        pipelineTemplateUpdate,
        vfModuleId,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['PipelineTemplatesApi.updatePipelineTemplate']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * PipelineTemplatesApi - factory interface
 * @export
 */
export const PipelineTemplatesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = PipelineTemplatesApiFp(configuration);
  return {
    /**
     * Create a pipeline.
     * @summary Create Pipeline Template
     * @param {PipelineTemplatesApiCreatePipelineTemplateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPipelineTemplate(
      requestParameters: PipelineTemplatesApiCreatePipelineTemplateRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<PipelineTemplate> {
      return localVarFp
        .createPipelineTemplate(
          requestParameters.pipelineTemplateCreate,
          requestParameters.vfModuleId,
          requestParameters.pipelineTemplateId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Delete a pipeline.
     * @summary Delete Pipeline Template
     * @param {PipelineTemplatesApiDeletePipelineTemplateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePipelineTemplate(
      requestParameters: PipelineTemplatesApiDeletePipelineTemplateRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .deletePipelineTemplate(
          requestParameters.pipelineTemplateId,
          requestParameters.vfModuleId,
          requestParameters.dataIn1,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Get a pipeline template by id.
     * @summary Get Pipeline Template
     * @param {PipelineTemplatesApiGetPipelineTemplateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPipelineTemplate(
      requestParameters: PipelineTemplatesApiGetPipelineTemplateRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<PipelineTemplate> {
      return localVarFp
        .getPipelineTemplate(requestParameters.pipelineTemplateId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get pipeline templates satisfies query filter
     * @summary Get Pipeline Templates
     * @param {PipelineTemplatesApiGetPipelineTemplatesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPipelineTemplates(
      requestParameters: PipelineTemplatesApiGetPipelineTemplatesRequest = {},
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<PipelineTemplate>> {
      return localVarFp
        .getPipelineTemplates(
          requestParameters.moduleId,
          requestParameters.id,
          requestParameters.limit,
          requestParameters.lastEvaluatedKey,
          requestParameters.bodyGetPipelineTemplates,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Get pipeline templates satisfies query filter
     * @summary Get Pipeline Templates Short
     * @param {PipelineTemplatesApiGetPipelineTemplatesShortRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPipelineTemplatesShort(
      requestParameters: PipelineTemplatesApiGetPipelineTemplatesShortRequest = {},
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<PipelineTemplateListItem>> {
      return localVarFp
        .getPipelineTemplatesShort(
          requestParameters.moduleId,
          requestParameters.id,
          requestParameters.limit,
          requestParameters.lastEvaluatedKey,
          requestParameters.bodyGetPipelineTemplatesShort,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Update a pipeline template.
     * @summary Update Pipeline Template
     * @param {PipelineTemplatesApiUpdatePipelineTemplateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePipelineTemplate(
      requestParameters: PipelineTemplatesApiUpdatePipelineTemplateRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<PipelineTemplate> {
      return localVarFp
        .updatePipelineTemplate(
          requestParameters.pipelineTemplateId,
          requestParameters.pipelineTemplateUpdate,
          requestParameters.vfModuleId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for createPipelineTemplate operation in PipelineTemplatesApi.
 * @export
 * @interface PipelineTemplatesApiCreatePipelineTemplateRequest
 */
export interface PipelineTemplatesApiCreatePipelineTemplateRequest {
  /**
   *
   * @type {PipelineTemplateCreate}
   * @memberof PipelineTemplatesApiCreatePipelineTemplate
   */
  readonly pipelineTemplateCreate: PipelineTemplateCreate;

  /**
   *
   * @type {string}
   * @memberof PipelineTemplatesApiCreatePipelineTemplate
   */
  readonly vfModuleId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelineTemplatesApiCreatePipelineTemplate
   */
  readonly pipelineTemplateId?: string;
}

/**
 * Request parameters for deletePipelineTemplate operation in PipelineTemplatesApi.
 * @export
 * @interface PipelineTemplatesApiDeletePipelineTemplateRequest
 */
export interface PipelineTemplatesApiDeletePipelineTemplateRequest {
  /**
   *
   * @type {string}
   * @memberof PipelineTemplatesApiDeletePipelineTemplate
   */
  readonly pipelineTemplateId: string;

  /**
   *
   * @type {string}
   * @memberof PipelineTemplatesApiDeletePipelineTemplate
   */
  readonly vfModuleId?: string;

  /**
   *
   * @type {DataIn1}
   * @memberof PipelineTemplatesApiDeletePipelineTemplate
   */
  readonly dataIn1?: DataIn1 | null;
}

/**
 * Request parameters for getPipelineTemplate operation in PipelineTemplatesApi.
 * @export
 * @interface PipelineTemplatesApiGetPipelineTemplateRequest
 */
export interface PipelineTemplatesApiGetPipelineTemplateRequest {
  /**
   *
   * @type {string}
   * @memberof PipelineTemplatesApiGetPipelineTemplate
   */
  readonly pipelineTemplateId: string;
}

/**
 * Request parameters for getPipelineTemplates operation in PipelineTemplatesApi.
 * @export
 * @interface PipelineTemplatesApiGetPipelineTemplatesRequest
 */
export interface PipelineTemplatesApiGetPipelineTemplatesRequest {
  /**
   *
   * @type {Array<string>}
   * @memberof PipelineTemplatesApiGetPipelineTemplates
   */
  readonly moduleId?: Array<string>;

  /**
   *
   * @type {Array<string>}
   * @memberof PipelineTemplatesApiGetPipelineTemplates
   */
  readonly id?: Array<string>;

  /**
   *
   * @type {number}
   * @memberof PipelineTemplatesApiGetPipelineTemplates
   */
  readonly limit?: number;

  /**
   *
   * @type {string}
   * @memberof PipelineTemplatesApiGetPipelineTemplates
   */
  readonly lastEvaluatedKey?: string;

  /**
   *
   * @type {BodyGetPipelineTemplates}
   * @memberof PipelineTemplatesApiGetPipelineTemplates
   */
  readonly bodyGetPipelineTemplates?: BodyGetPipelineTemplates;
}

/**
 * Request parameters for getPipelineTemplatesShort operation in PipelineTemplatesApi.
 * @export
 * @interface PipelineTemplatesApiGetPipelineTemplatesShortRequest
 */
export interface PipelineTemplatesApiGetPipelineTemplatesShortRequest {
  /**
   *
   * @type {Array<string>}
   * @memberof PipelineTemplatesApiGetPipelineTemplatesShort
   */
  readonly moduleId?: Array<string>;

  /**
   *
   * @type {Array<string>}
   * @memberof PipelineTemplatesApiGetPipelineTemplatesShort
   */
  readonly id?: Array<string>;

  /**
   *
   * @type {number}
   * @memberof PipelineTemplatesApiGetPipelineTemplatesShort
   */
  readonly limit?: number;

  /**
   *
   * @type {string}
   * @memberof PipelineTemplatesApiGetPipelineTemplatesShort
   */
  readonly lastEvaluatedKey?: string;

  /**
   *
   * @type {BodyGetPipelineTemplatesShort}
   * @memberof PipelineTemplatesApiGetPipelineTemplatesShort
   */
  readonly bodyGetPipelineTemplatesShort?: BodyGetPipelineTemplatesShort;
}

/**
 * Request parameters for updatePipelineTemplate operation in PipelineTemplatesApi.
 * @export
 * @interface PipelineTemplatesApiUpdatePipelineTemplateRequest
 */
export interface PipelineTemplatesApiUpdatePipelineTemplateRequest {
  /**
   *
   * @type {string}
   * @memberof PipelineTemplatesApiUpdatePipelineTemplate
   */
  readonly pipelineTemplateId: string;

  /**
   *
   * @type {PipelineTemplateUpdate}
   * @memberof PipelineTemplatesApiUpdatePipelineTemplate
   */
  readonly pipelineTemplateUpdate: PipelineTemplateUpdate;

  /**
   *
   * @type {string}
   * @memberof PipelineTemplatesApiUpdatePipelineTemplate
   */
  readonly vfModuleId?: string;
}

/**
 * PipelineTemplatesApi - object-oriented interface
 * @export
 * @class PipelineTemplatesApi
 * @extends {BaseAPI}
 */
export class PipelineTemplatesApi extends BaseAPI {
  /**
   * Create a pipeline.
   * @summary Create Pipeline Template
   * @param {PipelineTemplatesApiCreatePipelineTemplateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PipelineTemplatesApi
   */
  public createPipelineTemplate(
    requestParameters: PipelineTemplatesApiCreatePipelineTemplateRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return PipelineTemplatesApiFp(this.configuration)
      .createPipelineTemplate(
        requestParameters.pipelineTemplateCreate,
        requestParameters.vfModuleId,
        requestParameters.pipelineTemplateId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Delete a pipeline.
   * @summary Delete Pipeline Template
   * @param {PipelineTemplatesApiDeletePipelineTemplateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PipelineTemplatesApi
   */
  public deletePipelineTemplate(
    requestParameters: PipelineTemplatesApiDeletePipelineTemplateRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return PipelineTemplatesApiFp(this.configuration)
      .deletePipelineTemplate(
        requestParameters.pipelineTemplateId,
        requestParameters.vfModuleId,
        requestParameters.dataIn1,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get a pipeline template by id.
   * @summary Get Pipeline Template
   * @param {PipelineTemplatesApiGetPipelineTemplateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PipelineTemplatesApi
   */
  public getPipelineTemplate(
    requestParameters: PipelineTemplatesApiGetPipelineTemplateRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return PipelineTemplatesApiFp(this.configuration)
      .getPipelineTemplate(requestParameters.pipelineTemplateId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get pipeline templates satisfies query filter
   * @summary Get Pipeline Templates
   * @param {PipelineTemplatesApiGetPipelineTemplatesRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PipelineTemplatesApi
   */
  public getPipelineTemplates(
    requestParameters: PipelineTemplatesApiGetPipelineTemplatesRequest = {},
    options?: RawAxiosRequestConfig,
  ) {
    return PipelineTemplatesApiFp(this.configuration)
      .getPipelineTemplates(
        requestParameters.moduleId,
        requestParameters.id,
        requestParameters.limit,
        requestParameters.lastEvaluatedKey,
        requestParameters.bodyGetPipelineTemplates,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get pipeline templates satisfies query filter
   * @summary Get Pipeline Templates Short
   * @param {PipelineTemplatesApiGetPipelineTemplatesShortRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PipelineTemplatesApi
   */
  public getPipelineTemplatesShort(
    requestParameters: PipelineTemplatesApiGetPipelineTemplatesShortRequest = {},
    options?: RawAxiosRequestConfig,
  ) {
    return PipelineTemplatesApiFp(this.configuration)
      .getPipelineTemplatesShort(
        requestParameters.moduleId,
        requestParameters.id,
        requestParameters.limit,
        requestParameters.lastEvaluatedKey,
        requestParameters.bodyGetPipelineTemplatesShort,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update a pipeline template.
   * @summary Update Pipeline Template
   * @param {PipelineTemplatesApiUpdatePipelineTemplateRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PipelineTemplatesApi
   */
  public updatePipelineTemplate(
    requestParameters: PipelineTemplatesApiUpdatePipelineTemplateRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return PipelineTemplatesApiFp(this.configuration)
      .updatePipelineTemplate(
        requestParameters.pipelineTemplateId,
        requestParameters.pipelineTemplateUpdate,
        requestParameters.vfModuleId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * PipelinesApi - axios parameter creator
 * @export
 */
export const PipelinesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Create a pipeline.
     * @summary Create Pipeline
     * @param {PipelineCreate} pipelineCreate
     * @param {string} [instanceId]
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPipeline: async (
      pipelineCreate: PipelineCreate,
      instanceId?: string,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pipelineCreate' is not null or undefined
      assertParamExists('createPipeline', 'pipelineCreate', pipelineCreate);
      const localVarPath = `/api/v1/pipelines`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (instanceId !== undefined) {
        localVarQueryParameter['instance_id'] = instanceId;
      }

      if (httpApiKeyId !== undefined) {
        localVarQueryParameter['http_api_key_id'] = httpApiKeyId;
      }

      if (pipelineId !== undefined) {
        localVarQueryParameter['pipeline_id'] = pipelineId;
      }

      if (pipelineStepId !== undefined) {
        localVarQueryParameter['pipeline_step_id'] = pipelineStepId;
      }

      if (pipelineExecutionId !== undefined) {
        localVarQueryParameter['pipeline_execution_id'] = pipelineExecutionId;
      }

      if (pipelineNotificationId !== undefined) {
        localVarQueryParameter['pipeline_notification_id'] = pipelineNotificationId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(pipelineCreate, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Delete a pipeline.
     * @summary Delete Pipeline
     * @param {string} pipelineId
     * @param {string} [instanceId]
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {DataIn | null} [dataIn]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePipeline: async (
      pipelineId: string,
      instanceId?: string,
      httpApiKeyId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      dataIn?: DataIn | null,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pipelineId' is not null or undefined
      assertParamExists('deletePipeline', 'pipelineId', pipelineId);
      const localVarPath = `/api/v1/pipelines/{pipeline_id}`.replace(
        `{${'pipeline_id'}}`,
        encodeURIComponent(String(pipelineId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (instanceId !== undefined) {
        localVarQueryParameter['instance_id'] = instanceId;
      }

      if (httpApiKeyId !== undefined) {
        localVarQueryParameter['http_api_key_id'] = httpApiKeyId;
      }

      if (pipelineStepId !== undefined) {
        localVarQueryParameter['pipeline_step_id'] = pipelineStepId;
      }

      if (pipelineExecutionId !== undefined) {
        localVarQueryParameter['pipeline_execution_id'] = pipelineExecutionId;
      }

      if (pipelineNotificationId !== undefined) {
        localVarQueryParameter['pipeline_notification_id'] = pipelineNotificationId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(dataIn, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Filtered Pipelines
     * @param {PipelineFilteredRequestBody} pipelineFilteredRequestBody
     * @param {string} [instanceId]
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFilteredPipelines: async (
      pipelineFilteredRequestBody: PipelineFilteredRequestBody,
      instanceId?: string,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pipelineFilteredRequestBody' is not null or undefined
      assertParamExists('getFilteredPipelines', 'pipelineFilteredRequestBody', pipelineFilteredRequestBody);
      const localVarPath = `/api/v1/pipelines/filtered`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (instanceId !== undefined) {
        localVarQueryParameter['instance_id'] = instanceId;
      }

      if (httpApiKeyId !== undefined) {
        localVarQueryParameter['http_api_key_id'] = httpApiKeyId;
      }

      if (pipelineId !== undefined) {
        localVarQueryParameter['pipeline_id'] = pipelineId;
      }

      if (pipelineStepId !== undefined) {
        localVarQueryParameter['pipeline_step_id'] = pipelineStepId;
      }

      if (pipelineExecutionId !== undefined) {
        localVarQueryParameter['pipeline_execution_id'] = pipelineExecutionId;
      }

      if (pipelineNotificationId !== undefined) {
        localVarQueryParameter['pipeline_notification_id'] = pipelineNotificationId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        pipelineFilteredRequestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Filtered List
     * @param {PipelineFilteredListRequestBody} pipelineFilteredListRequestBody
     * @param {string} [instanceId]
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFilteredPipelinesList: async (
      pipelineFilteredListRequestBody: PipelineFilteredListRequestBody,
      instanceId?: string,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pipelineFilteredListRequestBody' is not null or undefined
      assertParamExists('getFilteredPipelinesList', 'pipelineFilteredListRequestBody', pipelineFilteredListRequestBody);
      const localVarPath = `/api/v1/pipelines/list/filtered`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (instanceId !== undefined) {
        localVarQueryParameter['instance_id'] = instanceId;
      }

      if (httpApiKeyId !== undefined) {
        localVarQueryParameter['http_api_key_id'] = httpApiKeyId;
      }

      if (pipelineId !== undefined) {
        localVarQueryParameter['pipeline_id'] = pipelineId;
      }

      if (pipelineStepId !== undefined) {
        localVarQueryParameter['pipeline_step_id'] = pipelineStepId;
      }

      if (pipelineExecutionId !== undefined) {
        localVarQueryParameter['pipeline_execution_id'] = pipelineExecutionId;
      }

      if (pipelineNotificationId !== undefined) {
        localVarQueryParameter['pipeline_notification_id'] = pipelineNotificationId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        pipelineFilteredListRequestBody,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get a pipeline by id.
     * @summary Get Pipeline
     * @param {string} pipelineId
     * @param {string} [instanceId]
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {DataIn | null} [dataIn]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPipeline: async (
      pipelineId: string,
      instanceId?: string,
      httpApiKeyId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      dataIn?: DataIn | null,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pipelineId' is not null or undefined
      assertParamExists('getPipeline', 'pipelineId', pipelineId);
      const localVarPath = `/api/v1/pipelines/{pipeline_id}`.replace(
        `{${'pipeline_id'}}`,
        encodeURIComponent(String(pipelineId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (instanceId !== undefined) {
        localVarQueryParameter['instance_id'] = instanceId;
      }

      if (httpApiKeyId !== undefined) {
        localVarQueryParameter['http_api_key_id'] = httpApiKeyId;
      }

      if (pipelineStepId !== undefined) {
        localVarQueryParameter['pipeline_step_id'] = pipelineStepId;
      }

      if (pipelineExecutionId !== undefined) {
        localVarQueryParameter['pipeline_execution_id'] = pipelineExecutionId;
      }

      if (pipelineNotificationId !== undefined) {
        localVarQueryParameter['pipeline_notification_id'] = pipelineNotificationId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(dataIn, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Pipelines List
     * @param {string} instanceId
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {DataIn | null} [dataIn]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPipelinesList: async (
      instanceId: string,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      dataIn?: DataIn | null,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'instanceId' is not null or undefined
      assertParamExists('getPipelinesList', 'instanceId', instanceId);
      const localVarPath = `/api/v1/pipelines/list/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (instanceId !== undefined) {
        localVarQueryParameter['instance_id'] = instanceId;
      }

      if (httpApiKeyId !== undefined) {
        localVarQueryParameter['http_api_key_id'] = httpApiKeyId;
      }

      if (pipelineId !== undefined) {
        localVarQueryParameter['pipeline_id'] = pipelineId;
      }

      if (pipelineStepId !== undefined) {
        localVarQueryParameter['pipeline_step_id'] = pipelineStepId;
      }

      if (pipelineExecutionId !== undefined) {
        localVarQueryParameter['pipeline_execution_id'] = pipelineExecutionId;
      }

      if (pipelineNotificationId !== undefined) {
        localVarQueryParameter['pipeline_notification_id'] = pipelineNotificationId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(dataIn, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update a pipeline.
     * @summary Update Pipeline
     * @param {string} pipelineId
     * @param {PipelineUpdate} pipelineUpdate
     * @param {string} [instanceId]
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePipeline: async (
      pipelineId: string,
      pipelineUpdate: PipelineUpdate,
      instanceId?: string,
      httpApiKeyId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pipelineId' is not null or undefined
      assertParamExists('updatePipeline', 'pipelineId', pipelineId);
      // verify required parameter 'pipelineUpdate' is not null or undefined
      assertParamExists('updatePipeline', 'pipelineUpdate', pipelineUpdate);
      const localVarPath = `/api/v1/pipelines/{pipeline_id}`.replace(
        `{${'pipeline_id'}}`,
        encodeURIComponent(String(pipelineId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (instanceId !== undefined) {
        localVarQueryParameter['instance_id'] = instanceId;
      }

      if (httpApiKeyId !== undefined) {
        localVarQueryParameter['http_api_key_id'] = httpApiKeyId;
      }

      if (pipelineStepId !== undefined) {
        localVarQueryParameter['pipeline_step_id'] = pipelineStepId;
      }

      if (pipelineExecutionId !== undefined) {
        localVarQueryParameter['pipeline_execution_id'] = pipelineExecutionId;
      }

      if (pipelineNotificationId !== undefined) {
        localVarQueryParameter['pipeline_notification_id'] = pipelineNotificationId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(pipelineUpdate, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PipelinesApi - functional programming interface
 * @export
 */
export const PipelinesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = PipelinesApiAxiosParamCreator(configuration);
  return {
    /**
     * Create a pipeline.
     * @summary Create Pipeline
     * @param {PipelineCreate} pipelineCreate
     * @param {string} [instanceId]
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createPipeline(
      pipelineCreate: PipelineCreate,
      instanceId?: string,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Pipeline>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createPipeline(
        pipelineCreate,
        instanceId,
        httpApiKeyId,
        pipelineId,
        pipelineStepId,
        pipelineExecutionId,
        pipelineNotificationId,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['PipelinesApi.createPipeline']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Delete a pipeline.
     * @summary Delete Pipeline
     * @param {string} pipelineId
     * @param {string} [instanceId]
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {DataIn | null} [dataIn]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deletePipeline(
      pipelineId: string,
      instanceId?: string,
      httpApiKeyId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      dataIn?: DataIn | null,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Pipeline>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deletePipeline(
        pipelineId,
        instanceId,
        httpApiKeyId,
        pipelineStepId,
        pipelineExecutionId,
        pipelineNotificationId,
        dataIn,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['PipelinesApi.deletePipeline']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Filtered Pipelines
     * @param {PipelineFilteredRequestBody} pipelineFilteredRequestBody
     * @param {string} [instanceId]
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFilteredPipelines(
      pipelineFilteredRequestBody: PipelineFilteredRequestBody,
      instanceId?: string,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PipelineFiltered>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getFilteredPipelines(
        pipelineFilteredRequestBody,
        instanceId,
        httpApiKeyId,
        pipelineId,
        pipelineStepId,
        pipelineExecutionId,
        pipelineNotificationId,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['PipelinesApi.getFilteredPipelines']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Filtered List
     * @param {PipelineFilteredListRequestBody} pipelineFilteredListRequestBody
     * @param {string} [instanceId]
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFilteredPipelinesList(
      pipelineFilteredListRequestBody: PipelineFilteredListRequestBody,
      instanceId?: string,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PipelineListItem>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getFilteredPipelinesList(
        pipelineFilteredListRequestBody,
        instanceId,
        httpApiKeyId,
        pipelineId,
        pipelineStepId,
        pipelineExecutionId,
        pipelineNotificationId,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['PipelinesApi.getFilteredPipelinesList']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Get a pipeline by id.
     * @summary Get Pipeline
     * @param {string} pipelineId
     * @param {string} [instanceId]
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {DataIn | null} [dataIn]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPipeline(
      pipelineId: string,
      instanceId?: string,
      httpApiKeyId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      dataIn?: DataIn | null,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Pipeline>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPipeline(
        pipelineId,
        instanceId,
        httpApiKeyId,
        pipelineStepId,
        pipelineExecutionId,
        pipelineNotificationId,
        dataIn,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['PipelinesApi.getPipeline']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Pipelines List
     * @param {string} instanceId
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {DataIn | null} [dataIn]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPipelinesList(
      instanceId: string,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      dataIn?: DataIn | null,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PipelineListItem>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPipelinesList(
        instanceId,
        httpApiKeyId,
        pipelineId,
        pipelineStepId,
        pipelineExecutionId,
        pipelineNotificationId,
        dataIn,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['PipelinesApi.getPipelinesList']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Update a pipeline.
     * @summary Update Pipeline
     * @param {string} pipelineId
     * @param {PipelineUpdate} pipelineUpdate
     * @param {string} [instanceId]
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updatePipeline(
      pipelineId: string,
      pipelineUpdate: PipelineUpdate,
      instanceId?: string,
      httpApiKeyId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Pipeline>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updatePipeline(
        pipelineId,
        pipelineUpdate,
        instanceId,
        httpApiKeyId,
        pipelineStepId,
        pipelineExecutionId,
        pipelineNotificationId,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['PipelinesApi.updatePipeline']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * PipelinesApi - factory interface
 * @export
 */
export const PipelinesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = PipelinesApiFp(configuration);
  return {
    /**
     * Create a pipeline.
     * @summary Create Pipeline
     * @param {PipelinesApiCreatePipelineRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPipeline(
      requestParameters: PipelinesApiCreatePipelineRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Pipeline> {
      return localVarFp
        .createPipeline(
          requestParameters.pipelineCreate,
          requestParameters.instanceId,
          requestParameters.httpApiKeyId,
          requestParameters.pipelineId,
          requestParameters.pipelineStepId,
          requestParameters.pipelineExecutionId,
          requestParameters.pipelineNotificationId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Delete a pipeline.
     * @summary Delete Pipeline
     * @param {PipelinesApiDeletePipelineRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePipeline(
      requestParameters: PipelinesApiDeletePipelineRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Pipeline> {
      return localVarFp
        .deletePipeline(
          requestParameters.pipelineId,
          requestParameters.instanceId,
          requestParameters.httpApiKeyId,
          requestParameters.pipelineStepId,
          requestParameters.pipelineExecutionId,
          requestParameters.pipelineNotificationId,
          requestParameters.dataIn,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Filtered Pipelines
     * @param {PipelinesApiGetFilteredPipelinesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFilteredPipelines(
      requestParameters: PipelinesApiGetFilteredPipelinesRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<PipelineFiltered>> {
      return localVarFp
        .getFilteredPipelines(
          requestParameters.pipelineFilteredRequestBody,
          requestParameters.instanceId,
          requestParameters.httpApiKeyId,
          requestParameters.pipelineId,
          requestParameters.pipelineStepId,
          requestParameters.pipelineExecutionId,
          requestParameters.pipelineNotificationId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Filtered List
     * @param {PipelinesApiGetFilteredPipelinesListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFilteredPipelinesList(
      requestParameters: PipelinesApiGetFilteredPipelinesListRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<PipelineListItem>> {
      return localVarFp
        .getFilteredPipelinesList(
          requestParameters.pipelineFilteredListRequestBody,
          requestParameters.instanceId,
          requestParameters.httpApiKeyId,
          requestParameters.pipelineId,
          requestParameters.pipelineStepId,
          requestParameters.pipelineExecutionId,
          requestParameters.pipelineNotificationId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Get a pipeline by id.
     * @summary Get Pipeline
     * @param {PipelinesApiGetPipelineRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPipeline(
      requestParameters: PipelinesApiGetPipelineRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Pipeline> {
      return localVarFp
        .getPipeline(
          requestParameters.pipelineId,
          requestParameters.instanceId,
          requestParameters.httpApiKeyId,
          requestParameters.pipelineStepId,
          requestParameters.pipelineExecutionId,
          requestParameters.pipelineNotificationId,
          requestParameters.dataIn,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Pipelines List
     * @param {PipelinesApiGetPipelinesListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPipelinesList(
      requestParameters: PipelinesApiGetPipelinesListRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<PipelineListItem>> {
      return localVarFp
        .getPipelinesList(
          requestParameters.instanceId,
          requestParameters.httpApiKeyId,
          requestParameters.pipelineId,
          requestParameters.pipelineStepId,
          requestParameters.pipelineExecutionId,
          requestParameters.pipelineNotificationId,
          requestParameters.dataIn,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Update a pipeline.
     * @summary Update Pipeline
     * @param {PipelinesApiUpdatePipelineRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePipeline(
      requestParameters: PipelinesApiUpdatePipelineRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Pipeline> {
      return localVarFp
        .updatePipeline(
          requestParameters.pipelineId,
          requestParameters.pipelineUpdate,
          requestParameters.instanceId,
          requestParameters.httpApiKeyId,
          requestParameters.pipelineStepId,
          requestParameters.pipelineExecutionId,
          requestParameters.pipelineNotificationId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for createPipeline operation in PipelinesApi.
 * @export
 * @interface PipelinesApiCreatePipelineRequest
 */
export interface PipelinesApiCreatePipelineRequest {
  /**
   *
   * @type {PipelineCreate}
   * @memberof PipelinesApiCreatePipeline
   */
  readonly pipelineCreate: PipelineCreate;

  /**
   *
   * @type {string}
   * @memberof PipelinesApiCreatePipeline
   */
  readonly instanceId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelinesApiCreatePipeline
   */
  readonly httpApiKeyId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelinesApiCreatePipeline
   */
  readonly pipelineId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelinesApiCreatePipeline
   */
  readonly pipelineStepId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelinesApiCreatePipeline
   */
  readonly pipelineExecutionId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelinesApiCreatePipeline
   */
  readonly pipelineNotificationId?: string;
}

/**
 * Request parameters for deletePipeline operation in PipelinesApi.
 * @export
 * @interface PipelinesApiDeletePipelineRequest
 */
export interface PipelinesApiDeletePipelineRequest {
  /**
   *
   * @type {string}
   * @memberof PipelinesApiDeletePipeline
   */
  readonly pipelineId: string;

  /**
   *
   * @type {string}
   * @memberof PipelinesApiDeletePipeline
   */
  readonly instanceId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelinesApiDeletePipeline
   */
  readonly httpApiKeyId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelinesApiDeletePipeline
   */
  readonly pipelineStepId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelinesApiDeletePipeline
   */
  readonly pipelineExecutionId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelinesApiDeletePipeline
   */
  readonly pipelineNotificationId?: string;

  /**
   *
   * @type {DataIn}
   * @memberof PipelinesApiDeletePipeline
   */
  readonly dataIn?: DataIn | null;
}

/**
 * Request parameters for getFilteredPipelines operation in PipelinesApi.
 * @export
 * @interface PipelinesApiGetFilteredPipelinesRequest
 */
export interface PipelinesApiGetFilteredPipelinesRequest {
  /**
   *
   * @type {PipelineFilteredRequestBody}
   * @memberof PipelinesApiGetFilteredPipelines
   */
  readonly pipelineFilteredRequestBody: PipelineFilteredRequestBody;

  /**
   *
   * @type {string}
   * @memberof PipelinesApiGetFilteredPipelines
   */
  readonly instanceId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelinesApiGetFilteredPipelines
   */
  readonly httpApiKeyId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelinesApiGetFilteredPipelines
   */
  readonly pipelineId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelinesApiGetFilteredPipelines
   */
  readonly pipelineStepId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelinesApiGetFilteredPipelines
   */
  readonly pipelineExecutionId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelinesApiGetFilteredPipelines
   */
  readonly pipelineNotificationId?: string;
}

/**
 * Request parameters for getFilteredPipelinesList operation in PipelinesApi.
 * @export
 * @interface PipelinesApiGetFilteredPipelinesListRequest
 */
export interface PipelinesApiGetFilteredPipelinesListRequest {
  /**
   *
   * @type {PipelineFilteredListRequestBody}
   * @memberof PipelinesApiGetFilteredPipelinesList
   */
  readonly pipelineFilteredListRequestBody: PipelineFilteredListRequestBody;

  /**
   *
   * @type {string}
   * @memberof PipelinesApiGetFilteredPipelinesList
   */
  readonly instanceId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelinesApiGetFilteredPipelinesList
   */
  readonly httpApiKeyId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelinesApiGetFilteredPipelinesList
   */
  readonly pipelineId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelinesApiGetFilteredPipelinesList
   */
  readonly pipelineStepId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelinesApiGetFilteredPipelinesList
   */
  readonly pipelineExecutionId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelinesApiGetFilteredPipelinesList
   */
  readonly pipelineNotificationId?: string;
}

/**
 * Request parameters for getPipeline operation in PipelinesApi.
 * @export
 * @interface PipelinesApiGetPipelineRequest
 */
export interface PipelinesApiGetPipelineRequest {
  /**
   *
   * @type {string}
   * @memberof PipelinesApiGetPipeline
   */
  readonly pipelineId: string;

  /**
   *
   * @type {string}
   * @memberof PipelinesApiGetPipeline
   */
  readonly instanceId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelinesApiGetPipeline
   */
  readonly httpApiKeyId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelinesApiGetPipeline
   */
  readonly pipelineStepId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelinesApiGetPipeline
   */
  readonly pipelineExecutionId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelinesApiGetPipeline
   */
  readonly pipelineNotificationId?: string;

  /**
   *
   * @type {DataIn}
   * @memberof PipelinesApiGetPipeline
   */
  readonly dataIn?: DataIn | null;
}

/**
 * Request parameters for getPipelinesList operation in PipelinesApi.
 * @export
 * @interface PipelinesApiGetPipelinesListRequest
 */
export interface PipelinesApiGetPipelinesListRequest {
  /**
   *
   * @type {string}
   * @memberof PipelinesApiGetPipelinesList
   */
  readonly instanceId: string;

  /**
   *
   * @type {string}
   * @memberof PipelinesApiGetPipelinesList
   */
  readonly httpApiKeyId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelinesApiGetPipelinesList
   */
  readonly pipelineId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelinesApiGetPipelinesList
   */
  readonly pipelineStepId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelinesApiGetPipelinesList
   */
  readonly pipelineExecutionId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelinesApiGetPipelinesList
   */
  readonly pipelineNotificationId?: string;

  /**
   *
   * @type {DataIn}
   * @memberof PipelinesApiGetPipelinesList
   */
  readonly dataIn?: DataIn | null;
}

/**
 * Request parameters for updatePipeline operation in PipelinesApi.
 * @export
 * @interface PipelinesApiUpdatePipelineRequest
 */
export interface PipelinesApiUpdatePipelineRequest {
  /**
   *
   * @type {string}
   * @memberof PipelinesApiUpdatePipeline
   */
  readonly pipelineId: string;

  /**
   *
   * @type {PipelineUpdate}
   * @memberof PipelinesApiUpdatePipeline
   */
  readonly pipelineUpdate: PipelineUpdate;

  /**
   *
   * @type {string}
   * @memberof PipelinesApiUpdatePipeline
   */
  readonly instanceId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelinesApiUpdatePipeline
   */
  readonly httpApiKeyId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelinesApiUpdatePipeline
   */
  readonly pipelineStepId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelinesApiUpdatePipeline
   */
  readonly pipelineExecutionId?: string;

  /**
   *
   * @type {string}
   * @memberof PipelinesApiUpdatePipeline
   */
  readonly pipelineNotificationId?: string;
}

/**
 * PipelinesApi - object-oriented interface
 * @export
 * @class PipelinesApi
 * @extends {BaseAPI}
 */
export class PipelinesApi extends BaseAPI {
  /**
   * Create a pipeline.
   * @summary Create Pipeline
   * @param {PipelinesApiCreatePipelineRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PipelinesApi
   */
  public createPipeline(requestParameters: PipelinesApiCreatePipelineRequest, options?: RawAxiosRequestConfig) {
    return PipelinesApiFp(this.configuration)
      .createPipeline(
        requestParameters.pipelineCreate,
        requestParameters.instanceId,
        requestParameters.httpApiKeyId,
        requestParameters.pipelineId,
        requestParameters.pipelineStepId,
        requestParameters.pipelineExecutionId,
        requestParameters.pipelineNotificationId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Delete a pipeline.
   * @summary Delete Pipeline
   * @param {PipelinesApiDeletePipelineRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PipelinesApi
   */
  public deletePipeline(requestParameters: PipelinesApiDeletePipelineRequest, options?: RawAxiosRequestConfig) {
    return PipelinesApiFp(this.configuration)
      .deletePipeline(
        requestParameters.pipelineId,
        requestParameters.instanceId,
        requestParameters.httpApiKeyId,
        requestParameters.pipelineStepId,
        requestParameters.pipelineExecutionId,
        requestParameters.pipelineNotificationId,
        requestParameters.dataIn,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Filtered Pipelines
   * @param {PipelinesApiGetFilteredPipelinesRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PipelinesApi
   */
  public getFilteredPipelines(
    requestParameters: PipelinesApiGetFilteredPipelinesRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return PipelinesApiFp(this.configuration)
      .getFilteredPipelines(
        requestParameters.pipelineFilteredRequestBody,
        requestParameters.instanceId,
        requestParameters.httpApiKeyId,
        requestParameters.pipelineId,
        requestParameters.pipelineStepId,
        requestParameters.pipelineExecutionId,
        requestParameters.pipelineNotificationId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Filtered List
   * @param {PipelinesApiGetFilteredPipelinesListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PipelinesApi
   */
  public getFilteredPipelinesList(
    requestParameters: PipelinesApiGetFilteredPipelinesListRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return PipelinesApiFp(this.configuration)
      .getFilteredPipelinesList(
        requestParameters.pipelineFilteredListRequestBody,
        requestParameters.instanceId,
        requestParameters.httpApiKeyId,
        requestParameters.pipelineId,
        requestParameters.pipelineStepId,
        requestParameters.pipelineExecutionId,
        requestParameters.pipelineNotificationId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get a pipeline by id.
   * @summary Get Pipeline
   * @param {PipelinesApiGetPipelineRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PipelinesApi
   */
  public getPipeline(requestParameters: PipelinesApiGetPipelineRequest, options?: RawAxiosRequestConfig) {
    return PipelinesApiFp(this.configuration)
      .getPipeline(
        requestParameters.pipelineId,
        requestParameters.instanceId,
        requestParameters.httpApiKeyId,
        requestParameters.pipelineStepId,
        requestParameters.pipelineExecutionId,
        requestParameters.pipelineNotificationId,
        requestParameters.dataIn,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Pipelines List
   * @param {PipelinesApiGetPipelinesListRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PipelinesApi
   */
  public getPipelinesList(requestParameters: PipelinesApiGetPipelinesListRequest, options?: RawAxiosRequestConfig) {
    return PipelinesApiFp(this.configuration)
      .getPipelinesList(
        requestParameters.instanceId,
        requestParameters.httpApiKeyId,
        requestParameters.pipelineId,
        requestParameters.pipelineStepId,
        requestParameters.pipelineExecutionId,
        requestParameters.pipelineNotificationId,
        requestParameters.dataIn,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update a pipeline.
   * @summary Update Pipeline
   * @param {PipelinesApiUpdatePipelineRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PipelinesApi
   */
  public updatePipeline(requestParameters: PipelinesApiUpdatePipelineRequest, options?: RawAxiosRequestConfig) {
    return PipelinesApiFp(this.configuration)
      .updatePipeline(
        requestParameters.pipelineId,
        requestParameters.pipelineUpdate,
        requestParameters.instanceId,
        requestParameters.httpApiKeyId,
        requestParameters.pipelineStepId,
        requestParameters.pipelineExecutionId,
        requestParameters.pipelineNotificationId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * SftpApi - axios parameter creator
 * @export
 */
export const SftpApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Create Inbound Account
     * @param {SFTPAccountCreate} sFTPAccountCreate
     * @param {string} [instanceId]
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createInboundAccount: async (
      sFTPAccountCreate: SFTPAccountCreate,
      instanceId?: string,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'sFTPAccountCreate' is not null or undefined
      assertParamExists('createInboundAccount', 'sFTPAccountCreate', sFTPAccountCreate);
      const localVarPath = `/api/v1/sftp/inbound/users`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (instanceId !== undefined) {
        localVarQueryParameter['instance_id'] = instanceId;
      }

      if (httpApiKeyId !== undefined) {
        localVarQueryParameter['http_api_key_id'] = httpApiKeyId;
      }

      if (pipelineId !== undefined) {
        localVarQueryParameter['pipeline_id'] = pipelineId;
      }

      if (pipelineStepId !== undefined) {
        localVarQueryParameter['pipeline_step_id'] = pipelineStepId;
      }

      if (pipelineExecutionId !== undefined) {
        localVarQueryParameter['pipeline_execution_id'] = pipelineExecutionId;
      }

      if (pipelineNotificationId !== undefined) {
        localVarQueryParameter['pipeline_notification_id'] = pipelineNotificationId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(sFTPAccountCreate, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Create Outbound Account
     * @param {SFTPAccountCreate} sFTPAccountCreate
     * @param {string} [instanceId]
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createOutboundAccount: async (
      sFTPAccountCreate: SFTPAccountCreate,
      instanceId?: string,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'sFTPAccountCreate' is not null or undefined
      assertParamExists('createOutboundAccount', 'sFTPAccountCreate', sFTPAccountCreate);
      const localVarPath = `/api/v1/sftp/outbound/users`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (instanceId !== undefined) {
        localVarQueryParameter['instance_id'] = instanceId;
      }

      if (httpApiKeyId !== undefined) {
        localVarQueryParameter['http_api_key_id'] = httpApiKeyId;
      }

      if (pipelineId !== undefined) {
        localVarQueryParameter['pipeline_id'] = pipelineId;
      }

      if (pipelineStepId !== undefined) {
        localVarQueryParameter['pipeline_step_id'] = pipelineStepId;
      }

      if (pipelineExecutionId !== undefined) {
        localVarQueryParameter['pipeline_execution_id'] = pipelineExecutionId;
      }

      if (pipelineNotificationId !== undefined) {
        localVarQueryParameter['pipeline_notification_id'] = pipelineNotificationId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(sFTPAccountCreate, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete Inbound User
     * @param {string} instanceId
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {DataIn | null} [dataIn]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteInboundAccountForInstance: async (
      instanceId: string,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      dataIn?: DataIn | null,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'instanceId' is not null or undefined
      assertParamExists('deleteInboundAccountForInstance', 'instanceId', instanceId);
      const localVarPath = `/api/v1/sftp/inbound/users/{instance_id}`.replace(
        `{${'instance_id'}}`,
        encodeURIComponent(String(instanceId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (httpApiKeyId !== undefined) {
        localVarQueryParameter['http_api_key_id'] = httpApiKeyId;
      }

      if (pipelineId !== undefined) {
        localVarQueryParameter['pipeline_id'] = pipelineId;
      }

      if (pipelineStepId !== undefined) {
        localVarQueryParameter['pipeline_step_id'] = pipelineStepId;
      }

      if (pipelineExecutionId !== undefined) {
        localVarQueryParameter['pipeline_execution_id'] = pipelineExecutionId;
      }

      if (pipelineNotificationId !== undefined) {
        localVarQueryParameter['pipeline_notification_id'] = pipelineNotificationId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(dataIn, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete Inbound Ssh Public Key
     * @param {SFTPKeyID} sFTPKeyID
     * @param {string} [instanceId]
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteInboundSshPublicKey: async (
      sFTPKeyID: SFTPKeyID,
      instanceId?: string,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'sFTPKeyID' is not null or undefined
      assertParamExists('deleteInboundSshPublicKey', 'sFTPKeyID', sFTPKeyID);
      const localVarPath = `/api/v1/sftp/inbound/keys`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (instanceId !== undefined) {
        localVarQueryParameter['instance_id'] = instanceId;
      }

      if (httpApiKeyId !== undefined) {
        localVarQueryParameter['http_api_key_id'] = httpApiKeyId;
      }

      if (pipelineId !== undefined) {
        localVarQueryParameter['pipeline_id'] = pipelineId;
      }

      if (pipelineStepId !== undefined) {
        localVarQueryParameter['pipeline_step_id'] = pipelineStepId;
      }

      if (pipelineExecutionId !== undefined) {
        localVarQueryParameter['pipeline_execution_id'] = pipelineExecutionId;
      }

      if (pipelineNotificationId !== undefined) {
        localVarQueryParameter['pipeline_notification_id'] = pipelineNotificationId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(sFTPKeyID, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete Outbound User
     * @param {string} instanceId
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {DataIn | null} [dataIn]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteOutboundAccountForInstance: async (
      instanceId: string,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      dataIn?: DataIn | null,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'instanceId' is not null or undefined
      assertParamExists('deleteOutboundAccountForInstance', 'instanceId', instanceId);
      const localVarPath = `/api/v1/sftp/outbound/users/{instance_id}`.replace(
        `{${'instance_id'}}`,
        encodeURIComponent(String(instanceId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (httpApiKeyId !== undefined) {
        localVarQueryParameter['http_api_key_id'] = httpApiKeyId;
      }

      if (pipelineId !== undefined) {
        localVarQueryParameter['pipeline_id'] = pipelineId;
      }

      if (pipelineStepId !== undefined) {
        localVarQueryParameter['pipeline_step_id'] = pipelineStepId;
      }

      if (pipelineExecutionId !== undefined) {
        localVarQueryParameter['pipeline_execution_id'] = pipelineExecutionId;
      }

      if (pipelineNotificationId !== undefined) {
        localVarQueryParameter['pipeline_notification_id'] = pipelineNotificationId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(dataIn, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Delete Outbound Ssh Public Key
     * @param {SFTPKeyID} sFTPKeyID
     * @param {string} [instanceId]
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteOutboundSshPublicKey: async (
      sFTPKeyID: SFTPKeyID,
      instanceId?: string,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'sFTPKeyID' is not null or undefined
      assertParamExists('deleteOutboundSshPublicKey', 'sFTPKeyID', sFTPKeyID);
      const localVarPath = `/api/v1/sftp/outbound/keys`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (instanceId !== undefined) {
        localVarQueryParameter['instance_id'] = instanceId;
      }

      if (httpApiKeyId !== undefined) {
        localVarQueryParameter['http_api_key_id'] = httpApiKeyId;
      }

      if (pipelineId !== undefined) {
        localVarQueryParameter['pipeline_id'] = pipelineId;
      }

      if (pipelineStepId !== undefined) {
        localVarQueryParameter['pipeline_step_id'] = pipelineStepId;
      }

      if (pipelineExecutionId !== undefined) {
        localVarQueryParameter['pipeline_execution_id'] = pipelineExecutionId;
      }

      if (pipelineNotificationId !== undefined) {
        localVarQueryParameter['pipeline_notification_id'] = pipelineNotificationId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(sFTPKeyID, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Describe Inbound User
     * @param {string} instanceId
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {DataIn | null} [dataIn]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInboundAccountForInstance: async (
      instanceId: string,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      dataIn?: DataIn | null,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'instanceId' is not null or undefined
      assertParamExists('getInboundAccountForInstance', 'instanceId', instanceId);
      const localVarPath = `/api/v1/sftp/inbound/users/{instance_id}`.replace(
        `{${'instance_id'}}`,
        encodeURIComponent(String(instanceId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (httpApiKeyId !== undefined) {
        localVarQueryParameter['http_api_key_id'] = httpApiKeyId;
      }

      if (pipelineId !== undefined) {
        localVarQueryParameter['pipeline_id'] = pipelineId;
      }

      if (pipelineStepId !== undefined) {
        localVarQueryParameter['pipeline_step_id'] = pipelineStepId;
      }

      if (pipelineExecutionId !== undefined) {
        localVarQueryParameter['pipeline_execution_id'] = pipelineExecutionId;
      }

      if (pipelineNotificationId !== undefined) {
        localVarQueryParameter['pipeline_notification_id'] = pipelineNotificationId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(dataIn, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Describe Outbound User
     * @param {string} instanceId
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {DataIn | null} [dataIn]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOutboundAccountForInstance: async (
      instanceId: string,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      dataIn?: DataIn | null,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'instanceId' is not null or undefined
      assertParamExists('getOutboundAccountForInstance', 'instanceId', instanceId);
      const localVarPath = `/api/v1/sftp/outbound/users/{instance_id}`.replace(
        `{${'instance_id'}}`,
        encodeURIComponent(String(instanceId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (httpApiKeyId !== undefined) {
        localVarQueryParameter['http_api_key_id'] = httpApiKeyId;
      }

      if (pipelineId !== undefined) {
        localVarQueryParameter['pipeline_id'] = pipelineId;
      }

      if (pipelineStepId !== undefined) {
        localVarQueryParameter['pipeline_step_id'] = pipelineStepId;
      }

      if (pipelineExecutionId !== undefined) {
        localVarQueryParameter['pipeline_execution_id'] = pipelineExecutionId;
      }

      if (pipelineNotificationId !== undefined) {
        localVarQueryParameter['pipeline_notification_id'] = pipelineNotificationId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(dataIn, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Import Inbound Ssh Public Key
     * @param {SFTPKey} sFTPKey
     * @param {string} [instanceId]
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    importInboundSshPublicKey: async (
      sFTPKey: SFTPKey,
      instanceId?: string,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'sFTPKey' is not null or undefined
      assertParamExists('importInboundSshPublicKey', 'sFTPKey', sFTPKey);
      const localVarPath = `/api/v1/sftp/inbound/keys`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (instanceId !== undefined) {
        localVarQueryParameter['instance_id'] = instanceId;
      }

      if (httpApiKeyId !== undefined) {
        localVarQueryParameter['http_api_key_id'] = httpApiKeyId;
      }

      if (pipelineId !== undefined) {
        localVarQueryParameter['pipeline_id'] = pipelineId;
      }

      if (pipelineStepId !== undefined) {
        localVarQueryParameter['pipeline_step_id'] = pipelineStepId;
      }

      if (pipelineExecutionId !== undefined) {
        localVarQueryParameter['pipeline_execution_id'] = pipelineExecutionId;
      }

      if (pipelineNotificationId !== undefined) {
        localVarQueryParameter['pipeline_notification_id'] = pipelineNotificationId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(sFTPKey, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Import Outbound Ssh Public Key
     * @param {SFTPKey} sFTPKey
     * @param {string} [instanceId]
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    importOutboundSshPublicKey: async (
      sFTPKey: SFTPKey,
      instanceId?: string,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'sFTPKey' is not null or undefined
      assertParamExists('importOutboundSshPublicKey', 'sFTPKey', sFTPKey);
      const localVarPath = `/api/v1/sftp/outbound/keys`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (instanceId !== undefined) {
        localVarQueryParameter['instance_id'] = instanceId;
      }

      if (httpApiKeyId !== undefined) {
        localVarQueryParameter['http_api_key_id'] = httpApiKeyId;
      }

      if (pipelineId !== undefined) {
        localVarQueryParameter['pipeline_id'] = pipelineId;
      }

      if (pipelineStepId !== undefined) {
        localVarQueryParameter['pipeline_step_id'] = pipelineStepId;
      }

      if (pipelineExecutionId !== undefined) {
        localVarQueryParameter['pipeline_execution_id'] = pipelineExecutionId;
      }

      if (pipelineNotificationId !== undefined) {
        localVarQueryParameter['pipeline_notification_id'] = pipelineNotificationId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(sFTPKey, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * SftpApi - functional programming interface
 * @export
 */
export const SftpApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SftpApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Create Inbound Account
     * @param {SFTPAccountCreate} sFTPAccountCreate
     * @param {string} [instanceId]
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createInboundAccount(
      sFTPAccountCreate: SFTPAccountCreate,
      instanceId?: string,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createInboundAccount(
        sFTPAccountCreate,
        instanceId,
        httpApiKeyId,
        pipelineId,
        pipelineStepId,
        pipelineExecutionId,
        pipelineNotificationId,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['SftpApi.createInboundAccount']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Create Outbound Account
     * @param {SFTPAccountCreate} sFTPAccountCreate
     * @param {string} [instanceId]
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createOutboundAccount(
      sFTPAccountCreate: SFTPAccountCreate,
      instanceId?: string,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createOutboundAccount(
        sFTPAccountCreate,
        instanceId,
        httpApiKeyId,
        pipelineId,
        pipelineStepId,
        pipelineExecutionId,
        pipelineNotificationId,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['SftpApi.createOutboundAccount']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Delete Inbound User
     * @param {string} instanceId
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {DataIn | null} [dataIn]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteInboundAccountForInstance(
      instanceId: string,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      dataIn?: DataIn | null,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteInboundAccountForInstance(
        instanceId,
        httpApiKeyId,
        pipelineId,
        pipelineStepId,
        pipelineExecutionId,
        pipelineNotificationId,
        dataIn,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['SftpApi.deleteInboundAccountForInstance']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Delete Inbound Ssh Public Key
     * @param {SFTPKeyID} sFTPKeyID
     * @param {string} [instanceId]
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteInboundSshPublicKey(
      sFTPKeyID: SFTPKeyID,
      instanceId?: string,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteInboundSshPublicKey(
        sFTPKeyID,
        instanceId,
        httpApiKeyId,
        pipelineId,
        pipelineStepId,
        pipelineExecutionId,
        pipelineNotificationId,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['SftpApi.deleteInboundSshPublicKey']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Delete Outbound User
     * @param {string} instanceId
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {DataIn | null} [dataIn]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteOutboundAccountForInstance(
      instanceId: string,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      dataIn?: DataIn | null,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOutboundAccountForInstance(
        instanceId,
        httpApiKeyId,
        pipelineId,
        pipelineStepId,
        pipelineExecutionId,
        pipelineNotificationId,
        dataIn,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['SftpApi.deleteOutboundAccountForInstance']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Delete Outbound Ssh Public Key
     * @param {SFTPKeyID} sFTPKeyID
     * @param {string} [instanceId]
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteOutboundSshPublicKey(
      sFTPKeyID: SFTPKeyID,
      instanceId?: string,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOutboundSshPublicKey(
        sFTPKeyID,
        instanceId,
        httpApiKeyId,
        pipelineId,
        pipelineStepId,
        pipelineExecutionId,
        pipelineNotificationId,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['SftpApi.deleteOutboundSshPublicKey']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Describe Inbound User
     * @param {string} instanceId
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {DataIn | null} [dataIn]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getInboundAccountForInstance(
      instanceId: string,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      dataIn?: DataIn | null,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SFTPAccount>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getInboundAccountForInstance(
        instanceId,
        httpApiKeyId,
        pipelineId,
        pipelineStepId,
        pipelineExecutionId,
        pipelineNotificationId,
        dataIn,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['SftpApi.getInboundAccountForInstance']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Describe Outbound User
     * @param {string} instanceId
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {DataIn | null} [dataIn]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOutboundAccountForInstance(
      instanceId: string,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      dataIn?: DataIn | null,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SFTPAccount>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getOutboundAccountForInstance(
        instanceId,
        httpApiKeyId,
        pipelineId,
        pipelineStepId,
        pipelineExecutionId,
        pipelineNotificationId,
        dataIn,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['SftpApi.getOutboundAccountForInstance']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Import Inbound Ssh Public Key
     * @param {SFTPKey} sFTPKey
     * @param {string} [instanceId]
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async importInboundSshPublicKey(
      sFTPKey: SFTPKey,
      instanceId?: string,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.importInboundSshPublicKey(
        sFTPKey,
        instanceId,
        httpApiKeyId,
        pipelineId,
        pipelineStepId,
        pipelineExecutionId,
        pipelineNotificationId,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['SftpApi.importInboundSshPublicKey']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Import Outbound Ssh Public Key
     * @param {SFTPKey} sFTPKey
     * @param {string} [instanceId]
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async importOutboundSshPublicKey(
      sFTPKey: SFTPKey,
      instanceId?: string,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.importOutboundSshPublicKey(
        sFTPKey,
        instanceId,
        httpApiKeyId,
        pipelineId,
        pipelineStepId,
        pipelineExecutionId,
        pipelineNotificationId,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['SftpApi.importOutboundSshPublicKey']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * SftpApi - factory interface
 * @export
 */
export const SftpApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = SftpApiFp(configuration);
  return {
    /**
     *
     * @summary Create Inbound Account
     * @param {SftpApiCreateInboundAccountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createInboundAccount(
      requestParameters: SftpApiCreateInboundAccountRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .createInboundAccount(
          requestParameters.sFTPAccountCreate,
          requestParameters.instanceId,
          requestParameters.httpApiKeyId,
          requestParameters.pipelineId,
          requestParameters.pipelineStepId,
          requestParameters.pipelineExecutionId,
          requestParameters.pipelineNotificationId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Create Outbound Account
     * @param {SftpApiCreateOutboundAccountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createOutboundAccount(
      requestParameters: SftpApiCreateOutboundAccountRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .createOutboundAccount(
          requestParameters.sFTPAccountCreate,
          requestParameters.instanceId,
          requestParameters.httpApiKeyId,
          requestParameters.pipelineId,
          requestParameters.pipelineStepId,
          requestParameters.pipelineExecutionId,
          requestParameters.pipelineNotificationId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Delete Inbound User
     * @param {SftpApiDeleteInboundAccountForInstanceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteInboundAccountForInstance(
      requestParameters: SftpApiDeleteInboundAccountForInstanceRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .deleteInboundAccountForInstance(
          requestParameters.instanceId,
          requestParameters.httpApiKeyId,
          requestParameters.pipelineId,
          requestParameters.pipelineStepId,
          requestParameters.pipelineExecutionId,
          requestParameters.pipelineNotificationId,
          requestParameters.dataIn,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Delete Inbound Ssh Public Key
     * @param {SftpApiDeleteInboundSshPublicKeyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteInboundSshPublicKey(
      requestParameters: SftpApiDeleteInboundSshPublicKeyRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .deleteInboundSshPublicKey(
          requestParameters.sFTPKeyID,
          requestParameters.instanceId,
          requestParameters.httpApiKeyId,
          requestParameters.pipelineId,
          requestParameters.pipelineStepId,
          requestParameters.pipelineExecutionId,
          requestParameters.pipelineNotificationId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Delete Outbound User
     * @param {SftpApiDeleteOutboundAccountForInstanceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteOutboundAccountForInstance(
      requestParameters: SftpApiDeleteOutboundAccountForInstanceRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .deleteOutboundAccountForInstance(
          requestParameters.instanceId,
          requestParameters.httpApiKeyId,
          requestParameters.pipelineId,
          requestParameters.pipelineStepId,
          requestParameters.pipelineExecutionId,
          requestParameters.pipelineNotificationId,
          requestParameters.dataIn,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Delete Outbound Ssh Public Key
     * @param {SftpApiDeleteOutboundSshPublicKeyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteOutboundSshPublicKey(
      requestParameters: SftpApiDeleteOutboundSshPublicKeyRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .deleteOutboundSshPublicKey(
          requestParameters.sFTPKeyID,
          requestParameters.instanceId,
          requestParameters.httpApiKeyId,
          requestParameters.pipelineId,
          requestParameters.pipelineStepId,
          requestParameters.pipelineExecutionId,
          requestParameters.pipelineNotificationId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Describe Inbound User
     * @param {SftpApiGetInboundAccountForInstanceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInboundAccountForInstance(
      requestParameters: SftpApiGetInboundAccountForInstanceRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<SFTPAccount> {
      return localVarFp
        .getInboundAccountForInstance(
          requestParameters.instanceId,
          requestParameters.httpApiKeyId,
          requestParameters.pipelineId,
          requestParameters.pipelineStepId,
          requestParameters.pipelineExecutionId,
          requestParameters.pipelineNotificationId,
          requestParameters.dataIn,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Describe Outbound User
     * @param {SftpApiGetOutboundAccountForInstanceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOutboundAccountForInstance(
      requestParameters: SftpApiGetOutboundAccountForInstanceRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<SFTPAccount> {
      return localVarFp
        .getOutboundAccountForInstance(
          requestParameters.instanceId,
          requestParameters.httpApiKeyId,
          requestParameters.pipelineId,
          requestParameters.pipelineStepId,
          requestParameters.pipelineExecutionId,
          requestParameters.pipelineNotificationId,
          requestParameters.dataIn,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Import Inbound Ssh Public Key
     * @param {SftpApiImportInboundSshPublicKeyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    importInboundSshPublicKey(
      requestParameters: SftpApiImportInboundSshPublicKeyRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .importInboundSshPublicKey(
          requestParameters.sFTPKey,
          requestParameters.instanceId,
          requestParameters.httpApiKeyId,
          requestParameters.pipelineId,
          requestParameters.pipelineStepId,
          requestParameters.pipelineExecutionId,
          requestParameters.pipelineNotificationId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Import Outbound Ssh Public Key
     * @param {SftpApiImportOutboundSshPublicKeyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    importOutboundSshPublicKey(
      requestParameters: SftpApiImportOutboundSshPublicKeyRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .importOutboundSshPublicKey(
          requestParameters.sFTPKey,
          requestParameters.instanceId,
          requestParameters.httpApiKeyId,
          requestParameters.pipelineId,
          requestParameters.pipelineStepId,
          requestParameters.pipelineExecutionId,
          requestParameters.pipelineNotificationId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for createInboundAccount operation in SftpApi.
 * @export
 * @interface SftpApiCreateInboundAccountRequest
 */
export interface SftpApiCreateInboundAccountRequest {
  /**
   *
   * @type {SFTPAccountCreate}
   * @memberof SftpApiCreateInboundAccount
   */
  readonly sFTPAccountCreate: SFTPAccountCreate;

  /**
   *
   * @type {string}
   * @memberof SftpApiCreateInboundAccount
   */
  readonly instanceId?: string;

  /**
   *
   * @type {string}
   * @memberof SftpApiCreateInboundAccount
   */
  readonly httpApiKeyId?: string;

  /**
   *
   * @type {string}
   * @memberof SftpApiCreateInboundAccount
   */
  readonly pipelineId?: string;

  /**
   *
   * @type {string}
   * @memberof SftpApiCreateInboundAccount
   */
  readonly pipelineStepId?: string;

  /**
   *
   * @type {string}
   * @memberof SftpApiCreateInboundAccount
   */
  readonly pipelineExecutionId?: string;

  /**
   *
   * @type {string}
   * @memberof SftpApiCreateInboundAccount
   */
  readonly pipelineNotificationId?: string;
}

/**
 * Request parameters for createOutboundAccount operation in SftpApi.
 * @export
 * @interface SftpApiCreateOutboundAccountRequest
 */
export interface SftpApiCreateOutboundAccountRequest {
  /**
   *
   * @type {SFTPAccountCreate}
   * @memberof SftpApiCreateOutboundAccount
   */
  readonly sFTPAccountCreate: SFTPAccountCreate;

  /**
   *
   * @type {string}
   * @memberof SftpApiCreateOutboundAccount
   */
  readonly instanceId?: string;

  /**
   *
   * @type {string}
   * @memberof SftpApiCreateOutboundAccount
   */
  readonly httpApiKeyId?: string;

  /**
   *
   * @type {string}
   * @memberof SftpApiCreateOutboundAccount
   */
  readonly pipelineId?: string;

  /**
   *
   * @type {string}
   * @memberof SftpApiCreateOutboundAccount
   */
  readonly pipelineStepId?: string;

  /**
   *
   * @type {string}
   * @memberof SftpApiCreateOutboundAccount
   */
  readonly pipelineExecutionId?: string;

  /**
   *
   * @type {string}
   * @memberof SftpApiCreateOutboundAccount
   */
  readonly pipelineNotificationId?: string;
}

/**
 * Request parameters for deleteInboundAccountForInstance operation in SftpApi.
 * @export
 * @interface SftpApiDeleteInboundAccountForInstanceRequest
 */
export interface SftpApiDeleteInboundAccountForInstanceRequest {
  /**
   *
   * @type {string}
   * @memberof SftpApiDeleteInboundAccountForInstance
   */
  readonly instanceId: string;

  /**
   *
   * @type {string}
   * @memberof SftpApiDeleteInboundAccountForInstance
   */
  readonly httpApiKeyId?: string;

  /**
   *
   * @type {string}
   * @memberof SftpApiDeleteInboundAccountForInstance
   */
  readonly pipelineId?: string;

  /**
   *
   * @type {string}
   * @memberof SftpApiDeleteInboundAccountForInstance
   */
  readonly pipelineStepId?: string;

  /**
   *
   * @type {string}
   * @memberof SftpApiDeleteInboundAccountForInstance
   */
  readonly pipelineExecutionId?: string;

  /**
   *
   * @type {string}
   * @memberof SftpApiDeleteInboundAccountForInstance
   */
  readonly pipelineNotificationId?: string;

  /**
   *
   * @type {DataIn}
   * @memberof SftpApiDeleteInboundAccountForInstance
   */
  readonly dataIn?: DataIn | null;
}

/**
 * Request parameters for deleteInboundSshPublicKey operation in SftpApi.
 * @export
 * @interface SftpApiDeleteInboundSshPublicKeyRequest
 */
export interface SftpApiDeleteInboundSshPublicKeyRequest {
  /**
   *
   * @type {SFTPKeyID}
   * @memberof SftpApiDeleteInboundSshPublicKey
   */
  readonly sFTPKeyID: SFTPKeyID;

  /**
   *
   * @type {string}
   * @memberof SftpApiDeleteInboundSshPublicKey
   */
  readonly instanceId?: string;

  /**
   *
   * @type {string}
   * @memberof SftpApiDeleteInboundSshPublicKey
   */
  readonly httpApiKeyId?: string;

  /**
   *
   * @type {string}
   * @memberof SftpApiDeleteInboundSshPublicKey
   */
  readonly pipelineId?: string;

  /**
   *
   * @type {string}
   * @memberof SftpApiDeleteInboundSshPublicKey
   */
  readonly pipelineStepId?: string;

  /**
   *
   * @type {string}
   * @memberof SftpApiDeleteInboundSshPublicKey
   */
  readonly pipelineExecutionId?: string;

  /**
   *
   * @type {string}
   * @memberof SftpApiDeleteInboundSshPublicKey
   */
  readonly pipelineNotificationId?: string;
}

/**
 * Request parameters for deleteOutboundAccountForInstance operation in SftpApi.
 * @export
 * @interface SftpApiDeleteOutboundAccountForInstanceRequest
 */
export interface SftpApiDeleteOutboundAccountForInstanceRequest {
  /**
   *
   * @type {string}
   * @memberof SftpApiDeleteOutboundAccountForInstance
   */
  readonly instanceId: string;

  /**
   *
   * @type {string}
   * @memberof SftpApiDeleteOutboundAccountForInstance
   */
  readonly httpApiKeyId?: string;

  /**
   *
   * @type {string}
   * @memberof SftpApiDeleteOutboundAccountForInstance
   */
  readonly pipelineId?: string;

  /**
   *
   * @type {string}
   * @memberof SftpApiDeleteOutboundAccountForInstance
   */
  readonly pipelineStepId?: string;

  /**
   *
   * @type {string}
   * @memberof SftpApiDeleteOutboundAccountForInstance
   */
  readonly pipelineExecutionId?: string;

  /**
   *
   * @type {string}
   * @memberof SftpApiDeleteOutboundAccountForInstance
   */
  readonly pipelineNotificationId?: string;

  /**
   *
   * @type {DataIn}
   * @memberof SftpApiDeleteOutboundAccountForInstance
   */
  readonly dataIn?: DataIn | null;
}

/**
 * Request parameters for deleteOutboundSshPublicKey operation in SftpApi.
 * @export
 * @interface SftpApiDeleteOutboundSshPublicKeyRequest
 */
export interface SftpApiDeleteOutboundSshPublicKeyRequest {
  /**
   *
   * @type {SFTPKeyID}
   * @memberof SftpApiDeleteOutboundSshPublicKey
   */
  readonly sFTPKeyID: SFTPKeyID;

  /**
   *
   * @type {string}
   * @memberof SftpApiDeleteOutboundSshPublicKey
   */
  readonly instanceId?: string;

  /**
   *
   * @type {string}
   * @memberof SftpApiDeleteOutboundSshPublicKey
   */
  readonly httpApiKeyId?: string;

  /**
   *
   * @type {string}
   * @memberof SftpApiDeleteOutboundSshPublicKey
   */
  readonly pipelineId?: string;

  /**
   *
   * @type {string}
   * @memberof SftpApiDeleteOutboundSshPublicKey
   */
  readonly pipelineStepId?: string;

  /**
   *
   * @type {string}
   * @memberof SftpApiDeleteOutboundSshPublicKey
   */
  readonly pipelineExecutionId?: string;

  /**
   *
   * @type {string}
   * @memberof SftpApiDeleteOutboundSshPublicKey
   */
  readonly pipelineNotificationId?: string;
}

/**
 * Request parameters for getInboundAccountForInstance operation in SftpApi.
 * @export
 * @interface SftpApiGetInboundAccountForInstanceRequest
 */
export interface SftpApiGetInboundAccountForInstanceRequest {
  /**
   *
   * @type {string}
   * @memberof SftpApiGetInboundAccountForInstance
   */
  readonly instanceId: string;

  /**
   *
   * @type {string}
   * @memberof SftpApiGetInboundAccountForInstance
   */
  readonly httpApiKeyId?: string;

  /**
   *
   * @type {string}
   * @memberof SftpApiGetInboundAccountForInstance
   */
  readonly pipelineId?: string;

  /**
   *
   * @type {string}
   * @memberof SftpApiGetInboundAccountForInstance
   */
  readonly pipelineStepId?: string;

  /**
   *
   * @type {string}
   * @memberof SftpApiGetInboundAccountForInstance
   */
  readonly pipelineExecutionId?: string;

  /**
   *
   * @type {string}
   * @memberof SftpApiGetInboundAccountForInstance
   */
  readonly pipelineNotificationId?: string;

  /**
   *
   * @type {DataIn}
   * @memberof SftpApiGetInboundAccountForInstance
   */
  readonly dataIn?: DataIn | null;
}

/**
 * Request parameters for getOutboundAccountForInstance operation in SftpApi.
 * @export
 * @interface SftpApiGetOutboundAccountForInstanceRequest
 */
export interface SftpApiGetOutboundAccountForInstanceRequest {
  /**
   *
   * @type {string}
   * @memberof SftpApiGetOutboundAccountForInstance
   */
  readonly instanceId: string;

  /**
   *
   * @type {string}
   * @memberof SftpApiGetOutboundAccountForInstance
   */
  readonly httpApiKeyId?: string;

  /**
   *
   * @type {string}
   * @memberof SftpApiGetOutboundAccountForInstance
   */
  readonly pipelineId?: string;

  /**
   *
   * @type {string}
   * @memberof SftpApiGetOutboundAccountForInstance
   */
  readonly pipelineStepId?: string;

  /**
   *
   * @type {string}
   * @memberof SftpApiGetOutboundAccountForInstance
   */
  readonly pipelineExecutionId?: string;

  /**
   *
   * @type {string}
   * @memberof SftpApiGetOutboundAccountForInstance
   */
  readonly pipelineNotificationId?: string;

  /**
   *
   * @type {DataIn}
   * @memberof SftpApiGetOutboundAccountForInstance
   */
  readonly dataIn?: DataIn | null;
}

/**
 * Request parameters for importInboundSshPublicKey operation in SftpApi.
 * @export
 * @interface SftpApiImportInboundSshPublicKeyRequest
 */
export interface SftpApiImportInboundSshPublicKeyRequest {
  /**
   *
   * @type {SFTPKey}
   * @memberof SftpApiImportInboundSshPublicKey
   */
  readonly sFTPKey: SFTPKey;

  /**
   *
   * @type {string}
   * @memberof SftpApiImportInboundSshPublicKey
   */
  readonly instanceId?: string;

  /**
   *
   * @type {string}
   * @memberof SftpApiImportInboundSshPublicKey
   */
  readonly httpApiKeyId?: string;

  /**
   *
   * @type {string}
   * @memberof SftpApiImportInboundSshPublicKey
   */
  readonly pipelineId?: string;

  /**
   *
   * @type {string}
   * @memberof SftpApiImportInboundSshPublicKey
   */
  readonly pipelineStepId?: string;

  /**
   *
   * @type {string}
   * @memberof SftpApiImportInboundSshPublicKey
   */
  readonly pipelineExecutionId?: string;

  /**
   *
   * @type {string}
   * @memberof SftpApiImportInboundSshPublicKey
   */
  readonly pipelineNotificationId?: string;
}

/**
 * Request parameters for importOutboundSshPublicKey operation in SftpApi.
 * @export
 * @interface SftpApiImportOutboundSshPublicKeyRequest
 */
export interface SftpApiImportOutboundSshPublicKeyRequest {
  /**
   *
   * @type {SFTPKey}
   * @memberof SftpApiImportOutboundSshPublicKey
   */
  readonly sFTPKey: SFTPKey;

  /**
   *
   * @type {string}
   * @memberof SftpApiImportOutboundSshPublicKey
   */
  readonly instanceId?: string;

  /**
   *
   * @type {string}
   * @memberof SftpApiImportOutboundSshPublicKey
   */
  readonly httpApiKeyId?: string;

  /**
   *
   * @type {string}
   * @memberof SftpApiImportOutboundSshPublicKey
   */
  readonly pipelineId?: string;

  /**
   *
   * @type {string}
   * @memberof SftpApiImportOutboundSshPublicKey
   */
  readonly pipelineStepId?: string;

  /**
   *
   * @type {string}
   * @memberof SftpApiImportOutboundSshPublicKey
   */
  readonly pipelineExecutionId?: string;

  /**
   *
   * @type {string}
   * @memberof SftpApiImportOutboundSshPublicKey
   */
  readonly pipelineNotificationId?: string;
}

/**
 * SftpApi - object-oriented interface
 * @export
 * @class SftpApi
 * @extends {BaseAPI}
 */
export class SftpApi extends BaseAPI {
  /**
   *
   * @summary Create Inbound Account
   * @param {SftpApiCreateInboundAccountRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SftpApi
   */
  public createInboundAccount(requestParameters: SftpApiCreateInboundAccountRequest, options?: RawAxiosRequestConfig) {
    return SftpApiFp(this.configuration)
      .createInboundAccount(
        requestParameters.sFTPAccountCreate,
        requestParameters.instanceId,
        requestParameters.httpApiKeyId,
        requestParameters.pipelineId,
        requestParameters.pipelineStepId,
        requestParameters.pipelineExecutionId,
        requestParameters.pipelineNotificationId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create Outbound Account
   * @param {SftpApiCreateOutboundAccountRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SftpApi
   */
  public createOutboundAccount(
    requestParameters: SftpApiCreateOutboundAccountRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return SftpApiFp(this.configuration)
      .createOutboundAccount(
        requestParameters.sFTPAccountCreate,
        requestParameters.instanceId,
        requestParameters.httpApiKeyId,
        requestParameters.pipelineId,
        requestParameters.pipelineStepId,
        requestParameters.pipelineExecutionId,
        requestParameters.pipelineNotificationId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete Inbound User
   * @param {SftpApiDeleteInboundAccountForInstanceRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SftpApi
   */
  public deleteInboundAccountForInstance(
    requestParameters: SftpApiDeleteInboundAccountForInstanceRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return SftpApiFp(this.configuration)
      .deleteInboundAccountForInstance(
        requestParameters.instanceId,
        requestParameters.httpApiKeyId,
        requestParameters.pipelineId,
        requestParameters.pipelineStepId,
        requestParameters.pipelineExecutionId,
        requestParameters.pipelineNotificationId,
        requestParameters.dataIn,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete Inbound Ssh Public Key
   * @param {SftpApiDeleteInboundSshPublicKeyRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SftpApi
   */
  public deleteInboundSshPublicKey(
    requestParameters: SftpApiDeleteInboundSshPublicKeyRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return SftpApiFp(this.configuration)
      .deleteInboundSshPublicKey(
        requestParameters.sFTPKeyID,
        requestParameters.instanceId,
        requestParameters.httpApiKeyId,
        requestParameters.pipelineId,
        requestParameters.pipelineStepId,
        requestParameters.pipelineExecutionId,
        requestParameters.pipelineNotificationId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete Outbound User
   * @param {SftpApiDeleteOutboundAccountForInstanceRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SftpApi
   */
  public deleteOutboundAccountForInstance(
    requestParameters: SftpApiDeleteOutboundAccountForInstanceRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return SftpApiFp(this.configuration)
      .deleteOutboundAccountForInstance(
        requestParameters.instanceId,
        requestParameters.httpApiKeyId,
        requestParameters.pipelineId,
        requestParameters.pipelineStepId,
        requestParameters.pipelineExecutionId,
        requestParameters.pipelineNotificationId,
        requestParameters.dataIn,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Delete Outbound Ssh Public Key
   * @param {SftpApiDeleteOutboundSshPublicKeyRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SftpApi
   */
  public deleteOutboundSshPublicKey(
    requestParameters: SftpApiDeleteOutboundSshPublicKeyRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return SftpApiFp(this.configuration)
      .deleteOutboundSshPublicKey(
        requestParameters.sFTPKeyID,
        requestParameters.instanceId,
        requestParameters.httpApiKeyId,
        requestParameters.pipelineId,
        requestParameters.pipelineStepId,
        requestParameters.pipelineExecutionId,
        requestParameters.pipelineNotificationId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Describe Inbound User
   * @param {SftpApiGetInboundAccountForInstanceRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SftpApi
   */
  public getInboundAccountForInstance(
    requestParameters: SftpApiGetInboundAccountForInstanceRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return SftpApiFp(this.configuration)
      .getInboundAccountForInstance(
        requestParameters.instanceId,
        requestParameters.httpApiKeyId,
        requestParameters.pipelineId,
        requestParameters.pipelineStepId,
        requestParameters.pipelineExecutionId,
        requestParameters.pipelineNotificationId,
        requestParameters.dataIn,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Describe Outbound User
   * @param {SftpApiGetOutboundAccountForInstanceRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SftpApi
   */
  public getOutboundAccountForInstance(
    requestParameters: SftpApiGetOutboundAccountForInstanceRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return SftpApiFp(this.configuration)
      .getOutboundAccountForInstance(
        requestParameters.instanceId,
        requestParameters.httpApiKeyId,
        requestParameters.pipelineId,
        requestParameters.pipelineStepId,
        requestParameters.pipelineExecutionId,
        requestParameters.pipelineNotificationId,
        requestParameters.dataIn,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Import Inbound Ssh Public Key
   * @param {SftpApiImportInboundSshPublicKeyRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SftpApi
   */
  public importInboundSshPublicKey(
    requestParameters: SftpApiImportInboundSshPublicKeyRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return SftpApiFp(this.configuration)
      .importInboundSshPublicKey(
        requestParameters.sFTPKey,
        requestParameters.instanceId,
        requestParameters.httpApiKeyId,
        requestParameters.pipelineId,
        requestParameters.pipelineStepId,
        requestParameters.pipelineExecutionId,
        requestParameters.pipelineNotificationId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Import Outbound Ssh Public Key
   * @param {SftpApiImportOutboundSshPublicKeyRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SftpApi
   */
  public importOutboundSshPublicKey(
    requestParameters: SftpApiImportOutboundSshPublicKeyRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return SftpApiFp(this.configuration)
      .importOutboundSshPublicKey(
        requestParameters.sFTPKey,
        requestParameters.instanceId,
        requestParameters.httpApiKeyId,
        requestParameters.pipelineId,
        requestParameters.pipelineStepId,
        requestParameters.pipelineExecutionId,
        requestParameters.pipelineNotificationId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * UserPermissionsApi - axios parameter creator
 * @export
 */
export const UserPermissionsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Retrieve user permissions.
     * @summary Get User Permissions
     * @param {string} instanceId
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {DataIn | null} [dataIn]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserPermissionsForInstance: async (
      instanceId: string,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      dataIn?: DataIn | null,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'instanceId' is not null or undefined
      assertParamExists('getUserPermissionsForInstance', 'instanceId', instanceId);
      const localVarPath = `/api/v1/user-permissions/{instance_id}`.replace(
        `{${'instance_id'}}`,
        encodeURIComponent(String(instanceId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (httpApiKeyId !== undefined) {
        localVarQueryParameter['http_api_key_id'] = httpApiKeyId;
      }

      if (pipelineId !== undefined) {
        localVarQueryParameter['pipeline_id'] = pipelineId;
      }

      if (pipelineStepId !== undefined) {
        localVarQueryParameter['pipeline_step_id'] = pipelineStepId;
      }

      if (pipelineExecutionId !== undefined) {
        localVarQueryParameter['pipeline_execution_id'] = pipelineExecutionId;
      }

      if (pipelineNotificationId !== undefined) {
        localVarQueryParameter['pipeline_notification_id'] = pipelineNotificationId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(dataIn, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UserPermissionsApi - functional programming interface
 * @export
 */
export const UserPermissionsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = UserPermissionsApiAxiosParamCreator(configuration);
  return {
    /**
     * Retrieve user permissions.
     * @summary Get User Permissions
     * @param {string} instanceId
     * @param {string} [httpApiKeyId]
     * @param {string} [pipelineId]
     * @param {string} [pipelineStepId]
     * @param {string} [pipelineExecutionId]
     * @param {string} [pipelineNotificationId]
     * @param {DataIn | null} [dataIn]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUserPermissionsForInstance(
      instanceId: string,
      httpApiKeyId?: string,
      pipelineId?: string,
      pipelineStepId?: string,
      pipelineExecutionId?: string,
      pipelineNotificationId?: string,
      dataIn?: DataIn | null,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<number>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUserPermissionsForInstance(
        instanceId,
        httpApiKeyId,
        pipelineId,
        pipelineStepId,
        pipelineExecutionId,
        pipelineNotificationId,
        dataIn,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['UserPermissionsApi.getUserPermissionsForInstance']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * UserPermissionsApi - factory interface
 * @export
 */
export const UserPermissionsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = UserPermissionsApiFp(configuration);
  return {
    /**
     * Retrieve user permissions.
     * @summary Get User Permissions
     * @param {UserPermissionsApiGetUserPermissionsForInstanceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserPermissionsForInstance(
      requestParameters: UserPermissionsApiGetUserPermissionsForInstanceRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<number>> {
      return localVarFp
        .getUserPermissionsForInstance(
          requestParameters.instanceId,
          requestParameters.httpApiKeyId,
          requestParameters.pipelineId,
          requestParameters.pipelineStepId,
          requestParameters.pipelineExecutionId,
          requestParameters.pipelineNotificationId,
          requestParameters.dataIn,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for getUserPermissionsForInstance operation in UserPermissionsApi.
 * @export
 * @interface UserPermissionsApiGetUserPermissionsForInstanceRequest
 */
export interface UserPermissionsApiGetUserPermissionsForInstanceRequest {
  /**
   *
   * @type {string}
   * @memberof UserPermissionsApiGetUserPermissionsForInstance
   */
  readonly instanceId: string;

  /**
   *
   * @type {string}
   * @memberof UserPermissionsApiGetUserPermissionsForInstance
   */
  readonly httpApiKeyId?: string;

  /**
   *
   * @type {string}
   * @memberof UserPermissionsApiGetUserPermissionsForInstance
   */
  readonly pipelineId?: string;

  /**
   *
   * @type {string}
   * @memberof UserPermissionsApiGetUserPermissionsForInstance
   */
  readonly pipelineStepId?: string;

  /**
   *
   * @type {string}
   * @memberof UserPermissionsApiGetUserPermissionsForInstance
   */
  readonly pipelineExecutionId?: string;

  /**
   *
   * @type {string}
   * @memberof UserPermissionsApiGetUserPermissionsForInstance
   */
  readonly pipelineNotificationId?: string;

  /**
   *
   * @type {DataIn}
   * @memberof UserPermissionsApiGetUserPermissionsForInstance
   */
  readonly dataIn?: DataIn | null;
}

/**
 * UserPermissionsApi - object-oriented interface
 * @export
 * @class UserPermissionsApi
 * @extends {BaseAPI}
 */
export class UserPermissionsApi extends BaseAPI {
  /**
   * Retrieve user permissions.
   * @summary Get User Permissions
   * @param {UserPermissionsApiGetUserPermissionsForInstanceRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserPermissionsApi
   */
  public getUserPermissionsForInstance(
    requestParameters: UserPermissionsApiGetUserPermissionsForInstanceRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return UserPermissionsApiFp(this.configuration)
      .getUserPermissionsForInstance(
        requestParameters.instanceId,
        requestParameters.httpApiKeyId,
        requestParameters.pipelineId,
        requestParameters.pipelineStepId,
        requestParameters.pipelineExecutionId,
        requestParameters.pipelineNotificationId,
        requestParameters.dataIn,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * VfModulesApi - axios parameter creator
 * @export
 */
export const VfModulesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Create a VF module.
     * @summary Create Vf Module
     * @param {VFModuleCreate} vFModuleCreate
     * @param {string} [vfModuleId]
     * @param {string} [pipelineTemplateId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createVfModule: async (
      vFModuleCreate: VFModuleCreate,
      vfModuleId?: string,
      pipelineTemplateId?: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'vFModuleCreate' is not null or undefined
      assertParamExists('createVfModule', 'vFModuleCreate', vFModuleCreate);
      const localVarPath = `/api/v1/vf-modules`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (vfModuleId !== undefined) {
        localVarQueryParameter['vf_module_id'] = vfModuleId;
      }

      if (pipelineTemplateId !== undefined) {
        localVarQueryParameter['pipeline_template_id'] = pipelineTemplateId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(vFModuleCreate, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Delete a VF module.
     * @summary Delete Vf Module
     * @param {string} vfModuleId
     * @param {string} [pipelineTemplateId]
     * @param {DataIn1 | null} [dataIn1]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteVfModule: async (
      vfModuleId: string,
      pipelineTemplateId?: string,
      dataIn1?: DataIn1 | null,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'vfModuleId' is not null or undefined
      assertParamExists('deleteVfModule', 'vfModuleId', vfModuleId);
      const localVarPath = `/api/v1/vf-modules/{vf_module_id}`.replace(
        `{${'vf_module_id'}}`,
        encodeURIComponent(String(vfModuleId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (pipelineTemplateId !== undefined) {
        localVarQueryParameter['pipeline_template_id'] = pipelineTemplateId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(dataIn1, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get a VF module by id.
     * @summary Get Vf Module
     * @param {string} vfModuleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVfModule: async (vfModuleId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'vfModuleId' is not null or undefined
      assertParamExists('getVfModule', 'vfModuleId', vfModuleId);
      const localVarPath = `/api/v1/vf-modules/{vf_module_id}`.replace(
        `{${'vf_module_id'}}`,
        encodeURIComponent(String(vfModuleId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get VF modules satisfies query filter
     * @summary Get Vf Modules
     * @param {number} [limit]
     * @param {string} [lastEvaluatedKey]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVfModules: async (
      limit?: number,
      lastEvaluatedKey?: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/api/v1/vf-modules`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (lastEvaluatedKey !== undefined) {
        localVarQueryParameter['last_evaluated_key'] = lastEvaluatedKey;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Update a VF module.
     * @summary Update Vf Module
     * @param {string} vfModuleId
     * @param {VFModuleUpdate} vFModuleUpdate
     * @param {string} [pipelineTemplateId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateVfModule: async (
      vfModuleId: string,
      vFModuleUpdate: VFModuleUpdate,
      pipelineTemplateId?: string,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'vfModuleId' is not null or undefined
      assertParamExists('updateVfModule', 'vfModuleId', vfModuleId);
      // verify required parameter 'vFModuleUpdate' is not null or undefined
      assertParamExists('updateVfModule', 'vFModuleUpdate', vFModuleUpdate);
      const localVarPath = `/api/v1/vf-modules/{vf_module_id}`.replace(
        `{${'vf_module_id'}}`,
        encodeURIComponent(String(vfModuleId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (pipelineTemplateId !== undefined) {
        localVarQueryParameter['pipeline_template_id'] = pipelineTemplateId;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(vFModuleUpdate, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * VfModulesApi - functional programming interface
 * @export
 */
export const VfModulesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = VfModulesApiAxiosParamCreator(configuration);
  return {
    /**
     * Create a VF module.
     * @summary Create Vf Module
     * @param {VFModuleCreate} vFModuleCreate
     * @param {string} [vfModuleId]
     * @param {string} [pipelineTemplateId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createVfModule(
      vFModuleCreate: VFModuleCreate,
      vfModuleId?: string,
      pipelineTemplateId?: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VFModule>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createVfModule(
        vFModuleCreate,
        vfModuleId,
        pipelineTemplateId,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['VfModulesApi.createVfModule']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Delete a VF module.
     * @summary Delete Vf Module
     * @param {string} vfModuleId
     * @param {string} [pipelineTemplateId]
     * @param {DataIn1 | null} [dataIn1]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteVfModule(
      vfModuleId: string,
      pipelineTemplateId?: string,
      dataIn1?: DataIn1 | null,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteVfModule(
        vfModuleId,
        pipelineTemplateId,
        dataIn1,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['VfModulesApi.deleteVfModule']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Get a VF module by id.
     * @summary Get Vf Module
     * @param {string} vfModuleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getVfModule(
      vfModuleId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VFModule>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getVfModule(vfModuleId, options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['VfModulesApi.getVfModule']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Get VF modules satisfies query filter
     * @summary Get Vf Modules
     * @param {number} [limit]
     * @param {string} [lastEvaluatedKey]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getVfModules(
      limit?: number,
      lastEvaluatedKey?: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VFModule>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getVfModules(limit, lastEvaluatedKey, options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['VfModulesApi.getVfModules']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     * Update a VF module.
     * @summary Update Vf Module
     * @param {string} vfModuleId
     * @param {VFModuleUpdate} vFModuleUpdate
     * @param {string} [pipelineTemplateId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateVfModule(
      vfModuleId: string,
      vFModuleUpdate: VFModuleUpdate,
      pipelineTemplateId?: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VFModule>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateVfModule(
        vfModuleId,
        vFModuleUpdate,
        pipelineTemplateId,
        options,
      );
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['VfModulesApi.updateVfModule']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * VfModulesApi - factory interface
 * @export
 */
export const VfModulesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = VfModulesApiFp(configuration);
  return {
    /**
     * Create a VF module.
     * @summary Create Vf Module
     * @param {VfModulesApiCreateVfModuleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createVfModule(
      requestParameters: VfModulesApiCreateVfModuleRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<VFModule> {
      return localVarFp
        .createVfModule(
          requestParameters.vFModuleCreate,
          requestParameters.vfModuleId,
          requestParameters.pipelineTemplateId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Delete a VF module.
     * @summary Delete Vf Module
     * @param {VfModulesApiDeleteVfModuleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteVfModule(
      requestParameters: VfModulesApiDeleteVfModuleRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<any> {
      return localVarFp
        .deleteVfModule(
          requestParameters.vfModuleId,
          requestParameters.pipelineTemplateId,
          requestParameters.dataIn1,
          options,
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Get a VF module by id.
     * @summary Get Vf Module
     * @param {VfModulesApiGetVfModuleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVfModule(
      requestParameters: VfModulesApiGetVfModuleRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<VFModule> {
      return localVarFp.getVfModule(requestParameters.vfModuleId, options).then((request) => request(axios, basePath));
    },
    /**
     * Get VF modules satisfies query filter
     * @summary Get Vf Modules
     * @param {VfModulesApiGetVfModulesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVfModules(
      requestParameters: VfModulesApiGetVfModulesRequest = {},
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<Array<VFModule>> {
      return localVarFp
        .getVfModules(requestParameters.limit, requestParameters.lastEvaluatedKey, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Update a VF module.
     * @summary Update Vf Module
     * @param {VfModulesApiUpdateVfModuleRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateVfModule(
      requestParameters: VfModulesApiUpdateVfModuleRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<VFModule> {
      return localVarFp
        .updateVfModule(
          requestParameters.vfModuleId,
          requestParameters.vFModuleUpdate,
          requestParameters.pipelineTemplateId,
          options,
        )
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for createVfModule operation in VfModulesApi.
 * @export
 * @interface VfModulesApiCreateVfModuleRequest
 */
export interface VfModulesApiCreateVfModuleRequest {
  /**
   *
   * @type {VFModuleCreate}
   * @memberof VfModulesApiCreateVfModule
   */
  readonly vFModuleCreate: VFModuleCreate;

  /**
   *
   * @type {string}
   * @memberof VfModulesApiCreateVfModule
   */
  readonly vfModuleId?: string;

  /**
   *
   * @type {string}
   * @memberof VfModulesApiCreateVfModule
   */
  readonly pipelineTemplateId?: string;
}

/**
 * Request parameters for deleteVfModule operation in VfModulesApi.
 * @export
 * @interface VfModulesApiDeleteVfModuleRequest
 */
export interface VfModulesApiDeleteVfModuleRequest {
  /**
   *
   * @type {string}
   * @memberof VfModulesApiDeleteVfModule
   */
  readonly vfModuleId: string;

  /**
   *
   * @type {string}
   * @memberof VfModulesApiDeleteVfModule
   */
  readonly pipelineTemplateId?: string;

  /**
   *
   * @type {DataIn1}
   * @memberof VfModulesApiDeleteVfModule
   */
  readonly dataIn1?: DataIn1 | null;
}

/**
 * Request parameters for getVfModule operation in VfModulesApi.
 * @export
 * @interface VfModulesApiGetVfModuleRequest
 */
export interface VfModulesApiGetVfModuleRequest {
  /**
   *
   * @type {string}
   * @memberof VfModulesApiGetVfModule
   */
  readonly vfModuleId: string;
}

/**
 * Request parameters for getVfModules operation in VfModulesApi.
 * @export
 * @interface VfModulesApiGetVfModulesRequest
 */
export interface VfModulesApiGetVfModulesRequest {
  /**
   *
   * @type {number}
   * @memberof VfModulesApiGetVfModules
   */
  readonly limit?: number;

  /**
   *
   * @type {string}
   * @memberof VfModulesApiGetVfModules
   */
  readonly lastEvaluatedKey?: string;
}

/**
 * Request parameters for updateVfModule operation in VfModulesApi.
 * @export
 * @interface VfModulesApiUpdateVfModuleRequest
 */
export interface VfModulesApiUpdateVfModuleRequest {
  /**
   *
   * @type {string}
   * @memberof VfModulesApiUpdateVfModule
   */
  readonly vfModuleId: string;

  /**
   *
   * @type {VFModuleUpdate}
   * @memberof VfModulesApiUpdateVfModule
   */
  readonly vFModuleUpdate: VFModuleUpdate;

  /**
   *
   * @type {string}
   * @memberof VfModulesApiUpdateVfModule
   */
  readonly pipelineTemplateId?: string;
}

/**
 * VfModulesApi - object-oriented interface
 * @export
 * @class VfModulesApi
 * @extends {BaseAPI}
 */
export class VfModulesApi extends BaseAPI {
  /**
   * Create a VF module.
   * @summary Create Vf Module
   * @param {VfModulesApiCreateVfModuleRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VfModulesApi
   */
  public createVfModule(requestParameters: VfModulesApiCreateVfModuleRequest, options?: RawAxiosRequestConfig) {
    return VfModulesApiFp(this.configuration)
      .createVfModule(
        requestParameters.vFModuleCreate,
        requestParameters.vfModuleId,
        requestParameters.pipelineTemplateId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Delete a VF module.
   * @summary Delete Vf Module
   * @param {VfModulesApiDeleteVfModuleRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VfModulesApi
   */
  public deleteVfModule(requestParameters: VfModulesApiDeleteVfModuleRequest, options?: RawAxiosRequestConfig) {
    return VfModulesApiFp(this.configuration)
      .deleteVfModule(
        requestParameters.vfModuleId,
        requestParameters.pipelineTemplateId,
        requestParameters.dataIn1,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get a VF module by id.
   * @summary Get Vf Module
   * @param {VfModulesApiGetVfModuleRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VfModulesApi
   */
  public getVfModule(requestParameters: VfModulesApiGetVfModuleRequest, options?: RawAxiosRequestConfig) {
    return VfModulesApiFp(this.configuration)
      .getVfModule(requestParameters.vfModuleId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get VF modules satisfies query filter
   * @summary Get Vf Modules
   * @param {VfModulesApiGetVfModulesRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VfModulesApi
   */
  public getVfModules(requestParameters: VfModulesApiGetVfModulesRequest = {}, options?: RawAxiosRequestConfig) {
    return VfModulesApiFp(this.configuration)
      .getVfModules(requestParameters.limit, requestParameters.lastEvaluatedKey, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Update a VF module.
   * @summary Update Vf Module
   * @param {VfModulesApiUpdateVfModuleRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VfModulesApi
   */
  public updateVfModule(requestParameters: VfModulesApiUpdateVfModuleRequest, options?: RawAxiosRequestConfig) {
    return VfModulesApiFp(this.configuration)
      .updateVfModule(
        requestParameters.vfModuleId,
        requestParameters.vFModuleUpdate,
        requestParameters.pipelineTemplateId,
        options,
      )
      .then((request) => request(this.axios, this.basePath));
  }
}
