import { createContext, useState } from 'react';

import { DocumentFilter } from 'src/api-new/bifrost';

import { DocumentFiltersOperatorUIOnly, FiltersOperator } from './types';

type InsightsState = {
  filters: DocumentFilter[];
  filtersOperator: FiltersOperator;
  setFilters: (filters: DocumentFilter[]) => void;
  setFiltersOperator: (operator: FiltersOperator) => void;
};

export const InsightsContext = createContext<InsightsState>({} as InsightsState);

export function InsightsProvider({ children }: { children: React.ReactNode }) {
  const [filters, setFilters] = useState<DocumentFilter[]>([]);
  const [filtersOperator, setFiltersOperator] = useState<FiltersOperator>(DocumentFiltersOperatorUIOnly.None);

  return (
    <InsightsContext.Provider value={{ filters, setFilters, filtersOperator, setFiltersOperator }}>
      {children}
    </InsightsContext.Provider>
  );
}
