import { Button, FormControl, Select, Wrapper, useStyletron } from '@visualfabriq/vf-ui-kit';
import React, { useState } from 'react';

import { PipelineStepType } from 'src/dto/PipelineStep/PipelineStepType';

type Props = {
  children: React.ReactNode;
  canAdd: boolean;
  onAddDraft: (type: PipelineStepType) => void;
};

export function TemplateSteps(props: Props) {
  const { children: steps, canAdd, onAddDraft } = props;
  const [_, $theme] = useStyletron();

  const [newStepType, setNewStepType] = useState<PipelineStepType>(SUPPORTED_STEP_TYPES[0]);

  const handleAddDraftStep = () => {
    onAddDraft(newStepType);
  };

  return (
    <>
      <FormControl label="Steps" required bottomSpace={$theme.sizing.scale200}>
        {steps}
      </FormControl>

      <Wrapper gap="200" marginBlock="200">
        <Select
          size="default"
          overrides={{ Root: { style: { width: 'fit-content' } } }}
          options={SUPPORTED_STEP_TYPES.map((type) => ({ id: type, label: type }))}
          value={[{ id: newStepType }]}
          onChange={(event) => {
            if (event.type !== 'select') return;
            setNewStepType(event.value[0].id as PipelineStepType);
          }}
        />
        <Button disabled={!canAdd} onClick={handleAddDraftStep}>
          Add Step
        </Button>
      </Wrapper>
    </>
  );
}

const SUPPORTED_STEP_TYPES = [PipelineStepType.data_processing, PipelineStepType.data_staging];
