/* eslint-disable react-hooks/rules-of-hooks */
import { useContext } from 'react';
import {
  AuthConsumerContext,
  HubHandler,
  UserContext as UserContextMFE,
  refreshTokenRequest,
} from '@visualfabriq/mfe-utils';

import { UserContext } from 'src/domain/user/UserContext';
import { EnvVars } from 'src/services/envVars';
import {
  AuthHubChannels,
  AuthConsumerHubEvents,
  AuthToken,
} from '@visualfabriq/mfe-utils/dist/network/auth/shared/types';

type AuthorizedUser = { getAccessToken: () => Promise<string>; email: string; firstName: string };

export function useUser(): AuthorizedUser {
  if (EnvVars.isMFE) {
    const authContext = useContext(AuthConsumerContext);
    const userContext = useContext(UserContextMFE);

    const getAccessToken = async () => {
      const expiresAt = authContext.authToken?.expiresAt ?? 0;
      if (expiresAt > Date.now()) {
        return Promise.resolve(authContext.authToken?.accessToken as TODO<string>);
      }

      return new Promise<TODO<string>>((resolve, reject) => {
        refreshTokenRequest();
        const unsubscribeAuthConsumer = HubHandler.hub.listen(AuthHubChannels.AuthConsumer, async ({ payload }) => {
          switch (payload.event) {
            case AuthConsumerHubEvents.AuthTokenUpdated: {
              unsubscribeAuthConsumer();
              if ((payload.data as AuthToken).accessToken) {
                return resolve(payload.data.accessToken);
              } else {
                return reject("Couldn't refresh token");
              }
            }
          }
        });
      });
    };

    return {
      getAccessToken,
      email: userContext.user.email,
      firstName: userContext.user.firstName,
    };
  }

  const userContext = useContext(UserContext);
  return {
    getAccessToken: async () => {
      const user = await userContext.api.getUser();
      return user.accessToken;
    },
    email: userContext.user.email,
    firstName: userContext.user.firstName,
  };
}
