import { EnvVars } from 'src/services/envVars';
import { buildUrl } from 'src/services/buildUrl';
import { captureAPIResponseCode, captureException } from 'src/services/sentry';

type MakeApiRequestArgs = {
  path: string;
  getAccessToken: () => Promise<string>;
  queryParams?: Record<string, string> | [string, string][];
  customHeaders?: Record<string, string>;
  body?: Record<string, any>;
  method?: string;
};

export async function makeApiRequest({
  path,
  getAccessToken,
  queryParams,
  customHeaders = {},
  body,
  method = 'GET',
}: MakeApiRequestArgs) {
  const url = buildUrl({
    baseUrl: EnvVars.apiBaseUrl,
    path,
    queryParams,
  });
  const headers = new Headers(customHeaders);
  await addAuthHeader(headers, getAccessToken);
  const requestBody = getBody(body, headers);

  try {
    const response = await fetch(url, {
      method,
      ...(requestBody ? { body: requestBody } : {}),
      // transform headers explicitly to be able check them in tests
      headers: Object.fromEntries(headers.entries()),
    });

    if (!response.ok) {
      captureAPIResponseCode(response, { url, requestBody, method });
    }

    return response;
  } catch (error) {
    captureException(error);
    throw error;
  }
}

export function getBody(body: MakeApiRequestArgs['body'], headers: Headers) {
  if (!body) {
    return;
  }

  headers.set('Content-Type', 'application/json');
  return JSON.stringify(body);
}

export async function addAuthHeader(headers: Headers, getAccessToken: () => Promise<string>) {
  const accessToken = await getAccessToken();
  headers.set(`Authorization`, accessToken);
}
