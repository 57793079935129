import { useContext, useEffect, useState } from 'react';
import { LabelMedium, Wrapper, useSnackbar } from '@visualfabriq/vf-ui-kit';

import { useStepper } from 'src/components/hooks/useStepper';
import { VFModule, VfModulesApi } from 'src/api-new/bifrost';
import { useBifrostApi } from 'src/services/useBifrostApi';
import { FilledTemplateEvent } from 'src/domain/pipelines/types';

import { SelectModuleStep } from './SelectModuleStep';
import { CreateCustomTemplateState } from './types';
import { SelectTemplatesStep } from './SelectTemplatesStep';
import { FillTemplateStep } from './FillArgumentsStep';
import { StepLoading } from './StepLayout';
import { ModalContext } from 'src/components/molecules/Modal/ModalProvider';
import { CreatePipelineUsingTemplateError } from 'src/domain/pipelines/PipelinesProvider/types';
import { CreateFromTemplatesErrors } from '../CreateFromTemplatesErrors';
import { Loader } from 'src/components/atoms/Loader';
import { captureException } from 'src/services/sentry';

type Props = {
  instanceId: string;
  createPipelinesFormTemplates: (args: FilledTemplateEvent & { instanceId: string }) => Promise<{
    errorsMap: Map<string, CreatePipelineUsingTemplateError>;
  }>;
  onClose: () => void;
};

export function CrateUsingTemplateDrawer(props: Props) {
  const { instanceId, createPipelinesFormTemplates, onClose } = props;
  const { Stepper, nextStep, prevStep, reset, state } = useStepper<CreateCustomTemplateState>({
    selectedModule: null,
    selectedTemplatesIds: [],
  });
  const snackbar = useSnackbar();
  const { openModal } = useContext(ModalContext);
  const [loading, setLoading] = useState(false);
  const [customLoader, setCustomLoader] = useState<JSX.Element | null>(null);
  const [modules, setModules] = useState<VFModule[]>([]);
  const vfModulesApi = useBifrostApi(VfModulesApi);

  useEffect(() => {
    async function initialFetch() {
      try {
        setLoading(true);
        const { data: modules } = await vfModulesApi.getVfModules();
        setModules(modules);
      } catch (error) {
        captureException(error);
        snackbar.enqueueErrorSnackbar(error.message);
      } finally {
        setLoading(false);
      }
    }

    initialFetch();
  }, []);

  const handleFinish = async (
    { filledTemplateMap, selectedTemplates, nameByTemplateIdMap }: FilledTemplateEvent,
    startNew: boolean,
  ) => {
    try {
      setLoading(true);
      setCustomLoader(
        <Wrapper width="600px" padding={600} direction="column" alignItems="center" gap={500}>
          <Loader />
          <LabelMedium>Creating pipelines...</LabelMedium>
        </Wrapper>,
      );
      const { errorsMap } = await createPipelinesFormTemplates({
        filledTemplateMap,
        selectedTemplates,
        instanceId,
        nameByTemplateIdMap,
      });
      if (errorsMap.size) {
        errorsMap.forEach((templateError) => {
          captureException(templateError.error);
        });
        openModal(<CreateFromTemplatesErrors errorsMap={errorsMap} />, { closeable: true, size: 'auto' });
      } else {
        snackbar.enqueueSuccessSnackbar('Pipeline(s) created');
        if (startNew) reset();
      }
    } finally {
      setLoading(false);
      setCustomLoader(null);
      if (!startNew) onClose();
    }
  };

  return (
    <Stepper loading={loading} loader={customLoader || <StepLoading />}>
      <SelectModuleStep state={state} onNext={nextStep} modules={modules} />
      <SelectTemplatesStep state={state} instanceId={instanceId} onNext={nextStep} onPrev={prevStep} />
      <FillTemplateStep state={state} onFinish={handleFinish} onPrev={prevStep} />
    </Stepper>
  );
}
