import { Wrapper, useStyletron } from '@visualfabriq/vf-ui-kit';

type Props = React.PropsWithChildren<{
  footer?: React.ReactElement;
  loading: boolean;
  loader: React.ReactElement;
}>;

export function StepConfigLayout(props: Props) {
  const { children, footer, loader: loadingComponent, loading } = props;
  const [css, theme] = useStyletron();

  if (loading) {
    return loadingComponent;
  }

  return (
    <Wrapper direction="column" height="100%" gap={200}>
      <Wrapper direction="column" className={css({ overflow: 'auto', marginBottom: theme.sizing.scale200 })} gap={200}>
        {children}
      </Wrapper>
      {footer}
    </Wrapper>
  );
}
