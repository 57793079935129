import { BugReportIcon, RocketLaunchIcon, Skeleton, Button, Tooltip } from '@visualfabriq/vf-ui-kit';
import { useAsyncRetry } from 'react-use';
import { PipelineExecutionsApi } from 'src/api-new/bifrost';

import { IconButton } from 'src/components/molecules/IconButton';
import { FINISHED_EXECUTION_STATES } from 'src/domain/pipelines/constants';
import { PipelineFilteredDTO } from 'src/dto/pipelines/Pipeline';
import { useBifrostApi } from 'src/services/useBifrostApi';

export function ExecutionButton({
  pipeline,
  disabled,
  onExecute,
}: {
  pipeline: PipelineFilteredDTO;
  disabled: boolean;
  onExecute: () => void;
}) {
  const pipelineExecutionsApi = useBifrostApi(PipelineExecutionsApi);
  const pipelineExecutionsState = useAsyncRetry(async () => {
    if (disabled) return;
    const response = await pipelineExecutionsApi.getPipelineExecutions({
      pipelineExecutionRequestBody: {
        pipeline_id_filter: { key: 'pipeline_id', condition: 'exact', exact_value: pipeline.id },
        display_state_filter: { key: 'display_state', condition: 'nin', exact_value: FINISHED_EXECUTION_STATES },
        skip: 0,
        limit: 1,
      },
    });
    return response.data;
  }, [pipeline, disabled]);

  if (pipelineExecutionsState.loading) {
    return <Skeleton width="32px" height="32px" />;
  }

  if (pipelineExecutionsState.error) {
    return (
      <Tooltip
        limitedWidth={false}
        content={
          <>
            Failed to fetch execution status
            <br /> Reason: {pipelineExecutionsState.error.message}
          </>
        }
      >
        <Button kind="secondary" startEnhancer={<BugReportIcon color="red" />} onClick={pipelineExecutionsState.retry}>
          Retry
        </Button>
      </Tooltip>
    );
  }

  return (
    <IconButton
      kind="secondary"
      icon={<RocketLaunchIcon />}
      title="Execute"
      disabled={disabled || pipelineExecutionsState.value!.length > 0 || pipeline.blocked}
      onClick={onExecute}
      placement="top"
    />
  );
}
