import { useContext, useEffect, useState } from 'react';
import {
  AddIcon,
  ArrowDownwardIcon,
  Button,
  DeleteIcon,
  NoContent,
  Wrapper,
  useSnackbar,
} from '@visualfabriq/vf-ui-kit';

import { downloadTextFile } from 'src/utils/files/downloadTextFile';
import { ModalContext } from 'src/components/molecules/Modal/ModalProvider';

import { HttpKeysTable } from './HttpKeysTable';
import { NewHTTPKeyModal } from './NewHTTPKeyModal';
import { SelectedCount } from 'src/components/atoms/SelectedCount';
import { useInstances } from 'src/components/hooks/useInstances';
import { BadResponse } from 'src/api/types';
import { useBifrostApi } from 'src/services/useBifrostApi';
import { HttpApiKeyApi, HTTPAPIKey as HttpKey } from 'src/api-new/bifrost';
import { captureException } from 'src/services/sentry';

export const HttpKeys = () => {
  const { selectedInstance } = useInstances();
  const { enqueueErrorSnackbar } = useSnackbar();
  const [keys, setKeys] = useState<HttpKey[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedIds, setSelectedIds] = useState(new Set<string>());
  const { openModal, closeModal } = useContext(ModalContext);
  const httpKeyApi = useBifrostApi(HttpApiKeyApi);

  useEffect(() => {
    const getInbound = async () => {
      setLoading(true);
      try {
        const { data: httpKeys } = await httpKeyApi.getFilteredHttpKeys({ instanceId: selectedInstance.id });
        setKeys(httpKeys);
      } catch (error) {
        enqueueErrorSnackbar(error.message);
      } finally {
        setLoading(false);
      }
    };

    if (selectedInstance?.id) {
      getInbound();
    }
  }, [selectedInstance?.id]);

  const onInboundClick = (items: { id: string; selected: boolean }[]) => {
    const selectedIds = new Set(items.filter(({ selected }) => selected).map(({ id }) => id));
    setSelectedIds(selectedIds);
  };

  const deleteHttpInboundByKeys = async (keysToDelete: string[]) => {
    const keysSet = new Set(keysToDelete);
    try {
      const responses = await Promise.all(
        Array.from(keysSet, (key) => httpKeyApi.deleteHttpKey({ httpApiKeyId: key })),
      );
      if (responses.every((response) => response.status === 200)) {
        setKeys(keys.filter(({ id }) => !keysSet.has(id as string)));
        setSelectedIds(new Set(keysToDelete.filter((id) => !keysSet.has(id))));
      } else if (responses[0].status === 403) {
        const { detail } = responses[0].data as unknown as BadResponse;
        enqueueErrorSnackbar(detail);
      } else {
        const fulfilled = await Promise.all(
          responses.filter((response) => response.status === 200).map(({ data }) => data),
        );
        const fulfilledIdsSet = new Set<string>(fulfilled.map(({ id }) => id));
        setSelectedIds(new Set(keysToDelete.filter((id) => !fulfilledIdsSet.has(id))));

        const failed = await Promise.all(
          responses
            .filter((response) => response.status !== 200)
            .map((response) => response.data as unknown as BadResponse),
        );

        enqueueErrorSnackbar(failed.map(({ detail }) => detail).join('\n'));
      }
    } catch (error) {
      enqueueErrorSnackbar(error.message);
    }
  };

  const openCreateNewCredentialModal = () => {
    openModal(
      <NewHTTPKeyModal
        onAdd={async (key) => {
          try {
            const { data } = await httpKeyApi.createHttpKey({
              hTTPAPIKeyCreate: { instance_id: selectedInstance.id, key_label: key },
            });
            setKeys([...keys, data]);
          } catch (error) {
            enqueueErrorSnackbar(error.message);
            captureException(error);
          } finally {
            closeModal();
          }
        }}
        onCancel={closeModal}
      />,
    );
  };

  const exportKeys = (selectedIds: Set<string>) => {
    const credentialsToExport = keys.filter(({ id }) => selectedIds.has(id));
    const text =
      'Key label | Key content\n' +
      credentialsToExport.map((credentials) => [credentials.key_label, credentials.shared_key].join(' | ')).join('\n');
    downloadTextFile('export.txt', text);
  };

  return (
    <Wrapper direction="column" gap={400}>
      <Wrapper gap={200}>
        <Button startEnhancer={AddIcon} onClick={openCreateNewCredentialModal}>
          Add Key
        </Button>
        <Button
          startEnhancer={ArrowDownwardIcon}
          kind="secondary"
          onClick={() => {
            exportKeys(selectedIds);
          }}
          disabled={!selectedIds.size}
        >
          Export Credential(s)
        </Button>
        <Button
          startEnhancer={DeleteIcon}
          kind="secondary"
          onClick={() => {
            deleteHttpInboundByKeys(Array.from(selectedIds));
          }}
          disabled={!selectedIds.size}
        >
          Delete Credential(s)
        </Button>
        {selectedIds.size >= 1 && <SelectedCount count={selectedIds.size} />}
      </Wrapper>
      {keys.length || loading ? (
        <HttpKeysTable keys={keys} onSelect={onInboundClick} loading={loading} />
      ) : (
        <NoContent />
      )}
    </Wrapper>
  );
};
