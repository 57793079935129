import Bowser from 'bowser';

const delay = (milliseconds: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, milliseconds);
  });

const browser = Bowser.getParser(window.navigator.userAgent);

const isChromeBase = browser.satisfies({ chrome: '>97' });

export const downloadFile = async (url: string, filename: string): Promise<void> => {
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  a.style.display = 'none';
  a.click();
  // it seem that chrome has some limitations for fast click, so slowdown a bit
  // if not chrome slowdown even more
  await delay(isChromeBase ? 300 : 1000);
  a.remove();
};
