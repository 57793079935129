import { useEffect, useState } from 'react';

import { Input, FormControl } from '@visualfabriq/vf-ui-kit';

type useDefaultFieldsConfigurationArgs = {
  step: { name: string; description: string; id: string };
};

export function useDefaultFieldsConfiguration({ step }: useDefaultFieldsConfigurationArgs) {
  const [description, setDescription] = useState<string>(step.description);
  const [name, setName] = useState<string>(step.name);

  useEffect(() => {
    setDescription(step.description);
    setName(step.name);
  }, [step.name, step.id, step.description]);

  const defaultFieldsConfiguration = (
    <>
      <FormControl label="Name" disabled={false} required>
        <Input value={name} onChange={(event) => setName(event.target.value)} />
      </FormControl>
      <FormControl label="Description" disabled={false} required>
        <Input value={description} onChange={(event) => setDescription(event.target.value)} />
      </FormControl>
    </>
  );

  return {
    defaultFieldsConfiguration,
    name,
    description,
  };
}
