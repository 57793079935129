import { ListLoadingBox, StyleObject, Wrapper, styled, useStyletron } from '@visualfabriq/vf-ui-kit';

type Props = {
  loading: boolean;
  loadingItems?: number;
  loaderTestId?: string;
  heading?: React.ReactNode;
  style?: StyleObject;
};

export function List(props: React.PropsWithChildren<Props>) {
  const { children, style = {}, loaderTestId = 'ListLoader', loadingItems = 10, loading, heading } = props;
  const [css] = useStyletron();

  return (
    <Wrapper direction="column" className={css(style)}>
      {heading}
      <ContentWrapper>
        {loading ? <ListLoadingBox itemsCount={loadingItems} itemHeight="20px" data-testid={loaderTestId} /> : children}
      </ContentWrapper>
    </Wrapper>
  );
}

const ContentWrapper = styled('div', () => ({
  overflow: 'auto',
  paddingRight: '10px',
  minHeight: '100px', // to prevent showing scroll for <Loading />
}));
