import { Button, Wrapper, useStyletron } from '@visualfabriq/vf-ui-kit';

import { PipelineStep } from 'src/dto/PipelineStep';

import { StepList } from './StepList';

type Props = {
  steps: PipelineStep[] | undefined;
  addStepButton: JSX.Element;
  reorderMode: boolean;
  onReorder: (event: { oldIndex: number; newIndex: number }) => void;
  onReorderModeChange: (value: boolean) => void;
};

export function Steps(props: Props) {
  const { steps, addStepButton, reorderMode, onReorder, onReorderModeChange } = props;
  const [, theme] = useStyletron();

  return (
    <Wrapper direction="column" marginBlock={`0 ${theme.sizing.scale200}`} height="100%">
      <StepList steps={steps} reorderEnabled={reorderMode} onReorder={onReorder} />
      <Wrapper gap="200" marginBlock="200">
        {addStepButton}
        <Button disabled={!steps?.length} onClick={() => onReorderModeChange(!reorderMode)} kind="secondary">
          {reorderMode ? 'Finished' : 'Change Step Order'}
        </Button>
      </Wrapper>
    </Wrapper>
  );
}
