import { HeadingXSmall, Tag, useStyletron, Wrapper } from '@visualfabriq/vf-ui-kit';

type Props = {
  label: string;
  unsaved: boolean;
};

export function TemplateHeading({ label, unsaved }: Props) {
  const [_, theme] = useStyletron();

  return (
    <Wrapper alignItems="center">
      <HeadingXSmall marginBottom={theme.sizing.scale200} marginTop={theme.sizing.scale200}>
        {label}
      </HeadingXSmall>
      {unsaved && (
        <Tag size="small" color="blue">
          Unsaved
        </Tag>
      )}
    </Wrapper>
  );
}
