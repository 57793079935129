export function groupMultipleByFn<TItem>(items: TItem[], groupBy: (item: TItem) => string): Map<string, TItem[]> {
  return items.reduce<Map<string, TItem[]>>((map, item) => {
    const key = groupBy(item);
    if (map.has(key)) {
      map.get(key)!.push(item);
    } else {
      map.set(key, [item]);
    }
    return map;
  }, new Map());
}
