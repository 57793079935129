import { endpointPaths } from 'src/api/endpointPaths';
import { makeApiRequest } from 'src/services/makeApiRequest';
import { UserPermission } from 'src/domain/permissions/types';

export async function getUserPermissions(
  instanceId: string,
  getAccessToken: () => Promise<string>,
): Promise<UserPermission[]> {
  const response = await makeApiRequest({ path: `${endpointPaths.userPermissions}/${instanceId}`, getAccessToken });

  const responseData = await response.json();
  if (!response.ok) {
    throw Error((responseData as { message: string }).message);
  }
  return responseData as UserPermission[];
}
