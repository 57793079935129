import { PivotColumnValues } from 'src/api-new/bifrost';
import { TransformationType } from './TransformationType';

export interface TransformationModel {
  transformation_type: TransformationType;
  filter?: Filter | null;
  transformation_parameters: TransformationParameters;
}

export type Filter = {
  column: string;
  option: FilterOptions;
  value: string | boolean | number;
};

export enum FilterOptions {
  equals = 'equals',
}

export type TransformationParameters =
  | ToDate
  | ToInt
  | ToFloat
  | ToBoolean
  | StripValue
  | RenameColumn
  | ToLowercase
  | ReplaceValue
  | ReplaceColumnValue
  | AddStaticColumn
  | ConcatenateColumns
  | ValueArithmeticOperation
  | ColumnArithmeticOperation
  | PivotColumnValues;

export enum TransformationParametersMethod {
  ToDate = 'to_date',
  ToInt = 'to_int',
  ToFloat = 'to_float',
  ToBoolean = 'to_boolean',
  StripValue = 'strip_value',
  RenameColumn = 'rename_column',
  ToLowercase = 'to_lowercase',
  ReplaceValue = 'replace_value',
  ReplaceColumnValue = 'replace_column_value',
  AddStaticColumn = 'add_static_column',
  ConcatenateColumns = 'concatenate_columns',
  ValueArithmeticOperation = 'value_arithmetic_operation',
  ColumnArithmeticOperation = 'column_arithmetic_operation',
  PivotColumnValues = 'pivot_columns_values',
}

export type TransformationParametersMethodAsLiteral = `${TransformationParametersMethod}`;

export type ToDate = {
  columns: string | string[];
  method: TransformationParametersMethod.ToDate;
  date_format: string;
  errors_as_none: boolean;
};

export type ToInt = {
  columns: string | string[];
  method: TransformationParametersMethod.ToInt;
  default: number;
  force_type: boolean;
};

export type ToFloat = {
  columns: string | string[];
  method: TransformationParametersMethod.ToFloat;
  default: number;
  force_type: boolean;
};

export type ToBoolean = {
  columns: string | string[];
  method: TransformationParametersMethod.ToBoolean;
  default: boolean;
  force_type: boolean;
};

export type StripValue = {
  columns: string | string[];
  method: TransformationParametersMethod.StripValue;
  to_strip: string;
  how: StripValueHow;
};

export enum StripValueHow {
  Left = 'left',
  Right = 'right',
  Both = 'both',
}

export interface ToLowercase {
  columns: string | string[];
  method: TransformationParametersMethod.ToLowercase;
}

export type RenameColumn = {
  columns: {
    [k: string]: string;
  };
  method: TransformationParametersMethod.RenameColumn;
};

export type ReplaceValue = {
  method: TransformationParametersMethod.ReplaceValue;
  columns: string | string[];
  from_replace: string;
  to_replace: string;
};

export type ReplaceColumnValue = {
  method: TransformationParametersMethod.ReplaceColumnValue;
  columns: string | string[];
  from_replace: string;
  to_replace: string;
};

export type AddStaticColumn = {
  columns: {
    [k: string]: string;
  };
  method: TransformationParametersMethod.AddStaticColumn;
};

export type ConcatenateColumns = {
  columns: string | string[];
  method: TransformationParametersMethod.ConcatenateColumns;
  to_column: string;
  concatenator_value: string;
};

export enum ArithmeticOperators {
  Add = 'add',
  Subtract = 'subtract',
  Mulitply = 'multiply',
  Divide = 'divide',
}

export type ValueArithmeticOperation = {
  columns: string | string[];
  method: TransformationParametersMethod.ValueArithmeticOperation;
  operator: ArithmeticOperators;
  operand: number;
};

export type ColumnArithmeticOperation = {
  columns: string | string[];
  method: TransformationParametersMethod.ColumnArithmeticOperation;
  operator: ArithmeticOperators;
  to_column: string;
};
