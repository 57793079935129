import { CreatePipelineDTO, PipelineDTO } from 'src/dto/pipelines/Pipeline';
import { PipelineType } from 'src/dto/pipelines/PipelineType';
import { endpointPaths } from '../../endpointPaths';
import { makeApiRequest } from 'src/services/makeApiRequest';
import { BadResponse } from 'src/api/types';

export async function createPipeline({
  instanceId,
  name,
  schedule,
  description,
  owner_email,
  time_zone,
  getAccessToken,
}: CreatePipelineDTO & {
  getAccessToken: () => Promise<string>;
}): Promise<PipelineDTO> {
  const pipeline: Partial<PipelineDTO> = {
    name,
    description,
    type: PipelineType.Inbound,
    instance_id: instanceId,
    enabled: false,
    schedule,
    owner_email,
    subscribers_emails: [],
    time_zone,
  };

  const response = await makeApiRequest({
    path: endpointPaths.pipelines,
    method: 'POST',
    body: pipeline,
    getAccessToken,
  });

  if (response.ok) {
    return response.json() as Promise<PipelineDTO>;
  } else {
    if ([422, 403].includes(response.status)) {
      const { detail } = (await response.json()) as BadResponse;
      throw new Error(detail);
    }
    throw new Error(await response.text());
  }
}
