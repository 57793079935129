import { EnvVars } from 'src/services/envVars';

type buildUrlArgs = {
  baseUrl?: string;
  path: string;
  queryParams?: Record<string, string> | [string, string][];
};

export function buildUrl({ baseUrl = EnvVars.apiBaseUrl!, path, queryParams }: buildUrlArgs) {
  const host = baseUrl.endsWith('/') ? baseUrl : `${baseUrl}/`;
  const normalizedPath = path.startsWith('/') ? path.slice(1) : path;
  const queryString = queryParams ? `?${new URLSearchParams(queryParams).toString()}` : '';
  return `${host}${normalizedPath}${queryString}`;
}
