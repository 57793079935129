import { Tag, Wrapper } from '@visualfabriq/vf-ui-kit';

type Props = {
  keyFromFile: string;
  targetCollection: string;
  isCreatingModal: boolean;
  isDraft: boolean;
  isUnsaved: boolean;
};

export function CollectionPanelTitle(props: Props) {
  const { keyFromFile, targetCollection, isCreatingModal, isDraft, isUnsaved } = props;

  return (
    <Wrapper gap={200} alignItems="center">
      {keyFromFile && targetCollection ? `${keyFromFile}: ${targetCollection}` : ''}
      {isUnsaved && !isDraft && !isCreatingModal && <Tag color="blue">Unsaved</Tag>}
      {isDraft && <Tag color="green">Creating</Tag>}
    </Wrapper>
  );
}
