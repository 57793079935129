import { useContext, useState } from 'react';
import { Accordion, Button, ContentWrapper, Panel, Tag, Wrapper } from '@visualfabriq/vf-ui-kit';

import * as DataStagingStep from 'src/dto/PipelineStep/DataStagingStep';
import { ModalContext } from 'src/components/molecules/Modal/ModalProvider';
import { getTransformationMethodDefaultParameters } from './getTransformationMethodDefaultParameters';
import { NewTransformationModal } from './NewTransformationModal';
import { TableTransformation } from './TableTransformation';

type TableTransformationsProps = {
  transformations: DataStagingStep.TransformationModel[];
  onUpdate: (transformations: DataStagingStep.TransformationModel[]) => void;
};

const TransformationTypeDisplayName = {
  [DataStagingStep.TransformationType.ColumnTransformation]: 'Column',
  [DataStagingStep.TransformationType.TypeTransformation]: 'Type',
  [DataStagingStep.TransformationType.ValueTransformation]: 'Value',
};

export function TableTransformations(props: TableTransformationsProps) {
  const { transformations, onUpdate } = props;
  const { openModal, closeModal } = useContext(ModalContext);
  const [changedTransformationIndex, setChangedTransformationIndex] = useState<number | null>(null);

  const handleAddTransformation = (type: DataStagingStep.TransformationType) => {
    let newTransformation: DataStagingStep.TransformationModel;

    switch (type) {
      case DataStagingStep.TransformationType.ColumnTransformation:
        newTransformation = {
          transformation_type: DataStagingStep.TransformationType.ColumnTransformation,
          transformation_parameters: getTransformationMethodDefaultParameters(
            DataStagingStep.TransformationParametersMethod.RenameColumn,
          ),
        };
        break;
      case DataStagingStep.TransformationType.TypeTransformation:
        newTransformation = {
          transformation_type: DataStagingStep.TransformationType.TypeTransformation,
          transformation_parameters: getTransformationMethodDefaultParameters(
            DataStagingStep.TransformationParametersMethod.ToDate,
          ),
        };
        break;
      case DataStagingStep.TransformationType.ValueTransformation:
        newTransformation = {
          transformation_type: DataStagingStep.TransformationType.ValueTransformation,
          transformation_parameters: getTransformationMethodDefaultParameters(
            DataStagingStep.TransformationParametersMethod.StripValue,
          ),
        };
        break;
    }

    onUpdate([...transformations, newTransformation]);
    closeModal();
  };

  const handleAddNewTransformation = () => {
    openModal(<NewTransformationModal onAdd={handleAddTransformation} onCancel={closeModal} />);
  };

  const handleTransformationUpdate =
    (indexToUpdate: number) => (transformationToUpdate: DataStagingStep.TransformationModel) => {
      setChangedTransformationIndex(null);
      onUpdate(
        transformations.map((transformation, index) =>
          index === indexToUpdate ? transformationToUpdate : transformation,
        ),
      );
    };

  const handleTransformationDelete = (indexToDelete: number) => () => {
    setChangedTransformationIndex(null);
    onUpdate(transformations.filter((_, index) => indexToDelete !== index));
  };

  return (
    <>
      <Accordion>
        {transformations.map((transformation, index) => {
          const method = transformation.transformation_parameters.method;
          return (
            <Panel
              key={`${method}_${index}`}
              title={
                <span>
                  {`${TransformationTypeDisplayName[transformation.transformation_type]}: ${method}`}{' '}
                  {index === changedTransformationIndex && <Tag color="blue">Changed</Tag>}
                </span>
              }
              onChange={() => setChangedTransformationIndex(null)}
            >
              <ContentWrapper>
                <TableTransformation
                  transformation={transformation}
                  onUpdate={handleTransformationUpdate(index)}
                  onDelete={handleTransformationDelete(index)}
                  onChanged={() => setChangedTransformationIndex(index)}
                />
              </ContentWrapper>
            </Panel>
          );
        })}
      </Accordion>

      <Wrapper gap="200" marginBlock="200">
        <Button onClick={handleAddNewTransformation}>Add Transformation</Button>
      </Wrapper>
    </>
  );
}
