import { generatePath, useMatch, useNavigate } from 'react-router-dom';

import { pathConstants } from 'src/routes';

const instanceIdPattern = `${pathConstants.ROOT}/${pathConstants.INSTANCE_NAME}/:page/*`;

export function useChangeInstance() {
  const instanceMatch = useMatch(instanceIdPattern);
  const navigate = useNavigate();

  const changeInstance = (instanceName: string) => {
    if (!instanceMatch) {
      throw new Error(`can't find match for ${instanceIdPattern}`);
    }

    if (instanceMatch.params.page === pathConstants.INSTANCE_NOT_FOUND) {
      const path = generatePath(instanceMatch.pattern.path, {
        page: pathConstants.WELCOME,
        instanceName,
      });
      navigate(path);
    } else {
      let path: string;
      switch (instanceMatch.params.page) {
        case pathConstants.DASHBOARD.root: {
          const dashboard = instanceMatch.params['*']?.split('/')[0];
          path = generatePath(instanceMatch.pattern.path, {
            page: instanceMatch.params.page,
            instanceName,
            '*': dashboard,
          });
          break;
        }
        default:
          path = generatePath(instanceMatch.pattern.path, { page: instanceMatch.params.page, instanceName });
      }
      navigate(path);
    }
  };

  return changeInstance;
}
