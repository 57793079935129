import { FormControl, Input, Select } from '@visualfabriq/vf-ui-kit';

import * as DataStagingStep from 'src/dto/PipelineStep/DataStagingStep';

type StripValueMethodProps = {
  fields: DataStagingStep.StripValue;
  onUpdate: (fields: DataStagingStep.StripValue) => void;
};

const stripValueHows = [
  DataStagingStep.StripValueHow.Left,
  DataStagingStep.StripValueHow.Right,
  DataStagingStep.StripValueHow.Both,
];

export function StripValueMethod(props: StripValueMethodProps) {
  const { fields, onUpdate } = props;

  return (
    <>
      <FormControl label="To Strip" disabled={false} required>
        <Input
          value={fields.to_strip}
          onChange={(event) =>
            onUpdate({
              ...fields,
              to_strip: event.target.value,
            })
          }
        />
      </FormControl>

      <FormControl label="How" disabled={false} required>
        <Select
          options={stripValueHows.map((type) => ({ id: type, label: type }))}
          value={[{ id: fields.how }]}
          onChange={(event) => {
            if (event.type !== 'select') return;
            onUpdate({
              ...fields,
              how: event.value[0].id as DataStagingStep.StripValueHow,
            });
          }}
        />
      </FormControl>
    </>
  );
}
