import { useEffect, useState, useMemo } from 'react';
import { Button, HeadingXSmall, Textarea, styled, FormControl, Wrapper } from '@visualfabriq/vf-ui-kit';

import { useInstances } from 'src/components/hooks/useInstances';
import { splitWithTruncate } from 'src/utils/string/splitWithTruncate';
import { FilesReplicator, Instance } from 'src/api-new/bifrost';

type Props = {
  replicator: FilesReplicator;
  onUpdate: (replicatorId: string, targetInstances: string[], isEnabled: boolean) => void;
  onDelete: (replicatorId: string) => void;
};

export function UpdateReplicator(props: Props) {
  const { replicator, onUpdate, onDelete } = props;
  const [instancesString, setInstancesString] = useState('');
  const [isEnabled, setIsEnabled] = useState(replicator.enabled);
  const { instances } = useInstances();
  const instancesMapByName = useMemo(
    () => instances.reduce((map, instance) => map.set(instance.name, instance), new Map<string, Instance>()),
    [instances],
  );

  useEffect(() => {
    setInstancesString(replicator?.target_instances.map((instance) => instance.name).join(', '));
    setIsEnabled(replicator.enabled);
  }, [replicator]);

  const handleTargetsUpdate = async () => {
    const targetsNames = new Set(splitWithTruncate(instancesString));
    const targetInstances = Array.from(targetsNames, (instanceName) => instancesMapByName.get(instanceName))
      .filter(Boolean)
      .map(({ id }) => id);

    onUpdate(replicator.id, targetInstances, isEnabled);
  };

  const handleDeleteReplicator = () => {
    onDelete(replicator.id);
  };

  const handleToggleEnabled = () => {
    onUpdate(
      replicator.id,
      replicator.target_instances.map(({ id }) => id),
      !replicator.enabled,
    );
  };

  return (
    <Container>
      <HeadingWrapper>
        <HeadingXSmall>Replicator status: {replicator.enabled ? 'enabled' : 'disabled'} </HeadingXSmall>
        <Button kind={replicator.enabled ? 'secondary' : 'primary'} onClick={handleToggleEnabled}>
          {replicator.enabled ? 'Disable' : 'Enable'}
        </Button>
      </HeadingWrapper>

      <FormControl
        label="Target Instances"
        required
        caption={
          <code>
            Enter a list of instances separated by comma i.e., <code>instance1, instance2, instance3</code>{' '}
          </code>
        }
      >
        <Textarea value={instancesString} onChange={(event) => setInstancesString(event.target.value)} />
      </FormControl>

      <Wrapper gap="200">
        <Button onClick={handleTargetsUpdate}>Update</Button>
        <div style={{ marginLeft: 'auto' }}>
          <Button kind="secondary" onClick={handleDeleteReplicator}>
            Delete
          </Button>
        </div>
      </Wrapper>
    </Container>
  );
}

const Container = styled('div', ({ $theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  width: '400px',
  gap: $theme.sizing.scale100,
}));

const HeadingWrapper = styled('div', ({ $theme }) => ({ display: 'flex', gap: $theme.sizing.scale100 }));
