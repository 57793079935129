import { useState } from 'react';

export function usePaginate<TItem>({ items, pageSize }: { items: TItem[]; pageSize: number }): {
  paginatedItems: TItem[];
  currentPage: number;
  pagesNumber: number;
  setCurrentPage: (page: number) => void;
} {
  const [currentPage, setCurrentPage] = useState(0);
  const pagesNumber = Math.ceil(items.length / pageSize);
  const paginatedItems = items.slice(currentPage * pageSize, (currentPage + 1) * pageSize);

  if (currentPage > pagesNumber) {
    setCurrentPage(0);
  }

  return {
    paginatedItems,
    pagesNumber,
    currentPage,
    setCurrentPage,
  };
}
