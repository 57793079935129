import { useState } from 'react';
import {
  ModalBody,
  ModalFooter,
  FileUploader,
  LabelMedium,
  useStyletron,
  ModalHeader,
  ModalButton,
} from '@visualfabriq/vf-ui-kit';
import { FileObjectList } from 'src/components/molecules/FileObjectList';

type Props = {
  onCancel: () => void;
  onConfirm: (files: File[]) => void;
};

export const UploadModal = (props: Props) => {
  const { onCancel, onConfirm } = props;
  const [selectedFiles, setSelectedFiles] = useState<File[] | null>(null);
  const [_, theme] = useStyletron();

  const handleOnDrop = (files: File[]) => {
    const selectedFilesNames = new Set((selectedFiles ?? []).map((file) => file.name));
    const filesToSelect = files.reduce<File[]>((filesToSelect, file) => {
      if (!selectedFilesNames.has(file.name)) {
        filesToSelect.push(file);
      }
      return filesToSelect;
    }, []);

    setSelectedFiles([...(selectedFiles ?? []), ...filesToSelect]);
  };

  const handleFileRemove = (fileName: string) => {
    setSelectedFiles((selectedFiles ?? []).filter((file) => file.name !== fileName));
  };

  const handleReset = () => {
    setSelectedFiles(null);
  };

  const handleConfirm = () => {
    onConfirm(selectedFiles!);
  };

  return (
    <>
      <ModalHeader>Upload file(s)</ModalHeader>
      <ModalBody>
        <FileUploader onDrop={handleOnDrop} />
        {selectedFiles && (
          <>
            <LabelMedium marginTop={theme.sizing.scale300}>Files to be uploaded</LabelMedium>{' '}
            <LabelMedium color={theme.colors.mono600}>{`(${selectedFiles.length} Total)`}</LabelMedium>
            <FileObjectList
              files={selectedFiles.map(({ name }) => ({ id: name, name, kind: 'File' }))}
              onObjectClick={handleFileRemove}
            />
          </>
        )}
      </ModalBody>

      <ModalFooter>
        {selectedFiles && (
          <ModalButton kind="tertiary" onClick={handleReset}>
            Reset
          </ModalButton>
        )}
        <ModalButton disabled={!selectedFiles?.length} onClick={handleConfirm}>
          Upload
        </ModalButton>
        <ModalButton kind="secondary" onClick={onCancel}>
          Cancel
        </ModalButton>
      </ModalFooter>
    </>
  );
};
