import { Button, ChevronLeftIcon, ChevronRightIcon, LabelSmall, Wrapper } from '@visualfabriq/vf-ui-kit';

type Props = {
  currentPageItemsCount: number;
  skip: number;
  size: number;
  onPrev: () => void;
  onNext: () => void;
  disabled?: boolean;
  className?: string;
};

export function ListPagination(props: Props) {
  const { currentPageItemsCount, skip, size, onNext, onPrev, className, disabled } = props;

  const cantPrev = skip === 0;
  const cantNext = currentPageItemsCount !== size;

  return (
    <Wrapper justifyContent="space-evenly" alignItems="end" className={className}>
      <Button disabled={cantPrev || disabled} kind="tertiary" startEnhancer={ChevronLeftIcon} onClick={onPrev} />
      <LabelSmall>
        {skip + 1} to {skip + currentPageItemsCount}
      </LabelSmall>
      <Button disabled={cantNext || disabled} kind="tertiary" startEnhancer={ChevronRightIcon} onClick={onNext} />
    </Wrapper>
  );
}
