import { Button, CopyIcon, styled, useSnackbar } from '@visualfabriq/vf-ui-kit';

export function Copyable({ children }: { children: string | number | undefined | null }) {
  const { enqueueInfoSnackbar } = useSnackbar();

  const handleCopy = () => {
    if (children) {
      navigator.clipboard.writeText(children.toString());
      enqueueInfoSnackbar('Copied to clipboard');
    }
  };

  if (!children) {
    return null;
  }

  return (
    <WrapperInline>
      {children}
      <Button
        kind="tertiary"
        size="mini"
        shape="square"
        colors={{ color: 'inherit', backgroundColor: 'inherit' }}
        startEnhancer={CopyIcon}
        onClick={handleCopy}
      />
    </WrapperInline>
  );
}

const WrapperInline = styled('span', ({ $theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  gap: $theme.sizing.scale100,
}));
