import {
  Button,
  ContentWrapper,
  LabelLarge,
  LabelMedium,
  LabelSmall,
  Wrapper,
  styled,
  useSnackbar,
} from '@visualfabriq/vf-ui-kit';

import { toLocalStringWithTimeZone } from 'src/utils/date/toLocalStringWithTimeZone';
import { addUTCZoneOffset } from 'src/utils/date/addUTCZoneOffset';
import { Copyable } from 'src/components/atoms/CopyableColumn';
import { FSMState, PipelineExecutionOutput, PipelineExecutionsApi } from 'src/api-new/bifrost';
import { FINISHED_EXECUTION_STATES } from 'src/domain/pipelines/constants';
import { useBifrostApi } from 'src/services/useBifrostApi';
import { downloadFile } from 'src/utils/files/downloadFile';
import { getErrorMessage } from 'src/api-new/bifrost/utils';
import { CodeBlock } from 'src/components/atoms/CodeBlock';
import { captureException } from 'src/services/sentry';

export function StepExecution({
  execution,
  isStopLoading,
  onStopExecution,
}: {
  execution: PipelineExecutionOutput;
  isStopLoading: boolean;
  onStopExecution: (executionId: string) => void;
}) {
  const pipelineExecutionsApi = useBifrostApi(PipelineExecutionsApi);
  const snackbar = useSnackbar();

  const handleDownloadFullLog = async (full_log_path: string) => {
    try {
      const { data } = await pipelineExecutionsApi.getPipelineExecutionStepFullLogPresignedUrl({
        pipelineExecutionId: execution.id,
        fullLogPath: full_log_path,
      });

      downloadFile(data.url, 'test');
    } catch (error) {
      captureException(error);
      snackbar.enqueueErrorSnackbar(getErrorMessage(error));
    }
  };

  return (
    <>
      <KeyValue>
        <LabelSmall>id:</LabelSmall>
        <Copyable>{execution.id}</Copyable>
      </KeyValue>

      <KeyValue>
        <LabelSmall>started:</LabelSmall>
        <LabelSmall kind="secondary">
          {execution.datetime_started
            ? toLocalStringWithTimeZone(new Date(addUTCZoneOffset(execution.datetime_started)), { timeZone: 'UTC' })
            : '-'}
        </LabelSmall>
      </KeyValue>

      <KeyValue>
        <LabelSmall>finished:</LabelSmall>
        <LabelSmall kind="secondary">
          {execution.datetime_finished
            ? toLocalStringWithTimeZone(new Date(addUTCZoneOffset(execution.datetime_finished)), { timeZone: 'UTC' })
            : '-'}
        </LabelSmall>
      </KeyValue>

      <Wrapper gap="200" marginBlock="200">
        <Button
          size="compact"
          isLoading={isStopLoading}
          disabled={!![...FINISHED_EXECUTION_STATES, FSMState.Stopping].includes(execution.display_state!)}
          onClick={() => onStopExecution(execution.id)}
        >
          Stop Execution
        </Button>
      </Wrapper>

      {!!execution.details?.length && (
        <ContentWrapper>
          <LabelLarge>Execution Logs</LabelLarge>
          {execution.details.map(({ step_name, log, full_log_path }) => (
            <ExecutionLog key={step_name}>
              <LabelMedium>{step_name}</LabelMedium>
              <CodeBlock>{log}</CodeBlock>
              {full_log_path && (
                <Button size="compact" kind="secondary" onClick={() => handleDownloadFullLog(full_log_path)}>
                  Download full log
                </Button>
              )}
            </ExecutionLog>
          ))}
        </ContentWrapper>
      )}
    </>
  );
}

const KeyValue = styled('div', ({ $theme }) => ({
  display: 'flex',
  marginBottom: $theme.sizing.scale200,
  gap: $theme.sizing.scale100,
  alignItems: 'baseline',
}));

const ExecutionLog = styled('div', ({ $theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: $theme.sizing.scale200,
  marginLeft: $theme.sizing.scale400,
  marginBottom: $theme.sizing.scale400,
}));
