import { useEffect, useMemo, useState } from 'react';
import { FormControl, Select, Button, NoContent, Wrapper } from '@visualfabriq/vf-ui-kit';

import * as DataStagingStep from 'src/dto/PipelineStep/DataStagingStep';
import { getTransformationMethodDefaultParameters } from '../getTransformationMethodDefaultParameters';
import { splitWithTruncate } from 'src/utils/string/splitWithTruncate';
import { getTransformationFilterValue } from 'src/domain/pipelines/getTransformationFilterValue';
import { MethodForm } from './MethodForm';
import { TransformationFilter, Value as FilterValue } from './TransformationFilter';
import { useStateWithWatchChange } from 'src/components/hooks/useStateWithWatchChange';
import { ColumnsForm } from './ColumnsForm';

const transformationTypes = [
  DataStagingStep.TransformationType.ColumnTransformation,
  DataStagingStep.TransformationType.TypeTransformation,
  DataStagingStep.TransformationType.ValueTransformation,
];

type Props = {
  transformation: DataStagingStep.TransformationModel;
  onUpdate: (transformation: DataStagingStep.TransformationModel) => void;
  onDelete: () => void;
  onChanged: (value: boolean) => void;
};

export function TableTransformation(props: Props) {
  const { transformation, onUpdate, onDelete, onChanged } = props;

  const [isFormValid, setIsFormValid] = useState(true);
  const [transformationType, setTransformationType] = useStateWithWatchChange(
    transformation.transformation_type,
    onChanged,
  );
  const [method, setMethod] = useStateWithWatchChange<DataStagingStep.TransformationParametersMethodAsLiteral>(
    transformation.transformation_parameters.method,
    onChanged,
  );
  const [columns, setColumns] = useStateWithWatchChange(
    () => formatColumns(transformation.transformation_parameters.columns),
    onChanged,
  );
  const [fields, setFields] = useStateWithWatchChange<DataStagingStep.TransformationParameters | undefined>(
    undefined,
    onChanged,
  );
  const [filter, setFilter] = useStateWithWatchChange<FilterValue>(
    {
      value: transformation.filter ? transformation.filter.value.toString() : '',
      option: transformation.filter?.option ?? DataStagingStep.FilterOptions.equals,
      column: transformation.filter?.column ?? '',
    },
    onChanged,
  );

  useEffect(() => {
    if (method === transformation.transformation_parameters.method) {
      setFields(transformation.transformation_parameters, true);
      setColumns(formatColumns(transformation.transformation_parameters.columns), true);
    } else {
      const defaultParams = getTransformationMethodDefaultParameters(
        method as DataStagingStep.TransformationParametersMethod,
      );
      setFields(defaultParams, true);
      setColumns(formatColumns(defaultParams.columns), true);
    }
  }, [method, transformation]);

  useEffect(() => {
    if (transformationType === transformation.transformation_type) {
      setMethod(transformation.transformation_parameters.method, true);
      return;
    }

    switch (transformationType) {
      case DataStagingStep.TransformationType.TypeTransformation:
        setMethod(DataStagingStep.TransformationParametersMethod.ToDate, true);
        break;
      case DataStagingStep.TransformationType.ColumnTransformation:
        setMethod(DataStagingStep.TransformationParametersMethod.RenameColumn, true);
        break;
      case DataStagingStep.TransformationType.ValueTransformation:
        setMethod(DataStagingStep.TransformationParametersMethod.StripValue, true);
        break;
    }
  }, [transformationType]);

  const transformationMethods = useMemo(() => {
    switch (transformationType) {
      case DataStagingStep.TransformationType.TypeTransformation:
        return [
          DataStagingStep.TransformationParametersMethod.ToDate,
          DataStagingStep.TransformationParametersMethod.ToInt,
          DataStagingStep.TransformationParametersMethod.ToFloat,
          DataStagingStep.TransformationParametersMethod.ToBoolean,
        ];
      case DataStagingStep.TransformationType.ColumnTransformation:
        return [
          DataStagingStep.TransformationParametersMethod.RenameColumn,
          DataStagingStep.TransformationParametersMethod.AddStaticColumn,
          DataStagingStep.TransformationParametersMethod.ConcatenateColumns,
          DataStagingStep.TransformationParametersMethod.ColumnArithmeticOperation,
          DataStagingStep.TransformationParametersMethod.PivotColumnValues,
        ];
      case DataStagingStep.TransformationType.ValueTransformation:
        return [
          DataStagingStep.TransformationParametersMethod.StripValue,
          DataStagingStep.TransformationParametersMethod.ToLowercase,
          DataStagingStep.TransformationParametersMethod.ReplaceValue,
          DataStagingStep.TransformationParametersMethod.ReplaceColumnValue,
          DataStagingStep.TransformationParametersMethod.ValueArithmeticOperation,
        ];
    }
  }, [transformationType]);

  const handleApplyChanges = () => {
    onChanged(false);
    onUpdate({
      ...transformation,
      transformation_type: transformationType,
      transformation_parameters: {
        ...fields,
        method,
        columns: typeof columns === 'string' ? splitWithTruncate(columns) : Object.fromEntries(columns),
      } as DataStagingStep.TransformationParameters,
      filter: getFilter(filter),
    });
  };

  function getFilter({ value, option, column }: FilterValue): DataStagingStep.Filter | null {
    if (!value || !column) {
      return null;
    }

    return {
      value: getTransformationFilterValue(value),
      option,
      column,
    };
  }

  if (!fields) {
    return <NoContent />;
  }

  return (
    <>
      <FormControl label="Transformation Type" required disabled={false}>
        <Select
          options={transformationTypes.map((type) => ({ id: type, label: type }))}
          value={[{ id: transformationType }]}
          onChange={(event) => {
            if (event.type !== 'select') return;
            setTransformationType(event.value[0].id as DataStagingStep.TransformationType);
          }}
        />
      </FormControl>

      <FormControl label="Method" required disabled={false}>
        <Select
          options={transformationMethods.map((method) => ({ id: method, label: method }))}
          value={[{ id: method }]}
          onChange={(event) => {
            if (event.type !== 'select') return;
            setMethod(event.value[0].id as DataStagingStep.TransformationParametersMethod);
          }}
        />
      </FormControl>

      <ColumnsForm columns={columns} onChange={setColumns} method={method} />

      {[
        DataStagingStep.TransformationType.TypeTransformation,
        DataStagingStep.TransformationType.ValueTransformation,
      ].includes(transformationType) ? (
        <TransformationFilter value={filter} onChange={setFilter} />
      ) : null}

      <MethodForm fields={fields} onChange={setFields} onValidChange={setIsFormValid} />

      <Wrapper gap="200">
        <Button onClick={handleApplyChanges} disabled={!isFormValid}>
          Apply Changes
        </Button>
        <div style={{ marginLeft: 'auto' }}>
          <Button kind="secondary" onClick={onDelete}>
            Delete Transformation
          </Button>
        </div>
      </Wrapper>
    </>
  );
}

const formatColumns = (columns: DataStagingStep.TransformationParameters['columns']) => {
  if (typeof columns === 'string') {
    return columns;
  }

  if (Array.isArray(columns)) {
    return columns.join(', ');
  }

  return Object.entries(columns);
};
