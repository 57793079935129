import { Navigate } from 'react-router-dom';
import { Skeleton } from '@visualfabriq/vf-ui-kit';

import { useUserPermissions } from 'src/domain/permissions/useUserPermissions';
import { HasAccessPredicate } from 'src/pages/config';

type Props = {
  children: JSX.Element;
  hasAccess: HasAccessPredicate;
};

export function PageGuard({ children, hasAccess }: Props) {
  const userPermissions = useUserPermissions();

  if (userPermissions.isLoading) {
    return <Skeleton width="50rem" height="21rem" rows={7} overrides={skeletonOverrides} />;
  }

  if (hasAccess(userPermissions)) {
    return children;
  }

  return <Navigate to="/" replace />;
}

const skeletonOverrides: React.ComponentProps<typeof Skeleton>['overrides'] = {
  Row: {
    style: {
      height: '2rem',
      marginBottom: '1rem',
    },
  },
};
