import { useEffect, useMemo, useState } from 'react';
import { Input, Pagination, TableBuilder, TableBuilderColumn } from '@visualfabriq/vf-ui-kit';

import { Selectable, useSelectColumn } from 'src/components/hooks/table/useSelectColumn';
import { useSortedColumn } from 'src/components/hooks/table/useSortedColumn';
import { toLocalStringWithTimeZone } from 'src/utils/date/toLocalStringWithTimeZone';
import { usePaginate } from 'src/components/hooks/usePaginate';
import { sizeToBytes } from 'src/utils/sizeToBytes';
import { FileObject } from 'src/domain/files/types';
import { S3ObjectKind } from 'src/api-new/bifrost';

type Props = {
  fileObjects: FileObject[];
  loading: boolean;
  onSelect: (items: Selectable<FileObject>[]) => void;
  renderNameColumn: (row: FileTableData) => JSX.Element;
  onOpenFolder?: (id: string) => void;
  selectable?: boolean;
  foldersOnTop?: boolean;
  hideSelectColumn?: boolean;
};
export function FilesTable(props: Props) {
  const {
    fileObjects,
    onSelect,
    renderNameColumn,
    loading,
    hideSelectColumn = false,
    selectable = !hideSelectColumn,
    foldersOnTop = false,
  } = props;

  const [searchQuery, setSearchQuery] = useState('');

  const tableData = useMemo(
    () =>
      fileObjects.map((item) => ({
        id: item.name,
        name: item.name,
        type: item.kind,
        size: item.size,
        modifiedDate: item.date_modified,
      })),
    [fileObjects],
  );

  const filteredItems = useMemo(() => {
    const query = searchQuery.toLocaleLowerCase();
    return tableData.filter((object) => object.name.toLocaleLowerCase().includes(query));
  }, [tableData, searchQuery]);

  const { sortedData, sortColumn, sortAsc, handleSort } = useSortedColumn({
    tableData: filteredItems,
    initialSortColumn: 'name',
    initialSortOrder: 'asc',
    customSorters: {
      size: (a, b) => {
        const NO_SIZE = '--';
        const sizeA = a === NO_SIZE ? Number.MIN_SAFE_INTEGER : sizeToBytes(a!);
        const sizeB = b === NO_SIZE ? Number.MIN_SAFE_INTEGER : sizeToBytes(b!);
        return sizeA - sizeB;
      },
      name: (a, b, aRaw, bRaw) => {
        if (aRaw.type === bRaw.type || !foldersOnTop) {
          return a.localeCompare(b);
        }
        return aRaw.type === 'Folder' ? -1 : 1;
      },
    },
  });

  const { currentPage, pagesNumber, paginatedItems, setCurrentPage } = usePaginate({ items: sortedData, pageSize: 10 });

  const handleSelect = (selectedIds: Set<string>) => {
    const newTableItems = fileObjects.map((row) => ({ ...row, selected: selectedIds.has(row.id) }));
    onSelect(newTableItems);
  };
  const [selectColumn, selectableTableData] = useSelectColumn(
    { tableData: paginatedItems, onSelect: handleSelect, disabled: !selectable },
    [fileObjects],
  );

  useEffect(() => {
    setCurrentPage(0);
  }, [fileObjects, searchQuery]);

  const handleSearchChanged = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchQuery(event.target.value);
  };

  return (
    <>
      <Input value={searchQuery} placeholder="Search" onChange={handleSearchChanged} />

      <TableBuilder
        overrides={{ Root: { style: { maxHeight: '90%' } } }}
        data={selectableTableData}
        sortColumn={sortColumn}
        sortOrder={sortAsc ? 'ASC' : 'DESC'}
        onSort={handleSort}
        isLoading={loading}
      >
        {!hideSelectColumn && selectColumn}
        <TableBuilderColumn id="name" header="Name" sortable>
          {(row) => renderNameColumn(row)}
        </TableBuilderColumn>
        <TableBuilderColumn id="type" header="Type" sortable>
          {(row) => row.type}
        </TableBuilderColumn>
        <TableBuilderColumn id="size" header="Size" sortable>
          {(row) => row.size}
        </TableBuilderColumn>
        <TableBuilderColumn id="modifiedDate" header="Date Modified" sortable>
          {(row) =>
            row.modifiedDate.valueOf() ? toLocalStringWithTimeZone(row.modifiedDate, { timeZone: 'UTC' }) : '--'
          }
        </TableBuilderColumn>
      </TableBuilder>
      <Pagination
        size="mini"
        currentPage={!pagesNumber ? 0 : currentPage + 1}
        numPages={pagesNumber}
        onPageChange={({ nextPage }) => setCurrentPage(nextPage - 1)}
      />
    </>
  );
}

export type FileTableData = {
  id: string;
  name: string;
  type: S3ObjectKind;
  size: string | undefined;
  modifiedDate: Date;
};
