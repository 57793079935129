import { Tag, Wrapper } from '@visualfabriq/vf-ui-kit';

export function NameColumn({ name, blocked }: { name: string; blocked: boolean }) {
  return (
    <Wrapper alignItems="baseline" wrap="nowrap">
      {name}
      {blocked && (
        <Tag color="black" isSelected size="small">
          blocked
        </Tag>
      )}
    </Wrapper>
  );
}
