import { useEffect } from 'react';
import { Navigate, useParams } from 'react-router-dom';

import { AuthProviderHelp } from 'src/components/organisms/AuthProviderHelp';
import { AUTH_PROVIDER_KEY as AUTH_PROVIDER_KEY } from './constants';

export function AuthProvider() {
  const { provider } = useParams<{ provider: string }>();

  useEffect(() => {
    if (provider) {
      localStorage.setItem(AUTH_PROVIDER_KEY, provider);
    }
  });

  return provider ? <Navigate to="/" replace /> : <AuthProviderHelp />;
}
