import { LabelSmall, SmartToyIcon, Tag, useStyletron, Wrapper } from '@visualfabriq/vf-ui-kit';

import { toLocalStringWithTimeZone } from 'src/utils/date/toLocalStringWithTimeZone';
import { addUTCZoneOffset } from 'src/utils/date/addUTCZoneOffset';
import { getExecutionStatusIcon, getStatusColor, getStatusDisplayName } from 'src/containers/Pipelines/utils';
import { FSMState, PipelineExecution } from 'src/api-new/bifrost';

type Props = {
  execution: PipelineExecution;
};

export function ExecutionTitle(props: Props) {
  const { execution } = props;
  const [, theme] = useStyletron();
  return (
    <Wrapper alignItems="flex-end" marginInline={`0 ${theme.sizing.scale600}`} gap={200}>
      <Tag
        size="small"
        color={getStatusColor(execution.display_state ?? (execution.state as FSMState))}
        startEnhancer={getExecutionStatusIcon(execution.display_state ?? (execution.state as FSMState)) || SmartToyIcon}
      >
        {getStatusDisplayName(execution.display_state ?? (execution.state as FSMState))}
      </Tag>
      <LabelSmall>
        {toLocalStringWithTimeZone(new Date(addUTCZoneOffset(execution.datetime_started)), { timeZone: 'UTC' })}
      </LabelSmall>
    </Wrapper>
  );
}
