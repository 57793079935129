import { LabelLarge, useStyletron } from '@visualfabriq/vf-ui-kit';

import { textBlockOverrides } from 'src/components/styles';

export function CardLabel({ children }: { children: React.ReactNode }) {
  const [_, theme] = useStyletron();

  return (
    <LabelLarge color={theme.colors.primary} overrides={textBlockOverrides}>
      {children}
    </LabelLarge>
  );
}
