import { validate as uuidValidate, version as uuidVersion } from 'uuid';

export function getUuidV4Parts(uuid: string) {
  if (!uuidValidate(uuid) || uuidVersion(uuid) !== 4) {
    throw new Error(`Uuid is invalid`);
  }

  const [timeLow, timeMid, timeHighAndVersion, clockSeqHiAndResClockSeqLow, node] = uuid.split('-');

  return { timeLow, timeMid, timeHighAndVersion, clockSeqHiAndResClockSeqLow, node };
}
