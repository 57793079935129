import { Button, LabelSmall, Wrapper } from '@visualfabriq/vf-ui-kit';

import { userWelcomeTestId } from './constants';
import { MFE_NAV_HEIGHT } from 'src/components/styles';

type ProfileMenuProps = {
  name: string;
  onSignOut: () => void;
};
export function ProfileMenu(props: ProfileMenuProps) {
  const { name, onSignOut } = props;

  return (
    <Wrapper
      height={
        MFE_NAV_HEIGHT // keep the height of the profile menu the same as the MFE nav
      }
      alignItems="baseline"
      paddingBlock={100}
      paddingInline={1000}
      justifyContent="end"
      gap={100}
    >
      <LabelSmall overrides={{ Block: { props: { 'data-testid': userWelcomeTestId } } }}>Hi {name}</LabelSmall>
      <Button kind="secondary" size="mini" onClick={onSignOut}>
        Logout
      </Button>
    </Wrapper>
  );
}
