import { styled } from '@visualfabriq/vf-ui-kit';

import { S3ObjectKind } from 'src/api-new/bifrost';
import { FileObject } from './FileObject';

export function FileObjectList(props: {
  files: { name: string; id: string; kind: S3ObjectKind }[];
  onObjectClick?: (fileName: string) => void;
}) {
  const { files, onObjectClick } = props;

  return (
    <ContentWrapper>
      {files.map((file) => (
        <FileObject key={file.id} file={file} onObjectClick={onObjectClick} />
      ))}
    </ContentWrapper>
  );
}

const ContentWrapper = styled('div', ({ $theme }) => ({
  maxHeight: '300px',
  backgroundColor: $theme.colors.backgroundTertiary,
  padding: $theme.sizing.scale400,
  overflowY: 'auto',
}));
