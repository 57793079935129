import { useEffect, useRef, useState } from 'react';
import { DeleteIcon, Wrapper, styled } from '@visualfabriq/vf-ui-kit';

import { KeyValueInput } from './KeyValueInput';

export type ValueType = 'text' | 'boolean' | 'number' | 'dict' | 'list' | 'date';

type KeysValues = {
  value: [string, string, ValueType][];
  onChange: (args: {
    key: string;
    value: string;
    index?: number;
    action: 'edit' | 'add' | 'delete';
    type: ValueType;
  }) => void;
  className?: string;
  withTypes?: boolean;
};

export function KeysValues(props: KeysValues) {
  const { value: pairs, onChange, className, withTypes = false } = props;
  const [currentlyEditing, setCurrentlyEditing] = useState<{ key: string; name: string } | null>(null);
  const currentlyEditingInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (currentlyEditing && currentlyEditingInputRef.current) {
      currentlyEditingInputRef.current.focus();
      setCurrentlyEditing(null);
    }
  }, [currentlyEditing, currentlyEditingInputRef]);

  return (
    <div className={className}>
      {pairs.map((pair, index) => {
        const [key, value, type] = pair;

        return (
          <Wrapper alignItems="baseline" key={`KeyValueInput${index}`}>
            <KeyValueInput
              canEditType={withTypes}
              value={{ key, value, type }}
              onChange={({ value: { key, value, type } }) => {
                onChange({ key, value, index, action: 'edit', type });
              }}
              ref={currentlyEditing?.key === key ? currentlyEditingInputRef : null}
              refTo={currentlyEditing?.key === key ? currentlyEditing?.name : undefined}
            />
            <DeleteButton>
              <DeleteIcon
                onClick={() => {
                  onChange({ key, value, type, index, action: 'delete' });
                }}
              />
            </DeleteButton>
          </Wrapper>
        );
      })}
      <Wrapper>
        <KeyValueInput
          canEditType={withTypes}
          value={{ key: '', value: '', type: 'text' }}
          onChange={({ value: { key, value, type }, name }) => {
            setCurrentlyEditing({ key, name });
            onChange({ key, value, action: 'add', type });
          }}
        />
      </Wrapper>
    </div>
  );
}

const DeleteButton = styled('div', ({ $theme }) => ({
  display: 'inline-block',
  marginRight: '20px',
  cursor: 'pointer',
  color: $theme.colors.buttonTertiaryActive,

  ':hover': {
    color: 'inherit',
  },
}));
