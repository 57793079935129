import { LabelSmall, Select, Wrapper, useStyletron } from '@visualfabriq/vf-ui-kit';
import { AuthConsumerContext } from '@visualfabriq/mfe-utils';

import { useInstances } from 'src/components/hooks/useInstances';
import { useChangeInstance } from 'src/domain/instances/useChangeInstance';

import { instanceSelectValueContainer } from './constants';
import { useContext } from 'react';
import { DifferentInstancesWarning } from './DifferentInstancesWarning';
import { EnvVars } from 'src/services/envVars';

export function InstancesSelect() {
  const mfeAuthConsumerContext = useContext(AuthConsumerContext);
  const { instances, selectedInstance, loading } = useInstances();
  const changeInstance = useChangeInstance();
  const [css] = useStyletron();

  return (
    <Wrapper gap={400} className={css({ height: 'fit-content', alignItems: 'center' })}>
      {EnvVars.isMFE && (
        <LabelSmall className={css({ textWrap: 'nowrap' })} marginBottom="0">
          Bifrost instance:
        </LabelSmall>
      )}
      <Select
        autoFocus={!loading && !selectedInstance}
        placeholder="Select instance..."
        dataTestid={instanceSelectValueContainer}
        options={instances.map((instance) => ({ id: instance.id, label: instance.name }))}
        maxDropdownHeight="500px"
        value={selectedInstance ? [{ id: selectedInstance?.id }] : []}
        onChange={(params) => {
          if (params.type != 'select') return;
          changeInstance(String(params.value[0].label));
        }}
        overrides={{ Root: { style: { width: '200px' } } }}
      />
      {EnvVars.isMFE && mfeAuthConsumerContext.selectedInstance?.id !== selectedInstance?.name && (
        <DifferentInstancesWarning
          bfName={selectedInstance?.name}
          vfName={mfeAuthConsumerContext.selectedInstance?.id ?? ''}
        />
      )}
    </Wrapper>
  );
}
