import { useState } from 'react';

import { Textarea, FormControl, ModalBody, ModalFooter, ModalHeader, ModalButton } from '@visualfabriq/vf-ui-kit';

type NewSftpCredentialModalProps = {
  onAdd: (key: string) => void;
  onCancel: () => void;
};

export function NewSftpCredentialModal({ onAdd, onCancel }: NewSftpCredentialModalProps) {
  const [key, setKey] = useState('');
  return (
    <>
      <ModalHeader>Add SSH public key</ModalHeader>
      <ModalBody>
        <FormControl label="" disabled={false} required>
          <Textarea
            value={key}
            onChange={(e) => setKey(e.target.value.replace(/(\\n)+$/, ''))}
            placeholder="ssh-rsa ..."
          />
        </FormControl>
      </ModalBody>
      <ModalFooter>
        <ModalButton onClick={() => onAdd(key)}>Add Key</ModalButton>
        <ModalButton kind="secondary" onClick={onCancel}>
          Cancel
        </ModalButton>
      </ModalFooter>
    </>
  );
}
