import { FormControl, Input } from '@visualfabriq/vf-ui-kit';

import * as DataStagingStep from 'src/dto/PipelineStep/DataStagingStep';

type ConcatenateColumnsMethodProps = {
  fields: DataStagingStep.ConcatenateColumns;
  onUpdate: (fields: DataStagingStep.ConcatenateColumns) => void;
};

export function ConcatenateColumnsMethod(props: ConcatenateColumnsMethodProps) {
  const { fields, onUpdate } = props;

  return (
    <>
      <FormControl label="To column" required>
        <Input
          value={fields.to_column}
          onChange={(event) =>
            onUpdate({
              ...fields,
              to_column: event.target.value,
            })
          }
        />
      </FormControl>

      <FormControl label="Concatenator Value" required>
        <Input
          value={fields.concatenator_value}
          onChange={(event) =>
            onUpdate({
              ...fields,
              concatenator_value: event.target.value,
            })
          }
        />
      </FormControl>
    </>
  );
}
