import {
  DeleteIcon,
  EditIcon,
  FileDownloadIcon,
  SettingsIcon,
  styled,
  useSnackbar,
  Wrapper,
} from '@visualfabriq/vf-ui-kit';
import { useContext } from 'react';
import { Link } from 'react-router-dom';

import { useDrawer } from 'src/components/molecules/Drawer/useDrawer';
import { IconButton } from 'src/components/molecules/IconButton';
import { ModalContext } from 'src/components/molecules/Modal/ModalProvider';
import { ConfirmModal } from 'src/components/organisms/ConfirmModal';
import { PipelinesContext } from 'src/domain/pipelines/PipelinesProvider';

import { PipelineConfiguration } from './PipelineConfiguration';
import { Copyable } from 'src/components/atoms/CopyableColumn';
import { PipelineStepsApi, PipelinesApi } from 'src/api-new/bifrost/api';
import { downloadTextFile } from 'src/utils/files/downloadTextFile';
import { pathConstants } from 'src/routes';
import { useUserPermissions } from 'src/domain/permissions/useUserPermissions';
import { useBifrostApi } from 'src/services/useBifrostApi';
import { PipelineStep } from 'src/dto/PipelineStep';
import { ExportedPipeline } from 'src/containers/Pipelines/types';
import { PipelineFilteredDTO } from 'src/dto/pipelines/Pipeline';
import { captureException } from 'src/services/sentry';

export function ConfigurationColumn({ pipeline }: { pipeline: PipelineFilteredDTO }) {
  const { openDrawer } = useDrawer();
  const { openModal, closeModal } = useContext(ModalContext);
  const { enqueueErrorSnackbar, enqueueSuccessSnackbar } = useSnackbar();
  const {
    api: { deletePipeline },
  } = useContext(PipelinesContext);
  const userPermission = useUserPermissions();
  const pipelinesApi = useBifrostApi(PipelinesApi);
  const pipelineStepsApi = useBifrostApi(PipelineStepsApi);

  const handleOpenConfiguration = () => {
    openDrawer(<PipelineConfiguration pipelineId={pipeline.id} />, {
      size: 'auto',
      title: 'Configuration',
      headerElement: (
        <PipelineId>
          id: <Copyable>{pipeline.id}</Copyable>
        </PipelineId>
      ),
    });
  };

  const handleDelete = async () => {
    openModal(
      <ConfirmModal
        onConfirm={async () => {
          try {
            await deletePipeline(pipeline.id);
            enqueueSuccessSnackbar('Pipeline has been deleted');
          } catch (error) {
            enqueueErrorSnackbar(error.message);
            captureException(error);
          } finally {
            closeModal();
          }
        }}
        onCancel={closeModal}
        question={`Are you sure you want to delete "${pipeline.id}"?`}
      />,
    );
  };

  const handleConfigurationExport = async () => {
    try {
      const { data: pipelineToExport } = await pipelinesApi.getPipeline({ pipelineId: pipeline.id });
      const { data: pipelineStepsToExport } = await pipelineStepsApi.getPipelineSteps({ pipelineId: pipeline.id });
      const data: ExportedPipeline = { ...pipelineToExport, steps: pipelineStepsToExport as any as PipelineStep[] };
      downloadTextFile(`${pipeline.name}.json`, JSON.stringify(data, null, 2));
    } catch (error) {
      enqueueErrorSnackbar('Something went wrong');
      throw Error;
    }
  };

  return (
    <Wrapper gap={200}>
      <Link style={{ color: 'initial' }} to={`${pipeline.id}/${pathConstants.PIPELINES.steps.root}`}>
        <IconButton kind="secondary" icon={<EditIcon />} title="Pipeline Steps" placement="top" />
      </Link>
      <IconButton
        kind="secondary"
        icon={<SettingsIcon />}
        title="Pipeline Configuration"
        onClick={handleOpenConfiguration}
        placement="top"
      />
      <IconButton
        kind="secondary"
        icon={<DeleteIcon />}
        title="Delete Pipeline"
        disabled={!userPermission.has('PipelinesDelete')}
        onClick={handleDelete}
        placement="top"
      />
      <IconButton
        kind="secondary"
        icon={<FileDownloadIcon />}
        title="Export Pipeline Configuration"
        onClick={handleConfigurationExport}
        placement="top"
      />
    </Wrapper>
  );
}

const PipelineId = styled('span', ({ $theme }) => ({
  color: $theme.colors.mono600,
}));
