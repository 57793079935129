import { LabelSmall, LabelXSmall, useStyletron, Wrapper } from '@visualfabriq/vf-ui-kit';

export function MutedNotificationsMessage() {
  const [_, $theme] = useStyletron();

  return (
    <Wrapper direction="column" marginBlock={200}>
      <LabelSmall color={$theme.colors.negative}>Pipeline notifications are muted</LabelSmall>
      <LabelXSmall color={$theme.colors.mono500}>
        Please contact your instance pipeline subscription manager for assistance
      </LabelXSmall>
    </Wrapper>
  );
}
