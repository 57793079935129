/* tslint:disable */
/* eslint-disable */
/**
 * Bullis API app :)
 * An API to interact with the bifrost usage logs interactions.
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 *
 * @export
 * @interface AggregateIdentifierFilter
 */
export interface AggregateIdentifierFilter {
  /**
   *
   * @type {string}
   * @memberof AggregateIdentifierFilter
   */
  key: AggregateIdentifierFilterKey;
  /**
   *
   * @type {FilterMethods}
   * @memberof AggregateIdentifierFilter
   */
  condition: FilterMethods;
  /**
   *
   * @type {ExactValue}
   * @memberof AggregateIdentifierFilter
   */
  exact_value: ExactValue;
}

export const AggregateIdentifierFilterKey = {
  AggregateIdentifier: 'aggregate_identifier',
} as const;

export type AggregateIdentifierFilterKey =
  (typeof AggregateIdentifierFilterKey)[keyof typeof AggregateIdentifierFilterKey];

/**
 *
 * @export
 * @enum {string}
 */

export const AllowedLogAction = {
  Create: 'CREATE',
  Update: 'UPDATE',
  Delete: 'DELETE',
} as const;

export type AllowedLogAction = (typeof AllowedLogAction)[keyof typeof AllowedLogAction];

/**
 *
 * @export
 * @interface Context
 */
export interface Context {}
/**
 *
 * @export
 * @interface ExactValue
 */
export interface ExactValue {}
/**
 *
 * @export
 * @interface ExactValue1
 */
export interface ExactValue1 {}
/**
 *
 * @export
 * @enum {string}
 */

export const FilterMethods = {
  Nin: 'nin',
  In: 'in',
  Exact: 'exact',
  Contains: 'contains',
  Gt: 'gt',
  Gte: 'gte',
  Lt: 'lt',
  Lte: 'lte',
  Range: 'range',
} as const;

export type FilterMethods = (typeof FilterMethods)[keyof typeof FilterMethods];

/**
 *
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
  /**
   *
   * @type {Array<ValidationError>}
   * @memberof HTTPValidationError
   */
  detail?: Array<ValidationError>;
}
/**
 *
 * @export
 * @interface ObjectIdentifierFilter
 */
export interface ObjectIdentifierFilter {
  /**
   *
   * @type {string}
   * @memberof ObjectIdentifierFilter
   */
  key: ObjectIdentifierFilterKey;
  /**
   *
   * @type {FilterMethods}
   * @memberof ObjectIdentifierFilter
   */
  condition: FilterMethods;
  /**
   *
   * @type {ExactValue}
   * @memberof ObjectIdentifierFilter
   */
  exact_value: ExactValue;
}

export const ObjectIdentifierFilterKey = {
  ObjectIdentifier: 'object_identifier',
} as const;

export type ObjectIdentifierFilterKey = (typeof ObjectIdentifierFilterKey)[keyof typeof ObjectIdentifierFilterKey];

/**
 *
 * @export
 * @interface ObjectTypeFilter
 */
export interface ObjectTypeFilter {
  /**
   *
   * @type {string}
   * @memberof ObjectTypeFilter
   */
  key: ObjectTypeFilterKey;
  /**
   *
   * @type {FilterMethods}
   * @memberof ObjectTypeFilter
   */
  condition: FilterMethods;
  /**
   *
   * @type {ExactValue}
   * @memberof ObjectTypeFilter
   */
  exact_value: ExactValue;
}

export const ObjectTypeFilterKey = {
  ObjectType: 'object_type',
} as const;

export type ObjectTypeFilterKey = (typeof ObjectTypeFilterKey)[keyof typeof ObjectTypeFilterKey];

/**
 *
 * @export
 * @enum {string}
 */

export const OrderBy = {
  Asc: 'asc',
  Desc: 'desc',
} as const;

export type OrderBy = (typeof OrderBy)[keyof typeof OrderBy];

/**
 *
 * @export
 * @enum {string}
 */

export const SortableFields = {
  Timestamp: 'timestamp',
  InstanceName: 'instance_name',
  ModuleName: 'module_name',
  ObjectIdentifier: 'object_identifier',
  ObjectType: 'object_type',
  UserIdentifier: 'user_identifier',
  Action: 'action',
  Success: 'success',
} as const;

export type SortableFields = (typeof SortableFields)[keyof typeof SortableFields];

/**
 *
 * @export
 * @interface TimeStampFilter
 */
export interface TimeStampFilter {
  /**
   *
   * @type {string}
   * @memberof TimeStampFilter
   */
  key: TimeStampFilterKey;
  /**
   *
   * @type {FilterMethods}
   * @memberof TimeStampFilter
   */
  condition: FilterMethods;
  /**
   *
   * @type {ValuesAbove}
   * @memberof TimeStampFilter
   */
  values_above?: ValuesAbove | null;
  /**
   *
   * @type {ValuesUnder}
   * @memberof TimeStampFilter
   */
  values_under?: ValuesUnder | null;
  /**
   *
   * @type {ExactValue1}
   * @memberof TimeStampFilter
   */
  exact_value?: ExactValue1 | null;
}

export const TimeStampFilterKey = {
  Timestamp: 'timestamp',
} as const;

export type TimeStampFilterKey = (typeof TimeStampFilterKey)[keyof typeof TimeStampFilterKey];

/**
 *
 * @export
 * @interface UsageLogAllBody
 */
export interface UsageLogAllBody {
  /**
   *
   * @type {number}
   * @memberof UsageLogAllBody
   */
  limit?: number;
  /**
   *
   * @type {string}
   * @memberof UsageLogAllBody
   */
  last_evaluated_key?: string | null;
  /**
   *
   * @type {string}
   * @memberof UsageLogAllBody
   */
  instance_name: string;
  /**
   *
   * @type {string}
   * @memberof UsageLogAllBody
   */
  module_name: string;
  /**
   *
   * @type {SortableFields}
   * @memberof UsageLogAllBody
   */
  sort_by?: SortableFields;
  /**
   *
   * @type {OrderBy}
   * @memberof UsageLogAllBody
   */
  order_by?: OrderBy;
  /**
   *
   * @type {Array<UsageLogAllBodyFiltersInner>}
   * @memberof UsageLogAllBody
   */
  filters?: Array<UsageLogAllBodyFiltersInner> | null;
}

/**
 * @type UsageLogAllBodyFiltersInner
 * @export
 */
export type UsageLogAllBodyFiltersInner =
  | ({ key: 'aggregate_identifier' } & AggregateIdentifierFilter)
  | ({ key: 'object_identifier' } & ObjectIdentifierFilter)
  | ({ key: 'object_type' } & ObjectTypeFilter)
  | ({ key: 'timestamp' } & TimeStampFilter)
  | ({ key: 'user_identifier' } & UserIdentifierFilter);

/**
 *
 * @export
 * @interface UsageLogOutputItemModel
 */
export interface UsageLogOutputItemModel {
  /**
   *
   * @type {string}
   * @memberof UsageLogOutputItemModel
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof UsageLogOutputItemModel
   */
  instance_name: string;
  /**
   *
   * @type {string}
   * @memberof UsageLogOutputItemModel
   */
  module_name: string;
  /**
   *
   * @type {string}
   * @memberof UsageLogOutputItemModel
   */
  object_identifier: string;
  /**
   *
   * @type {string}
   * @memberof UsageLogOutputItemModel
   */
  object_type: string;
  /**
   *
   * @type {string}
   * @memberof UsageLogOutputItemModel
   */
  object_name: string;
  /**
   *
   * @type {string}
   * @memberof UsageLogOutputItemModel
   */
  user_identifier: string;
  /**
   *
   * @type {string}
   * @memberof UsageLogOutputItemModel
   */
  timestamp: string;
  /**
   *
   * @type {AllowedLogAction}
   * @memberof UsageLogOutputItemModel
   */
  action: AllowedLogAction;
  /**
   *
   * @type {boolean}
   * @memberof UsageLogOutputItemModel
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof UsageLogOutputItemModel
   */
  aggregate_identifier?: string | null;
  /**
   *
   * @type {Context}
   * @memberof UsageLogOutputItemModel
   */
  context?: Context | null;
  /**
   *
   * @type {string}
   * @memberof UsageLogOutputItemModel
   */
  message?: string | null;
}

/**
 *
 * @export
 * @interface UsageLogOutputItemsModel
 */
export interface UsageLogOutputItemsModel {
  /**
   *
   * @type {Array<UsageLogOutputItemModel>}
   * @memberof UsageLogOutputItemsModel
   */
  items: Array<UsageLogOutputItemModel>;
  /**
   *
   * @type {string}
   * @memberof UsageLogOutputItemsModel
   */
  last_evaluated_key: string | null;
}
/**
 *
 * @export
 * @interface UserIdentifierFilter
 */
export interface UserIdentifierFilter {
  /**
   *
   * @type {string}
   * @memberof UserIdentifierFilter
   */
  key: UserIdentifierFilterKey;
  /**
   *
   * @type {FilterMethods}
   * @memberof UserIdentifierFilter
   */
  condition: FilterMethods;
  /**
   *
   * @type {ExactValue}
   * @memberof UserIdentifierFilter
   */
  exact_value: ExactValue;
}

export const UserIdentifierFilterKey = {
  UserIdentifier: 'user_identifier',
} as const;

export type UserIdentifierFilterKey = (typeof UserIdentifierFilterKey)[keyof typeof UserIdentifierFilterKey];

/**
 *
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
  /**
   *
   * @type {Array<ValidationErrorLocInner>}
   * @memberof ValidationError
   */
  loc: Array<ValidationErrorLocInner>;
  /**
   *
   * @type {string}
   * @memberof ValidationError
   */
  msg: string;
  /**
   *
   * @type {string}
   * @memberof ValidationError
   */
  type: string;
}
/**
 *
 * @export
 * @interface ValidationErrorLocInner
 */
export interface ValidationErrorLocInner {}
/**
 *
 * @export
 * @interface ValuesAbove
 */
export interface ValuesAbove {}
/**
 *
 * @export
 * @interface ValuesUnder
 */
export interface ValuesUnder {}

/**
 * UsageLogsInteractionApi - axios parameter creator
 * @export
 */
export const UsageLogsInteractionApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary All Usage Logs Interaction
     * @param {UsageLogAllBody} usageLogAllBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    allUsageLogsInteraction: async (
      usageLogAllBody: UsageLogAllBody,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'usageLogAllBody' is not null or undefined
      assertParamExists('allUsageLogsInteraction', 'usageLogAllBody', usageLogAllBody);
      const localVarPath = `/api/usage_logs_interaction/all`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(usageLogAllBody, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get Usage Log Interaction
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUsageLogInteraction: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getUsageLogInteraction', 'id', id);
      const localVarPath = `/api/usage_logs_interaction/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * UsageLogsInteractionApi - functional programming interface
 * @export
 */
export const UsageLogsInteractionApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = UsageLogsInteractionApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary All Usage Logs Interaction
     * @param {UsageLogAllBody} usageLogAllBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async allUsageLogsInteraction(
      usageLogAllBody: UsageLogAllBody,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsageLogOutputItemsModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.allUsageLogsInteraction(usageLogAllBody, options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['UsageLogsInteractionApi.allUsageLogsInteraction']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
    /**
     *
     * @summary Get Usage Log Interaction
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUsageLogInteraction(
      id: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UsageLogOutputItemModel>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUsageLogInteraction(id, options);
      const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
      const localVarOperationServerBasePath =
        operationServerMap['UsageLogsInteractionApi.getUsageLogInteraction']?.[localVarOperationServerIndex]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration,
        )(axios, localVarOperationServerBasePath || basePath);
    },
  };
};

/**
 * UsageLogsInteractionApi - factory interface
 * @export
 */
export const UsageLogsInteractionApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = UsageLogsInteractionApiFp(configuration);
  return {
    /**
     *
     * @summary All Usage Logs Interaction
     * @param {UsageLogsInteractionApiAllUsageLogsInteractionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    allUsageLogsInteraction(
      requestParameters: UsageLogsInteractionApiAllUsageLogsInteractionRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<UsageLogOutputItemsModel> {
      return localVarFp
        .allUsageLogsInteraction(requestParameters.usageLogAllBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get Usage Log Interaction
     * @param {UsageLogsInteractionApiGetUsageLogInteractionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUsageLogInteraction(
      requestParameters: UsageLogsInteractionApiGetUsageLogInteractionRequest,
      options?: RawAxiosRequestConfig,
    ): AxiosPromise<UsageLogOutputItemModel> {
      return localVarFp
        .getUsageLogInteraction(requestParameters.id, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for allUsageLogsInteraction operation in UsageLogsInteractionApi.
 * @export
 * @interface UsageLogsInteractionApiAllUsageLogsInteractionRequest
 */
export interface UsageLogsInteractionApiAllUsageLogsInteractionRequest {
  /**
   *
   * @type {UsageLogAllBody}
   * @memberof UsageLogsInteractionApiAllUsageLogsInteraction
   */
  readonly usageLogAllBody: UsageLogAllBody;
}

/**
 * Request parameters for getUsageLogInteraction operation in UsageLogsInteractionApi.
 * @export
 * @interface UsageLogsInteractionApiGetUsageLogInteractionRequest
 */
export interface UsageLogsInteractionApiGetUsageLogInteractionRequest {
  /**
   *
   * @type {string}
   * @memberof UsageLogsInteractionApiGetUsageLogInteraction
   */
  readonly id: string;
}

/**
 * UsageLogsInteractionApi - object-oriented interface
 * @export
 * @class UsageLogsInteractionApi
 * @extends {BaseAPI}
 */
export class UsageLogsInteractionApi extends BaseAPI {
  /**
   *
   * @summary All Usage Logs Interaction
   * @param {UsageLogsInteractionApiAllUsageLogsInteractionRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsageLogsInteractionApi
   */
  public allUsageLogsInteraction(
    requestParameters: UsageLogsInteractionApiAllUsageLogsInteractionRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return UsageLogsInteractionApiFp(this.configuration)
      .allUsageLogsInteraction(requestParameters.usageLogAllBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Usage Log Interaction
   * @param {UsageLogsInteractionApiGetUsageLogInteractionRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UsageLogsInteractionApi
   */
  public getUsageLogInteraction(
    requestParameters: UsageLogsInteractionApiGetUsageLogInteractionRequest,
    options?: RawAxiosRequestConfig,
  ) {
    return UsageLogsInteractionApiFp(this.configuration)
      .getUsageLogInteraction(requestParameters.id, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
