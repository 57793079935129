import * as DataStagingStep from 'src/dto/PipelineStep/DataStagingStep';

export function getFileTypeInitialFields(
  file_type: DataStagingStep.FileType,
): DataStagingStep.DataStagingFileTypeParameters {
  switch (file_type) {
    case DataStagingStep.FileType.CSV:
      return {
        file_type: DataStagingStep.FileType.CSV,
        delimiter: ',',
        escapechar: '',
      };
    case DataStagingStep.FileType.JSON:
      return {
        file_type: DataStagingStep.FileType.JSON,
      };
    case DataStagingStep.FileType.XML:
      return {
        file_type: DataStagingStep.FileType.XML,
      };
    case DataStagingStep.FileType.EXCEL:
      return {
        file_type: DataStagingStep.FileType.EXCEL,
        sheet: '',
      };
    case DataStagingStep.FileType.PARQUET:
      return {
        file_type: DataStagingStep.FileType.PARQUET,
      };
  }
}
