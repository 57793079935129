import { Skeleton, styled } from '@visualfabriq/vf-ui-kit';

import { RouterTabs } from 'src/components/organisms/RouterTabs';
import { useUserPermissions } from 'src/domain/permissions/useUserPermissions';
import { useInstances } from 'src/components/hooks/useInstances';

import { itemTestId } from './constants';
import { InstancesSelect } from './InstanceSelect';
import { useUserPermittedTabs } from './useUserPermittedTabs';

export function Navigation() {
  const { selectedInstance, loading: instancesLoading } = useInstances();
  const { permissions, isLoading: isPermissionsLoading, managedModules } = useUserPermissions();
  const tabs = useUserPermittedTabs({
    permissions,
    instanceId: selectedInstance?.name,
    managedModules,
  });

  if (instancesLoading) {
    return (
      <NavigationWrapper>
        <Skeleton width="100%" height="2rem" />
      </NavigationWrapper>
    );
  }

  return (
    <NavigationWrapper>
      <RouterTabs isLoading={isPermissionsLoading} itemTestId={itemTestId} tabs={tabs} mode="navigation" />
      <InstancesSelect />
    </NavigationWrapper>
  );
}

const NavigationWrapper = styled('div', ({ $theme }) => ({
  padding: `0 ${$theme.sizing.scale1000}`,
  margin: `${$theme.sizing.scale200} 0`,
  display: 'flex',
  justifyContent: 'space-between',
}));
