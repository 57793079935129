import { ErrorPage404, DesignSystemProvider } from '@visualfabriq/vf-ui-kit';
import { BrowserRouter, Route, Navigate, Routes } from 'react-router-dom';
import loadable from '@loadable/component';

import { AuthProvider } from 'src/pages/AuthProvider';

import { WelcomePage } from 'src/pages/Welcome';
import { InstancesPage } from 'src/pages/Instances';
import { FilesPage } from 'src/pages/Files';
import { ReplicatorPage } from 'src/pages/Replicator';
import { InsightsPage } from 'src/pages/Insights';
import { PageLayout } from 'src/pages/PageLayout';
import { PipelineTemplatesPage } from 'src/pages/PipelineTemplates';
import { MaintenancePage } from 'src/pages/Maintenance';
import { IsMaintenanceGuard } from 'src/pages/Maintenance/IsMaintenanceGuard';

import { CredentialsPage } from 'src/pages/Credentials';
import { HttpKeys } from 'src/containers/Credentials/HttpKeys';
import SftpCredentials from 'src/containers/Credentials/SftpCredentials';

import { PipelinesPage } from 'src/pages/Pipelines';
import { PipelineSteps } from 'src/containers/Pipelines/PipelineSteps';
import { StepConfiguration } from 'src/containers/Pipelines/StepConfiguration';
import { TableConfiguration } from 'src/containers/Pipelines/TableConfiguration';
import { AuthorizedAxiosProvider } from './services/AuthorizedAxios';
import { EMPTY_INSTANCE_NAME } from './domain/instances/InstancesProvider/constants';
import { PipelineDrawer } from './containers/Pipelines/PipelineDrawer';
import { StandaloneOrMFE } from 'src/domain/user/UserProviderWithOutlet';
import { InstanceProviderWithOutlet } from 'src/domain/instances/InstancesProvider/InstanceProviderWithOutlet';
import { InstanceNotFoundPage } from './pages/InstanceNotFound';

import { Collection } from 'src/containers/Insights/Collection';
import { Document } from 'src/containers/Insights/Document';
const PipelineSchedule = loadable(() => import('./containers/Dashboards/PipelineSchedule'));
const UsageLogsPage = loadable(() => import('./pages/UsageLogsPage'));
const DashboardsPage = loadable(() => import('./pages/DashboardPage'));
const Dashboards = loadable(() => import('./containers/Dashboards'));

import { pathConstants } from 'src/routes';

export function App() {
  return (
    <DesignSystemProvider prefix="bifrost">
      <BrowserRouter>
        <Routes>
          <Route path={pathConstants.ROOT}>
            <Route path={pathConstants.SSO_WITH_PROVIDER} element={<AuthProvider />} />
            <Route path={pathConstants.SSO} element={<AuthProvider />} />
            <Route path={pathConstants.MAINTENANCE} element={<MaintenancePage />} />
            <Route element={<IsMaintenanceGuard element={<StandaloneOrMFE />} />}>
              <Route index element={<Navigate to={`${pathConstants.ROOT}/${EMPTY_INSTANCE_NAME}`} replace />} />
              <Route
                path={pathConstants.INSTANCE_NAME}
                element={
                  <AuthorizedAxiosProvider>
                    <InstanceProviderWithOutlet />
                  </AuthorizedAxiosProvider>
                }
              >
                <Route element={<PageLayout />}>
                  <Route path={pathConstants.WELCOME} element={<WelcomePage />} />
                  <Route path={pathConstants.INSTANCES} element={<InstancesPage />} />
                  <Route path={pathConstants.FILES}>
                    <Route path="*" index element={<FilesPage />} />
                  </Route>
                  <Route path={pathConstants.ENDPOINTS.root} element={<CredentialsPage />}>
                    <Route path={pathConstants.ENDPOINTS.http} element={<HttpKeys />} />
                    <Route path={pathConstants.ENDPOINTS.sftp} element={<SftpCredentials />} />
                    <Route index element={<Navigate to={pathConstants.ENDPOINTS.http} replace />} />
                  </Route>
                  <Route path={pathConstants.PIPELINES.root} element={<PipelinesPage />}>
                    <Route element={<PipelineDrawer />}>
                      <Route path={pathConstants.PIPELINES.pipelineId}>
                        <Route path={pathConstants.PIPELINES.steps.root}>
                          <Route index element={<PipelineSteps />} />
                          <Route path={pathConstants.PIPELINES.steps.stepId}>
                            <Route index element={<StepConfiguration />} />
                            <Route path={pathConstants.PIPELINES.steps.tableIndex} element={<TableConfiguration />} />
                          </Route>
                        </Route>
                      </Route>
                    </Route>
                  </Route>
                  <Route path={pathConstants.PIPELINE_TEMPLATES} element={<PipelineTemplatesPage />} />
                  <Route path={pathConstants.REPLICATOR} element={<ReplicatorPage />} />
                  <Route path={pathConstants.INSIGHTS.root} element={<InsightsPage />}>
                    <Route path={pathConstants.INSIGHTS.collectionName} element={<Collection />}>
                      <Route path={pathConstants.INSIGHTS.documentId} element={<Document />} />
                    </Route>
                  </Route>
                  <Route path={pathConstants.DASHBOARD.root} element={<DashboardsPage />}>
                    <Route index element={<Dashboards />} />
                    <Route path={pathConstants.DASHBOARD.pipelineSchedule} element={<PipelineSchedule />} />
                    <Route path={pathConstants.DASHBOARD.usageLogs} element={<UsageLogsPage />} />
                  </Route>
                  <Route path={pathConstants.INSTANCE_NOT_FOUND} element={<InstanceNotFoundPage />} />
                  <Route index element={<Navigate to={pathConstants.WELCOME} replace />} />
                  <Route
                    path="*"
                    element={
                      <div style={{ position: 'relative', height: '100%' }}>
                        <ErrorPage404 />
                      </div>
                    }
                  />
                </Route>
              </Route>
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </DesignSystemProvider>
  );
}
