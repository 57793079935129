import { useState, Key } from 'react';
import { Tab, Tabs, Wrapper } from '@visualfabriq/vf-ui-kit';

import { StepExecutionHistory } from './StepExecutionHistory';
import { PipelineLogs } from './PipelineLogs';

export function PipelineHistory({ pipelineId }: { pipelineId: string }) {
  const [activeTab, setActiveTab] = useState<Key>('0');

  return (
    <Wrapper direction="column" height="100%" width="1000px">
      <Tabs activeKey={activeTab} onChange={({ activeKey }) => setActiveTab(activeKey as string)}>
        <Tab overrides={tabOverrides} title="Execution History">
          <StepExecutionHistory pipelineId={pipelineId} />
        </Tab>
        <Tab overrides={tabOverrides} title="Usage History">
          <PipelineLogs pipelineId={pipelineId} />
        </Tab>
      </Tabs>
    </Wrapper>
  );
}

const tabOverrides: React.ComponentProps<typeof Tab>['overrides'] = {
  TabPanel: {
    style: {
      height: 'calc(100vh - 60px - 53px - 5px)',
      overflow: 'overlay',
    },
  },
};
