import React from 'react';
import { LabelMedium, Notification, Wrapper } from '@visualfabriq/vf-ui-kit';

import { AUTH_PROVIDER_KEY } from 'src/pages/AuthProvider/constants';
import { pathConstants } from 'src/routes';

type Props = {
  testId?: string;
};

export function AuthProviderError(props: Props) {
  const { testId } = props;
  const provider = localStorage.getItem(AUTH_PROVIDER_KEY);
  const exampleURL = `${window.location.protocol}//${window.location.host}/${pathConstants.SSO}/visualfabriq`;
  return (
    <Wrapper height="100vh" alignItems="center" justifyContent="center" data-testid={testId}>
      <Notification kind="negative" closeable={false}>
        <Wrapper direction="column">
          <LabelMedium>Failed to get Auth config. Please check provider value.</LabelMedium>
          <LabelMedium>
            Current provider value is <LabelMedium overrides={providerOverrides}>{provider}</LabelMedium>. To change it
            go to <code>/sso/provider</code>
          </LabelMedium>
          <LabelMedium>
            e.g.
            <code>
              <a target="_self" href={exampleURL}>
                {exampleURL}
              </a>
            </code>
          </LabelMedium>
        </Wrapper>
      </Notification>
    </Wrapper>
  );
}

const providerOverrides: React.ComponentProps<typeof LabelMedium>['overrides'] = {
  Block: {
    style: () => ({
      fontWeight: 600,
    }),
  },
};
