import { useState } from 'react';
import { FormControl, Input, ModalBody, ModalButton, ModalFooter, ModalHeader } from '@visualfabriq/vf-ui-kit';

export function InstanceModal(props: { name?: string; onClick: (name: string) => void; onCancel: () => void }) {
  const { name, onClick, onCancel } = props;

  const [nameValue, setNameValue] = useState(name);
  return (
    <>
      <ModalHeader>{name ? 'Update instance' : 'Create Instance'}</ModalHeader>
      <ModalBody>
        <FormControl required label="Name">
          <Input value={nameValue} onChange={(event) => setNameValue(event.target.value)} />
        </FormControl>
      </ModalBody>
      <ModalFooter>
        <ModalButton disabled={!nameValue} onClick={() => onClick(nameValue!)}>
          {name ? 'Update' : 'Create'}
        </ModalButton>
        <ModalButton kind="tertiary" onClick={onCancel}>
          Cancel
        </ModalButton>
      </ModalFooter>
    </>
  );
}
