import { SentryWrappedXMLHttpRequest } from '@sentry/types';
import { SentryHandler } from '@visualfabriq/mfe-utils';
import { AxiosError } from 'axios';
import { getErrorMessage } from 'src/api-new/bifrost/utils';

enum ErrorModule {
  API = 'api ',
}

export function captureAPIResponseCode(
  response: Response,
  requestData: { url: string; requestBody: string | undefined; method: string },
) {
  const { url, requestBody } = requestData;

  SentryHandler.captureEvent({
    level: response.status.toString().startsWith('5') ? 'error' : 'warning',
    message: `Got response status code: ${response.status}`,
    tags: {
      module: ErrorModule.API,
    },
    extra: {
      url,
      requestBody: requestBody ?? '(Empty)',
    },
  });
}

export function captureAxiosException(error: AxiosError) {
  const response = error.response;
  const request: SentryWrappedXMLHttpRequest | undefined = error.request;
  const responseStatus = response?.status;
  SentryHandler.captureEvent({
    level: !responseStatus || responseStatus < 500 ? 'warning' : 'error',
    message: getErrorMessage(error),
    extra: {
      request: request ? request.__sentry_xhr_v3__ : null,
      response: error.response
        ? {
            data: response?.data,
            headers: response?.headers,
            status: response?.status,
          }
        : null,
    },
  });
}

export function captureException(error: Error) {
  if (error instanceof AxiosError) {
    captureAxiosException(error);
  } else {
    SentryHandler.captureException(error);
  }
}
