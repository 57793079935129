import { useState, useMemo } from 'react';
import { Input, Button, FormLoadingBox, FormControl, Checkbox, Wrapper } from '@visualfabriq/vf-ui-kit';

import { getRelativePath } from 'src/domain/pipelines/getRelativePath';
import { useInstances } from 'src/components/hooks/useInstances';
import { AzureOutboundBearerStep } from 'src/dto/PipelineStep';
import { StepConfigLayout } from '../StepConfigLayout';

type Props = {
  step: AzureOutboundBearerStep;
  defaultFieldsConfiguration: JSX.Element;
  loading: boolean;
  onUpdate: (step: AzureOutboundBearerStep) => void;
  deleteButton: JSX.Element;
};

export function AzureOutboundBearerStepConfig(props: Props) {
  const { step, onUpdate, defaultFieldsConfiguration, loading, deleteButton } = props;
  const { configuration } = step;
  const { selectedInstance } = useInstances();

  const [sasUrl, setSASUrl] = useState(configuration?.blob_sas_url ?? '');
  const [destinationPath, setDestinationPath] = useState(configuration?.destination_path ?? '');
  const [fileSearchExpression, setFileSearchExpression] = useState(
    getRelativePath({ path: configuration?.file_search_expression ?? '', instance: selectedInstance }),
  );

  const [moveAfterUploadToProcessed, setMoveAfterUploadToProcessed] = useState(
    !!configuration.move_after_upload_to_processed,
  );

  const canUpdate = useMemo(() => fileSearchExpression && sasUrl, [fileSearchExpression, sasUrl]);

  const handleUpdate = () => {
    onUpdate({
      ...step,
      configuration: {
        ...step.configuration,
        blob_sas_url: sasUrl,
        file_search_expression: `${selectedInstance.name}/${fileSearchExpression}`,
        destination_path: destinationPath,
        move_after_upload_to_processed: moveAfterUploadToProcessed,
      },
    });
  };

  return (
    <StepConfigLayout
      footer={
        <Wrapper gap="200" direction="column">
          <Button disabled={!canUpdate} onClick={handleUpdate}>
            Update Step
          </Button>
          {deleteButton}
        </Wrapper>
      }
      loader={<FormLoadingBox items={['text', 'text', 'text', 'text', 'text', 'buttonGroup']} itemsCount={6} />}
      loading={loading}
    >
      {defaultFieldsConfiguration}

      <FormControl
        label="Azure Container SAS url"
        caption="example: https://&#60;&#60;account&#62;&#62;.blob.core.windows.net/&#60;&#60;container
        name&#62;&#62;?sv=&#60;&#60;sas token&#62;&#62"
        required
      >
        <Input value={sasUrl} onChange={(event) => setSASUrl(event.target.value)} />
      </FormControl>

      <Checkbox
        checked={moveAfterUploadToProcessed}
        onChange={(event) => {
          setMoveAfterUploadToProcessed(event.target.checked);
        }}
      >
        Move after upload to processed
      </Checkbox>

      <FormControl label="File pattern" caption={`${selectedInstance.name}/${fileSearchExpression}`} required>
        <Input value={fileSearchExpression} onChange={(event) => setFileSearchExpression(event.target.value)} />
      </FormControl>

      <FormControl label="Target path" caption="Leave empty to place in root folder">
        <Input value={destinationPath} onChange={(event) => setDestinationPath(event.target.value)} />
      </FormControl>
    </StepConfigLayout>
  );
}
