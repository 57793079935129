import { Button, Wrapper, NoContent } from '@visualfabriq/vf-ui-kit';

export function EnableReplicator({ onCreate }: { onCreate: () => void }) {
  return (
    <Wrapper padding="30px" direction="column" width="500px" alignItems="center">
      <NoContent message="No existing replicator configuration was found for this instance" />
      <Button onClick={onCreate}>Enable Replication</Button>
    </Wrapper>
  );
}
