import { EnvVars } from 'src/services/envVars';

export const pathConstants = {
  ROOT: EnvVars.isMFE ? '/bifrost' : '',

  MAINTENANCE: 'maintenance',
  SSO_WITH_PROVIDER: 'sso/:provider',
  SSO: 'sso',

  INSTANCE_NAME: `:instanceName`,

  WELCOME: 'welcome',
  INSTANCES: 'instances',
  FILES: 'files',
  ENDPOINTS: {
    root: 'endpoints',
    http: 'http',
    sftp: 'sftp',
  },
  PIPELINES: {
    root: 'pipelines',
    pipelineId: ':pipelineId',
    steps: {
      root: 'steps',
      stepId: ':stepId',
      tableIndex: ':tableIndex',
    },
  },
  PIPELINE_TEMPLATES: 'pipeline_templates',
  REPLICATOR: 'replicator',
  INSIGHTS: {
    root: 'insights',
    collectionName: ':collectionName',
    documentId: ':documentId',
  },
  DASHBOARD: {
    root: 'dashboard',
    pipelineSchedule: 'pipeline-schedule',
    usageLogs: 'usage-logs',
  },

  INSTANCE_NOT_FOUND: 'instance_not_found',
} as const;
