import { NoContent, Wrapper, useStyletron } from '@visualfabriq/vf-ui-kit';

import { SelectedModulesState } from '../types';
import { VFModule } from 'src/api-new/bifrost';
import * as StepLayout from '../StepLayout';
import { Card, CardLabel } from 'src/components/atoms/Card';

type Props = {
  state: SelectedModulesState;
  modules: VFModule[];
  onNext: (state: SelectedModulesState) => void;
};

export function SelectModuleStep({ modules, onNext }: Props) {
  const [css] = useStyletron();

  const handleSelect = (module: VFModule) => onNext({ selectedModule: module });

  return (
    <StepLayout.StepLayout>
      <StepLayout.StepHeader title="Please select a module" />
      <StepLayout.StepBody className={css({ width: '600px', overflowX: 'hidden' })}>
        {modules.length ? (
          <Wrapper gap={400} wrap="wrap">
            {modules.map((module) => (
              <Card key={module.id} onClick={() => handleSelect(module)}>
                <CardLabel>{module.name}</CardLabel>
              </Card>
            ))}
          </Wrapper>
        ) : (
          <NoContent message="No modules to show" />
        )}
      </StepLayout.StepBody>
    </StepLayout.StepLayout>
  );
}
