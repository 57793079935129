import { styled } from '@visualfabriq/vf-ui-kit';

type LoaderProps = {
  radius?: number;
  cover?: boolean;
  'data-testid'?: string;
};

export function Loader(props: LoaderProps) {
  const { radius = 50, cover = false } = props;
  const dataTestId = props['data-testid'];

  return (
    <Container data-testid={dataTestId} $cover={cover}>
      <Spinner $radius={radius} />
    </Container>
  );
}

const Container = styled<'div', { $cover: boolean }>('div', ({ $cover }) => ({
  display: 'flex',
  justifyContent: 'space-around',
  width: '100%',
  height: '100%',
  alignItems: ' center',
  ...($cover
    ? {
        position: 'absolute',
        backgroundColor: '#fff',
        zIndex: 1,
      }
    : {}),
}));

const Spinner = styled<'div', { $radius: number }>('div', ({ $radius }) => ({
  height: `${$radius}px`,
  width: `${$radius}px`,
  borderWidth: `${$radius / 10}px`,
  border: '5px solid #f6f7f8',
  borderTopColor: '#c2c2c2',
  borderRadius: '50%',
  animationDuration: '2s',
  animationIterationCount: 'infinite',
  animationName: {
    from: {
      transform: ' rotate(0deg)',
    },
    to: {
      transform: 'rotate(360deg)',
    },
  } as any,
}));
