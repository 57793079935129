import { Button, NoContent, Wrapper } from '@visualfabriq/vf-ui-kit';

import { useUserPermissions } from 'src/domain/permissions/useUserPermissions';

type Props = {
  filtered: boolean;
  onNewPipeline: () => void;
  createUsingTemplate: () => void;
};

export function NoPipelines({ onNewPipeline, createUsingTemplate, filtered }: Props) {
  const userPermission = useUserPermissions();

  const canCreatePipeline = userPermission.has('PipelinesCreate');

  if (filtered) {
    return (
      <Wrapper direction="column" alignItems="center">
        <NoContent isFilterApplied />
      </Wrapper>
    );
  }

  return (
    <Wrapper direction="column" alignItems="center">
      <NoContent message="No existing pipelines were found for this instance" />
      <Wrapper gap={200} alignItems="center">
        <Button disabled={!canCreatePipeline} onClick={onNewPipeline}>
          Get Started!
        </Button>
        or
        <Button disabled={!canCreatePipeline} onClick={createUsingTemplate}>
          Pipeline module wizard
        </Button>
      </Wrapper>
    </Wrapper>
  );
}
