import { useUserPermissions } from 'src/domain/permissions/useUserPermissions';
import { PipelineTemplates } from 'src/containers/PipelineTemplates';
import { PageGuard } from 'src/pages/PageGuard';
import { PipelineTemplatesProvider } from 'src/domain/PipelineTemplatesContext';
import { PAGES_CONFIG } from '../config';

export const PipelineTemplatesPage = () => {
  const { managedModules: managedModulesCodes } = useUserPermissions();

  return (
    <PageGuard hasAccess={PAGES_CONFIG.pipelineTemplates.hasAccess}>
      <PipelineTemplatesProvider managedModulesCodes={managedModulesCodes}>
        <PipelineTemplates />
      </PipelineTemplatesProvider>
    </PageGuard>
  );
};
