import { ButtonOverrides, FileIcon, FolderIcon, useStyletron } from '@visualfabriq/vf-ui-kit';
import { S3ObjectKind } from 'src/api-new/bifrost';

export const nameButtonOverrides: ButtonOverrides = {
  BaseButton: { style: ({ $theme }) => ({ color: $theme.colors.buttonTertiaryText }) },
};

export function useFileObjectIcon(kind: S3ObjectKind) {
  const [, theme] = useStyletron();

  switch (kind) {
    case 'File':
      return <FileIcon />;
    case 'Folder':
      return <FolderIcon color={theme.colors.primary400} />;
  }
}
