import { Files } from 'src/containers/Files';
import { PageGuard } from 'src/pages/PageGuard';
import { DrawerProvider } from 'src/components/molecules/Drawer';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { PAGES_CONFIG } from '../config';

export const FilesPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname.endsWith('/')) navigate(location.pathname.slice(0, -1));
  }, [location]);

  return (
    <PageGuard hasAccess={PAGES_CONFIG.files.hasAccess}>
      <DrawerProvider>
        <Files />
      </DrawerProvider>
    </PageGuard>
  );
};
