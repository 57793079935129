import { FileConfiguration } from './FileConfiguration';

export enum TableType {
  Sap = 'sap',
  Custom = 'custom',
}

export interface DataStagingStepConfiguration {
  files_configuration_model: FileConfiguration[];
  encoding?: string;
  file_type_parameters?: DataStagingFileTypeParameters;
  instance_name?: string;
  import_folder?: string;
  sap_system_name?: string;
  table_type: TableType;
  pipeline_id: string;
}

export type DataStagingFileTypeParameters =
  | CsvFileParameters
  | XmlFileParameters
  | JsonFileParameters
  | ExcelFileParameters
  | ParquetFileParameters;

export enum FileType {
  CSV = 'csv',
  XML = 'xml',
  JSON = 'json',
  EXCEL = 'excel',
  PARQUET = 'parquet',
}

export interface CsvFileParameters {
  file_type: FileType.CSV;
  delimiter?: string;
  quotechar?: string;
  fieldnames?: string[];
  escapechar?: string;
  contains_header?: boolean;
}

export interface XmlFileParameters {
  file_type: FileType.XML;
  xml_element_map?: string;
}

export interface JsonFileParameters {
  file_type: FileType.JSON;
  key?: string;
}

export interface ExcelFileParameters {
  file_type: FileType.EXCEL;
  sheet?: string;
}

export interface ParquetFileParameters {
  file_type: FileType.PARQUET;
}
