import { useContext, useMemo, useState } from 'react';

import {
  Input,
  FormControl,
  Button,
  Select,
  styled,
  FormLoadingBox,
  ListLoadingBox,
  ContentWrapper,
  Wrapper,
} from '@visualfabriq/vf-ui-kit';

import * as DataStagingStep from 'src/dto/PipelineStep/DataStagingStep';
import { downloadTextFile } from 'src/utils/files/downloadTextFile';
import { ConfirmModal } from 'src/components/organisms/ConfirmModal';
import { getFileTypeInitialFields } from './helpers/getFileTypeInitialFields';

import { ModalContext } from 'src/components/molecules/Modal/ModalProvider';
import { useInstances } from 'src/components/hooks/useInstances';
import { FileTypeParametersForm } from './components/FileTypeParametersForm';
import { StepConfigLayout } from '../StepConfigLayout';
import { TablesList } from './components/TablesList';
import { splitWithTruncate } from 'src/utils/string/splitWithTruncate';
import { CreateMultipleTableModal } from './CreateMultipleTableModal';
import { CreateSingleTableModal } from './CreateSingleTableModal';
import { getFileConfiguration } from 'src/containers/Pipelines/TableConfiguration/MultiTable/Form/getFileConfiguration';
import { ImportConfiguration } from './ImportConfiguration';

type DataStagingStepConfigProps = {
  step: DataStagingStep.DataStagingStep;
  onUpdate: (step: DataStagingStep.DataStagingStep) => void;
  defaultFieldsConfiguration: JSX.Element;
  deleteButton: JSX.Element;
  loading: boolean;
};

const FileTypes = [
  DataStagingStep.FileType.CSV,
  DataStagingStep.FileType.XML,
  DataStagingStep.FileType.JSON,
  DataStagingStep.FileType.EXCEL,
  DataStagingStep.FileType.PARQUET,
];
const TableTypes = [DataStagingStep.TableType.Sap, DataStagingStep.TableType.Custom];

export function DataStagingStepConfig(props: DataStagingStepConfigProps) {
  const { step, onUpdate, defaultFieldsConfiguration, loading, deleteButton } = props;
  const { configuration } = step;
  const { openModal, closeModal } = useContext(ModalContext);
  const { selectedInstance } = useInstances();

  const [importFolder, setImportFolder] = useState(configuration.import_folder);
  const [encoding, setEncoding] = useState(configuration.encoding || 'utf-8');
  const [table_type, setTableType] = useState(configuration.table_type || DataStagingStep.TableType.Sap);
  const [file_type_parameters, setFileTypeParameters] = useState(
    configuration.file_type_parameters ||
      ({
        file_type: DataStagingStep.FileType.CSV,
        delimiter: ',',
        escapechar: '',
      } as DataStagingStep.CsvFileParameters),
  );

  const canUpdate = useMemo(() => true, []);

  const handleFieldsUpdate = async () => {
    onUpdate({
      ...step,
      configuration: {
        ...configuration,
        import_folder: importFolder,
        encoding,
        table_type: table_type,
        file_type_parameters: file_type_parameters,
      },
    });
  };

  const handleTablesUpdate = (tables: DataStagingStep.FileConfiguration[]) => {
    onUpdate({
      ...step,
      configuration: {
        ...configuration,
        files_configuration_model: tables,
      },
    });
  };

  const handleConfigurationExport = async () => {
    return downloadTextFile(`${step.name}.json`, JSON.stringify(step.configuration, null, 2));
  };

  const handleConfigurationImport = async (configuration: DataStagingStep.DataStagingStepConfiguration) => {
    onUpdate({ ...step, configuration: { ...configuration, instance_name: selectedInstance.name } });
  };

  const handleTableTypeChange = (event) => {
    if (event.type !== 'select') return;
    if (step.configuration.files_configuration_model?.length > 0) {
      openModal(
        <ConfirmModal
          onConfirm={async () => {
            handleTablesUpdate([]);
            setTableType(event.value[0].id);
            closeModal();
          }}
          onCancel={closeModal}
          question={`This will switch to ${event.value[0].id}.
          If you continue, existing tables for ${table_type} will be deleted.`}
        />,
      );
    } else {
      setTableType(event.value[0].id);
    }
  };

  const handleFileTypeChange = (event) => {
    if (event.type !== 'select') return;
    const file_type = event.value[0].id;
    openModal(
      <ConfirmModal
        onConfirm={() => {
          setFileTypeParameters(getFileTypeInitialFields(file_type as DataStagingStep.FileType));
          closeModal();
        }}
        onCancel={closeModal}
        question={`Are you sure you want to change file type to ${file_type}?`}
      />,
    );
  };

  const handelFileTypeParametersChange = (
    data:
      | DataStagingStep.CsvFileParameters
      | DataStagingStep.XmlFileParameters
      | DataStagingStep.JsonFileParameters
      | DataStagingStep.ExcelFileParameters
      | DataStagingStep.ParquetFileParameters,
  ) => {
    setFileTypeParameters(data);
  };

  const handleCreateSingleTableImport = () => {
    openModal(
      <CreateSingleTableModal
        fileType={file_type_parameters.file_type}
        onCancel={closeModal}
        onCreate={(event) => {
          onUpdate({
            ...step,
            configuration: {
              ...configuration,
              files_configuration_model: [
                ...configuration.files_configuration_model,
                {
                  fnmatch_expression: event.fnmatchExpression,
                  lower_level_key: event.lowerLevelKey,
                  import_type: DataStagingStep.ImportType.Table,
                  key: event.key,
                  import_parameters: {
                    target_collection: event.targetCollection,
                    persist_method: event.persistMethod,
                    update_keys: splitWithTruncate(event.updateKeys),
                    mandatory_keys: splitWithTruncate(event.mandatoryKeys),
                    transformations: event.transformations,
                  },
                },
              ],
            },
          });
          closeModal();
        }}
      />,
    );
  };

  const handleAddMultipleTableImport = () => {
    openModal(
      <CreateMultipleTableModal
        onCancel={closeModal}
        onCreate={(event) => {
          onUpdate({
            ...step,
            configuration: {
              ...configuration,
              files_configuration_model: [...configuration.files_configuration_model, getFileConfiguration(event)],
            },
          });
          closeModal();
        }}
      />,
    );
  };

  if (loading) {
    return (
      <DataStagingStepConfigWrapper>
        <StepConfiguration>
          <FormLoadingBox items={['text', 'buttonGroup', 'double', 'block']} itemsCount={6} />
        </StepConfiguration>
        <TablesConfiguration>
          <ListLoadingBox itemsCount={3} />
        </TablesConfiguration>
      </DataStagingStepConfigWrapper>
    );
  }

  return (
    <DataStagingStepConfigWrapper>
      <StepConfiguration>
        <StepConfigLayout
          loading={false}
          loader={<></>}
          footer={
            <Wrapper gap={200} direction="column">
              <Button onClick={handleFieldsUpdate} disabled={!canUpdate}>
                Update Step
              </Button>
              {deleteButton}
            </Wrapper>
          }
        >
          {defaultFieldsConfiguration}

          <FormControl label="Import Folder" disabled={false} required>
            <Input value={importFolder} onChange={(event) => setImportFolder(event.target.value)} />
          </FormControl>

          <FormControl label="File Type" disabled={false} required>
            <Select
              options={FileTypes.map((type) => ({ id: type, label: type }))}
              value={[{ id: file_type_parameters.file_type }]}
              onChange={handleFileTypeChange}
            />
          </FormControl>

          <FormControl label="Table Type" disabled={false} required>
            <Select
              options={TableTypes.map((type) => ({ id: type, label: type }))}
              value={[{ id: table_type }]}
              onChange={handleTableTypeChange}
            />
          </FormControl>

          <FormControl label="Encoding" disabled={false} required>
            <Input value={encoding} onChange={(event) => setEncoding(event.target.value)} />
          </FormControl>

          <FileTypeParametersForm parameters={file_type_parameters} onChange={handelFileTypeParametersChange} />

          <ContentWrapper>
            <ImportConfiguration onImport={handleConfigurationImport} />
            <Wrapper gap="200">
              <Button kind="secondary" onClick={handleConfigurationExport}>
                Export Configuration
              </Button>
            </Wrapper>
          </ContentWrapper>
        </StepConfigLayout>
      </StepConfiguration>

      <TablesConfiguration>
        <TablesList tables={step.configuration.files_configuration_model || []} />
        <Wrapper gap="200">
          <Button onClick={handleCreateSingleTableImport}>Add Single Table Import</Button>
          <Button onClick={handleAddMultipleTableImport}>Add Multi Table Import</Button>
        </Wrapper>
      </TablesConfiguration>
    </DataStagingStepConfigWrapper>
  );
}

const DataStagingStepConfigWrapper = styled('div', ({ $theme }) => ({
  height: '100%',
  width: '1000px',
  display: 'flex',
  gap: $theme.sizing.scale200,
  margin: `${$theme.sizing.scale100} 0`,
}));

const StepConfiguration = styled('div', ({ $theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  paddingRight: $theme.sizing.scale200,
  borderRight: '2px solid #f2f2f2',
  overflowY: 'auto',
}));

const TablesConfiguration = styled('div', () => ({
  flex: 1,
}));
