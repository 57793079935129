import { Insights } from 'src/containers/Insights';
import { PageGuard } from 'src/pages/PageGuard';
import { InsightsProvider } from 'src/domain/insights';
import { PAGES_CONFIG } from '../config';

export const InsightsPage = () => (
  <PageGuard hasAccess={PAGES_CONFIG.insights.hasAccess}>
    <InsightsProvider>
      <Insights />
    </InsightsProvider>
  </PageGuard>
);
