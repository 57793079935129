import { useEffect, useState } from 'react';
import { FormControl, FormLoadingBox, Textarea, useSnackbar, Button, Wrapper } from '@visualfabriq/vf-ui-kit';

import { NotificationMode, PipelineNotification, PipelineNotificationsApi } from 'src/api-new/bifrost';
import { useBifrostApi } from 'src/services/useBifrostApi';
import { ModeSelect } from '../ModeSelect';
import { getErrorMessage } from 'src/api-new/bifrost/utils';
import { splitWithTruncate } from 'src/utils/string/splitWithTruncate';
import { captureException } from 'src/services/sentry';

export function AdminNotificationConfig(props: { pipelineId: string; muteButton: React.ReactNode }) {
  const { pipelineId, muteButton } = props;
  const notificationsApi = useBifrostApi(PipelineNotificationsApi);
  const snackbar = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [subscribers, setSubscribers] = useState('');
  const [mode, setMode] = useState<NotificationMode>('always');
  const [notifications, setNotifications] = useState<PipelineNotification[]>();

  useEffect(() => {
    getSubscribers(pipelineId);
  }, [pipelineId]);

  useEffect(() => {
    setSubscribers(notifications?.map((n) => n.user_email).join(', ') ?? '');
    setMode(notifications?.length ? notifications[0].mode : 'always');
  }, [notifications]);

  const getSubscribers = async (pipelineId: string) => {
    try {
      setLoading(true);
      const { data: notifications } = await notificationsApi.getPipelineServiceNotifications({ pipelineId });
      setNotifications(notifications);
    } catch (error) {
      captureException(error);
      snackbar.enqueueErrorSnackbar(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async () => {
    try {
      setLoading(true);
      const emails = splitWithTruncate(subscribers);
      await notificationsApi.updatePipelineServiceNotifications({
        pipelineNotificationList: {
          pipeline_id: pipelineId,
          emails: splitWithTruncate(subscribers),
          mode,
        },
      });
      setSubscribers(emails.join(', ') ?? '');
      snackbar.enqueueSuccessSnackbar('Notification settings been updated');
    } catch (error) {
      captureException(error);
      snackbar.enqueueErrorSnackbar(getErrorMessage(error));
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <FormLoadingBox />;
  }

  return (
    <Wrapper direction="column" marginBlock={200} gap={200}>
      <FormControl label="Subscribers" required>
        <Textarea value={subscribers} onChange={(e) => setSubscribers(e.target.value)} />
      </FormControl>
      <ModeSelect value={mode} onChange={setMode} />
      <Wrapper gap={200}>
        <Button onClick={handleUpdate}>Update</Button>
        <div style={{ marginLeft: 'auto' }}>{muteButton}</div>
      </Wrapper>
    </Wrapper>
  );
}
