import { Button } from '@visualfabriq/vf-ui-kit';

import { S3ObjectKind } from 'src/api-new/bifrost';
import { FileTableData } from '../index';
import { nameButtonOverrides, useFileObjectIcon } from './shared';

export function NameColumnButton({ row, onClick }: { row: FileTableData; onClick: (id: string) => void }) {
  const isFolder = row.type === S3ObjectKind.Folder;
  const icon = useFileObjectIcon(row.type);

  return (
    <Button
      startEnhancer={icon}
      disabled={!isFolder}
      kind="tertiary"
      onClick={() => onClick(row.id)}
      overrides={nameButtonOverrides}
    >
      {row.name}
    </Button>
  );
}
