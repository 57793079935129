export const endpointPaths = {
  pipelines: 'api/v1/pipelines',
  pipelinesSteps: 'api/v1/pipeline-steps',
  userPermissions: 'api/v1/user-permissions',

  auth: {
    auth: 'auth',
    user: '/user/eu-prod',
  },
};
