import {
  ParagraphSmall,
  Notification,
  Accordion,
  Panel,
  ContentWrapper,
  Wrapper,
  NoContent,
  LabelMedium,
  Input,
  FormControl,
} from '@visualfabriq/vf-ui-kit';

import { PipelineTemplate } from 'src/api-new/bifrost';

import { StepConfigurationForm } from './StepConfigurationForm';
import { FilledTemplateMap } from 'src/domain/pipelines/types';

type Props = {
  template: PipelineTemplate;
  name: string | undefined;
  onNameChange: (name: string, templateId: string) => void;
  filledConfigMap: FilledTemplateMap;
  className?: string;
  onValidateChange: OnValidateChangeFn;
  onChange: OnChangeFn;
};

export function Template(props: Props) {
  const { template, filledConfigMap, onValidateChange, onChange, className, name, onNameChange } = props;

  return (
    <Wrapper direction="column" gap={200} className={className}>
      <LabelMedium>{template.label}</LabelMedium>
      <ParagraphSmall>{template.description}</ParagraphSmall>
      {template.zendesk_url && (
        <Notification closeable={false}>
          Explanation of this Pipeline Template can be found in our Zendesk Knowledge Base:{' '}
          <a target="_blank" href={template.zendesk_url}>
            link to Zendesk
          </a>
        </Notification>
      )}

      <FormControl label="Pipeline name">
        <Input
          value={name ?? template.label}
          placeholder={template.label}
          onChange={(event) => onNameChange(event.target.value, template.id)}
        />
      </FormControl>
      {template.steps_configuration.length === 0 ? (
        <NoContent message="No configuration required" />
      ) : (
        <Accordion>
          {template.steps_configuration.map((stepConfiguration) => (
            <Panel key={stepConfiguration.id} title={stepConfiguration.type}>
              <ContentWrapper>
                <StepConfigurationForm
                  key={stepConfiguration.id}
                  templateId={template.id}
                  stepConfiguration={stepConfiguration}
                  initData={filledConfigMap[template.id]?.[stepConfiguration.id] ?? {}}
                  onValidateChange={onValidateChange}
                  onChange={onChange}
                />
              </ContentWrapper>
            </Panel>
          ))}
        </Accordion>
      )}
    </Wrapper>
  );
}

export type OnValidateChangeFn = (event: { templateId: string; stepConfigurationId: string; isValid: boolean }) => void;
export type OnChangeFn = (event: {
  templateId: string;
  stepConfigurationId: string;
  args: Record<string, string>;
}) => void;
