import { Button, styled, useStyletron } from '@visualfabriq/vf-ui-kit';
import { useState } from 'react';
import { v4 as uuid4 } from 'uuid';

import { Collapse } from 'src/components/organisms/Collapse';

import { Arg } from './Arg';
import { Heading } from './Heading';
import { ARG_DRAFT_ID, defaultDraftArg } from './constants';
import { DataProcessingArgs } from 'src/api-new/bifrost';

type Props = {
  args: DataProcessingArgs[];
  unsavedArgs: Set<string>;
  onChange: (args: DataProcessingArgs[], changedArgId: string) => void;
  onDelete: (args: DataProcessingArgs[], changedArgId: string) => void;
  onValidChange: (isValid: boolean) => void;
};

export function Args(props: Props) {
  const { args, unsavedArgs, onChange, onDelete, onValidChange } = props;
  const [_, theme] = useStyletron();
  const [draftArg, setDraftArg] = useState<DataProcessingArgs | null>(null);
  const [validationState, setValidationState] = useState<Record<string, boolean>>({});

  const handleCreateArg = () => {
    if (draftArg) {
      setDraftArg(null);
      onChange([...args, { ...draftArg, id: uuid4() }], ARG_DRAFT_ID);
      onValidChange(true);
    }
  };

  const getIsFormValid = (validationState: Record<string, boolean>) => {
    return Object.values(validationState).every(Boolean);
  };

  const handleChange = (argToUpdate: DataProcessingArgs) => {
    onChange(
      args.map((template) => (template.id === argToUpdate.id ? argToUpdate : template)),
      argToUpdate.id,
    );
  };

  const handleCancel = () => {
    setDraftArg(null);
  };

  const handleDelete = (idToDelete: string) => {
    onDelete(
      args.filter((arg) => arg.id !== idToDelete),
      idToDelete,
    );

    delete validationState[idToDelete];
    setValidationState({ ...validationState });
  };

  const handleValidChange = (isValid: boolean, id: string) => {
    if (draftArg) {
      return;
    }

    const newValidationState = { ...validationState, [id]: isValid };
    setValidationState(newValidationState);
    onValidChange(getIsFormValid(newValidationState));
  };

  return (
    <ArgsWrapper>
      {(draftArg ? [...args, draftArg] : args).map((arg) => {
        const isArgDraft = arg.id === ARG_DRAFT_ID;
        return (
          <Collapse
            heading={<Heading label={arg.key} isDraft={isArgDraft} isUnsaved={unsavedArgs.has(arg.id)} />}
            key={arg.id}
            initialOpenState={isArgDraft}
            margin={`0 0 ${theme.sizing.scale400}`}
            backgroundColor="mono200"
            padding={theme.sizing.scale400}
          >
            <Arg
              action={isArgDraft ? 'create' : 'update'}
              arg={arg}
              onChange={isArgDraft ? setDraftArg : handleChange}
              onCreate={handleCreateArg}
              onCancel={handleCancel}
              onDelete={handleDelete}
              onValidChange={(isValid) => handleValidChange(isValid, arg.id)}
            />
          </Collapse>
        );
      })}
      <Button kind="primary" disabled={!!draftArg} onClick={() => setDraftArg(defaultDraftArg)}>
        Add Argument
      </Button>
    </ArgsWrapper>
  );
}

const ArgsWrapper = styled('div', ({ $theme }) => ({
  padding: `${$theme.sizing.scale400} ${$theme.sizing.scale200} ${$theme.sizing.scale200}`,
  marginBottom: $theme.sizing.scale200,
  backgroundColor: $theme.colors.mono100,
}));
