import { LabelMedium, Wrapper } from '@visualfabriq/vf-ui-kit';

export const Field = ({ gap = 200, ...rest }: Parameters<typeof Wrapper>[0]) => <Wrapper gap={gap} {...rest} />;

export function Name({ children }: { children: React.ReactNode }) {
  return <LabelMedium>{children}:</LabelMedium>;
}

export function Value({ children }: { children: React.ReactNode }) {
  return <LabelMedium kind="secondary">{children}</LabelMedium>;
}
