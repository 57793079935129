import { NoContent } from '@visualfabriq/vf-ui-kit';
import { StepsConfigurationInner } from 'src/api-new/bifrost';

import { TemplateArgsForm } from '../TemplateArgsForm';
import { TemplateArgType } from 'src/domain/pipelines/types';

type Props = {
  initData: Record<string, string>;
  templateId: string;
  stepConfiguration: StepsConfigurationInner;
  onChange: (event: { templateId: string; stepConfigurationId: string; args: Record<string, TemplateArgType> }) => void;
  onValidateChange: (event: { templateId: string; stepConfigurationId: string; isValid: boolean }) => void;
};

export function StepConfigurationForm(props: Props) {
  const { initData, stepConfiguration, templateId, onChange, onValidateChange } = props;

  const handleArgsChange = (args: Record<string, string>) => {
    onChange({
      templateId,
      stepConfigurationId: stepConfiguration.id,
      args,
    });
  };

  const handleArgsValidChange = (isValid: boolean) => {
    onValidateChange({ templateId, stepConfigurationId: stepConfiguration.id, isValid });
  };

  switch (stepConfiguration.type) {
    case 'data_processing':
      return (
        <TemplateArgsForm
          initData={initData}
          key={stepConfiguration.id}
          templateArgs={stepConfiguration.args}
          onChange={handleArgsChange}
          onValidateChange={handleArgsValidChange}
        />
      );
    case 'data_staging':
      return (
        <NoContent
          isFilterApplied
          hideHintMessage
          message="This step contains a pre-configured data-staging configuration. You can inspect and adjust it after Pipeline creation"
        />
      );
  }
}
