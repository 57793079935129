import { useState } from 'react';
import { Button, Tag, Wrapper } from '@visualfabriq/vf-ui-kit';

import * as DataStagingStep from 'src/dto/PipelineStep/DataStagingStep';
import { ImportParameters, Form, FormValue } from '../Form';
import { getFileConfiguration } from '../Form/getFileConfiguration';

type Props = {
  configuration: DataStagingStep.MultiTableConfiguration;
  onUpdate: (table: DataStagingStep.MultiTableConfiguration) => void;
  onDelete: () => void;
  onCancel: () => void;
};
export function MultiTableConfiguration(props: Props) {
  const { configuration, onUpdate, onDelete, onCancel } = props;

  const [formState, setFormState] = useState<FormValue>({
    fnmatchExpression: configuration.fnmatch_expression ?? '',
    lowerLevelKey: configuration.lower_level_key ?? '',
    importParameters: configuration.import_parameters.map(formatImportParameters),
  });
  const [unsaved, setUnsaved] = useState(false);

  const handleFormChange = (value: FormValue) => {
    setFormState(value);
  };

  const handleVerboseFormChange = (value: FormValue) => {
    setUnsaved(true);
    setFormState(value);
  };

  const handleTableUpdate = () => {
    onUpdate(getFileConfiguration(formState));
  };

  function formatImportParameters({
    target_collection,
    persist_method,
    update_keys,
    mandatory_keys,
    inherit_keys,
    transformations,
  }: DataStagingStep.MultiTableConfiguration['import_parameters'][0]): ImportParameters {
    const [keyFromFile, targetCollection] = target_collection;
    return {
      keyFromFile,
      targetCollection,
      persistMethod: persist_method,
      updateKeys: update_keys.join(','),
      mandatoryKeys: mandatory_keys.join(','),
      inheritKeys: inherit_keys?.join(',') ?? '',
      transformations,
      isDraft: false,
    };
  }

  const isValid = !!formState.fnmatchExpression && !!formState.importParameters.length;

  return (
    <Wrapper direction="column" padding={600}>
      <Form value={formState} onChange={handleFormChange} onVerboseChange={handleVerboseFormChange} />
      <Wrapper gap="200" marginBlock="200">
        <Button disabled={!isValid} onClick={handleTableUpdate}>
          Update Table
        </Button>
        <Button kind="secondary" onClick={onCancel}>
          Cancel
        </Button>
        {unsaved && <Tag color="orange">Unsaved</Tag>}
        <div style={{ marginLeft: 'auto' }}>
          <Button kind="secondary" onClick={onDelete}>
            Delete Table
          </Button>
        </div>
      </Wrapper>
    </Wrapper>
  );
}
