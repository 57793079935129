import { useEffect, useMemo } from 'react';
import { Pagination, TableBuilder, TableBuilderColumn } from '@visualfabriq/vf-ui-kit';

import { toLocalStringWithTimeZone } from 'src/utils/date/toLocalStringWithTimeZone';
import { Instance } from 'src/api-new/bifrost';
import { useSortedColumn } from 'src/components/hooks/table/useSortedColumn';
import { Selectable, useSelectColumn } from 'src/components/hooks/table/useSelectColumn';
import { usePaginate } from 'src/components/hooks/usePaginate';
import { addUTCZoneOffset } from 'src/utils/date/addUTCZoneOffset';

import { InstancesTableTestId } from './constants';

type Props = {
  instances: Instance[];
  onSelect: (tableData: Selectable<Instance>[]) => void;
  loading: boolean;
};

export function InstancesTable(props: Props) {
  const { instances, onSelect, loading } = props;

  const tableData = useMemo(
    () =>
      instances.map((instance) => ({
        id: instance.id,
        name: instance.name,
        dateCreated: new Date(addUTCZoneOffset(instance.date_created)),
        dateModified: new Date(addUTCZoneOffset(instance.date_modified)),
      })),
    [instances],
  );
  const { sortedData, sortColumn, sortAsc, handleSort } = useSortedColumn({ tableData, initialSortColumn: 'name' });
  const { paginatedItems, currentPage, pagesNumber, setCurrentPage } = usePaginate({
    items: sortedData,
    pageSize: 10,
  });
  const [selectColumn, selectableTableData] = useSelectColumn(
    {
      tableData: paginatedItems,
      onSelect: (selectedIds) => {
        const newTableItems = instances.map((tableItem) => ({ ...tableItem, selected: selectedIds.has(tableItem.id) }));
        onSelect(newTableItems);
      },
    },
    [instances],
  );

  useEffect(() => {
    setCurrentPage(0);
  }, [instances]);

  return (
    <>
      <TableBuilder
        overrides={{ Root: { style: { maxHeight: '90%' } } }}
        data={selectableTableData}
        sortColumn={sortColumn}
        sortOrder={sortAsc ? 'ASC' : 'DESC'}
        onSort={handleSort}
        data-testid={InstancesTableTestId.Table}
        isLoading={loading}
      >
        {selectColumn}
        <TableBuilderColumn id="name" header="Name" sortable>
          {(row) => <span data-testid={InstancesTableTestId.InstanceName}>{row.name}</span>}
        </TableBuilderColumn>
        <TableBuilderColumn id="dateCreated" header="Date Created" sortable>
          {(row) => toLocalStringWithTimeZone(row.dateCreated, { timeZone: 'UTC' })}
        </TableBuilderColumn>
        <TableBuilderColumn id="dateModified" header="Date Modified" sortable>
          {(row) => toLocalStringWithTimeZone(row.dateModified, { timeZone: 'UTC' })}
        </TableBuilderColumn>
      </TableBuilder>
      <Pagination
        currentPage={!pagesNumber ? 0 : currentPage + 1}
        numPages={pagesNumber}
        onPageChange={({ nextPage }) => setCurrentPage(nextPage - 1)}
      />
    </>
  );
}
